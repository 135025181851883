import { Test1Result, Test2Result } from './M71.formulas';
import { calcHeight } from '../../../AFT_FireTestSection/Data/M71/M71.formulas';
import { dropdownPlaceHolder2, dropdownPlaceHolder3, dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';
import { AutomaticActivationFireSuppression, FunctionTestType } from 'journeys/portal/Forms/Sections.constants';
import { conditionalValueSelector } from 'helpers/Validation';

export const AsLeftFireTestSectionDataM71 = (formData) => {
    const automaticDiscardCheck = formData.F696AltTst1AutomaticActivationFireSuppression === AutomaticActivationFireSuppression.DISCARD;
    const manualDiscardCheck = formData.F697AltTst2ManualActivationFireSuppression === AutomaticActivationFireSuppression.DISCARD;
    const dryTestCheck = formData.F663FunctionTestType === FunctionTestType.DRY_TEST;
    const altDisabled = (formData?.F102AftResult === 'PASS' || formData?.F619AsLeftTestRequired === 'NO');
    const testPerform1 = conditionalValueSelector(manualDiscardCheck,
        'Perform', formData.F696AltTst1AutomaticActivationFireSuppression);
    const testPerform2 = conditionalValueSelector(automaticDiscardCheck,
        'Perform', formData.F697AltTst2ManualActivationFireSuppression);
    const manualDisableCheck = altDisabled || manualDiscardCheck;
    const automaticDisableCheck = altDisabled || automaticDiscardCheck;
    const autoDryTestCheck = altDisabled || dryTestCheck || automaticDiscardCheck;
    const manualDryTestCheck = altDisabled || dryTestCheck || manualDiscardCheck;
    const autoHeightVal = conditionalValueSelector(automaticDisableCheck,
        '1.24rem', '1.5rem');
    const manualHeightVal = conditionalValueSelector(manualDisableCheck,
        '1.24rem', '1.5rem');
    const borderRightCheck = conditionalValueSelector(manualDisableCheck, '', '0');
    const borderBottomCheck = altDisabled || conditionalValueSelector(dryTestCheck, '', '0');
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'As-Left Test',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F696AltTst1AutomaticActivationFireSuppression',
                label: 'Test 1 - Automatic Activation of Fire Suppression',
                value: altDisabled ? '' : testPerform1,
                xs: 6,
                isDisabled: altDisabled,
                readOnly: manualDisableCheck,
                isRequired: !altDisabled,
                labelWidth: conditionalValueSelector(manualDisableCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(manualDisableCheck,
                    '40.9%', '43%'),
                height: manualHeightVal,
                isDropdown: !altDisabled && !manualDiscardCheck,
                placeholder: dropdownPlaceHolder3,
                options: [{ name: dropdownPlaceHolder3, value: '' },
                { name: AutomaticActivationFireSuppression.DISCARD, value: AutomaticActivationFireSuppression.DISCARD },
                { name: 'Perform', value: 'Perform' }],
                marginLeft: '8px',
                inputMarginRight: '4px',
                inputTextAlign: 'center',
                textAlign: 'center',
                borderBottom: conditionalValueSelector(automaticDisableCheck, '', '0'),
            },
            {
                key: 'F697AltTst2ManualActivationFireSuppression',
                label: 'Test 2 - Manual Activation of Fire Suppression',
                value: conditionalValueSelector(altDisabled, '', testPerform2),
                isRequired: !altDisabled,
                isDropdown: !altDisabled && !automaticDiscardCheck,
                isDisabled: altDisabled,
                readOnly: automaticDisableCheck,
                placeholder: dropdownPlaceHolder3,
                options: [{ name: dropdownPlaceHolder3, value: '' },
                { name: AutomaticActivationFireSuppression.DISCARD, value: AutomaticActivationFireSuppression.DISCARD },
                { name: 'Perform', value: 'Perform' }],
                xs: 6,
                height: autoHeightVal,
                labelWidth: conditionalValueSelector(altDisabled, '66.5%', '64.5%'),
                width: conditionalValueSelector(automaticDisableCheck,
                    '42%', '43.5%'),
                marginRight: conditionalValueSelector(automaticDisableCheck,
                    '4px', '0.5rem'),
                direction: 'rtl',
                textAlign: 'center',
                inputTextAlign: 'center',
                inputMarginRight: '4px',
                borderRight: '0',
                borderBottom: borderRightCheck,
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F698AltTst1ActivatedFireSuppressionSystem',
                label: 'Activated Fire Suppression System',
                value: conditionalValueSelector(automaticDisableCheck,
                    '', formData.F698AltTst1ActivatedFireSuppressionSystem),
                xs: 6,
                isDropdown: !(automaticDisableCheck),
                isRequired: !(automaticDisableCheck),
                isDisabled: automaticDisableCheck,
                readOnly: automaticDisableCheck,
                labelWidth: conditionalValueSelector(automaticDisableCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(automaticDisableCheck,
                    '40.9%', '43%'),
                height: autoHeightVal,
                inputMarginRight: '4px',
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0',
            },
            {
                key: 'F699AltTst2ActivatedFireSuppressionSystem',
                label: 'Activated Fire Suppression System',
                value: conditionalValueSelector(manualDisableCheck,
                    '', formData.F699AltTst2ActivatedFireSuppressionSystem),
                isDropdown: !(manualDisableCheck),
                isRequired: !(manualDisableCheck),
                isDisabled: manualDisableCheck,
                readOnly: manualDisableCheck,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                height: manualHeightVal,
                labelWidth: conditionalValueSelector(manualDisableCheck,
                    '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDisableCheck,
                    '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDisableCheck,
                    '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: '0',
                borderRight: borderRightCheck
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F700AltTst1ConfirmDischargeValveOperatesCorrectly',
                label: 'Confirm Discharge Valve Operates Correctly',
                value: conditionalValueSelector(automaticDisableCheck,
                    '', formData.F700AltTst1ConfirmDischargeValveOperatesCorrectly),
                xs: 6,
                isDropdown: !(automaticDisableCheck),
                isRequired: !(automaticDisableCheck),
                isDisabled: automaticDisableCheck,
                readOnly: automaticDisableCheck,
                labelWidth: conditionalValueSelector(automaticDisableCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(automaticDisableCheck,
                    '40.9%', '43%'),
                height: altDisabled
                    ? '1.17rem' : calcHeight(automaticDiscardCheck, dryTestCheck),
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                inputMarginRight: '4px',
                borderBottom: borderBottomCheck,
            },
            {
                key: 'F701AltTst2ConfirmDischargeValveOperatesCorrectly',
                label: 'Confirm Discharge Valve Operates Correctly',
                value: manualDisableCheck ? '' : formData.F701AltTst2ConfirmDischargeValveOperatesCorrectly,
                isDropdown: !(manualDisableCheck),
                isRequired: !(manualDisableCheck),
                isDisabled: manualDisableCheck,
                readOnly: manualDisableCheck,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                height: altDisabled
                    ? '1.17rem' : calcHeight(manualDiscardCheck, dryTestCheck),
                labelWidth: conditionalValueSelector(manualDisableCheck,
                    '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDisableCheck,
                    '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDisableCheck,
                    '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: borderBottomCheck,
                borderRight: borderRightCheck
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'F702AltTst1ConfirmExtinguishantReleasedProtectedArea',
                label: 'Confirm Extinguishant Released to Protected Area',
                value: conditionalValueSelector(autoDryTestCheck,
                    '', formData.F702AltTst1ConfirmExtinguishantReleasedProtectedArea),
                xs: 6,
                isDisabled: autoDryTestCheck,
                isRequired: !(autoDryTestCheck),
                readOnly: autoDryTestCheck,
                isDropdown: !(autoDryTestCheck),
                labelWidth: conditionalValueSelector(autoDryTestCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(autoDryTestCheck,
                    '40.9%', '43%'),
                height: conditionalValueSelector(autoDryTestCheck,
                    '1.24rem', '1.5rem'),
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputMarginRight: '4px',
                inputTextAlign: 'center',
                borderBottom: '0',
            },
            {
                key: 'F703AltTst2ConfirmExtinguishantReleasedProtectedArea',
                label: 'Confirm Extinguishant Released to Protected Area',
                value: conditionalValueSelector(manualDryTestCheck,
                    '', formData.F703AltTst2ConfirmExtinguishantReleasedProtectedArea),
                isDisabled: manualDryTestCheck,
                readOnly: manualDryTestCheck,
                isRequired: !(manualDryTestCheck),
                isDropdown: !(manualDryTestCheck),
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                height: conditionalValueSelector(manualDryTestCheck,
                    '1.24rem', '1.5rem'),
                labelWidth: conditionalValueSelector(manualDryTestCheck,
                    '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDryTestCheck,
                    '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDryTestCheck,
                    '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: '0',
                borderRight: manualDisableCheck || conditionalValueSelector(dryTestCheck, '', '0')
            }]
        },
        {
            key: 7,
            fields: [{
                key: 'F704AltTst1ConfirmCorrectHmiIndication',
                label: 'Confirm Correct HMI Indication',
                value: conditionalValueSelector(automaticDisableCheck, '',
                    formData.F704AltTst1ConfirmCorrectHmiIndication),
                xs: 6,
                isDropdown: !(automaticDisableCheck),
                isRequired: !(automaticDisableCheck),
                isDisabled: automaticDisableCheck,
                readOnly: automaticDisableCheck,
                labelWidth: conditionalValueSelector(automaticDisableCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(automaticDisableCheck,
                    '40.9%', '43%'),
                height: autoHeightVal,
                placeholder: dropdownPlaceHolder2,
                options: [{ name: dropdownPlaceHolder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0',
                inputMarginRight: '4px',
            },
            {
                key: 'F705AltTst2ConfirmCorrectHmiIndication',
                label: 'Confirm Correct HMI Indication',
                value: conditionalValueSelector(manualDisableCheck, '',
                    formData.F705AltTst2ConfirmCorrectHmiIndication),
                isDropdown: !(manualDisableCheck),
                isRequired: !(manualDisableCheck),
                isDisabled: manualDisableCheck,
                readOnly: manualDisableCheck,
                placeholder: dropdownPlaceHolder2,
                options: [{ name: dropdownPlaceHolder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                xs: 6,
                height: manualHeightVal,
                labelWidth: conditionalValueSelector(manualDisableCheck,
                    '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDisableCheck, '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDisableCheck, '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: '0',
                borderRight: borderRightCheck
            }]
        },
        {
            key: 8,
            fields: [{
                key: 'F706AltTst1ConfirmLampsFGMatrixPanel',
                label: 'Confirm Lamps on F&G Matrix Panel',
                value: conditionalValueSelector(automaticDisableCheck,
                    '', formData.F706AltTst1ConfirmLampsFGMatrixPanel),
                xs: 6,
                isDropdown: !(automaticDisableCheck),
                isRequired: !(automaticDisableCheck),
                isDisabled: automaticDisableCheck,
                readOnly: automaticDisableCheck,
                labelWidth: conditionalValueSelector(automaticDisableCheck,
                    '65.5%', '64.5%'),
                width: conditionalValueSelector(automaticDisableCheck,
                    '40.9%', '43%'),
                height: autoHeightVal,
                placeholder: dropdownPlaceHolder2,
                options: [{ name: dropdownPlaceHolder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                marginLeft: '8px',
                inputMarginRight: '4px',
                inputTextAlign: 'center',
                borderBottom: '0',
            },
            {
                key: 'F707AltTst2ConfirmLampsFGMatrixPanel',
                label: 'Confirm Lamps on F&G Matrix Panel',
                value: conditionalValueSelector(manualDisableCheck,
                    '', formData.F707AltTst2ConfirmLampsFGMatrixPanel),
                isDropdown: !(manualDisableCheck),
                isRequired: !(manualDisableCheck),
                isDisabled: manualDisableCheck,
                readOnly: manualDisableCheck,
                placeholder: dropdownPlaceHolder2,
                options: [{ name: dropdownPlaceHolder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                xs: 6,
                height: manualHeightVal,
                labelWidth: conditionalValueSelector(manualDisableCheck, '66.5%', '64.5%'),
                width: conditionalValueSelector(manualDisableCheck, '41.5%', '43.5%'),
                marginRight: conditionalValueSelector(manualDisableCheck, '4px', '0.5rem'),
                inputMarginRight: '4px',
                direction: 'rtl',
                inputTextAlign: 'center',
                borderBottom: '0',
                borderRight: borderRightCheck
            }]
        },
        {
            key: 9,
            fields: [{
                key: 'F708AltTst1Result',
                label: 'Test 1 Result',
                value: conditionalValueSelector(automaticDisableCheck,
                    '', Test1Result(formData)),
                xs: 6,
                isDisabled: automaticDisableCheck,
                readOnly: true,
                isCalculatedNew: true,
                labelWidth: conditionalValueSelector(automaticDisableCheck, '65.5%',
                    '60.8%'),
                width: conditionalValueSelector(automaticDisableCheck, '40.9%', '39.5%'),
                textAlign: 'center',
                height: '1.24rem',
                marginLeft: '8px',
                inputMarginRight: '4px',
                marginRight: conditionalValueSelector(automaticDisableCheck, '0', '10px')
            },
            {
                key: 'F709AltTst2Result',
                label: 'Test 2 Result',
                isCalculatedNew: true,
                value: conditionalValueSelector(manualDisableCheck, '', Test2Result(formData)),
                xs: 6,
                isDisabled: manualDisableCheck,
                readOnly: true,
                labelWidth: conditionalValueSelector(manualDisableCheck,
                    '66.5%', '65.5%'),
                width: conditionalValueSelector(manualDisableCheck,
                    '41.5%', '42.5%'),
                marginRight: conditionalValueSelector(manualDisableCheck,
                    '4px', '0.5rem'),
                textAlign: 'center',
                direction: 'rtl',
                borderRight: borderRightCheck,
                inputMarginRight: '4px',
                height: '1.24rem',
            }]
        }]
    };
};
