export const SerialNumberDataP724 = (selectedTag, formData, formName) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F503SerialNumber',
            label: 'Serial Number',
            value: formData.F503SerialNumber,
            width: '63.3%',
            labelWidth: '36.2%',
            xs: 6,
            marginBottom: '5px',
            marginTop: '8px',
            maxLength: '30',
            height: '1.2rem'
        },
        {
            key: 'F504DescriptionofService',
            label: 'Description of Service',
            value: formData.F504DescriptionofService,
            marginLeft: '2rem',
            width: '66.5%',
            labelWidth: '32%',
            xs: 6,
            marginBottom: '5px',
            marginTop: '8px',
            maxLength: '30',
            height: '1.2rem',
        }]
    }]
});

export const selectSerialNumberSectionData = (selectedTag, formName, formData) => {
    let filterViewData = { rows: [] };
    if (formName === 'P7-24') {
        filterViewData = SerialNumberDataP724(selectedTag, formData, formName);
    }
    return filterViewData;
};
