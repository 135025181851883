import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import FormInput from 'elements/Inputs/FormInput/Input';
import { StyledLabel } from 'elements/Label/Label';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { selectValveSizeTravelSectionData } from './ValveSizeTravelSection.data';
import { StyleDropdown } from '../AsFoundTestTableSection/AsFoundTestSection.styled';
import { DatePickerForForm } from 'elements/Inputs/DatePicker/DatepickerForForm';

const ValveSizeTravelSection = (props) => {
    const { selectedTag, changeHandler, formName, formData, isFormReadOnly, userRole } = props;
    const filterViewData = selectValveSizeTravelSectionData(selectedTag, formName, formData);
    const historicalDate = formData?.F963DateHistoricalValveTimingUsed ? formData?.F963DateHistoricalValveTimingUsed : null;
    const styleInput = (field) => field.isLabel ? (
        <StyledLabel
            id={field.key}
            label={field.label}
            fontWeight={field.fontWeight}
            fontSize={field.fontSize}
            textAlign={field.textAlign}
            labelWidth={field.labelWidth}
            padding={field.padding}
            color={field.color}
            marginTop={field.marginTop}
            marginLeft={field.marginLeft}
        >
            {field.label}
        </StyledLabel>
    )
        : (
            <FormInput
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                isRequired={field.isRequired}
                handleChange={changeHandler}
                marginLeft={field.marginLeft}
                width={field.width}
                type={field.type}
                isCalculatedNew={field.isCalculatedNew}
                labelWidth={field.labelWidth}
                direction={field.direction}
                marginRight={field.marginRight}
                readOnly={isFormReadOnly || field.readOnly}
                height={field.height}
                marginTop={field.marginTop}
                textAlign={field.textAlign}
                hasDPSError={field.hasDPSError}
                maxLength={field.maxLength}
                userRole={userRole}
                textAlignLabel={field.textAlignLabel}
                borderLeft={field.borderLeft}
                borderRight={field.borderRight}
                isDisabled={field.isDisabled}
                borderBottom={field.borderBottom}
                borderTop={field.borderTop}
            />
        );
        const datepickerField = (field) => field.isDatefield ? (
                <DatePickerForForm
                    id={field.key}
                    name={field.key}
                    value={historicalDate}
                    width={field.width}
                    isDisabled={field.isDisabled}
                    height={field.height}
                    onChange={(event) => changeHandler({ F963DateHistoricalValveTimingUsed: event })}
                    formName={field.key}
                    marginLeft={field.marginLeft}
                    marginRight={field.marginRight}
                    fontSize={field.fontSize}
                    marginBottom={field.marginBottom}
                    isRequired={field.isRequired}
                    textAlign={field.textAlign}
                />
        )
            : styleInput(field);
    return (
        <Grid container spacing={1}>
            {
                filterViewData.rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingBottom: '0', paddingTop: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isDropdown
                                    ? (
                                        <StyleDropdown
                                            formName={formName}
                                        >
                                            <FormDropdown
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...field}
                                                id={field.key}
                                                name={field.key}
                                                handleChange={changeHandler}
                                                readOnly={isFormReadOnly || field.readOnly}
                                            />
                                        </StyleDropdown>
                                    )
                                    : datepickerField(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ValveSizeTravelSection);

ValveSizeTravelSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
