import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataM67 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'DetectorReinstatement',
            label: 'Detector reinstatement',
            isLabel: true,
            xs: 12,
            fontWeight: '700'
        }]
    },
    {
        key: 2,
        fields: [
            {
                key: 'F382HmistatusAfterTest',
                label: 'HMI Status after test',
                value: formData.F382HmistatusAfterTest,
                isRequired: true,
                isDropDown: true,
                placeholder: Placeholder.HMI_STATUS_PLACEHOLDER,
                options: [
                    { name: Placeholder.HMI_STATUS_PLACEHOLDER, value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                xs: 5,
                inputTextAlign: 'center',
                width: '48.8%',
                labelWidth: '50%',
                marginRight: '0.5rem',
                direction: 'rtl'
            },
            {
                key: 'nodate',
                isLabel: true,
                xs: 1
            },
            {
                key: 'F85TestResult',
                label: 'Test Result',
                value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                isRequired: true,
                isDropDown: true,
                placeholder: 'Select Test Result',
                options: TestResultDropdownOptions(formData),
                xs: 6,
                labelWidth: '18rem',
                width: '51.8%',
                marginRight: '0.5rem',
            }
        ]
    }
    ]
});
