import { AsLeftResultFormulaP611 } from './P611.formulas';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsLeftTestTableSectionDataP611 = (selectedTag, formData) => ({
        rows: [{
            key: 1,
            fields: [{
                key: 'As-LeftTest',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F443AltForcedOutputsignal',
                label: 'Forced Trip Signal',
                value: formData.F102AftResult !== 'PASS' ? formData.F443AltForcedOutputsignal : '',
                isRequired: formData.F102AftResult !== 'PASS',
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                xs: 6,
                labelWidth: '51%',
                width: formData.F102AftResult !== 'PASS' ? '49%' : '47.5%',
                isDropdown: formData.F102AftResult !== 'PASS',
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F444AltConfirmContactorOperates',
                label: 'Confirm Contactor Operates Correctly',
                value: formData.F102AftResult !== 'PASS' ? formData.F444AltConfirmContactorOperates : '',
                isRequired: formData.F102AftResult !== 'PASS',
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                isDropdown: formData.F102AftResult !== 'PASS',
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                labelWidth: '51%',
                width: formData.F102AftResult !== 'PASS' ? '49%' : '47.5%',
                height: formData.F102AftResult !== 'PASS' ? '1.5rem' : '1.2rem',
                marginLeft: '8px',
                inputTextAlign: 'center',
                xs: 6,
                borderBottom: '0'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F579AltConfirmLampsSwitchCorrectly',
                label: 'Confirm Lamps Switch Correctly',
                value: formData.F102AftResult !== 'PASS' ? formData.F579AltConfirmLampsSwitchCorrectly : '',
                isRequired: formData.F102AftResult !== 'PASS',
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                isDropdown: formData.F102AftResult !== 'PASS',
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                labelWidth: '51%',
                width: formData.F102AftResult !== 'PASS' ? '49%' : '47.5%',
                height: formData.F102AftResult !== 'PASS' ? '1.5rem' : '1.2rem',
                marginLeft: '8px',
                inputTextAlign: 'center',
                xs: 6,
                borderBottom: '0'
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F445AltConfirmcorrectHmiindication',
                label: 'Confirm Correct HMI Indication ',
                value: formData.F102AftResult !== 'PASS' ? formData.F445AltConfirmcorrectHmiindication : '',
                isDisabled: formData.F102AftResult === 'PASS',
                isDropdown: formData.F102AftResult !== 'PASS',
                isRequired: formData.F102AftResult !== 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                xs: 6,
                labelWidth: '51%',
                width: formData.F102AftResult !== 'PASS' ? '49%' : '47.5%',
                height: formData.F102AftResult !== 'PASS' ? '' : '1.4rem',
                marginLeft: '8px',
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                inputTextAlign: 'center',
                marginBottom: '4px',
                borderBottom: '0',
            },
            {
                key: 'F103AltResult',
                label: 'As-Left Result',
                value: formData.F102AftResult !== 'PASS' ? AsLeftResultFormulaP611(formData) : '',
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: true,
                isCalculatedNew: true,
                textAlign: 'center',
                height: '1.26rem',
                borderRight: '0',
                marginRight: '8px',
                direction: 'rtl',
                inputMarginRight: '4px',
                isLabelBold: true,
                fontWeight: '700',
                xs: 6,
                width: '50.2%',
                labelWidth: '49.8%',
                borderBottom: '0',
            }]
        }]
    });
