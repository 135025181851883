import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const AsFoundTestTableF61AsFoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F338PhysicalValvePositionPriortotest) === 'Intermediate'
        || FieldHasValue(formData.F338PhysicalValvePositionPriortotest) === 'Open') {
        return 'Fail';
    }
    if (FieldHasValue(formData.F733AftValveActionUponTrip) === 'Failed to Open') {
        return 'Fail';
    }
    if (!FieldHasValue(formData.F344ValveTravelTimeRequirement)
        && FieldHasValue(formData.F733AftValveActionUponTrip) === 'Fully Opened') {
        return 'Pass';
    }
    if (FieldHasValue(formData.F344ValveTravelTimeRequirement)
        && FieldHasValue(formData.F733AftValveActionUponTrip)
        && FieldHasValue(formData.F735AftMeasuredValveTravelTime)) {
        if (formData.F733AftValveActionUponTrip === 'Fully Opened'
            && ValidateNumberField(formData.F735AftMeasuredValveTravelTime) <= ValidateNumberField(formData.F344ValveTravelTimeRequirement)) {
            return 'Pass';
        }
        return 'Fail';
    }
    return '';
};

export const isDisabledAsFoundFields = (formData) => (
    FieldHasValue(formData.F338PhysicalValvePositionPriortotest) === 'Intermediate'
    || FieldHasValue(formData.F338PhysicalValvePositionPriortotest) === 'Open');
