import { FieldHasValue, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';

export const AsLeftResultFormula = (formData) => {
    if (FieldHasValue(formData.F139AltGaugeReading1)
        && FieldHasValue(formData.F140AltGaugeReading2)
        && FieldHasValue(formData.F141AltGaugeReading3)
        && FieldHasValue(formData.F161AltTransmitterReading1)
        && FieldHasValue(formData.F162AltTransmitterReading2)
        && FieldHasValue(formData.F163AltTransmitterReading3)
        && FieldHasValue(formData.F109AltHmireading1)
        && FieldHasValue(formData.F113AltHmireading2)
        && FieldHasValue(formData.F114AltHmireading3) && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F78Deviation2Eu1), ValidateNumberField(formData.F79Deviation2Eu2), ValidateNumberField(formData.F80Deviation2Eu3)])
            <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AsLeftTableF78Deviation2Eu1Formula = (formData) => FieldHasValue(formData.F139AltGaugeReading1) && FieldHasValue(formData.F161AltTransmitterReading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F139AltGaugeReading1) - ValidateNumberField(formData.F161AltTransmitterReading1))) : '';
export const AsLeftTableF79Deviation2Eu2Formula = (formData) => FieldHasValue(formData.F140AltGaugeReading2) && FieldHasValue(formData.F162AltTransmitterReading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F140AltGaugeReading2) - ValidateNumberField(formData.F162AltTransmitterReading2))) : '';
export const AsLeftTableF80Deviation2Eu3Formula = (formData) => FieldHasValue(formData.F141AltGaugeReading3) && FieldHasValue(formData.F163AltTransmitterReading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F141AltGaugeReading3) - ValidateNumberField(formData.F163AltTransmitterReading3))) : '';
