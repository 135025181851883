import {
    AsFoundTestTableDeviationEu1Formula,
    AsFoundTestTableDeviationEu2Formula,
    AsFoundTestTableDeviationEu3Formula
} from './P63.formula';
import {
    HmiInst1FormulaP63,
    HmiInst2FormulaP63, HmiInst3FormulaP63
} from '../Formulas';
import { paddingAF } from '../../../AsLeftTestTableSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataP63andP632 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsFoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'HMI-Inst',
                label: '% - Inst.',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                padding: '5px 0 5px 8px',
                fontSize: '9pt'
            },
            {
                key: 'TestDeviceIndication',
                label: 'Test Device Indication',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'TX Reading',
                label: 'TX Reading',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'HMI Reading',
                label: 'HMI Reading',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'mA Reading',
                label: 'mA Reading',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Deviation (EU)',
                label: 'Deviation (EU)',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F203AftHmiInst1',
                value: HmiInst1FormulaP63(formData).length > 255
                    ? HmiInst1FormulaP63(formData).slice(0, 255) : HmiInst1FormulaP63(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F320AftRtdSimulatorReading1',
                value: formData.F320AftRtdSimulatorReading1,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0'
            },
            {
                key: 'F209AftTxreading1',
                value: formData.F209AftTxreading1,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                borderLeft: '0'
            },
            {
                key: 'F106AftHmireading1',
                value: formData.F106AftHmireading1,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F212AftMAreading1',
                value: formData.F212AftMAreading1,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
            },
            {
                key: 'F215AftDeviation1',
                value: AsFoundTestTableDeviationEu1Formula(formData),
                readOnly: true,
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F204AftHmiInst2',
                value: HmiInst2FormulaP63(formData).length > 255
                    ? HmiInst2FormulaP63(formData).slice(0, 255) : HmiInst2FormulaP63(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F321AftRtdSimulatorReading2',
                value: formData.F321AftRtdSimulatorReading2,
                isRequired: true,
                maxLength: '10',
                xs: 2,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0'
            },
            {
                key: 'F210AftTxreading2',
                value: formData.F210AftTxreading2,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                borderLeft: '0'
            },
            {
                key: 'F107AftHmireading2',
                value: formData.F107AftHmireading2,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F213AftMAreading2',
                value: formData.F213AftMAreading2,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0'
            },
            {
                key: 'F216AftDeviation2',
                value: AsFoundTestTableDeviationEu2Formula(formData),
                xs: 2,
                readOnly: true,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px'
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F205AftHmiInst3',
                value: HmiInst3FormulaP63(formData).length > 255
                    ? HmiInst3FormulaP63(formData).slice(0, 255) : HmiInst3FormulaP63(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                marginLeft: '4px'
            },
            {
                key: 'F322AftRtdSimulatorReading3',
                value: formData.F322AftRtdSimulatorReading3,
                isRequired: true,
                maxLength: '10',
                xs: 2,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem'
            },
            {
                key: 'F211AftTxreading3',
                value: formData.F211AftTxreading3,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0'
            },
            {
                key: 'F108AftHmireading3',
                value: formData.F108AftHmireading3,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F214AftMAreading3',
                value: formData.F214AftMAreading3,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0'
            },
            {
                key: 'F217AftDeviation3',
                value: AsFoundTestTableDeviationEu3Formula(formData),
                xs: 2,
                readOnly: true,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                inputMarginRight: '4px'
            }]
        }
    ]
});
