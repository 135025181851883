export const TestResultSectionDataP710andP68 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F110HmireadingAftertheTest',
                    label: 'HMI Reading After the Test',
                    value: formData.F110HmireadingAftertheTest,
                    isRequired: true,
                    xs: 4,
                    padding: '0',
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '50%',
                    width: '50%',
                    direction: 'rtl',
                    textAlign: 'center'
                },
                {
                    key: 'Nodata4',
                    label: '',
                    xs: 1,
                    isLabel: true
                },
                {
                    key: 'F190ProcessTxreadingAftertheTestLabel',
                    label: 'Process TX Reading After the Test',
                    xs: 3,
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '5px 0 0 0',
                    labelWidth: '97%',
                    textAlign: 'end',
                    isLabel: true,
                },
                {
                    key: 'F190ProcessTxreadingAftertheTest',
                    value: formData.F190ProcessTxreadingAftertheTest,
                    isRequired: true,
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    marginLeft: '0.8px'
                }
            ]
        }]
});
