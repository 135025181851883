import { FieldHasValue } from 'helpers/Validation';
import { AlarmTestResult } from 'journeys/portal/Forms/Sections.constants';

export const AftLowAlarmTestResultFormula = (formData) => {
    if (FieldHasValue(formData.F822AftLowAlarmTest) && FieldHasValue(formData.F372AftHmistatus1)) {
        if (formData.F822AftLowAlarmTest === AlarmTestResult.PERFORM && formData.F372AftHmistatus1 === 'LOW ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AftHighAlarmTestResultFormula = (formData) => {
    if (FieldHasValue(formData.F824AftHighAlarmTest) && FieldHasValue(formData.F373AftHmistatus2)) {
        if (formData.F824AftHighAlarmTest === AlarmTestResult.PERFORM && formData.F373AftHmistatus2 === 'HIGH ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AftBeamblockTestResultFormula = (formData) => {
    if (FieldHasValue(formData.F826AftBeamBlockTest) && FieldHasValue(formData.F827AftHmiStatus)) {
        if (formData.F826AftBeamBlockTest === AlarmTestResult.PERFORM && formData.F827AftHmiStatus === 'FAULT') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AsFoundResultFormula = (formData) => {
    const fields = [
        { test: 'F823AftLowAlarmTestResult', discard: 'F824AftHighAlarmTest', discard2: 'F826AftBeamBlockTest' },
        { test: 'F825AftHighAlarmTestResult', discard: 'F822AftLowAlarmTest', discard2: 'F826AftBeamBlockTest' },
        { test: 'F823AftLowAlarmTestResult', test2: 'F825AftHighAlarmTestResult', discard: 'F826AftBeamBlockTest' },
        { test: 'F825AftHighAlarmTestResult', test2: 'F828AftBeamBlockTestResult', discard: 'F822AftLowAlarmTest' },
        { test: 'F823AftLowAlarmTestResult', test2: 'F828AftBeamBlockTestResult', discard: 'F824AftHighAlarmTest' },
        { test: 'F823AftLowAlarmTestResult', test2: 'F825AftHighAlarmTestResult', test3: 'F828AftBeamBlockTestResult' }
    ];

    for (const item of fields) {
        const { test, test2, test3, discard, discard2 } = item;
        if (
            (FieldHasValue(formData[test]) && formData[discard] === AlarmTestResult.DISCARD && formData[discard2] === AlarmTestResult.DISCARD)
            || (FieldHasValue(formData[test]) && FieldHasValue(formData[test2]) && formData[discard] === AlarmTestResult.DISCARD)
            || (FieldHasValue(formData[test]) && FieldHasValue(formData[test2]) && FieldHasValue(formData[test3]))
        ) {
            if (formData[test] === 'FAIL' || formData[test2] === 'FAIL' || formData[test3] === 'FAIL') {
                return 'FAIL';
            }
            return 'PASS';
        }
    }

    return '';
};
