import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const AsLeftResultFormulaP77 = (formData) => {
    if (FieldHasValue(formData.F228AltIndependentReading1)
        && FieldHasValue(formData.F229AltIndependentReading2)
        && FieldHasValue(formData.F230AltIndependentReading3)
        && FieldHasValue(formData.F231AltTxreading1)
        && FieldHasValue(formData.F232AltTxreading2)
        && FieldHasValue(formData.F233AltTxreading3)
        && FieldHasValue(formData.F109AltHmireading1)
        && FieldHasValue(formData.F113AltHmireading2)
        && FieldHasValue(formData.F114AltHmireading3)
        && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F237AltDeviation1),
        ValidateNumberField(formData.F238AltDeviation2),
        ValidateNumberField(formData.F239AltDeviation3)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }

    return '';
};

export const highSetPointBorderBottom = (selectedTag) => (selectedTag?.HighHighSetpoint && !selectedTag.LowLowSetpoint);

export const highSetPointRequiredField = (selectedTag) => (selectedTag?.HighHighSetpoint
    || (selectedTag?.HighHighSetpoint && selectedTag?.LowLowSetpoint)
    || !(selectedTag?.HighHighSetpoint && selectedTag?.LowLowSetpoint));

export const lowSetPointRequiredField = (selectedTag) => (selectedTag?.LowLowSetpoint
    || (selectedTag?.HighHighSetpoint && selectedTag?.LowLowSetpoint)
    || !(selectedTag?.HighHighSetpoint && selectedTag?.LowLowSetpoint));

export const isHighSetpointDisabled = (formData) => (FieldHasValue(formData?.F241LowSetpoint)
    || FieldHasValue(formData?.F243IndependentLevelReading2)
    || FieldHasValue(formData?.F49HmiReading2)
    || FieldHasValue(formData?.F491IndependentFlowReading2)
    || FieldHasValue(formData?.F471IndependentFlowReading2)
    || FieldHasValue(formData?.F467FlowTxReading2))
    && !(FieldHasValue(formData?.F240HighSetpoint)
        || FieldHasValue(formData?.F242IndependentLevelReading1)
        || FieldHasValue(formData?.F48HmiReading1)
        || FieldHasValue(formData?.F490IndependentFlowReading1)
        || FieldHasValue(formData?.F470IndependentFlowReading1)
        || FieldHasValue(formData?.F466FlowTxReading1));

export const isLowSetpointDisabled = (formData) => (FieldHasValue(formData?.F240HighSetpoint)
    || FieldHasValue(formData?.F242IndependentLevelReading1)
    || FieldHasValue(formData?.F48HmiReading1)
    || FieldHasValue(formData?.F490IndependentFlowReading1)
    || FieldHasValue(formData?.F470IndependentFlowReading1)
    || FieldHasValue(formData?.F466FlowTxReading1))
    && !(FieldHasValue(formData?.F241LowSetpoint)
        || FieldHasValue(formData?.F243IndependentLevelReading2)
        || FieldHasValue(formData?.F49HmiReading2)
        || FieldHasValue(formData?.F491IndependentFlowReading2)
        || FieldHasValue(formData?.F471IndependentFlowReading2)
        || FieldHasValue(formData?.F467FlowTxReading2));

export const TestModuleReading1Required = (selectedTag, formData) => selectedTag?.HighHighSetpoint
    || (!selectedTag?.HighHighSetpoint && !selectedTag?.F241LowSetpoint)
    || !(FieldHasValue(formData?.F241LowSetpoint)
        && FieldHasValue(formData?.F243IndependentLevelReading2)
        && FieldHasValue(formData?.F49HmiReading2)
        && FieldHasValue(formData?.F470IndependentFlowReading1)
        && FieldHasValue(formData?.F466FlowTxReading1));

export const TestModuleReading2Required = (selectedTag, formData) => selectedTag?.LowLowSetpoint
    || (!selectedTag?.LowLowSetpoint && !selectedTag?.F240HighSetpoint)
    || !(FieldHasValue(formData?.F240HighSetpoint)
        && FieldHasValue(formData?.F242IndependentLevelReading1)
        && FieldHasValue(formData?.F48HmiReading1)
        && FieldHasValue(formData?.F467FlowTxReading2)
        && FieldHasValue(formData?.F471IndependentFlowReading2));
