import { FoundResultF62SensorTrimNeedFormula } from '../Formulas';
import { FoundResultF61AsFoundResultFormula } from './P75.formulas';

export const FoundResultSectionDataP75 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'ToleranceLabel',
            value: 'Tolerance (EU)',
            xs: 2,
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            borderRight: '0',
            borderBottom: '0',
            marginLeft: '4px',
            borderLeft: '0',
            isLabelNew: true,
            marginBottom: '4px'
        },
        {
            key: 'F60ToleranceEu',
            value: selectedTag.DateTested ? formData?.F60ToleranceEu : formData?.F60ToleranceEu ?? selectedTag?.ToleranceInInstrumentEu,
            type: 'number',
            xs: 2,
            isRequired: true,
            width: '100%',
            labelWidth: '0',
            maxLength: '10',
            textAlign: 'center',
            height: '1.1rem',
            borderBottom: '0',
            borderRight: '0',
        },
        {
            key: 'AftResultLabel',
            value: 'As Found Result',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            xs: 2,
            borderRight: '0',
            borderBottom: '0',
            isLabelBold: true,
            textAlign: 'end'
        },
        {
            key: 'F61AsFoundResult',
            value: FoundResultF61AsFoundResultFormula(formData),
            readOnly: true,
            isCalculatedNew: true,
            isRequired: false,
            xs: 2,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.1rem',
            borderRight: '0',
            borderBottom: '0'
        },
        {
            key: 'F62SensorTrimNeedLabel',
            value: 'Sensor Trim Needed?',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            xs: 2,
            borderBottom: '0',
            borderRight: '0',
            isLabelBold: true,
            textAlign: 'end'
        },
        {
            key: 'F62SensorTrimNeed',
            value: FoundResultF62SensorTrimNeedFormula(formData),
            readOnly: true,
            isCalculatedNew: true,
            xs: 2,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.1rem',
            inputMarginRight: '4px',
            borderBottom: '0',
            borderRight: '0'
        }]
    }]
});
