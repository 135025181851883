const dateObj = new Date();
export const moSelectionInitialState = {
    moDataAll: [],
    totalCount: 0,
    filteredMoData: [],
    searchCriteria: {
        moNumber: '',
        moDescription: '',
        functionalLocation: ''
    },
    searchedMoData: [],
    startDate: new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - 30),
    endDate: new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() + 35)
};
