import styled from 'styled-components';

export const ErrorText = styled.div`
font-family: "Segoe UI", "Helvetica", "Arial", sans-serif;
font-size: 20px;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 25vh;
padding: 25px;
`;
