import styled from 'styled-components';

export const RefreshSVGButton = styled.svg`
font-size: 13pt;
cursor: pointer;
 &:hover {
    path {
    stroke: #335B03;
    }
}
padding-right: 18px;
`;
