import { moSelectionInitialState } from './MOSelection.data';
import { MOACTIONS } from './MOSelection.constants';

export default function MOSelectionReducer(state = moSelectionInitialState, action) {
    switch (action.type) {
        case MOACTIONS.GET_MORECORDS_PENDING:
        case MOACTIONS.GET_MORECORDS_SUCCESS:
        case MOACTIONS.GET_MORECORDS_FAILURE:
        case MOACTIONS.SET_MORECORD_ROW:
        case MOACTIONS.GET_SORTMORECORDS_PENDING:
        case MOACTIONS.GET_SORTMORECORDS_SUCCESS:
        case MOACTIONS.GET_SORTMORECORDS_FAILURE:
        case MOACTIONS.SET_DATE_FOR_MO_SCREEN:
        case MOACTIONS.SET_DATE_FOR_MO_SCREEN_FAILURE:
        case MOACTIONS.SET_DATE_FOR_MO_SCREEN_SUCCESS:
        case MOACTIONS.SET_SELECTED_DATE:
        case MOACTIONS.FILTER_MO_DATA_ON_DATE_SELECTION:
        case MOACTIONS.RESET_DATE_FOR_MO_SCREEN_SUCCESS:
        case MOACTIONS.GET_SEARCHMORECORDS_PENDING:
        case MOACTIONS.GET_SEARCHMORECORDS_SUCCESS:
        case MOACTIONS.GET_SEARCHMORECORDS_FAILURE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
