import { FieldHasValue } from 'helpers/Validation';

export const F103AltResultFormula = (formData) => {
    if (!FieldHasValue(formData.F947AltSimulatedSandPresence) || !FieldHasValue(formData.F949AltObservedNoticeableSpike)) {
        return '';
    }
    if (FieldHasValue(formData.F947AltSimulatedSandPresence) === 'YES' && FieldHasValue(formData.F949AltObservedNoticeableSpike) === 'YES') {
        return 'PASS';
    }
    return 'FAIL';
};

export const isAltRawValuePeakReadingRequired = (formData) => {
  const isRawValueFilled = FieldHasValue(formData?.F946AltRawValuePeakReading);
  const isSandRateFilled = FieldHasValue(formData?.F948AltSandRatePeakReading);
  return (!isRawValueFilled && !isSandRateFilled) || (isRawValueFilled && isSandRateFilled) || (isRawValueFilled && !isSandRateFilled);
};

export const isAltSandRatePeakReadingRequired = (formData) => {
  const isRawValueFilled = FieldHasValue(formData?.F946AltRawValuePeakReading);
  const isSandRateFilled = FieldHasValue(formData?.F948AltSandRatePeakReading);
  return (!isRawValueFilled && !isSandRateFilled) || (isRawValueFilled && isSandRateFilled) || (!isRawValueFilled && isSandRateFilled);
};
