export const testEquipmentInitialState = {
    addTestEquipmentData: {},
    editTestEquipmentData: {},
    testEquipmentTableData: [],
    searchStringForTestEq: '',
    totalCount: 0,
    unSavedChangesTE: false,
    testEquipmentDataAll: [],
    sortStatusAscOrder: true,
    sortDeviceTypeAscOrder: true,
    sortColumn: ''
};
export const testEquipmentFormState = (selectedAssetName, regionName) => ({
    Region: regionName.toUpperCase(),
    Asset: selectedAssetName.toUpperCase(),
    DeviceType: '',
    TestEqManufacturer: '',
    TestEqSn: '',
    CreatedBy: '',
    ModifiedBy: '',
    CreatedDate: '',
    LastModifiedDate: '',
    TestEqModel: '',
    CalDueDate: '',
    Status: 'Active',
    Notes: ''
});
