import { strEquals } from 'journeys/portal/Admin/AdminScreen.helper';

let deleteIcons = false;
export const getAltDeleteIconStatus = () => deleteIcons;

export const showAltDeleteIcons = (testPoint, previousVal) => {
    deleteIcons = previousVal !== testPoint;
    return deleteIcons;
};

export const hideAltDeleteIcons = () => {
    deleteIcons = false;
    return deleteIcons;
};

export const disableAsLeftWithSensorTrimVal = (asFoundResult, sensorTrimNeeded) => strEquals(asFoundResult, 'PASS')
    && strEquals(sensorTrimNeeded, 'NO');

export const disableAsLeft = (asFoundResult) => strEquals(asFoundResult, 'PASS');

export const isAsLeftTestRequired = (asFoundResult, asLeftTestRequired) => strEquals(asFoundResult, 'PASS') || strEquals(asLeftTestRequired, 'NO');
