import React from 'react';
import { FormControl, InputAdornment } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { DatePicker as DatePickerElement, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Colors } from 'helpers/Colors';
import PropTypes from 'prop-types';
import { FormControlDiv, FormTextField } from './DatePicker.styled';

const themeDark = createTheme({
  palette: {
    primary: { main: Colors.green },
    background: {
      default: '#000000'
    },
    text: {
      primary: '#000000'
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        height: '2px',
        cursor: 'pointer'
      }
    }
  }
});
const bgColor = (value, isRequired) => {
  if (isRequired && value) {
    return 'rgb(255, 229, 175)';
  }
  if (isRequired) {
    return 'rgb(255, 255, 0)';
  }
  return 'rgb(255, 255, 255)';
};
export const DatePickerForForm = ({ name, width, fontSize, value, onChange, marginBottom, isRequired, textAlign }) => (
  <div style={{ marginBottom: marginBottom || '10px' }}>
    <FormControlDiv>
      <FormControl style={{ width: '100%', display: 'contents' }}>
        <FormTextField style={{ textAlign: 'center' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={themeDark}>
              <DatePickerElement
                id="date-picker-inline"
                name={name}
                variant="inline"
                value={value}
                onChange={onChange}
                autoOk
                invalidDateMessage={null}
                inputVariant="outlined"
                format="dd-MMM-yyyy"
                keyboard
                isRequired={isRequired}
                InputAdornmentProps={{ position: 'start' }}
                textAlign={textAlign}
                InputProps={{
                  style: {
                    height: '25px',
                    fontSize: '10pt',
                    width: width || '55%',
                    borderStyle: 'solid',
                    borderColor: 'rgb(120,120,120)',
                    borderWidth: '2px',
                    marginLeft: '0',
                    cursor: 'pointer',
                    backgroundColor: bgColor(value, isRequired),
                    marginTop: '10px',
                    overflowY: 'hidden',
                    textAlign: 'center'
                  },
                  name,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayIcon style={{ fontSize: fontSize || '18pt' }} />
                    </InputAdornment>
                  )
                }}
              />
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </FormTextField>
      </FormControl>
    </FormControlDiv>
  </div>
);

DatePickerForForm.propTypes = {
  fontSize: PropTypes.string,
  isRequired: PropTypes.bool,
  marginBottom: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  textAlign: PropTypes.string,
  value: PropTypes.any,
  width: PropTypes.string
};
