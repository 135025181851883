export const VisualInspectSectionDataP811 = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect instrument tagging, tubing/fittings/ manifold integrity, instrument wiring connection/ terminations, 
                instrument condition, general deterioration and no leak sign`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit',
                marginTop: formName === 'P8-7.1' ? '15px' : '',
            }]
        }
    ]
});
