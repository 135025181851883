import { FieldHasValue } from 'helpers/Validation';

export const UnitConverterMmToMil = (formData) => {
    if (FieldHasValue(formData.F766UnitMmValue)) {
        return (((formData.F766UnitMmValue) / (0.0254)).toFixed(2));
    }
    return '';
};
export const UnitConverterMilToMm = (formData) => {
    if (FieldHasValue(formData.F767UnitMilValue)) {
        return (((formData.F767UnitMilValue) / (1000) * 25.4).toFixed(2));
    }
    return '';
};
