import React from 'react';
import { useMsal } from '@azure/msal-react';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';

export const LoginComp = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect();
    };
    return (
        <SiteWrapper>
            <div>
                Login LoginComp
            </div>
            <div>
                <button type="button" onClick={() => handleLogin()}>Login using Redirect</button>
            </div>
            <div>
                <button type="button" onClick={() => instance.logout()}>logout</button>
            </div>
        </SiteWrapper>
    );
};
