import { ValidateNumberField, FieldHasValue } from 'helpers/Validation';

export const AsFoundTestTableM613Formula = (formData) => {
    if (formData.F364DamperFullStrokePositionIndicationAft === 'OPEN' || formData.F364DamperFullStrokePositionIndicationAft === 'INTERMEDIATE') {
        return 'FAIL';
    }
    if (FieldHasValue(formData.F363DamperTravelTimeRequirement) && (formData.F364DamperFullStrokePositionIndicationAft !== 'SELECT DAMPER POSITION')
        && FieldHasValue(formData.F365DamperFullStrokeTravelTimeAft)) {
        if (formData.F364DamperFullStrokePositionIndicationAft === 'CLOSE' && (ValidateNumberField(formData.F365DamperFullStrokeTravelTimeAft)
            <= ValidateNumberField(formData.F363DamperTravelTimeRequirement))) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
