import styled from 'styled-components';

export const DateSelection = styled.div`
display: flex;
justify-content: space-between;
`;

export const DatePickerStyle = styled.div`
margin-top: 8px;
`;

export const DateFilter = styled.div`
padding: 5px;
`;

export const AllDateButton = styled.div`
button {
    border-color: transparent;
    background-color: rgb(73, 130, 5);
    color: #FFFFFF;
    border-radius: 20px;
    width: 100%;
    margin: 2px;
    height: 35px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    &:hover{
            background-color: rgb(65, 117, 4);
}
`;

export const DateButton = styled.div`
button {
    border-color: transparent;
    background-color: rgb(73, 130, 5);
    color: #FFFFFF;
    border-radius: 20px;
    margin-bottom: 2px;
    width: 33.3%;
    height: 35px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    &:hover{
            background-color: rgb(65, 117, 4);
}
`;
