import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const FailureModeSectionDataP711 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F787MeasuredSpecificGravityLabel',
                label: 'Measured Specific Gravity (SG) of Fluid',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                marginTop: '20px',
            },
            {
                key: 'F787MeasuredSpecificGravity',
                value: formData.F787MeasuredSpecificGravity,
                xs: 2,
                labelWidth: '0%',
                textAlign: 'center',
                width: '100%',
                height: '1.2rem',
                marginTop: '20px',
                type: 'number',
                maxLength: '10',
            },
            {
                key: 'F788ConfiguredSpecificGravityLabel',
                label: 'Configured Specific Gravity (SG) of Fluid',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                marginTop: '20px',
            },
            {
                key: 'F788ConfiguredSpecificGravity',
                value: formData.F788ConfiguredSpecificGravity,
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                marginTop: '20px',
                height: '1.2rem',
                type: 'number',
                maxLength: '10',
                marginRight: '0'
            }
            ]
        }
    ]
});
