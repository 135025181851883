import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputWrapper, StyledTextArea } from './Inputs.styled';

export const FormInputMultiline = (props) => {
    const { id, label, type, name, value, handleChange, rows, readOnly, formName, marginTop,
        fontSize, marginBottom, maxLength, width, fontColor, isLocked } = props;
    const [inputValue, handleValue] = useState(value || '');
    const isFirstRun = useRef(true);
    useEffect(() => {
        let timeOutId;
        if (isFirstRun.current) {
            isFirstRun.current = false;
        } else {
            timeOutId = setTimeout(() => handleChange({ [name]: inputValue }), 500);
        }
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);
    useEffect(() => {
        handleValue(value);
    }, [value]);
    const handleTextValue = (e) => {
        const newTextValue = e.target.value?.trimStart();
        if (newTextValue.length <= (maxLength ?? 255)) {
            handleValue(newTextValue);
        }
    };
    return (
        <InputWrapper marginTop={marginTop} marginBottom={marginBottom} fontColor={fontColor}>
            <div className="label">
                {label}
            </div>
            <StyledTextArea
                id={id}
                formName={formName}
                rows={rows || 2}
                type={type || 'text'}
                value={typeof inputValue === 'number' ? inputValue : inputValue?.replace(/^\s+/, '')}
                onChange={(e) => handleTextValue(e)}
                readOnly={readOnly}
                fontSize={fontSize}
                width={width}
                isLocked={isLocked}
            />
        </InputWrapper>
    );
};

FormInputMultiline.propTypes = {
    fontColor: PropTypes.string,
    fontSize: PropTypes.string,
    formName: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    isLocked: PropTypes.bool,
    label: PropTypes.string.isRequired,
    marginBottom: PropTypes.string,
    marginTop: PropTypes.string,
    maxLength: PropTypes.string,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    rows: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.string
};
