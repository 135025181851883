import { Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import { APIEndpoints } from 'helpers/APILists';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { trackErrors } from '../helper';
import DropdownInput from '../../elements/DropdownInput';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { strEquals } from '../../AdminScreen.helper';
import ModalFooterActions from '../../elements/ModalFooterActions';

const MultiTrfDetailsForm = ({ isOpen, title, onClose, rowData, dropdownsData, onRecordSubmit }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [typesList, setmultiTrfList] = useState([]);
    const [nameError, setNameError] = useState('');
    const [titleError, setTitleError] = useState('');
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const submitButtonVisible = !_.isEqual(rowData, createRowData) && nameError === '' && titleError === ''
        && createRowData.TrfmasterId
        && createRowData.Trftag
        && createRowData.Trftitle
        && createRowData.Status;

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetMultiTRFDetails)
            .then((res) => {
                setmultiTrfList(res.data.map(x => ({ RowId: x.RowId, TrfmasterId: x.TrfmasterId, Trftag: x.Trftag, Trftitle: x.Trftitle })));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleInputChange = ((e) => setCreateRowData((prev) => (
        {
            ...prev,
            [e.target.name]: e.target.value
        }
    )));

    const handleBlurTag = () => {
        setNameError('');
        if (typesList.find(x => strEquals(x.Trftag, createRowData.Trftag)
            && x.RowId !== createRowData.RowId
            && x.TrfmasterId === createRowData.TrfmasterId
        )) {
            setNameError(' *(TRF Type already exists for this TRF ID)');
        }
    };

    const handleBlurTitle = () => {
        setTitleError('');
        if (typesList.find(x => strEquals(x.Trftitle, createRowData.Trftitle)
            && x.RowId !== createRowData.RowId)) {
            setTitleError(' *(TRF Title already exists)');
        }
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setNameError('');
        setTitleError('');
        setCreateRowData(rowData);
    };

    const submitButtonHandler = () => {
        createRowData.Status = createRowData.Status === 'Active';
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.RowId) {
            updateRecord(createRowData);
        } else {
            createRowData.RowId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddMultiTRFDetails, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.RowId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateMultiTRFDetails, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.RowId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <DropdownInput
                        name="TrfmasterId"
                        title="TRF ID"
                        value={createRowData.TrfmasterId}
                        options={dropdownsData.trfIdOptions}
                        handleInputChange={handleInputChange}
                        required
                        handleBlur={handleBlurTag}
                    />
                    <TextInput
                        name="Trftag"
                        title="TRF Type"
                        value={createRowData.Trftag}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="35"
                        handleBlur={handleBlurTag}
                        errorMsg={nameError}
                    />
                    <TextInput
                        name="Trftitle"
                        title="TRF Title"
                        value={createRowData.Trftitle}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="65"
                        isMultiline
                        handleBlur={handleBlurTitle}
                        errorMsg={titleError}
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        options={['Active', 'Inactive']}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.RowId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.RowId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData?.RowId ? formatDate(new Date(createRowData.LastModifiedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comment"
                        title="Comments"
                        value={createRowData.Comment}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i> Note: TRF ID Contains the list of TRF&apos;S from TRF Master List where TRF Type is Multi. </i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i>If TRF ID is not available in the list please check the Type in TRF Master List table.</i>
                    </InputLabel>
                </div>
                <ModalFooterActions
                    submitButtonVisible={submitButtonVisible}
                    submitButtonHandler={submitButtonHandler}
                    resetButtonHandler={resetButtonHandler}
                    cancelButtonHandler={cancelButtonHandler}
                />
            </DialogActions>
        </Dialog>

    );
};

export default MultiTrfDetailsForm;

MultiTrfDetailsForm.propTypes = {
    dropdownsData: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
