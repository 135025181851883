import { TxAsFoundLabel } from '../../Sections.constants';
import { paddingLabel } from '../HMISection/Data/C11/C11.data';
import { labelWidth } from '../HMISection/Data/P710/P710.data';
import { unitsUsedSectionDataS73 } from './Data/S73/S73.data';
import { TxAsFoundDataS74 } from './Data/TxAsFoundS74/TxAsFoundS74.data';

const unitsUsedSectionData = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F980UnitsUsedLabel',
                label: 'Units used for readings:',
                labelWidth,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
                marginTop: '20px'
            },
            {
                key: 'F980UnitsUsed',
                value: formData.F980UnitsUsed ? formData.F980UnitsUsed : 'Instrument',
                options: [{ name: 'Instrument', value: 'Instrument' },
                { name: 'HMI', value: 'HMI' }],
                isDropDown: true,
                isRequired: true,
                labelWidth: '0%',
                width: '100%',
                inputTextAlign: 'center',
                noDefaultOption: true,
                textAlign: 'center',
                marginTop: '20px',
                xs: 2
            },
            {
                key: 'F979SqrtExtractionLabel',
                label: 'Use SqRT Extraction?',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
                marginTop: '20px'
            },
            {
                key: 'F979SqrtExtraction',
                value: formData.F979SqrtExtraction ? formData.F979SqrtExtraction : 'NO',
                options: [{ name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                isDropDown: true,
                isRequired: true,
                labelWidth: '0%',
                width: '100%',
                inputTextAlign: 'center',
                noDefaultOption: true,
                textAlign: 'center',
                marginTop: '20px',
                xs: 2
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F977TxAsFoundLabel',
                label: TxAsFoundLabel.Tx_As_Found,
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel
            },
            {
                key: 'F977TxAsFound',
                value: formData.F977TxAsFound,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                height: '1.2rem',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                borderTop: '0'
            },
            {
                key: 'F978AftAlternativeTxLabel',
                label: TxAsFoundLabel.Alternative_Tx,
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel
            },
            {
                key: 'F978AftAlternativeTx',
                value: formData.F978AftAlternativeTx,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                height: '1.2rem',
                textAlign: 'center',
                borderTop: '0'
            },
            ]
        },
    ]
});

export const selectUnitsUsedSectionData = (formName, formData) => {
    const formNameToFunctionMap = {
        'S7-2': () => unitsUsedSectionData(formData),
        'S7-3': () => unitsUsedSectionDataS73(formData),
        'S7-4': () => TxAsFoundDataS74(formData)
    };
    let filterViewData = { rows: [] };
    const functionToExecute = formNameToFunctionMap[formName];
    if (functionToExecute) {
        filterViewData = functionToExecute();
    }
    return filterViewData;
};
