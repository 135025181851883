export const PreTestData = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'Nodata1',
                label: 'Pre-Test',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '94%',
                textAlign: 'left',
                padding: '5px 0 5px 8px',
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F383IsThereHazardousAreaRestrictionLabel',
                    label: 'Is there hazardous area restriction to apply heat to the detector in the field?',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F383IsThereHazardousAreaRestriction',
                    value: formData.F383IsThereHazardousAreaRestriction,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    borderBottom: '0',
                    labelWidth: '0%',
                    width: '100%',
                    height: '2.24rem',
                    inputTextAlign: 'center',
                    paddingRightPlaceholder: '15px',
                    xs: 2,
                    marginBottom: '4px',
                    paddingPlaceholder: '6px',
                    arrowMarginTop: '6px'
                },
            ]
        },
    ]
});

export const selectPreTestSectionData = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'M6-8') {
        filterViewData = PreTestData(selectedTag, formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
