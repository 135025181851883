import React from 'react';
import { connect } from 'react-redux';
import Form from '../../FormsTemplate';
import { FormNames } from '../../Forms.constants';
import UserInfoSection from '../../Sections/UserInfoSection/UserInfoSection';
import ManufacturerModelSection from '../../Sections/ManufacturerModelSection/ManufacturerModelSection';
import DeviceTypesSection from '../../Sections/DeviceTypesSection/DeviceTypesSection';
import HMISection from '../../Sections/HMISection/HMISection';
import VisualInspectSection from '../../Sections/VisualInspectSection/VisualInspectSection';
import NoteSection from '../../Sections/NoteSection/NoteSection';
import AsFoundTestTableSection from '../../Sections/AsFoundTestTableSection/AsFoundTestTableSection';
import AsLeftTestTableSection from '../../Sections/AsLeftTestTableSection/AsLeftTestTableSection';
import BorderedBox from '../../BorderedBox';
import FailureModeSection from '../../Sections/FailureModeSection/FailureModeSection';
import TestResultComponent from '../../Sections/TestResultComponent/TestResultComponent';
import UnitsUsedSection from '../../Sections/UnitsUsedSection/UnitsUsedSection';
import TxAsLeftSection from '../../Sections/TxAsLeft/TxAsLeft';
import SetpointSection from '../../Sections/SetpointSection/SetpointSection';
import ToleranceInstrumentEUCommentSection from '../../Sections/ToleranceInstrumentEUComment/ToleranceInstrumentEUComment';
import FullPartialStrokeTest from '../../Sections/FullPartialStrokeTest/FullPartialStrokeTest';
import AsFoundPstMethodSection from '../../Sections/AsFound_PST_Method/AsFound_PST_Method';

const S74Form = (props) => (
        <Form
            formName={FormNames.S74}
        >
            <UserInfoSection />
            <FullPartialStrokeTest />
            <ManufacturerModelSection />
            <DeviceTypesSection />
            <HMISection />
            <VisualInspectSection />
            <UnitsUsedSection />
            <AsFoundPstMethodSection />
            <BorderedBox>
                <AsFoundTestTableSection />
            </BorderedBox>
            <ToleranceInstrumentEUCommentSection />
            <FailureModeSection />
            <BorderedBox>
                <AsLeftTestTableSection />
            </BorderedBox>
            <TxAsLeftSection />
            <BorderedBox>
                <SetpointSection />
            </BorderedBox>
            <TestResultComponent />
            <NoteSection />
        </Form>
);

const mapStateToProps = ({ Forms }) => ({
    selectedTag: Forms.selectedTag,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(S74Form);
