import React from 'react';
import { connect } from 'react-redux';
import Form from '../../FormsTemplate';
import { FormNames } from '../../Forms.constants';

import UserInfoSection from '../../Sections/UserInfoSection/UserInfoSection';
import ManufacturerModelSection from '../../Sections/ManufacturerModelSection/ManufacturerModelSection';
import DeviceTypesSection from '../../Sections/DeviceTypesSection/DeviceTypesSection';
import HMISection from '../../Sections/HMISection/HMISection';
import VisualInspectSection from '../../Sections/VisualInspectSection/VisualInspectSection';
import TestResultSection from '../../Sections/TestResultSection/TestResultSection';
import AsFoundTestTableSection from '../../Sections/AsFoundTestTableSection/AsFoundTestTableSection';
import AsLeftTestTableSection from '../../Sections/AsLeftTestTableSection/AsLeftTestTableSection';
import NoteSection from '../../Sections/NoteSection/NoteSection';
import PreTestSection from '../../Sections/Pre-Test/Pre-Test';
import BorderedBox from '../../BorderedBox';
import AsFoundTestWorkshopSection from '../../Sections/AsFoundTestWorkshop/AsFoundTestWorkshop';
import AsLeftTestWorkshopSection from '../../Sections/AsLeftTestWorkshop/AsLeftTestWorkshop';
import DetectorreinstatementSection from '../../Sections/DetectorReinstatement/DetectorReinstatement';
import DetectorLenseSection from '../../Sections/DetectorLenseSection/DetectorLense';
import TestResultComponent from '../../Sections/TestResultComponent/TestResultComponent';

const M68Form = (props) => (
    <Form
        formName={FormNames.M68}
    >
        <UserInfoSection />
        <ManufacturerModelSection />
        <DeviceTypesSection />
        <HMISection />
        <VisualInspectSection />
        <BorderedBox>
            <PreTestSection />
        </BorderedBox>
        <BorderedBox>
            <AsFoundTestTableSection />
        </BorderedBox>
        <BorderedBox>
            <AsFoundTestWorkshopSection />
        </BorderedBox>
        <BorderedBox>
            <AsLeftTestTableSection />
        </BorderedBox>
        <BorderedBox>
            <AsLeftTestWorkshopSection />
        </BorderedBox>
        <DetectorLenseSection />
        <BorderedBox
            withoutmargin
        >
            <DetectorreinstatementSection />
        </BorderedBox>
        <TestResultSection />
        <TestResultComponent />
        <NoteSection />
    </Form>
);

const mapStateToProps = ({ Forms }) => ({
    selectedTag: Forms.selectedTag,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(M68Form);
