import { TestPointRange } from 'helpers/Validation';
import { TestPassFailFormula, AsFoundResultFormula, AsFoundResultMandatoryCheck } from './M610.formulas';
import { getAftDeleteIconStatus } from '../../AsFoundTestTableSection.helpers';
import { paddingValue } from '../P720/P720.data';
import { Label } from 'journeys/portal/Forms/Sections.constants';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataM610 = (formData, aftTestPoint) => {
    const rows = [];
    rows.push({
        key: 1,
        fields: [{
            key: 'AsFoundTest',
            label: 'As-Found Test',
            isLabel: true,
            xs: 5,
            fontWeight: '700',
            padding: '8px 0 0 8px',
            borderTop: '0',
        },
        {
            key: 'F675NumberOfTestPoints',
            label: 'Number of Test Points',
            value: formData?.F675NumberOfTestPoints,
            isBlur: true,
            type: 'number',
            maxLength: '10',
            xs: 3,
            labelWidth: '80.2%',
            width: '64%',
            height: '1.26rem',
            isRequired: true,
            marginRight: '0.5rem',
            textAlign: 'center',
            marginTop: '4px',
            borderTop: '0',
            marginBottom: '10px',
            direction: 'rtl',
            readOnly: getAftDeleteIconStatus(),
            hasTestPointError: !TestPointRange(formData.F675NumberOfTestPoints, 8)
        }]
    },
        {
            key: 2,
            fields: [
                {
                    key: 'AftDelete',
                    value: getAftDeleteIconStatus() ? 'Delete' : '',
                    textAlign: 'center',
                    fontColor: 'red',
                    isDeleteLabel: getAftDeleteIconStatus(),
                    width: '100%',
                    labelWidth: '0',
                    xs: 2,
                    borderLeft: '0',
                    borderRight: '0',
                    borderBottom: '0',
                    borderTop: '0',
                    readOnly: true,
                    marginLeft: '4px'
                },
                {
                    key: 'AftSmokeConfirmation',
                    label: Label.SMOKE_CONFIRMATION_LABEL,
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    padding: paddingValue,
                    fontSize: '9pt',
                    width: '100%'
                },
                {
                    key: 'AftHmiStatus',
                    label: 'HMI Status',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                    width: '100%'
                },
                {
                    key: 'AftTransitTime',
                    label: 'Record transit time (sec)',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                    width: '100%'
                },
                {
                    key: 'AftResultReading',
                    label: '',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                    width: '100%'
                }]
        });
    if (TestPointRange(aftTestPoint ?? formData?.F675NumberOfTestPoints, 8)) {
        for (let index = 2; index < Number(aftTestPoint ?? formData?.F675NumberOfTestPoints) + 2; index += 1) {
            rows.push(
                {
                    key: 3 + index,
                    fields: [{
                        key: index,
                        xs: 2,
                        borderTop: '0',
                        borderBottom: '0',
                        borderLeft: '0',
                        borderRight: '0',
                        readOnly: true,
                        isDelete: getAftDeleteIconStatus(),
                        width: '100%'
                    },
                    {
                        key: `AftSmokeConfirmation${index}`,
                        value: formData[`AftSmokeConfirmation${index}`],
                        isDropdown: true,
                        isRequired: true,
                        placeholder: dropdownPlaceholder,
                        options: [
                            { name: dropdownPlaceholder, value: '' },
                            { name: 'YES', value: 'YES' },
                            { name: 'NO', value: 'NO' }],
                        inputTextAlign: 'center',
                        width: '100%',
                        labelWidth: '0',
                        height: '1.5rem',
                        borderRight: '0',
                        borderBottom: '0',
                        xs: 2
                    },
                    {
                        key: `AftHmiStatus${index}`,
                        value: formData[`AftHmiStatus${index}`],
                        isRequired: true,
                        isDropdown: true,
                        placeholder: 'Select HMI Status',
                        options: [{ name: 'Select HMI Status', value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'ALARM', value: 'ALARM' },
                        { name: 'FAULT', value: 'FAULT' }],
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.5rem',
                        xs: 2,
                        maxLength: '10',
                        borderBottom: '0'
                    },
                    {
                        key: `AftRecordTransitTime${index}`,
                        value: formData[`AftRecordTransitTime${index}`],
                        type: 'number',
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.26rem',
                        xs: 2,
                        maxLength: '10',
                        borderBottom: '0',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: `AftResultReading${index}`,
                        value: TestPassFailFormula(formData[`AftSmokeConfirmation${index}`], formData[`AftHmiStatus${index}`]),
                        readOnly: true,
                        isCalculatedNew: true,
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.26rem',
                        xs: 2,
                        borderBottom: '0',
                        fontWeight: 700
                    }]
                });
        }
    }
    rows.push({
        key: 14,
        fields: [
            {
                key: 'noDataAft',
                value: '',
                xs: 8,
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                borderBottom: '0',
                marginLeft: '0.26rem',
                borderLeft: '0',
                borderRight: '0',
            },
            {
                key: 'AsFoundResultLabel',
                value: 'As-Found Result',
                xs: 2,
                textAlign: 'end',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                height: '1.1rem',
                marginBottom: '4px',
                borderBottom: '0',
                borderLeft: '0',
                isLabelBold: true,
            },
            {
                key: 'F102AftResult',
                value: AsFoundResultMandatoryCheck(formData, aftTestPoint)
                    ? AsFoundResultFormula(formData, aftTestPoint) : '',
                isCalculatedNew: true,
                readOnly: true,
                xs: 2,
                labelWidth: '0',
                textAlign: 'center',
                width: '100%',
                borderBottom: '0',
                height: '1.1rem',
                borderLeft: '0',
                borderRight: '0',
                inputMarginRight: '4px'
            }]
    });
    return { rows };
};
