import styled from 'styled-components';

export const StyleFailureNewDropdown = styled.div`

#F619AsLeftTestRequired-wrapper > .Dropdown-root > .Dropdown-control > .Dropdown-menu {
    text-align: ${props => (['P7-26', 'P7-25', 'M7-1', 'M6-11', 'M6-14', 'M6-15', 'M6-1'].includes(props.formName)) ? 'center' : 'left'};
}
.Dropdown-placeholder.is-selected {
    width: 100%;
    overflow: hidden;
}
.Dropdown-menu {
    text-align: center;
}
`;

export const StyleMagneticFloatCheckDropdown = styled.div`
.Dropdown-placeholder.is-selected {
    width: 100%;
    overflow: hidden;
}
.Dropdown-menu {
    text-align: center;
}
`;
