import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const FoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F124AftGaugeReading1)
        && FieldHasValue(formData.F125AftGaugeReading2)
        && FieldHasValue(formData.F126AftGaugeReading3)
        && FieldHasValue(formData.F147AftTransmitterReading1)
        && FieldHasValue(formData.F148AftTransmitterReading2)
        && FieldHasValue(formData.F149AftTransmitterReading3)
        && FieldHasValue(formData.F106AftHmireading1)
        && FieldHasValue(formData.F107AftHmireading2)
        && FieldHasValue(formData.F108AftHmireading3) && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1), ValidateNumberField(formData.F55DeviationEu2), ValidateNumberField(formData.F56DeviationEu3)])
            <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const SensorTrimNeededFormula = (formData) => {
    if (formData.F102AftResult && FieldHasValue(formData.F117InstrumentCalibratedUrv)
        && ValidateNumberField(formData.F117InstrumentCalibratedUrv) > ValidateNumberField(formData.F116InstrumentCalibratedLrv)) {
        return Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1), ValidateNumberField(formData.F55DeviationEu2),
        ValidateNumberField(formData.F56DeviationEu3)]) / (ValidateNumberField(formData.F117InstrumentCalibratedUrv) - ValidateNumberField(formData.F116InstrumentCalibratedLrv)) <= 0.02 ? 'NO' : 'YES';
    }
    return '';
};
