import { labelWidth } from '../P710/P710.data';

export const placeholderName = 'Select Physical Position';
export const HMISectionDataP733P620P611P717 = (selectedTag, formName, formData) => ({
    rows: [{
        key: 1,
        fields: [
            {
                key: 'F437PhysicalPositionofContactirPtLabel',
                label: 'Physical Position of Contactor Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '18px 0 0 0'
            },
            {
                key: 'F437PhysicalPositionofContactirPt',
                value: formData.F437PhysicalPositionofContactirPt,
                isDropdown: true,
                placeholder: placeholderName,
                options: [
                    { name: placeholderName, value: '' },
                    { name: 'Open', value: 'Open' },
                    { name: 'Closed', value: 'Closed' }],
                width: '100%',
                labelWidth: '0%',
                xs: 2,
                isRequired: true,
                inputTextAlign: 'center',
                marginTop: '15px'
            },
            {
                key: 'F438HmipositionofConactorPtLabel',
                label: 'HMI Status of Heater Prior to Test',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                textAlign: 'right',
                fontSize: '9pt',
                padding: '18px 5px 0 0'
            },
            {
                key: 'F438HmipositionofConactorPt',
                value: formData.F438HmipositionofConactorPt,
                isDropdown: true,
                isRequired: true,
                placeholder: 'Select HMI Status',
                options: [{ name: 'Select HMI Status', value: '' },
                { name: 'ON', value: 'ON' },
                { name: 'OFF', value: 'OFF' }],
                xs: 2,
                width: '100%',
                inputTextAlign: 'center',
                marginTop: '15px',
                labelWidth: '0%',
            }]
    }]
});
