import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import _ from 'lodash';
import { APIEndpoints } from 'helpers/APILists';
import { formatDate } from 'helpers/DateFormatter';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import DropdownInput from '../../elements/DropdownInput';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { trackErrors } from '../helper';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { strEquals } from '../../AdminScreen.helper';

const SecurityGroupForm = ({ isOpen, title, onClose, rowData, onRecordSubmit, dropdownsData }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [securityGroupData, setSecurityGroupData] = useState([]);
    const [objectIdError, setObjectIdError] = useState('');
    const [nameError, setNameError] = useState('');
    const [uniqueRecordError, setUniqueRecordError] = useState('');
    const [roleOptions, setRoleOptions] = useState([]);
    const adminAdGroup = 'Admin';
    const viewRole = 'Viewer';
    const businessAdGroup = 'Business';

    const handleInputChange = ((e) => setCreateRowData((prev) => (
        {
            ...prev,
            [e.target.name]: e.target.value
        }
    )));

    const { instance } = useMsal();
    const userName = instance.getActiveAccount();

    const FooterButtonVisible = !_.isEqual(rowData, createRowData)
        && objectIdError === ''
        && nameError === ''
        && uniqueRecordError === ''
        && createRowData.ObjectId
        && createRowData.Name
        && createRowData.BpRegion
        && createRowData.BpRegionAlias
        && createRowData.RoleId
        && createRowData.AdGroupTypeId;

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetMratSecurityGroups)
            .then((res) => {
                setSecurityGroupData(res.data);
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    useEffect(() => {
        if (createRowData.BpRegion === 'ALL') {
            setCreateRowData(prev => ({
                ...prev,
                BpRegionAlias: 'ALL'
            }));
        } else if (createRowData.BpRegion) {
            const regionName = dropdownsData.bpRegionAliasNameOptions
                .filter(x => x.BpRegion === createRowData.BpRegion)
                .map(y => y.BpRegionAlias)[0];
            setCreateRowData(prev => ({
                ...prev,
                BpRegionAlias: regionName
            }));
        } else {
            setCreateRowData(prev => ({
                ...prev,
                BpRegionAlias: ''
            }));
        }
    }, [createRowData.BpRegion, dropdownsData.bpRegionAliasNameOptions]);

    useEffect(() => {
        if (createRowData.AdGroupTypeId === dropdownsData.adGroupTypeIdOptions?.find(x => x.label === adminAdGroup)?.value) {
            setRoleOptions(dropdownsData.roleIdOptions.filter(x => x.label.includes(adminAdGroup)));
        } else {
            createRowData.AdGroupTypeId && setRoleOptions(dropdownsData.roleIdOptions.filter(x => !x.label.includes(adminAdGroup)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createRowData.AdGroupTypeId]);

    useEffect(() => {
        generateSecurityGroupName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createRowData.BpRegionAlias, createRowData.AdGroupTypeId, createRowData.RoleId]);

    const generateSecurityGroupName = () => {
        if (createRowData.RowId) {
            return;
        }
        const adGroupLabel = dropdownsData.adGroupTypeIdOptions.find(x => x.value === createRowData.AdGroupTypeId)?.label;
        (adGroupLabel === adminAdGroup) && setCreateRowData(prev => ({
            ...prev,
            Name: ''
        }));
        if (createRowData.BpRegionAlias && createRowData.AdGroupTypeId && createRowData.RoleId) {
            let groupName = `G ${createRowData.BpRegionAlias} MRAT`;
            if (dropdownsData.roleIdOptions.find(x => x.label === viewRole)?.value !== createRowData.RoleId) {
                groupName += ` ${dropdownsData.roleIdOptions.find(x => x.value === createRowData.RoleId)?.label}`;
            } else {
                groupName += ' View';
            }
            if (dropdownsData.adGroupTypeIdOptions.find(x => x.label === businessAdGroup)?.value !== createRowData.AdGroupTypeId) {
                groupName += ` ${adGroupLabel}`;
            }
            (adGroupLabel !== adminAdGroup) && setCreateRowData(prev => ({
                ...prev,
                Name: groupName
            }));
        }
    };

    const handleBlurObjectId = () => {
        setObjectIdError('');
        // to validate if the Object Id entered is a valid GUID
        if (!createRowData.ObjectId.toString().match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
            setObjectIdError(' *(Object Id is not valid)');
        }
        // to validate if the new Object Id is unique
        if (securityGroupData.filter(x => strEquals(x.ObjectId, createRowData.ObjectId) && x.RowId !== createRowData.RowId).length >= 1) {
            setObjectIdError(' *(Object Id already exists)');
        }
    };

    const handleBlur = () => {
        setNameError('');
        setUniqueRecordError('');
        // to validate if the new group name is unique
        if (securityGroupData.filter(x => strEquals(x.Name, createRowData.Name)
            && x.RowId !== createRowData.RowId).length >= 1) {
            setNameError(' *(Name already exists)');
        }
        if (securityGroupData
            .filter(x => x.BpRegion === createRowData.BpRegion
                && x.AdGroupTypeId === createRowData.AdGroupTypeId
                && x.RoleId === createRowData.RoleId
                && x.Status === 'Active'
                && createRowData.Status === 'Active'
                && x.RowId !== createRowData.RowId).length >= 1) {
            setUniqueRecordError(`*An active record with the same combination of Region, AD Group & Role already exists. 
                                    Either choose different values for these columns or mark the Status as Inactive.`);
        }
    };

    const handleSubmitButton = () => {
        createRowData.AdGroupType = null;
        createRowData.Role = null;
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.RowId) {
            updateRecord(createRowData);
        } else {
            createRowData.RowId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const resetButtonHandler = () => {
        setObjectIdError('');
        setNameError('');
        setUniqueRecordError('');
        setCreateRowData(rowData);
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_SubmitSecurityGroupRecord, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.RowId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateMRATSecurityGroupRecord, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.RowId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"

        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}

            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="ObjectId"
                        errorMsg={objectIdError}
                        title="Object ID"
                        value={createRowData.ObjectId}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlurObjectId}
                        type="string"
                        maxLength="64"
                        isMultiline
                    />

                    <DropdownInput
                        name="BpRegion"
                        title="BP Region (SAP PM)"
                        value={createRowData.BpRegion}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        options={dropdownsData.bpRegionOptions}
                    />
                    <TextInput
                        name="BpRegionAlias"
                        title="BP Region Alias"
                        value={createRowData.BpRegionAlias}
                        required
                        handleInputChange={handleInputChange}
                        disabled
                    />
                    <div />
                    <DropdownInput
                        name="AdGroupTypeId"
                        title="AD Group Type"
                        value={createRowData.AdGroupTypeId}
                        options={dropdownsData.adGroupTypeIdOptions}
                        handleBlur={handleBlur}
                        handleInputChange={handleInputChange}
                        required
                    />
                    <DropdownInput
                        name="RoleId"
                        title="Role"
                        value={createRowData.RoleId}
                        options={roleOptions}
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        required
                    />
                    <TextInput
                        name="Name"
                        errorMsg={nameError}
                        title="Name (AD Group)"
                        value={createRowData.Name}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        type="string"
                        maxLength="65"
                        isMultiline
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        options={['Active', 'Inactive']}
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.RowId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.RowId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData.LastModifiedDate || formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '10px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        type="string"
                        isMultiline
                        width="1080px"
                    />
                </div>
                {uniqueRecordError && <span style={{ fontSize: '11px', color: 'rgb(255, 0, 0)', fontWeight: 'bold', right: '10%' }}>{uniqueRecordError}</span>}
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i> Note: Object ID will be generated after adding a new AD Group on Azure. </i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i> If BP Region is not available in the list please add a new record in Group Mapping table.</i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i> Select AD Group Type first to get the list of Roles.</i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i> If AD Group Type is not available in the list please add a new record in AD Group Type LKUP table.</i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i> If Role is not available in the list please add a new record in Roles LKUP table.</i>
                    </InputLabel>
                </div>
                <div style={{ display: 'flex', gap: '0 8px' }}>
                    {FooterButtonVisible && (
                        <Button
                            classes={{ root: classes.button }}
                            onClick={handleSubmitButton}
                            variant="outlined"
                        >
                            Submit
                        </Button>
                    )}
                    <Button
                        classes={{ root: classes.button }}
                        onClick={resetButtonHandler}
                        variant="outlined"
                    >
                        Reset
                    </Button>
                    <Button
                        classes={{ root: classes.button }}
                        onClick={cancelButtonHandler}
                        variant="outlined"
                    >
                        Close
                    </Button>
                </div>
            </DialogActions>
        </Dialog>

    );
};
export default SecurityGroupForm;

SecurityGroupForm.propTypes = {
    dropdownsData: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string,

};
