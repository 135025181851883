import DataGridComponent from 'components/DataGrid/DataGridComponent';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import React, { useState } from 'react';
import { trackErrors } from '../helper';
import {
    dpsFieldData, dpsFieldApiEndpoint,
    searchDataInitialState, dpsFieldInitialState
} from './DpsField.data';

import DpsFieldForm from './DpsFieldForm';

const addTitle = 'Add New Record for DPS Field';
const editTitle = 'Edit Record for DPS Field';

const DpsField = () => {
    const apiEndpoints = dpsFieldApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(dpsFieldInitialState);
    const [modalTitle, setModalTitile] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'DpsfieldId',
        rowNumber: ''
    });

    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const result = data.map((res) => ({
                    ...res,
                    Status: res.Status === true ? 'Active' : 'Inactive'
                }));
            resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            }
        })();
    });

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitile(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(dpsFieldInitialState);
        setModalTitile(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
        setRefreshDatagrid(prev => !prev);
    };

    return (
        <>
            <DataGridComponent
                headerData={dpsFieldData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={searchDataInitialState}
                rowDetails={rowDetails}
                refreshDatagrid={refreshDatagrid}
                dataParser={parseData}
            />

            {showModal
                && (
                    <DpsFieldForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};

export default DpsField;
