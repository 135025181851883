import { Setpoint, asFoundTestLabel } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import {
    DeviationAftFormula, expectedReadingFormula, valueToApplyFormula, AsFoundResultFormula, totalNumberOfFields,
    getValueToApplyUnit, getUnits
} from './S72.formula';

export const stepMultiplier = 25;
export const halfNumberOfFields = 4;
export const borderCondition = i => i === totalNumberOfFields ? '' : '0';
export const AsFoundTestTableSectionDataS72 = (selectedTag, formName, formData) => {
const readingUnit = getUnits(formData);
    const rows = [
        {
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: asFoundTestLabel(formName),
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'Step',
                    label: 'Step (%)',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                },
                {
                    key: 'ValueToApply',
                    label: `${Setpoint.VALUE_TO_APPLY} ${getValueToApplyUnit(formData, formName)}`,
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                },
                {
                    key: 'ExpectedReading',
                    label: `Expected Reading ${readingUnit}`,
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                },
                {
                    key: 'ActualTxReading',
                    label: `Actual Tx Reading ${readingUnit}`,
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation(%)',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt'
                }
            ]
        }
    ];

    for (let i = 0; i <= totalNumberOfFields; i += 1) {
        const value = i <= halfNumberOfFields ? i * stepMultiplier : (totalNumberOfFields - i) * stepMultiplier;
        rows.push({
            key: i + 3,
            fields: [
                {
                    key: `StepKey${i}`,
                    value,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    readOnly: true,
                    type: 'number',
                    height: '1.2rem',
                    borderBottom: borderCondition(i),
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px',
                    textAlign: 'center',
                    fontSize: '9pt',
                },
                {
                    key: `AftValueToApply${i + 2}`,
                    value: valueToApplyFormula(formData, formName, value, formData[`AftExpectedReading${i + 2}`]),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    type: 'number',
                    maxLength: '10',
                    readOnly: true,
                    borderBottom: borderCondition(i),
                    textAlign: 'center',
                    isCalculatedNew: true,
                },
                {
                    key: `AftExpectedReading${i + 2}`,
                    value: expectedReadingFormula(formData, formName, value),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    type: 'number',
                    maxLength: '10',
                    readOnly: true,
                    borderBottom: borderCondition(i),
                    textAlign: 'center',
                    isCalculatedNew: true,
                    borderRight: '0',
                    borderLeft: '0'
                },
                {
                    key: `AftTxReading${i + 2}`,
                    value: formData[`AftTxReading${i + 2}`],
                    xs: 2,
                    width: '100%',
                    isRequired: true,
                    labelWidth: '0%',
                    height: '1.2rem',
                    type: 'number',
                    maxLength: '10',
                    borderBottom: borderCondition(i),
                    textAlign: 'center',
                    borderRight: '0',
                },
                {
                    key: `AftDeviation${i + 2}(%)`,
                    value: DeviationAftFormula(formName, formData, formData[`AftExpectedReading${i + 2}`], formData[`AftTxReading${i + 2}`]),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    type: 'number',
                    maxLength: '10',
                    readOnly: true,
                    borderBottom: borderCondition(i),
                    textAlign: 'center',
                    isCalculatedNew: true,
                }
            ]
        });
    }
    rows.push({
        key: 12,
        fields: [
            {
                key: 'F101AftToleranceLabel',
                label: 'Tolerance (%)',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'left',
                fontSize: '9pt',
                padding: '5px 0 5px 8px'
            },
            {
                key: 'F101AftTolerance',
                value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                height: '1.25rem',
                type: 'number',
                maxLength: '10',
                borderBottom: '0',
                textAlign: 'center',
                isRequired: true,
                borderTop: '0',
            },
            {
                key: 'Nodata',
                isLabel: true,
                xs: 2,
            },
            {
                key: 'F102AftResultLabel',
                label: 'As-Found Result',
                xs: 2,
                isLabel: true,
                fontWeight: '700',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F102AftResult',
                value: AsFoundResultFormula(formName, formData),
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                height: '1.25rem',
                readOnly: true,
                borderBottom: '0',
                textAlign: 'center',
                isCalculatedNew: true,
                borderTop: '0',
            }]
    });
    return { rows };
};
