import React from 'react';
import 'elements/React-Dropdown/style.css';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { StyledLabelForFilter, SelectWrapper } from './AutocompleteFilter.styled';

export const AutocompleteFilter = (props) => {
    const { id, label, name, handleChange, handleInputChange, value, options = [], widthForAutoComplete } = props;
    const elementId = id || `id-${name}`;

    return (
        <SelectWrapper id={`${elementId}-wrapper`}>
            <StyledLabelForFilter>{label}</StyledLabelForFilter>
            <Autocomplete
                disablePortal
                disableClearable
                id={id}
                style={{ width: widthForAutoComplete }}
                value={value}
                options={options}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        /* eslint-disable react/jsx-props-no-spreading */
                        {...params}
                        variant="outlined"
                        type="search"
                        onChange={(e) => {
                            e.target.value === '' && handleInputChange();
                        }}
                    />
                )}

            />
        </SelectWrapper>
    );
};

AutocompleteFilter.propTypes = {
    handleChange: PropTypes.func,
    handleInputChange: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    renderInput: PropTypes.func,
    value: PropTypes.any,
    widthForAutoComplete: PropTypes.string
};
