import { FieldHasValue, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';

const AsLeftResultFormula = (formData) => {
    if (FieldHasValue(formData.F701AltSimulatedGap1)
        && FieldHasValue(formData.AltSimulatedGap2)
        && FieldHasValue(formData.AltSimulatedGap3)
        && FieldHasValue(formData.F69Tx2Reading1)
        && FieldHasValue(formData.F70Tx2Reading2)
        && FieldHasValue(formData.F71Tx2Reading3)
        && FieldHasValue(formData.F72Hmi2Reading1)
        && FieldHasValue(formData.F73Hmi2Reading2)
        && FieldHasValue(formData.F74Hmi2Reading3) && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F78Deviation2Eu1), ValidateNumberField(formData.F79Deviation2Eu2), ValidateNumberField(formData.F80Deviation2Eu3)])
            <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const FoundResultF102AftResultFormula = (formData) => AsLeftResultFormula(formData);

export const TestResultF81AsLeftResultFormula = (formData) => AsLeftResultFormula(formData);

export const AsLeftTableF78Deviation2Eu1Formula = (formData) => FieldHasValue(formData.F69Tx2Reading1) && FieldHasValue(formData.F701AltSimulatedGap1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F69Tx2Reading1) - ValidateNumberField(formData.F701AltSimulatedGap1))) : '';
export const AsLeftTableF79Deviation2Eu2Formula = (formData) => FieldHasValue(formData.F70Tx2Reading2) && FieldHasValue(formData.AltSimulatedGap2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F70Tx2Reading2) - ValidateNumberField(formData.AltSimulatedGap2))) : '';
export const AsLeftTableF80Deviation2Eu3Formula = (formData) => FieldHasValue(formData.F71Tx2Reading3) && FieldHasValue(formData.AltSimulatedGap3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F71Tx2Reading3) - ValidateNumberField(formData.AltSimulatedGap3))) : '';
