import { FieldHasValue, ValidateNumberField, ValidateField } from 'helpers/Validation';

export const FoundResultAsFoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F451AftCalibratorFieldUnits1)
        && FieldHasValue(formData.F452AftCalibratorFieldUnits2)
        && FieldHasValue(formData.F453AftCalibratorFieldUnits3)
        && FieldHasValue(formData.F209AftTxreading1)
        && FieldHasValue(formData.F210AftTxreading2)
        && FieldHasValue(formData.F211AftTxreading3)
        && FieldHasValue(formData.F106AftHmireading1)
        && FieldHasValue(formData.F107AftHmireading2)
        && FieldHasValue(formData.F108AftHmireading3)
        && FieldHasValue(formData.F215AftDeviation1)
        && FieldHasValue(formData.F216AftDeviation2)
        && FieldHasValue(formData.F217AftDeviation3)
        && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateField(formData.F215AftDeviation1), ValidateField(formData.F216AftDeviation2),
        ValidateField(formData.F217AftDeviation3)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const FoundResultAsFoundResultFormulaP622 = (formData, formName) => {
    if (formName === 'P7-30' && formData.F472SignalToTriggerLevelPriortoTest === 'Error') {
        return 'FAIL';
    }
    if (FieldHasValue(formData.F800TestDeviceReading)
        && FieldHasValue(formData.F801TestDeviceReading)
        && FieldHasValue(formData.F802TestDeviceReading)
        && FieldHasValue(formData.F45TxReading1)
        && FieldHasValue(formData.F46TxReading2)
        && FieldHasValue(formData.F47TxReading3)
        && FieldHasValue(formData.F48HmiReading1)
        && FieldHasValue(formData.F49HmiReading2)
        && FieldHasValue(formData.F50HmiReading3)
        && FieldHasValue(formData.F101AftTolerance)) {
        return (Math.max.apply(Math, [ValidateField(formData.F54DeviationEu1), ValidateField(formData.F55DeviationEu2),
        ValidateField(formData.F56DeviationEu3)]) <= ValidateNumberField(formData.F101AftTolerance))
            ? 'PASS' : 'FAIL';
    }
    return '';
};
