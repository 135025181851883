import React from 'react';
import PropTypes from 'prop-types';
import SearchField from './SearchField';

const SearchBar = (props) => (
    <div className="targetSearchBar" style={{ display: 'flex', overflow: 'hidden' }}>
        {props.searchFields.filter(x => x.hideInTable === false).map(field => (
            <SearchField
                key={field.field}
                readOnly={field.isDisabled || props.loading}
                name={field.field}
                handleSearch={props.handleColumnSearch}
                width={`${field.width}px`}
            />
        ))}
        <SearchField
            name="extra"
            handleSearch={props.handleColumnSearch}
            disabled
        />
    </div>
);

export default SearchBar;

SearchBar.propTypes = {
    handleColumnSearch: PropTypes.func,
    loading: PropTypes.bool,
    searchFields: PropTypes.array
};
