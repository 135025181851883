export const TestResultSectionDataP617 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F143HmireadingAfterTest',
                    label: 'HMI Reading After the Test',
                    value: formData.F143HmireadingAfterTest,
                    isRequired: true,
                    xs: 4,
                    padding: '0',
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '50%',
                    width: '50%',
                    direction: 'rtl',
                    textAlign: 'center'
                },
                {
                    key: 'Nodata7',
                    label: '',
                    isLabel: true,
                    xs: 1
                },
                {
                    key: 'F267ProcessTxreadingAfterTestLabel',
                    label: 'Process TX Reading After the Test',
                    xs: 3,
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '5px 0 0 0',
                    labelWidth: '97%',
                    textAlign: 'end',
                    isLabel: true,
                },
                {
                    key: 'F267ProcessTxreadingAfterTest',
                    value: formData.F267ProcessTxreadingAfterTest,
                    isRequired: true,
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    labelWidth: '0%',
                    width: '100%',
                    textAlign: 'center',
                    marginLeft: '0.8px'
                }]
        }]
});
