import {
    AsFoundTestTableF39HmiInst1Formula,
    AsFoundTestTableF40HmiInst2Formula,
    AsFoundTestTableF41HmiInst3Formula,
} from '../../../AsFoundTestTableSection/Data/P711/P711.formulas';
import {
    AsLeftTestTableDeviationEu1Formula,
    AsLeftTestTableDeviationEu2Formula,
    AsLeftTestTableDeviationEu3Formula,
    AsLeftResultFormulaP6022
} from './P6022.formulas';
import { paddingAF } from '../M71/M71.data';
import { signalToTriggerIsErrorCheck } from '../../../SignalToTrigger/SignalToTrigger.data';
import { isEnabledcondition } from '../M611/M611.data';
import { Result } from '../../../../Sections.constants';

export const AsLeftTestTableSectionDataP6022 = (selectedTag, formData, formName) => {
    const disabledCondition = (formData.F102AftResult === Result.RESULT_PASS && formData.F477ZeroFlowCheckPassFailResult === Result.RESULT_PASS)
        || (formData.F473ZeroFlowReading === 'DISCARD' && formData.F102AftResult === Result.RESULT_PASS)
        || (formName === 'P7-30' && formData.F472SignalToTriggerLevelPriortoTest === 'Error');
    const disableField = signalToTriggerIsErrorCheck(formName, formData) || disabledCondition;
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'As-LeftTest',
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: 700,
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'AltHMI-Inst',
                    label: '% - HMI - Inst.',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: '5px 0 5px 6px',
                    fontSize: '9pt'
                },
                {
                    key: 'AltTestDeviceReading',
                    label: 'Test Device Reading (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'AltTXReading',
                    label: 'TX Reading (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    textAlign: 'center',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'AltHMIReading',
                    label: 'HMI Reading (HMI EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'mAReading',
                    label: 'mA Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt',
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F63Hmi2Inst1',
                    value: isEnabledcondition(AsFoundTestTableF39HmiInst1Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F803TestDeviceReading',
                    value: isEnabledcondition(formData.F803TestDeviceReading, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F69Tx2Reading1',
                    value: isEnabledcondition(formData.F69Tx2Reading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F72Hmi2Reading1',
                    value: isEnabledcondition(formData.F72Hmi2Reading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F75MA2Reading1',
                    value: isEnabledcondition(formData.F75MA2Reading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F78Deviation2Eu1',
                    value: isEnabledcondition(AsLeftTestTableDeviationEu1Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    readOnly: true,
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F64Hmi2Inst2',
                    value: isEnabledcondition(AsFoundTestTableF40HmiInst2Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F804TestDeviceReading',
                    value: isEnabledcondition(formData.F804TestDeviceReading, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F70Tx2Reading2',
                    value: isEnabledcondition(formData.F70Tx2Reading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F73Hmi2Reading2',
                    value: isEnabledcondition(formData.F73Hmi2Reading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F76MA2Reading2',
                    value: isEnabledcondition(formData.F76MA2Reading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F79Deviation2Eu2',
                    value: isEnabledcondition(AsLeftTestTableDeviationEu2Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    readOnly: true,
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F65Hmi2Inst3',
                    value: isEnabledcondition(AsFoundTestTableF41HmiInst3Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F805TestDeviceReading',
                    value: isEnabledcondition(formData.F805TestDeviceReading, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F71Tx2Reading3',
                    value: isEnabledcondition(formData.F71Tx2Reading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F74Hmi2Reading3',
                    value: isEnabledcondition(formData.F74Hmi2Reading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F77MA2Reading3',
                    value: isEnabledcondition(formData.F77MA2Reading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0'
                },
                {
                    key: 'F80Deviation2Eu3',
                    value: isEnabledcondition(AsLeftTestTableDeviationEu3Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    readOnly: true
                }]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'NoData2',
                        xs: 4,
                        isLabel: true,
                        padding: '5px 25px 18.5px 0'
                    },
                    {
                        key: 'F81AsLeftResultLabel',
                        value: 'As-Left Result',
                        readOnly: true,
                        xs: 2,
                        isLabelBold: true,
                        textAlign: 'end',
                        labelWidth: '0',
                        width: '100%',
                        height: '1.1rem',
                        borderRight: '0',
                        borderTop: '0',
                        borderBottom: '0',
                        borderLeft: '0'
                    },
                    {
                        key: 'F81AsLeftResult',
                        value: !disabledCondition ? AsLeftResultFormulaP6022(formData, formName) : '',
                        isDisabled: disabledCondition,
                        readOnly: true,
                        isCalculatedNew: true,
                        xs: 2,
                        labelWidth: '0',
                        width: '100%',
                        height: '1.1rem',
                        borderTop: (disableField) ? '' : '0',
                        inputMarginRight: '-2px',
                        borderBottom: '0',
                        textAlign: 'center',
                        marginBottom: '4px'
                    }]
            }]
    };
};
