import { ValidateNumberField, FieldHasValue } from 'helpers/Validation';

export const AsFoundTestTableF61AsFoundResultFormula = (formData) => {
    if ((formData.F338PhysicalValvePositionPriortotest === 'Closed'
        && formData.F341ValveFullStrokeLocalPosition === 'Open')
        || (formData.F338PhysicalValvePositionPriortotest === 'Open'
            && formData.F341ValveFullStrokeLocalPosition === 'Closed')) {
        if (FieldHasValue(formData.F344ValveTravelTimeRequirement)
            && FieldHasValue(formData.F342ValveFullStrokeTravelTime)) {
            if (ValidateNumberField(formData.F342ValveFullStrokeTravelTime) <= ValidateNumberField(formData.F344ValveTravelTimeRequirement)) {
                return 'PASS';
            }
            return 'FAIL';
        }
    } else if ((FieldHasValue(formData.F338PhysicalValvePositionPriortotest)
        && FieldHasValue(formData.F341ValveFullStrokeLocalPosition))
        || FieldHasValue(formData.F338PhysicalValvePositionPriortotest) === 'Intermediate') {
        return 'FAIL';
    }
    return '';
};

export const disableAFTValveFullStrokeTravelTime = (formData) => {
    if ((formData.F338PhysicalValvePositionPriortotest === 'Closed'
        && formData.F341ValveFullStrokeLocalPosition === 'Open')
        || (formData.F338PhysicalValvePositionPriortotest === 'Open'
            && formData.F341ValveFullStrokeLocalPosition === 'Closed')) {
        return false;
    }
    return (
        (FieldHasValue(formData.F338PhysicalValvePositionPriortotest) === 'Intermediate')
        || (FieldHasValue(formData.F338PhysicalValvePositionPriortotest)
        && FieldHasValue(formData.F341ValveFullStrokeLocalPosition))
    );
};
