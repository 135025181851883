import { disableTestSectionAft } from '../../AFT_SolenoidLimitSection/AsFoundSolenoidLimitSection.data';
import { test2Result, test3Result } from '../AsFoundIP_TransmitterSection.formulas';

export const asFoundIPTransmitterTestResultData = (formData) => {
    const disableTest2Key = formData.F850AftTest2IpPositioner;
    const disableTest3Key = formData.F870AftTest3PositionTransmitter;
    return {
        key: 12,
        fields: [{
            key: 'Nodata',
            isLabel: 'true',
            xs: 4,
        },
        {
            key: 'F869AftTest2ResultLabel',
            value: 'Test 2 Result',
            xs: 2,
            width: '96%',
            labelWidth: '0',
            height: '1.2rem',
            textAlign: 'right',
            borderRight: '0',
            isLabelNew: true,
            borderTop: '0',
            readOnly: true,
            borderBottom: '0',
            borderLeft: '0',
        },
        {
            key: 'F869AftTest2Result',
            value: test2Result(formData),
            isDisabled: disableTestSectionAft(disableTest2Key),
            xs: 2,
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            textAlign: 'center',
            borderTop: '0',
            isCalculatedNew: true,
        },
        {
            key: 'F889AftTest3ResultLabel',
            value: 'Test 3 Result',
            xs: 2,
            width: '96%',
            labelWidth: '0',
            height: '1.2rem',
            textAlign: 'right',
            borderRight: '0',
            isLabelNew: true,
            borderTop: '0',
            readOnly: true,
            borderBottom: '0',
            borderLeft: '0',
        },
        {
            key: 'F889AftTest3Result',
            value: test3Result(formData),
            isDisabled: disableTestSectionAft(disableTest3Key),
            xs: 2,
            width: '100%',
            labelWidth: '0',
            readOnly: true,
            height: '1.2rem',
            textAlign: 'center',
            borderTop: '0',
            borderRight: '0',
            isCalculatedNew: true,
        }
        ]
    };
};
