import { placeholderName } from '../../../HMISection/Data/P733/P733.data';

export const TestResultSectionDataP733P620 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F446PhysicalPositionofContactirAt',
            label: 'Physical Position of Contactor After Test',
            value: formData.F446PhysicalPositionofContactirAt,
            isRequired: true,
            isDropDown: true,
            placeholder: placeholderName,
            options: [{ name: placeholderName, value: '' },
            { name: 'Open', value: 'Open' },
            { name: 'Closed', value: 'Closed' }],
            direction: 'rtl',
            xs: 6,
            width: '49%',
            labelWidth: '51.9%',
            inputTextAlign: 'center',
        },
        {
            key: 'F447HmipositionofConactorPt',
            label: 'HMI Status of Heater After Test',
            xs: 6,
            value: formData.F447HmipositionofConactorPt,
            isRequired: true,
            isDropDown: true,
            placeholder: 'Select HMI Status',
            options: [{ name: 'Select HMI Status', value: '' },
            { name: 'ON', value: 'ON' },
            { name: 'OFF', value: 'OFF' }],
            labelWidth: '39%',
            marginRight: '0.5rem',
            width: '61%',
            borderBottom: '0',
        }]
    }]
});
