import { Button, Dialog, DialogActions } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { PageViewer } from 'components/InformationPage/InformationPage.styled';
import { useStyles } from './ImageUploader.styled';

const PdfViewer = ({ pdfData }) => {
    const classes = useStyles();
    const [showPdf, setShowPdf] = useState(false);
    const [displayZoomButton, setDisplayZoomButton] = useState(true);
    const [scale, setScale] = useState(0.9);
    const [pages, setPages] = useState(null);

    const handleDocumentLoadSuccess = ({ numPages }) => {
        setPages(numPages);
        setDisplayZoomButton(true);
    };

    const handleButtonClick = () => {
        setShowPdf(true);
    };

    const handleOnClose = () => {
        setShowPdf(false);
        setDisplayZoomButton(false);
        setScale(0.9);
    };

    return (
        <>
            <Button
                variant="text"
                size="small"
                style={{ textTransform: 'capitalize', fontSize: '11px', color: 'blue' }}
                onClick={handleButtonClick}
            >
                View file
            </Button>
            {showPdf && (
                <Dialog
                    open
                    onClose={handleOnClose}
                    scroll="paper"
                    maxWidth="md"
                    fullWidth
                    style={{ bottom: '6px' }}
                >
                    <div>
                        <Document
                            file={`data:application/pdf;base64,${pdfData}`}
                            onLoadSuccess={handleDocumentLoadSuccess}
                        >
                            {[...Array(pages)].map((x, i) => i + 1).map((page, i) => (
                                <PageViewer key={page}>
                                    <Page scale={scale} className="page" pageNumber={page} />
                                    <br />
                                </PageViewer>
                            ))}
                        </Document>
                    </div>
                    <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px' }}>
                        {displayZoomButton && pdfData && (
                            <div style={{ position: 'fixed', left: '45%', bottom: '0.1%', zIndex: 1 }}>
                                <Button classes={{ root: classes.button }} style={{ marginRight: '5px' }} onClick={() => { setScale(prevState => prevState + 0.1); }}><ZoomInIcon /></Button>
                                {scale > 0.9 && <Button classes={{ root: classes.button }} onClick={() => { setScale(prevState => prevState > 0.9 ? prevState - 0.1 : 0.9); }}><ZoomOutIcon /></Button>}
                            </div>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default PdfViewer;

PdfViewer.propTypes = {
    pdfData: PropTypes.string.isRequired
};
