import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import _ from 'lodash';
import { APIEndpoints } from 'helpers/APILists';
import { formatDate } from 'helpers/DateFormatter';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import DropdownInput from '../../elements/DropdownInput';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { trackErrors } from '../helper';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { strEquals } from '../../AdminScreen.helper';

const UserInputTypeForm = ({ isOpen, title, onClose, rowData, onRecordSubmit, dropdownsData }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [userInputTypeData, setuserInputTypeData] = useState([]);
    const [typeError, setTypeError] = useState('');

    const handleInputChange = ((e) => setCreateRowData((prev) => (
        {
            ...prev,
            [e.target.name]: e.target.value
        }
    )));

    const { instance } = useMsal();
    const userName = instance.getActiveAccount();

    const FooterButtonVisible = !_.isEqual(rowData, createRowData) && !typeError
        && createRowData.Type
        && createRowData.Description
        && createRowData.InputFieldTypeId;

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetUserInputTypes)
            .then((res) => {
                setuserInputTypeData(res.data);
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleBlurType = () => {
        setTypeError('');
        // to validate if the new type is unique
        if (userInputTypeData.find(x => strEquals(x.Type, createRowData.Type)
            && x.UserInputTypeId !== createRowData.UserInputTypeId)) {
            setTypeError(' *(Type already exists)');
        }
    };

    const handleSubmitButton = () => {
        createRowData.InputFieldType = null;
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.UserInputTypeId) {
            updateRecord(createRowData);
        } else {
            createRowData.UserInputTypeId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const resetButtonHandler = () => {
        setTypeError('');
        setCreateRowData(rowData);
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_SubmitUserInputTypeRecord, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.UserInputTypeId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateUserInputTypeRecord, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.UserInputTypeId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"

        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}

            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="Type"
                        errorMsg={typeError}
                        title="User Input Type"
                        value={createRowData.Type}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlurType}
                        maxLength="45"
                        type="string"
                        isMultiline
                    />
                    <TextInput
                        name="Description"
                        title="Description"
                        value={createRowData.Description}
                        required
                        handleInputChange={handleInputChange}
                        maxLength="45"
                        isMultiline
                        type="string"
                    />
                    <DropdownInput
                        name="InputFieldTypeId"
                        title="Input Field Type"
                        value={createRowData.InputFieldTypeId}
                        handleInputChange={handleInputChange}
                        options={dropdownsData.inputFieldTypeIdOptions}
                        required
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        options={['Active', 'Inactive']}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.UserInputTypeId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.UserInputTypeId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData?.UserInputTypeId ? formatDate(new Date(createRowData.LastModifiedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '10px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        type="string"
                        isMultiline
                        width="1080px"
                    />
                </div>

            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i> Note: If Input Field Type is not available in the list please add a new record in Input Field Type LKUP table. </i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i> This is a lookup record for User Input Type dropdown options in DPS Mapping table.</i>
                    </InputLabel>
                </div>
                <div style={{ display: 'flex', gap: '0 8px' }}>
                    {FooterButtonVisible && (
                        <Button
                            classes={{ root: classes.button }}
                            onClick={handleSubmitButton}
                            variant="outlined"
                        >
                            Submit
                        </Button>
                    )}
                    <Button
                        classes={{ root: classes.button }}
                        onClick={resetButtonHandler}
                        variant="outlined"
                    >
                        Reset
                    </Button>
                    <Button
                        classes={{ root: classes.button }}
                        onClick={cancelButtonHandler}
                        variant="outlined"
                    >
                        Close
                    </Button>
                </div>
            </DialogActions>
        </Dialog>

    );
};
export default UserInputTypeForm;

UserInputTypeForm.propTypes = {
    dropdownsData: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string,

};
