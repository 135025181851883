import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { selectDeviceTypesSectionData } from './DeviceTypesSection.data';
import { SearchableDropdown } from 'elements/Inputs/FormInput/SearchableDropdown';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const ManufacturerModelSection = (props) => {
    const { selectedTag, testMakeModelData, changeHandler, formName, formData, isFormReadOnly } = props;
    const classes = useStyles();
    const filterViewData = selectDeviceTypesSectionData(selectedTag, testMakeModelData, formName, formData);
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                filterViewData.rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ marginBottom: '15px' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                <SearchableDropdown
                                    id={field.key}
                                    name={field.key}
                                    label={field.label}
                                    value={field.value}
                                    handleChange={changeHandler}
                                    options={field.options}
                                    isRequired={field.isRequired}
                                    width={field.width}
                                    labelWidth={field.labelWidth}
                                    readOnly={isFormReadOnly}
                                    direction={field.direction}
                                    marginLeft={field.marginLeft}
                                    borderTop={field.borderTop}
                                    marginRight={field.marginRight}
                                    marginTop={field.marginTop}
                                    testEqDisplay="flex"
                                    placeholder={field.placeholder}
                                />
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home, TestEquipment }) => ({
    selectedTag: Forms.selectedTag,
    testMakeModelData: TestEquipment.testEquipmentDataAll
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManufacturerModelSection);

ManufacturerModelSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    testMakeModelData: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ])
};
