import { FieldHasValue } from 'helpers/Validation';
import {
    AsFoundTestTableF574AftTestPoint1Formula,
    AsFoundTestTableF575AftTestPoint2Formula,
    AsFoundTestTableF576AftTestPoint3Formula,
    AsFoundTestTableDeviationEu1Formula,
    AsFoundTestTableDeviationEu2Formula,
    AsFoundTestTableDeviationEu3Formula
} from './P891.formulas';
import { paddingAF } from '../../../AsLeftTestTableSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataP891 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: 700,
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'TestPoint',
                label: 'Test Point',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                padding: '5px 0 5px 6px',
                fontSize: '9pt'
            },
            {
                key: 'Bath/BlockTemperature',
                label: 'Bath / Block Temperature',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Thermometer Readout',
                label: 'Thermometer Readout',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                textAlign: 'center',
                labelWidth: '100%',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Ω Reading',
                label: 'Ω Reading',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'mV Reading',
                label: 'mV Reading',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Deviation (EU)',
                label: 'Deviation (EU)',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F574AftTestPoint1',
                value: AsFoundTestTableF574AftTestPoint1Formula(formData).length > 255
                    ? AsFoundTestTableF574AftTestPoint1Formula(formData).slice(0, 255) : AsFoundTestTableF574AftTestPoint1Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F577AftBathBlockTemperature1',
                value: formData.F577AftBathBlockTemperature1,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F580AftThermometerReadout1',
                value: formData.F580AftThermometerReadout1,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: formData.F573TemperatureSensorType === 'Thermocouple' ? '' : '0',
                borderBottom: '0'
            },
            {
                key: 'F583Aftreading1',
                value: formData.F573TemperatureSensorType !== 'Thermocouple'
                ? formData.F583Aftreading1 : '',
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                isDisabled: formData.F573TemperatureSensorType === 'Thermocouple',
                readOnly: formData.F573TemperatureSensorType === 'Thermocouple',
            },
            {
                key: 'F586AftMVreading1',
                value: formData.F573TemperatureSensorType !== 'RTD'
                    ? formData.F586AftMVreading1 : '',
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderLeft: FieldHasValue(formData.F573TemperatureSensorType) ? '' : '0',
                borderRight: '0',
                isDisabled: formData.F573TemperatureSensorType === 'RTD',
                readOnly: formData.F573TemperatureSensorType === 'RTD'
            },
            {
                key: 'F215AftDeviation1',
                value: AsFoundTestTableDeviationEu1Formula(formData),
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F575AftTestPoint2',
                value: AsFoundTestTableF575AftTestPoint2Formula(formData).length > 255
                    ? AsFoundTestTableF575AftTestPoint2Formula(formData).slice(0, 255) : AsFoundTestTableF575AftTestPoint2Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F578AftBathBlockTemperature2',
                value: formData.F578AftBathBlockTemperature2,
                isRequired: true,
                maxLength: '10',
                xs: 2,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F581AftThermometerReadout2',
                value: formData.F581AftThermometerReadout2,
                xs: 2,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: formData.F573TemperatureSensorType === 'Thermocouple' ? '' : '0',
                borderBottom: '0'
            },
            {
                key: 'F584Aftreading2',
                value: formData.F573TemperatureSensorType !== 'Thermocouple'
                ? formData.F584Aftreading2 : '',
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                isDisabled: formData.F573TemperatureSensorType === 'Thermocouple',
                readOnly: formData.F573TemperatureSensorType === 'Thermocouple'
            },
            {
                key: 'F587AftMVreading2',
                value: formData.F573TemperatureSensorType !== 'RTD'
                ? formData.F587AftMVreading2 : '',
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderLeft: FieldHasValue(formData.F573TemperatureSensorType) ? '' : '0',
                borderRight: '0',
                isDisabled: formData.F573TemperatureSensorType === 'RTD',
                readOnly: formData.F573TemperatureSensorType === 'RTD'
            },
            {
                key: 'F216AftDeviation2',
                value: AsFoundTestTableDeviationEu2Formula(formData),
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'F576AftTestPoint3',
                value: AsFoundTestTableF576AftTestPoint3Formula(formData).length > 255
                    ? AsFoundTestTableF576AftTestPoint3Formula(formData).slice(0, 255) : AsFoundTestTableF576AftTestPoint3Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                marginLeft: '4px'
            },
            {
                key: 'F579AftBathBlockTemperature3',
                value: formData.F579AftBathBlockTemperature3,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F582AftThermometerReadout3',
                value: formData.F582AftThermometerReadout3,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: formData.F573TemperatureSensorType === 'Thermocouple' ? '' : '0',
            },
            {
                key: 'F585Aftreading3',
                value: formData.F573TemperatureSensorType !== 'Thermocouple'
                ? formData.F585Aftreading3 : '',
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                isDisabled: formData.F573TemperatureSensorType === 'Thermocouple',
                readOnly: formData.F573TemperatureSensorType === 'Thermocouple'
            },
            {
                key: 'F588AftMVreading3',
                value: formData.F573TemperatureSensorType !== 'RTD'
                ? formData.F588AftMVreading3 : '',
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.1rem',
                borderRight: '0',
                borderLeft: FieldHasValue(formData.F573TemperatureSensorType) ? '' : '0',
                isDisabled: formData.F573TemperatureSensorType === 'RTD',
                readOnly: formData.F573TemperatureSensorType === 'RTD'
            },
            {
                key: 'F217AftDeviation3',
                value: AsFoundTestTableDeviationEu3Formula(formData),
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.1rem',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
            }]
        }
    ]
});
