import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import { FormInputViewOnly } from 'elements/Inputs/FormInput/FormInputViewOnly';
import { UserInformationSectionData } from './UserInformationSection.data';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    }
  }));

const UserInformationSectionEditTEI = (props) => {
    const { selectedTag, formValues } = props;
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const classes = useStyles();

    const filterViewData = UserInformationSectionData(selectedTag, userName, formValues);

    return (
        <Grid container spacing={1} className={classes.root}>
            {
                filterViewData.rows.map(row => (
                    <Grid item container xs={12} key={row.key}>
                        {row.fields.map(field => (
                            <Grid item xs={4} key={field.key}>
                                <FormInputViewOnly
                                    id={field.key}
                                    label={field.label}
                                    value={field.value}
                                    labelWidth={field.labelWidth}
                                    marginTop={field.marginTop}
                                    paddingLeft={field.paddingLeft}
                                    labelfontSize={field.labelfontSize}
                                    labelfontFamily={field.labelfontFamily}
                                />
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ TagSelectionMOScreen, TestEquipment, Forms }) => ({
    selectedTag: Forms.selectedTag,
    formValues: TestEquipment.editTestEquipmentData,
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInformationSectionEditTEI);

UserInformationSectionEditTEI.propTypes = {
    formValues: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    selectedTag: PropTypes.object
};
