export const VisualInspectSectionDataS71 = (formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F955VisualInspectionSatisfactoryLabel',
                    label: 'Visual Inspection Satisfactory?',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '4px 0 0 0',
                },
                {
                    key: 'F955VisualInspectionSatisfactory',
                    value: formData.F955VisualInspectionSatisfactory ? formData.F955VisualInspectionSatisfactory : 'YES',
                    isDropdown: true,
                    isRequired: true,
                    options: [{ name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    noDefaultOption: true,
                    xs: 2,
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    alignItems: 'inherit',
                    borderBottom: '0',
                    padding: '0'
                }]
        },
        {
            key: 2,
            fields: [{
                key: 'F119VisualInspectionLabel',
                label: 'Visual Inspection Details',
                xs: 4,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '4px 0 0 0',
                isLabel: true,
            },
            {
                key: 'F119VisualInspection',
                value: formData.F119VisualInspection,
                isRequired: formData.F955VisualInspectionSatisfactory === 'NO',
                isMultiline: true,
                xs: 8,
                width: '0%',
                inputWidth: '100%',
                alignItems: 'inherit',
                padding: '0',
                marginRight: '0',
            }]
        },
        (['S7-2', 'S7-3', 'S7-4'].includes(formName)) ? {
            key: 3,
            fields: [
                {
                    key: 'F120DiagnosticsLabel',
                    label: 'Retrieve diagnostic message (if applicable)',
                    xs: 4,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '4px 0 0 0',
                    isLabel: true,
                },
                {
                    key: 'F120Diagnostics',
                    value: formData.F120Diagnostics,
                    isMultiline: true,
                    xs: 8,
                    width: '0%',
                    inputWidth: '100%',
                    alignItems: 'inherit',
                    padding: '0',
                    marginRight: '0',
                    marginTop: '11px',
                }]
        } : {
            key: 3,
            fields: []
        }
    ]
});
