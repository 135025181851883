import React from 'react';
import PropTypes from 'prop-types';
import { InputWrapper, ViewWrapper } from './Inputs.styled';

export const InputViewOnly = (props) => {
    const { id, label, value, marginBottom } = props;

    return (
        <InputWrapper marginBottom={marginBottom}>
            <div className="label">
                {label}
            </div>
            <ViewWrapper id={id}>
                {value}
            </ViewWrapper>
        </InputWrapper>
    );
};

InputViewOnly.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    marginBottom: PropTypes.string,
    value: PropTypes.any,
};
