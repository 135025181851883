import { ValidateNumberField, IsANumber, FieldHasValue } from 'helpers/Validation';

export const AsLeftResultFormula = (formData) => {
    if (
        ((FieldHasValue(formData.F66Gauge2Reading1) || FieldHasValue(formData.F67Gauge2Reading2) || FieldHasValue(formData.F68Gauge2Reading3))
            && (FieldHasValue(formData.F69Tx2Reading1) || FieldHasValue(formData.F70Tx2Reading2) || FieldHasValue(formData.F71Tx2Reading3))
            && (FieldHasValue(formData.F72Hmi2Reading1) || FieldHasValue(formData.F73Hmi2Reading2) || FieldHasValue(formData.F74Hmi2Reading3)))
        && FieldHasValue(formData.F26InstrumentUrv) && IsANumber(formData.F26InstrumentUrv) && FieldHasValue(formData.F60ToleranceEu) && IsANumber(formData.F60ToleranceEu)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F78Deviation2Eu1),
        ValidateNumberField(formData.F79Deviation2Eu2),
        ValidateNumberField(formData.F80Deviation2Eu3)]) <= ValidateNumberField(formData.F60ToleranceEu)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
