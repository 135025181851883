import { FieldHasValue } from 'helpers/Validation';
import { getAltDeleteIconStatus } from '../../AsLeftTestTableSection.helpers';
import { notRequiredVal } from '../../../DetectorLenseSection/DetectorLense.data';

export const AsLeftResultMandatoryCheck = (formData, aftTestPoint) => {
    let flag = false;
    if (getAltDeleteIconStatus()) {
        return flag;
    }
    for (let index = 2; index < Number(aftTestPoint) + 2; index += 1) {
        if (!FieldHasValue(formData[`AltResultReading${index}`])) {
            flag = false;
            break;
        }
        flag = true;
    }
    return flag;
};

export const AsLeftResultFormula = (formData, asLeftTestPoint) => {
    const asLeftResultValue = [];
    const indexValue = 2;
    for (let index = indexValue; index < Number(asLeftTestPoint) + 2; index += 1) {
        asLeftResultValue.push((formData[`AltResultReading${index}`]));
    }
    if (asLeftResultValue.length > 0) {
        if (asLeftResultValue.includes('FAIL')) {
            return 'FAIL';
        }
        return 'PASS';
    }
    return '';
};

export const setAltTestPoint = (formData, altTestPoint) => {
    if (formData.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal) {
        return formData?.F721NumberOfTestPoints ?? altTestPoint;
    }
    return '';
};
