export const keyName = [
    {
        'P7-20': ['AftVibrationAmplitude',
            'AftVibrationFrequency',
            'AftSensorOutput',
            'AftSystemReading',
            'AftHmiReading']
    },
    {
        'P7-21': ['AftSimulatedGap',
            'AftExpectedOutput',
            'AftMeasuredReading',
            'AftHmiReading',
            'AftSystemReading']
    }, {
        'M6-10': [
            'AftSmokeConfirmation',
            'AftHmiStatus',
            'AftRecordTransitTime'
        ]
    }
];
