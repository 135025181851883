import { labelWidth } from '../HMISection/Data/P710/P710.data';
import { valveSizeValue } from '../HMISection/Data/P725/P725.data';
import { ValveSizeTravelSectionDataS71 } from './Data/S71/S71.data';

export const ValveSizeLabel = 'Valve Size (inch)';
export const ValveSizeTravelSectionDataP610 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F340ValveSizeLabel',
                    label: ValveSizeLabel,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '21px 0 0 0'
                },
                {
                    key: 'F340ValveSize',
                    value: valveSizeValue(selectedTag, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    marginTop: '20px',
                },
                {
                    key: 'F344ValveTravelTimeRequirement',
                    label: 'Valve Travel Time Requirement (sec)',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '23px 0 0 0'
                },
                {
                    key: 'F344ValveTravelTimeRequirement',
                    value: selectedTag.DateTested ? formData?.F344ValveTravelTimeRequirement : formData?.F344ValveTravelTimeRequirement ?? selectedTag?.DesignElementResponseTime,
                    xs: 2,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    direction: 'rtl',
                    height: '1.2rem',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    marginTop: '20px',
                }
            ]
        }
    ]
});

export const ValveSizeTravelSectionDataP716 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'remark',
                label: `Remark: Different arrangements for Partial Stroke Test (PST) capability are possible. Typically,
                 PST is perfomed via solenoid valve and/or valve positioner. Select either or both methods as required.`,
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '17px 0 5px 0',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F340ValveSizeLabel',
                    label: ValveSizeLabel,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '10px 0 0 0'
                },
                {
                    key: 'F340ValveSize',
                    value: valveSizeValue(selectedTag, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    marginTop: '10px',
                },
                {
                    key: 'F494PstsettingLabel',
                    label: 'As-Found Test PST Method',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    labelWidth,
                    textAlign: 'right',
                    padding: '15px 5px 0 0'
                },
                {
                    key: 'F494Pstsetting',
                    value: formData.F494Pstsetting,
                    isDropdown: true,
                    placeholder: 'Select PST Method',
                    options: [{ name: 'Select PST Method', value: '' },
                    { name: 'Solenoid Valve', value: 'Solenoid Valve' },
                    { name: 'Smart Positioner', value: 'Smart Positioner' },
                    { name: 'Both - valve & positioner', value: 'Both - valve & positioner' }],
                    xs: 2,
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    inputTextAlign: 'center',
                    marginTop: '10px',
                }
            ]
        }
    ]
});

export const selectValveSizeTravelSectionData = (selectedTag, formName, formData) => {
    let filterViewData = { rows: [] };
    if (formName === 'P6-10' || formName === 'P7-6' || formName === 'P7-27') {
        filterViewData = ValveSizeTravelSectionDataP610(selectedTag, formData);
    } else if (formName === 'P7-16' || formName === 'P6-13') {
        filterViewData = ValveSizeTravelSectionDataP716(selectedTag, formData);
    } else if (['S7-1'].includes(formName) && formData.F956TrfTypeSelection === 'Full') {
        filterViewData = ValveSizeTravelSectionDataS71(selectedTag, formData);
    }
    return filterViewData;
};
