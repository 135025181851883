import { labelWidth } from '../P710/P710.data';

export const placeholderName = 'Select Valve Position';
const labelPadding = '18px 0px 0px 0px';
export const HMISectionDataP615 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [
            {
                key: 'F572SolenoidValvePositionPriorToTestLabel',
                label: 'Solenoid Valve Position Prior to Test',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                marginTop: '35px',
                fontSize: '9pt',
                padding: labelPadding,
            },
            {
            key: 'F572SolenoidValvePositionPriorToTest',
            value: formData.F572SolenoidValvePositionPriorToTest,
            isDropdown: true,
            placeholder: placeholderName,
            options: [
                { name: placeholderName, value: '' },
                { name: 'Open', value: 'Open' },
                { name: 'Closed', value: 'Closed' }],
            width: '100%',
            labelWidth: '0%',
            xs: 2,
            isRequired: true,
            height: '1.5rem',
            inputTextAlign: 'center',
            marginTop: '15px'
        }]
    }]
});
