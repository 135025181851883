export const AdditionalInformationDataP76 = (selectedTag, formData, selectedTrfId) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F17Mi',
                value: formData.F17Mi ?? selectedTag.Mi,
            },
            {
                key: 'F18MiDescription',
                value: formData.F18MiDescription,
            },
            {
                key: 'F19Mp',
                value: formData.F19Mp ?? selectedTag.MaintenanceItemPm,
            },
            {
                key: 'F20Mo',
                value: formData.F20Mo ?? selectedTag.HdWorkOrder,
            },
            {
                key: 'F22TrfId',
                value: selectedTrfId ?? (formData.F22TrfId ?? (selectedTag.TrfId ? selectedTag.TrfId.trim() : '')),
            },
            {
                key: 'F23RegionDocId',
                value: formData.F23RegionDocId ?? selectedTag.BpRegion,
            },
            {
                key: 'RowId',
                value: formData.RowId,
            }]
        }
    ]

});
