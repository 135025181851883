import React from 'react';
import Dropdown from 'react-dropdown';
import 'elements/React-Dropdown/style.css';
import PropTypes from 'prop-types';
import { StyledLabel, SelectWrapper } from './Dropdown.styled';

export const FormDropdown = (props) => {
    const { className, id, label, name, handleChange, value, options = [],
        isRequired, readOnly, labelWidth, width, placeholder, marginRight,
        marginLeft, marginBottom, marginTop, direction, noDefaultOption, textAlign, borderTop,
        fontWeight, inputTextAlign, borderBottom, borderLeft, fontSize, borderRight, rootMarginRight,
        height, paddingPlaceholder, paddingRightPlaceholder, subMenu,
        arrowMarginTop } = props;
    const elementId = id || `id-${name}`;
    if (!noDefaultOption && options && options[0] && options[0].value !== '' && !subMenu) {
        options.unshift({ name: ' ', value: '' });
    }
    return (
        <SelectWrapper
            id={`${elementId}-wrapper`}
            width={width}
            borderTop={borderTop}
            borderBottom={borderBottom}
            marginTop={marginTop}
            marginBottom={marginBottom}
            inputTextAlign={inputTextAlign}
            borderLeft={borderLeft}
            borderRight={borderRight}
            fontSize={fontSize}
            rootMarginRight={rootMarginRight}
            height={height}
            paddingPlaceholder={paddingPlaceholder}
            paddingRightPlaceholder={paddingRightPlaceholder}
            arrowMarginTop={arrowMarginTop}
            value={value}
        >
            <StyledLabel
                textAlign={textAlign}
                labelWidth={labelWidth}
                marginLeft={marginLeft}
                marginRight={marginRight}
                direction={direction}
                fontWeight={fontWeight}
            >
                {label}
            </StyledLabel>
            <Dropdown
                id={elementId}
                name={name}
                value={value}
                options={subMenu
                    ? [...options?.map(x => ({
                        type: x.type,
                        name: x.label,
                        items: [...x?.i?.map(item => ({ label: item.name, value: item.value }))]
                    }))]
                    : [...options.map(x => ({ label: x.name, value: x.value }))]}
                onChange={(e) => handleChange({ [name]: e.value })}
                disabled={readOnly}
                placeholder={placeholder}
                width={width}
                rootMarginRight={rootMarginRight}
                className={`${className ?? ''} ${isRequired ? 'isRequired' : ''} ${readOnly ? 'readOnly' : ''}`}
            />
        </SelectWrapper>
    );
};

FormDropdown.propTypes = {
    arrowMarginTop: PropTypes.string,
    borderBottom: PropTypes.string,
    borderLeft: PropTypes.string,
    borderRight: PropTypes.string,
    borderTop: PropTypes.string,
    className: PropTypes.string,
    direction: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    handleChange: PropTypes.func,
    height: PropTypes.string,
    id: PropTypes.string,
    inputTextAlign: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    labelWidth: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    marginTop: PropTypes.string,
    name: PropTypes.string,
    noDefaultOption: PropTypes.bool,
    options: PropTypes.array,
    paddingPlaceholder: PropTypes.string,
    paddingRightPlaceholder: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    rootMarginRight: PropTypes.string,
    subMenu: PropTypes.bool,
    textAlign: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.string,
};
