import {
    AsLeftTestTableHmiInst1Formula,
    AsLeftTestTableHmiInst2Formula,
    AsLeftTestTableHmiInst3Formula,
    AsLeftTestTableDeviationEu1Formula,
    AsLeftTestTableDeviationEu2Formula,
    AsLeftResultFormulaP65,
    AsLeftTestTableDeviationEu3Formula,
} from './P65.formulas';
import { paddingAF, disableAsLeftSection, selectConditionsLabel } from '../P77/P77.data';
import { conditionalValueSelector } from 'helpers/Validation';

export const AsLeftTestTableSectionDataP65andP831 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'HMI-Inst',
                label: '% - HMI - Inst.',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                padding: '15px 0 5px 6px',
                fontSize: '9pt'
            },
            {
                key: 'AltLevelConditions',
                label: 'Level Conditions',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Independent Reading',
                label: 'Independent/Simulator Reading (%)',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'TXReading',
                label: 'TX Reading (%)',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                textAlign: 'center',
                labelWidth: '100%',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'HMIReading',
                label: 'HMI Reading (%)',
                isLabel: true,
                xs: 1,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'mAReading',
                label: 'mA Reading',
                isLabel: true,
                xs: 1,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Deviation',
                label: 'Deviation (%)',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt',
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F225AltHmiInst1',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', AsLeftTestTableHmiInst1Formula(formData)),
                isDisabled: disableAsLeftSection(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F792AltLevelConditions1',
                xs: 2,
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F792AltLevelConditions1),
                isDisabled: disableAsLeftSection(formData),
                isRequired: !(disableAsLeftSection(formData)),
                readOnly: disableAsLeftSection(formData),
                isDropdown: !(disableAsLeftSection(formData)),
                borderBottom: '0',
                borderRight: '0',
                placeholder: selectConditionsLabel,
                options: [
                    { name: selectConditionsLabel, value: '' },
                    { name: 'Actual', value: 'Actual' },
                    { name: 'Simulated', value: 'Simulated' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: conditionalValueSelector(disableAsLeftSection(formData), '1.2rem', '1.46rem')
            },
            {
                key: 'F228AltIndependentReading1',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F228AltIndependentReading1),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F231AltTxreading1',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F231AltTxreading1),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F109AltHmireading1',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F109AltHmireading1),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F234AltMAreading1',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F234AltMAreading1),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0'
            },
            {
                key: 'F237AltDeviation1',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', AsLeftTestTableDeviationEu1Formula(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: true,
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F226AltHmiInst2',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', AsLeftTestTableHmiInst2Formula(formData)),
                isDisabled: disableAsLeftSection(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F793AltLevelConditions2',
                xs: 2,
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F793AltLevelConditions2),
                isDisabled: disableAsLeftSection(formData),
                isRequired: !(disableAsLeftSection(formData)),
                readOnly: disableAsLeftSection(formData),
                isDropdown: !(disableAsLeftSection(formData)),
                borderBottom: '0',
                borderRight: '0',
                placeholder: selectConditionsLabel,
                options: [
                    { name: selectConditionsLabel, value: '' },
                    { name: 'Actual', value: 'Actual' },
                    { name: 'Simulated', value: 'Simulated' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: conditionalValueSelector(disableAsLeftSection(formData), '1.2rem', '1.46rem')
            },
            {
                key: 'F229AltIndependentReading2',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F229AltIndependentReading2),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F232AltTxreading2',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F232AltTxreading2),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F113AltHmireading2',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F113AltHmireading2),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                xs: 1,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F235AltMAreading2',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F235AltMAreading2),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                xs: 1,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0'
            },
            {
                key: 'F238AltDeviation2',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', AsLeftTestTableDeviationEu2Formula(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: true,
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F227AltHmiInst3',
                value: conditionalValueSelector(disableAsLeftSection(formData), '',
                    AsLeftTestTableHmiInst3Formula(formData)),
                isDisabled: disableAsLeftSection(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                marginLeft: '4px'
            },
            {
                key: 'F794AltLevelConditions3',
                xs: 2,
                value: conditionalValueSelector(disableAsLeftSection(formData), '',
                    formData.F794AltLevelConditions3),
                isDisabled: disableAsLeftSection(formData),
                isRequired: !(disableAsLeftSection(formData)),
                readOnly: disableAsLeftSection(formData),
                isDropdown: !(disableAsLeftSection(formData)),
                borderRight: '0',
                placeholder: selectConditionsLabel,
                options: [
                    { name: selectConditionsLabel, value: '' },
                    { name: 'Actual', value: 'Actual' },
                    { name: 'Simulated', value: 'Simulated' }],
                inputTextAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: conditionalValueSelector(disableAsLeftSection(formData), '1.2rem', '1.57rem')
            },
            {
                key: 'F230AltIndependentReading3',
                value: conditionalValueSelector(disableAsLeftSection(formData), '',
                    formData.F230AltIndependentReading3),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0'
            },
            {
                key: 'F233AltTxreading3',
                value: conditionalValueSelector(disableAsLeftSection(formData),
                    '', formData.F233AltTxreading3),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0'
            },
            {
                key: 'F114AltHmireading3',
                value: conditionalValueSelector(disableAsLeftSection(formData), '',
                    formData.F114AltHmireading3),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0'
            },
            {
                key: 'F236AltMAreading3',
                value: conditionalValueSelector(disableAsLeftSection(formData), '', formData.F236AltMAreading3),
                isRequired: !(disableAsLeftSection(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: disableAsLeftSection(formData),
                type: 'number',
                maxLength: '10',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0'
            },
            {
                key: 'F239AltDeviation3',
                value: conditionalValueSelector(disableAsLeftSection(formData), '',
                    AsLeftTestTableDeviationEu3Formula(formData)),
                isDisabled: disableAsLeftSection(formData),
                readOnly: true,
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                inputMarginRight: '4px'
            }]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'NoData2',
                    xs: 4,
                    isLabel: true,
                    padding: '5px 25px 18.5px 0'
                },
                {
                    key: 'F103AltResultLabel',
                    value: 'As-Left Result',
                    xs: 2,
                    readOnly: true,
                    isLabelBold: true,
                    textAlign: 'end',
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    borderBottom: '0',
                },
                {
                    key: 'F103AltResult',
                    value: conditionalValueSelector(disableAsLeftSection(formData), '',
                        AsLeftResultFormulaP65(formData)),
                    isDisabled: disableAsLeftSection(formData),
                    readOnly: true,
                    isCalculatedNew: true,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderTop: conditionalValueSelector(disableAsLeftSection(formData), '', '0'),
                    inputMarginRight: '-2px',
                    borderBottom: '0',
                    marginBottom: '4px',
                    textAlign: 'center',
                }
            ]
        }
    ]
});
