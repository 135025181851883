import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField } from 'helpers/Validation';

export const AsFoundTestTableF574AftTestPoint1Formula = (formData) => (
    FieldHasValue(formData.F117InstrumentCalibratedUrv)
    && FieldHasValue(formData.F116InstrumentCalibratedLrv))
    ? '0% - '
        .concat(ValidateField(formData.F116InstrumentCalibratedLrv))
        .concat(' ')
        .concat(ValidateField(formData.F118InstrumentEngineeringUnits)) : '0%';

export const AsFoundTestTableF575AftTestPoint2Formula = (formData) => (
    FieldHasValue(formData.F117InstrumentCalibratedUrv)
    && FieldHasValue(formData.F116InstrumentCalibratedLrv))
    ? '50% - '
        .concat(ExponentialToDecimal(ValidateNumberField(formData.F116InstrumentCalibratedLrv)
            + (ValidateNumberField(formData.F117InstrumentCalibratedUrv) - ValidateNumberField(formData.F116InstrumentCalibratedLrv)) * (0.5)))
        .concat(' ')
        .concat(ValidateField(formData.F118InstrumentEngineeringUnits)) : '50%';

export const AsFoundTestTableF576AftTestPoint3Formula = (formData) => (
    FieldHasValue(formData.F117InstrumentCalibratedUrv)
    && FieldHasValue(formData.F116InstrumentCalibratedLrv))
    ? '100% - '
        .concat(ValidateField(formData.F117InstrumentCalibratedUrv))
        .concat(' ')
        .concat(ValidateField(formData.F118InstrumentEngineeringUnits)) : '100%';

export const AsFoundTestTableDeviationEu1Formula = (formData) => FieldHasValue(formData.F577AftBathBlockTemperature1) && FieldHasValue(formData.F580AftThermometerReadout1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F577AftBathBlockTemperature1) - ValidateNumberField(formData.F580AftThermometerReadout1))) : '';
export const AsFoundTestTableDeviationEu2Formula = (formData) => FieldHasValue(formData.F578AftBathBlockTemperature2) && FieldHasValue(formData.F581AftThermometerReadout2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F578AftBathBlockTemperature2) - ValidateNumberField(formData.F581AftThermometerReadout2))) : '';
export const AsFoundTestTableDeviationEu3Formula = (formData) => FieldHasValue(formData.F579AftBathBlockTemperature3) && FieldHasValue(formData.F582AftThermometerReadout3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F579AftBathBlockTemperature3) - ValidateNumberField(formData.F582AftThermometerReadout3))) : '';
