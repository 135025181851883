import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useState } from 'react';
import {
    inputFieldTypesHeaderData, inputFieldTypesApiEndpoint,
    searchDataInitialState, inputFieldTypesInitialState
} from './InputFieldTypes.data';
import InputFieldTypesForm from './InputFieldTypesForm';

const addTitle = 'Add New Record for Input Field Type';
const editTitle = 'Edit Record for Input Field Type';

const InputFieldTypes = () => {
    const apiEndpoints = inputFieldTypesApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(inputFieldTypesInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    // the below object is being used in datagrid to refresh the table on add/update
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    // to highlight the updated or newly added row
    const [rowDetails, setRowDetails] = useState({
        columnName: 'InputFieldTypeId',
        rowNumber: ''
    });

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };

    const handleAddRecord = () => {
        setEditRowData(inputFieldTypesInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={inputFieldTypesHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={searchDataInitialState}
                rowDetails={rowDetails}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <InputFieldTypesForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};

export default InputFieldTypes;
