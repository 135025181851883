import { Setpoint } from 'journeys/portal/Forms/Sections.constants';
import {
    paddingLabel, setpointNote, highHighSetpointComment, lowSetpointComment, lowLowSetpointComment,
    highSetPointComment, highHighSetpointCommentValue, highSetpointCommentValue, lowLowSetpointCommentValue, lowSetpointCommentValue,
    borderBottomHHDisable, borderBottomDisable, borderBottomLDisable
} from '../C11/C11.data';
import { disableDpsFields, signalToTriggerIsErrorCheck } from '../../../SignalToTrigger/SignalToTrigger.data';

const calculateMarginBottom = (formData, selectedTag, formName) => {
    const commentExists = formData.F449LowLowSetpointComment ?? selectedTag?.LowLowSetpointComment;
    return !commentExists || signalToTriggerIsErrorCheck(formName, formData) ? '4px' : '';
};

const setpointValue = (formData, selectedTag, formName) => selectedTag.DateTested && !signalToTriggerIsErrorCheck(formName, formData);
const highHighSetpointValue = (formData, selectedTag, formName) => setpointValue(formData, selectedTag, formName) ? formData?.F240HighHighSetpoint
    : disableDpsFields(formData.F472SignalToTriggerLevelPriortoTest, selectedTag?.HighHighSetpoint, formData?.F240HighHighSetpoint, formName);
const highSetpointValue = (formData, selectedTag, formName) => setpointValue(formData, selectedTag, formName) ? formData?.F32HighSetpoint
    : disableDpsFields(formData.F472SignalToTriggerLevelPriortoTest, selectedTag?.SetPointHigh, formData?.F32HighSetpoint, formName);
const lowSetpointValue = (formData, selectedTag, formName) => setpointValue(formData, selectedTag, formName) ? formData?.F33LowSetpoint
    : disableDpsFields(formData.F472SignalToTriggerLevelPriortoTest, selectedTag?.LowSetPoint, formData?.F33LowSetpoint, formName);
const lowLowSetpointValue = (formData, selectedTag, formName) => setpointValue(formData, selectedTag, formName) ? formData?.F241LowLowSetpoint
    : disableDpsFields(formData.F472SignalToTriggerLevelPriortoTest, selectedTag?.LowLowSetpoint, formData?.F241LowLowSetpoint, formName);
const lowLowsetpointCommentCondition = (formData, selectedTag, formName) => lowLowSetpointComment(selectedTag, formData)
    && !signalToTriggerIsErrorCheck(formName, formData);
const lowsetpointCommentCondition = (formData, selectedTag, formName) => lowSetpointComment(selectedTag, formData)
    && !signalToTriggerIsErrorCheck(formName, formData);
const highHighSetpointCommentCondition = (formData, selectedTag, formName) => highHighSetpointComment(selectedTag, formData)
    && !signalToTriggerIsErrorCheck(formName, formData);
const highSetpointCommentCondition = (formData, selectedTag, formName) => highSetPointComment(selectedTag, formData)
    && !signalToTriggerIsErrorCheck(formName, formData);

export const SetpointSectionDataTestDeviceReading = (selectedTag, formName, formData) => {
    const MarginBottom = calculateMarginBottom(formData, selectedTag, formName);
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'remark',
                    label: setpointNote,
                    isLabel: true,
                    xs: 12,
                    fontWeight: '600',
                    labelWidth: '99%',
                    padding: '5px 1px 10px 3px',
                    fontSize: '8.5pt',
                    color: '#ea7c34',
                    marginTop: '0'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F240HighHighSetpointLabel',
                        label: 'High High Setpoint',
                        xs: 2,
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'left',
                        fontSize: '9pt',
                        fontWeight: '600',
                    },
                    {
                        key: 'F240HighHighSetpoint',
                        value: highHighSetpointValue(formData, selectedTag, formName),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        marginBottom: '0',
                        labelWidth: '0',
                        width: '100%',
                        height: '1.25rem',
                        textAlign: 'center',
                        borderBottom: borderBottomHHDisable(selectedTag, formData),
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    },
                    {
                        key: 'F806TestDeviceReading',
                        label: Setpoint.TEST_DEVICE_READING,
                        value: signalToTriggerIsErrorCheck(formName, formData) ? '' : formData.F806TestDeviceReading,
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '51%',
                        width: '49%',
                        height: '1.25rem',
                        marginRight: '0.3rem',
                        textAlign: 'center',
                        borderBottom: borderBottomHHDisable(selectedTag, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    },
                    {
                        key: 'F51HmiReading1',
                        label: Setpoint.HMI_READING,
                        value: signalToTriggerIsErrorCheck(formName, formData) ? '' : formData.F51HmiReading1,
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '50%',
                        width: '50%',
                        height: '1.25rem',
                        marginRight: '0.5rem',
                        textAlign: 'center',
                        borderRight: '0',
                        borderBottom: borderBottomHHDisable(selectedTag, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                    }]
            },
            highHighSetpointCommentCondition(formData, selectedTag, formName) ? {
                key: 3,
                fields: [
                    {
                        key: 'F448HighHighSetpointCommentLabel',
                        label: 'High High Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F448HighHighSetpointComment',
                        value: highHighSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: paddingLabel,
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 3,
                fields: []
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F32HighSetpointLabel',
                        label: 'High Setpoint',
                        xs: 2,
                        height: '1.2rem',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'left',
                        fontSize: '9pt',
                        fontWeight: '600',
                    },
                    {
                        key: 'F32HighSetpoint',
                        value: highSetpointValue(formData, selectedTag, formName),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        marginBottom: '0',
                        labelWidth: '0',
                        width: '100%',
                        textAlign: 'center',
                        height: '1.2rem',
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                        borderBottom: borderBottomDisable(selectedTag, formData)
                    },

                    {
                        key: 'F807TestDeviceReading',
                        label: Setpoint.TEST_DEVICE_READING,
                        value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F807TestDeviceReading : '',
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '51%',
                        width: '49%',
                        textAlign: 'center',
                        height: '1.2rem',
                        marginRight: '0.3rem',
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                        borderBottom: borderBottomDisable(selectedTag, formData)
                    },
                    {
                        key: 'F53HmiReading3',
                        label: Setpoint.HMI_READING,
                        value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F53HmiReading3 : '',
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '50%',
                        width: '50%',
                        textAlign: 'center',
                        height: '1.2rem',
                        marginRight: '0.5rem',
                        borderRight: '0',
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                        borderBottom: borderBottomDisable(selectedTag, formData)
                    }]
            },
            highSetpointCommentCondition(formData, selectedTag, formName) ? {
                key: 5,
                fields: [
                    {
                        key: 'F796HighSetpointCommentLabel',
                        label: 'High Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                    },
                    {
                        key: 'F796HighSetpointComment',
                        value: highSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: paddingLabel,
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 5,
                fields: []
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'F33LowSetpointLabel',
                        label: 'Low Setpoint',
                        xs: 2,
                        height: '1.2rem',
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'left',
                        fontSize: '9pt',
                        fontWeight: '600',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F33LowSetpoint',
                        value: lowSetpointValue(formData, selectedTag, formName),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0',
                        width: '100%',
                        textAlign: 'center',
                        height: '1.2rem',
                        borderBottom: borderBottomLDisable(selectedTag, formData),
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    },
                    {
                        key: 'F808TestDeviceReading',
                        label: Setpoint.TEST_DEVICE_READING,
                        value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F808TestDeviceReading : '',
                        type: 'number',
                        maxLength: '10',
                        textAlign: 'center',
                        xs: 4,
                        height: '1.2rem',
                        labelWidth: '51%',
                        width: '49%',
                        borderBottom: borderBottomLDisable(selectedTag, formData),
                        marginRight: '0.3rem',
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    },
                    {
                        key: 'F54HmiReading4',
                        label: Setpoint.HMI_READING,
                        value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F54HmiReading4 : '',
                        type: 'number',
                        maxLength: '10',
                        textAlign: 'center',
                        xs: 4,
                        height: '1.2rem',
                        labelWidth: '50%',
                        width: '50%',
                        marginRight: '0.5rem',
                        borderRight: '0',
                        borderBottom: borderBottomLDisable(selectedTag, formData),
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    }]
            },
            lowsetpointCommentCondition(formData, selectedTag, formName) ? {
                key: 7,
                fields: [
                    {
                        key: 'F797LowSetpointCommentLabel',
                        label: 'Low Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                        fontStyle: 'italic'
                    },
                    {
                        key: 'F797LowSetpointComment',
                        value: lowSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: paddingLabel,
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 7,
                fields: []
            },
            {
                key: 8,
                fields: [
                    {
                        key: 'F241LowLowSetpointLabel',
                        label: 'Low Low Setpoint',
                        xs: 2,
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'left',
                        fontSize: '9pt',
                        fontWeight: '600',
                        marginLeft: '0.2rem',
                    },
                    {
                        key: 'F241LowLowSetpoint',
                        value: lowLowSetpointValue(formData, selectedTag, formName),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0',
                        width: '100%',
                        height: '1.25rem',
                        textAlign: 'center',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: !lowLowSetpointComment(selectedTag, formData) ? 0 : '',
                        marginBottom: MarginBottom,
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    },
                    {
                        key: 'F809TestDeviceReading',
                        label: Setpoint.TEST_DEVICE_READING,
                        value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F809TestDeviceReading : '',
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '51%',
                        width: '49%',
                        marginRight: '0.3rem',
                        height: '1.25rem',
                        textAlign: 'center',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: !lowLowSetpointComment(selectedTag, formData) ? 0 : '',
                        marginBottom: MarginBottom,
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    },
                    {
                        key: 'F52HmiReading2',
                        label: Setpoint.HMI_READING,
                        value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F52HmiReading2 : '',
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '50%',
                        width: '50%',
                        height: '1.25rem',
                        marginRight: '0.5rem',
                        textAlign: 'center',
                        borderRight: '0',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: !lowLowSetpointComment(selectedTag, formData) ? 0 : '',
                        marginBottom: MarginBottom,
                        isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                        readOnly: signalToTriggerIsErrorCheck(formName, formData),
                    }]
            },
            lowLowsetpointCommentCondition(formData, selectedTag, formName) ? {
                key: 9,
                fields: [
                    {
                        key: 'F449LowLowSetpointCommentLabel',
                        label: 'Low Low Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        padding: '5px 0 3px 3px',
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                        fontStyle: 'italic'
                    },
                    {
                        key: 'F449LowLowSetpointComment',
                        value: lowLowSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: '5px 0 3px 3px',
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 9,
                fields: []
            }
        ]
    };
};
