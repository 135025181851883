export const VisualInspectSectionDataM65 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect  condition of acoustic gas detector and security of fixtures, 
                check for possible contamination or damage to microphone head. Note: If contamination or 
                damage is visible continue with function test so that an ‘as found’ condition can be achieved`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]

});
