import { FieldHasValue } from 'helpers/Validation';

export const AsLeftResultFormulaM63andM64 = (formData) => {
    if (FieldHasValue(formData.F413AltConfirmTestTorch) && FieldHasValue(formData.F378AltHmistatus1)) {
        if (formData.F413AltConfirmTestTorch === 'YES' && formData.F378AltHmistatus1 === 'ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
