import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataM63andM64 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'DetectorReinstatement',
                label: 'Detector Reinstatement',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '0'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F382HmistatusAfterTest',
                    label: 'HMI Status after test',
                    value: formData.F382HmistatusAfterTest,
                    isRequired: true,
                    isDropDown: true,
                    xs: 6,
                    labelWidth: '58.5%',
                    width: '41.5%',
                    inputTextAlign: 'center',
                    placeholder: Placeholder.HMI_STATUS_PLACEHOLDER,
                    options: [{ name: Placeholder.HMI_STATUS_PLACEHOLDER, value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                    direction: 'rtl'
                },
                {
                    key: 'nodata',
                    isLabel: true,
                    xs: 1
                },
                {
                    key: 'F85TestResult',
                    label: 'Test Result',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 5,
                    width: '55.8%',
                    labelWidth: '44.2%',
                    marginRight: '0.5rem',
                }
            ]
        }
    ]
});
