import { AsLeftResultFormulaM68 } from './M68.formulas';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const AsLeftTestTableSectionDataM68 = (selectedTag, formData) => {
    const disableAsLeftTestField = formData.F383IsThereHazardousAreaRestriction === 'YES' || formData.F102AftResult === 'PASS';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'ASLEFTTEST',
                    label: 'As-Left Test - Field',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 5px 8px'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F388AltConfirmThatExternalHeatAppliedFieldLabel',
                        label: 'Confirm that external heat has been applied to the detector in the field (any heat source such as hot air gun or magnet)',
                        xs: 4,
                        labelWidth: '98%',
                        padding: '0 0 0 8px',
                        isLabel: true,
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F388AltConfirmThatExternalHeatAppliedField',
                        value: disableAsLeftTestField ? '' : formData.F388AltConfirmThatExternalHeatAppliedField,
                        isRequired: !disableAsLeftTestField,
                        isDisabled: disableAsLeftTestField,
                        readOnly: disableAsLeftTestField,
                        xs: 2,
                        width: '100%',
                        labelWidth: '0',
                        isDropdown: !disableAsLeftTestField,
                        height: '3.24rem',
                        inputTextAlign: 'center',
                        placeholder: 'Select YES or NO',
                        options: [{ name: 'Select YES or NO', value: '' },
                        { name: 'YES', value: 'YES' },
                        { name: 'NO', value: 'NO' }],
                        paddingPlaceholder: '13px',
                        arrowMarginTop: '13px'
                    },
                ]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F109AltHmireading1Label',
                        label: 'HMI Reading',
                        xs: 4,
                        labelWidth: '98%',
                        padding: '5px 0 5px 8px',
                        borderLeft: '0',
                        isLabel: true,
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F109AltHmireading1',
                        value: disableAsLeftTestField ? '' : formData.F109AltHmireading1,
                        isRequired: !disableAsLeftTestField,
                        isDisabled: disableAsLeftTestField,
                        readOnly: disableAsLeftTestField,
                        xs: 2,
                        borderBottom: '0',
                        borderTop: '0',
                        width: '100%',
                        labelWidth: '0',
                        isDropdown: !disableAsLeftTestField,
                        height: disableAsLeftTestField ? '1.4rem' : '1.5rem',
                        inputTextAlign: 'center',
                        placeholder: 'Select HMI Reading',
                        options: [{ name: 'Select HMI Reading', value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'ALARM', value: 'ALARM' },
                        { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                        marginBottom: '4px'
                    },
                    {
                        key: 'F103AltResultLabel',
                        label: 'As-Left Field Result',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '700',
                        labelWidth,
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F103AltResult',
                        value: disableAsLeftTestField ? '' : AsLeftResultFormulaM68(formData),
                        isDisabled: disableAsLeftTestField,
                        isCalculatedNew: true,
                        xs: 2,
                        readOnly: true,
                        fontWeight: '700',
                        borderBottom: '0',
                        borderRight: '0',
                        inputMarginRight: '4px',
                        width: '100%',
                        height: '1.25rem',
                        textAlign: 'center',
                        labelWidth: '0',
                        direction: 'rtl'
                    }]
            }
        ]
    };
};
