import { F103AltResultFormula, isAltRawValuePeakReadingRequired, isAltSandRatePeakReadingRequired } from './P66.formula';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { paddingVal } from '../../../AsFoundTestTableSection/Data';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';

export const disableAsLeftTestFields = (formData) => disableAsLeftTestRequired(formData) || formData.F619AsLeftTestRequired === 'NO';
export const disableAsLeftTestRequired = (formData) => formData.F102AftResult === 'PASS';

export const AsLeftTestTableSectionDataP66 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsLeftTest',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F945AltSandPresenceSimulationMethodLabel',
                    label: 'Sand Presence Simulation Method',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F945AltSandPresenceSimulationMethod',
                    value: disableAsLeftTestFields(formData) ? '' : formData.F945AltSandPresenceSimulationMethod,
                    isDisabled: disableAsLeftTestFields(formData),
                    isRequired: !disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    isDropdown: !disableAsLeftTestFields(formData),
                    placeholder: 'Select Method',
                    options: [{ name: 'Select Method', value: '' },
                    { name: 'Wire Brush Rubbing', value: 'Wire Brush Rubbing' },
                    { name: 'Sand Injection', value: 'Sand Injection' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    height: disableAsLeftTestFields(formData) ? '1.21rem' : '1.61rem'
                },
                {
                    key: 'F946AltRawValuePeakReadingLabel',
                    label: 'Raw Value Peak Reading',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    padding: paddingLabel,
                    fontSize: '9pt',
                    textAlign: 'right'
                },
                {
                    key: 'F946AltRawValuePeakReading',
                    value: disableAsLeftTestFields(formData) ? '' : formData.F946AltRawValuePeakReading,
                    isRequired: !disableAsLeftTestFields(formData) && isAltRawValuePeakReadingRequired(formData),
                    isDisabled: disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    fontWeight: '700',
                    height: disableAsLeftTestFields(formData) ? '1.2rem' : '1.25rem',
                    type: 'number',
                    maxLength: '10'
                }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F947AltSimulatedSandPresenceLabel',
                    label: 'Simulated Sand Presence',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt'
                },
                {
                    key: 'F947AltSimulatedSandPresence',
                    value: disableAsLeftTestFields(formData) ? '' : formData.F947AltSimulatedSandPresence,
                    isDisabled: disableAsLeftTestFields(formData),
                    isRequired: !disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    isDropdown: !disableAsLeftTestFields(formData),
                    placeholder: Placeholder.SELECT_YES_OR_NO,
                    options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderTop: '0',
                    height: disableAsLeftTestFields(formData) ? '1.2rem' : '1.5rem'
                },
                {
                    key: 'F948AltSandRatePeakReadingLabel',
                    label: 'Sand Rate Peak Reading (gram/sec)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    padding: paddingLabel,
                    fontSize: '9pt',
                    textAlign: 'right'
                },
                {
                    key: 'F948AltSandRatePeakReading',
                    value: disableAsLeftTestFields(formData) ? '' : formData.F948AltSandRatePeakReading,
                    isRequired: !disableAsLeftTestFields(formData) && isAltSandRatePeakReadingRequired(formData),
                    isDisabled: disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    fontWeight: '700',
                    borderTop: '0',
                    height: disableAsLeftTestFields(formData) ? '1.2rem' : '1.25rem',
                    type: 'number',
                    maxLength: '10'
                }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F949AltObservedNoticeableSpikeLabel',
                    label: 'Observed Noticeable Spike',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt'
                },
                {
                    key: 'F949AltObservedNoticeableSpike',
                    value: disableAsLeftTestFields(formData) ? '' : formData.F949AltObservedNoticeableSpike,
                    isDisabled: disableAsLeftTestFields(formData),
                    isRequired: !disableAsLeftTestFields(formData),
                    readOnly: disableAsLeftTestFields(formData),
                    isDropdown: !disableAsLeftTestFields(formData),
                    placeholder: Placeholder.SELECT_YES_OR_NO,
                    options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderTop: '0',
                    borderBottom: '0',
                    marginBottom: '4px',
                    height: '1.5rem'
                },
                {
                    key: 'F103AltResultLabel',
                    label: 'As-Left Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    padding: paddingLabel,
                    fontSize: '9pt',
                    textAlign: 'right'
                },
                {
                    key: 'F103AltResult',
                    value: disableAsLeftTestFields(formData) ? '' : F103AltResultFormula(formData),
                    isDisabled: disableAsLeftTestFields(formData),
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0%',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    fontWeight: '700',
                    isLabelBold: true,
                    borderTop: '0',
                    borderBottom: '0',
                    height: disableAsLeftTestFields(formData) ? '1.5rem' : '1.38rem',
                    marginBottom: '4px'
                }]
        },
    ]
});
