import { FieldHasValue, ValidateNumberField, ValidateField } from 'helpers/Validation';

const evaluateResultFormula = (formData, closureTimeField, openingTimeField) => {
    const { F344ValveTravelTimeRequirement, F957ValveFailureMode } = formData;
    if (FieldHasValue(F344ValveTravelTimeRequirement) && FieldHasValue(F957ValveFailureMode)) {
        if (F957ValveFailureMode === 'FC' && FieldHasValue(formData[closureTimeField])) {
            return ValidateField(formData[closureTimeField]) <= ValidateNumberField(F344ValveTravelTimeRequirement) ? 'PASS' : 'FAIL';
        }
        if (F957ValveFailureMode === 'FO' && FieldHasValue(formData[openingTimeField])) {
            return ValidateField(formData[openingTimeField]) <= ValidateNumberField(F344ValveTravelTimeRequirement) ? 'PASS' : 'FAIL';
        }
    }
    return '';
};

export const AsFoundResultFormulaS71 = formData => evaluateResultFormula(formData, 'F965AftValveFullClosureTravelTime', 'F966AftValveFullOpeningTravelTime');

export const AsLeftResultFormulaS71 = formData => evaluateResultFormula(formData, 'F969AltValveFullClosureTravelTime', 'F970AltValveFullOpeningTravelTime');
