import { FieldHasValue } from 'helpers/Validation';

export const AsLeftResultFormula = (formData) => {
    if (FieldHasValue(formData.F408AltTestMethodApplied) && FieldHasValue(formData.F378AltHmistatus1)) {
        if (formData.F408AltTestMethodApplied === 'YES' && formData.F378AltHmistatus1 === 'ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
