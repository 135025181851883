export const AsLeftTestTableSectionDataP891 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsleftTest',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'remark',
                label: 'Remark: As-left test is not applicable to temperature sensors because there is no opportunity to rectify their failures in isolation.',
                fontWeight: '600',
                isLabel: true,
                labelWidth: '100%',
                width: '0',
                fontSize: '8.5pt',
                color: '#ea7c34',
                padding: '5px 0 5px 8px'
            },
            ]
        }]
});
