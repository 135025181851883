import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import { APIEndpoints } from 'helpers/APILists';
import {
    trfHeaderData, trfMasterListApiEndpoint,
    searchDataInitialState, trfMasterListInitialState
} from './TrfMasterList.data';
import TrfMasterForm from './TrfMasterForm';
import { customSort, trackErrors } from '../helper';
import { AdminConstants } from '../../AdminScreen.constants';

const addTitle = 'Add New Record for TRF Master List';
const editTitle = 'Edit Record for TRF Master List';

const TrfMasterList = () => {
    const apiEndpoints = trfMasterListApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(trfMasterListInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    const [dropdownsData, setDropdownsData] = useState({
        regionsList: [],
        trfTableNameList: [],
    });
    // the below object is being used in datagrid to refresh the table on add/update
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    // to highlight the updated or newly added row
    const [rowDetails, setRowDetails] = useState({
        columnName: 'RowId',
        rowNumber: ''
    });

    useEffect(() => {
        axios.get(APIEndpoints.GetMratGroupMappings).then((res) => {
            const liveRegionsList = _.uniq(res.data.filter(y => y.Status === AdminConstants.LIVE_STATUS).map(x => x.BpCompanyCodeName));
            setDropdownsData(prev => ({
                ...prev,
                regionsList: customSort(['ALL', ...liveRegionsList])
            }));
        }).catch((err) => {
            trackErrors({
                messageType: MESSAGE_TYPE.ERROR,
                message: err.message,
                exception: err
            });
        });
    }, []);

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetTableNames)
            .then((res) => {
                setDropdownsData(prev => ({
                    ...prev,
                    trfTableNameList: customSort(res.data.filter(x => x.includes('ITF')))
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };

    const handleAddRecord = () => {
        setEditRowData(trfMasterListInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={trfHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={searchDataInitialState}
                rowDetails={rowDetails}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <TrfMasterForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                        dropdownsData={dropdownsData}
                    />
                )}
        </>
    );
};

export default TrfMasterList;
