import styled from 'styled-components';

export const CaptureImageWrapper = styled.div`
width:50%;
position: fixed;
`;

export const Camera = styled.div`
margin: 0.4rem 0 0 1rem;
cursor: pointer;
`;

export const DisplayMessageWrapper = styled.div`
background-color: black;
width: 70vh;
height: 45vh;
color: white;
margin: 1% 2%;
`;

export const DisplayMessage = styled.p`
font-family: "Segoe UI",sans-serif;
font-size: 12pt;
text-align: center;
padding-top: ${props => props.paddingTop};
vertical-align: middle;   
`;

export const Info = styled.p`
text-align: center;
margin: 5% 5% 2% 5%;
width: 53px;
`;
export const UpdateNoteButton = styled.div`
cursor: pointer;
button {
    border-color: transparent;
    background-color: rgb(73, 130, 5);
    color: #FFFFFF;
    border-radius: 20px;
    width: 8rem;
    height: 28px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    &:hover{
        background-color: rgb(65, 117, 4);
    }
    @media only screen and (max-width: 1020px) {
        left: 0.5rem;
    }
    @media only screen and (min-width: 1021px) {
        left: 2rem;
    }
    position: relative;
}
button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}
`;
