import styled from 'styled-components';

export const FormControlDiv = styled.div`
.MuiFormControl-root {
    display: contents;
}
`;
export const FormTextField = styled.label`
.MuiOutlinedInput-root {
    border-radius: 0;
}

.MuiOutlinedInput-input { 
    padding: 18.5px 5px;
    text-align: ${props => props.textAlign ?? 'start'};}
`;
