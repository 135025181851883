import { FieldHasValue } from 'helpers/Validation';

export const AsLeftResultFormulaP615 = (formData) => {
    if (FieldHasValue(formData.F572SolenoidValvePositionPriorToTest)
        && FieldHasValue(formData.F575AltSolenoidValveActionUponTrip)
        && FieldHasValue(formData.F576AltSolenoidValvePositionAfterTrip)) {
        if (formData.F575AltSolenoidValveActionUponTrip === 'Quick'
            && formData.F576AltSolenoidValvePositionAfterTrip !== formData.F572SolenoidValvePositionPriorToTest) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
