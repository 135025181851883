import { FoundResultFormula } from './P8141.formula';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsLeftTestTableSectionDataP8141 = (formData) => {
    const asFoundResult = formData?.F102AftResult === 'PASS';
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'As-LeftTest',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F783AltMovedTargetPositionActivateSwitch',
                label: 'Moved Target\'s Position to Activate Switch',
                value: asFoundResult ? '' : formData.F783AltMovedTargetPositionActivateSwitch,
                xs: 6,
                isRequired: !asFoundResult,
                width: '48.5%',
                labelWidth: '51.5%',
                isDropdown: !asFoundResult,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                textAlign: 'center',
                inputTextAlign: 'center',
                borderBottom: '0',
                isDisabled: asFoundResult,
                readOnly: asFoundResult
            },
            {
                key: 'F784AltMovedTargetPositionDeactivateSwitch',
                label: 'Moved Target\'s Position to Deactivate Switch',
                value: asFoundResult ? '' : formData.F784AltMovedTargetPositionDeactivateSwitch,
                isRequired: !asFoundResult,
                isDropdown: !asFoundResult,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                width: '48.5%',
                labelWidth: asFoundResult ? '60.5%' : '57.5%',
                marginRight: '0.5rem',
                direction: 'rtl',
                rootMarginRight: '4px',
                inputMarginRight: '4px',
                textAlign: 'center',
                inputTextAlign: 'center',
                borderRight: '0',
                borderBottom: '0',
                isDisabled: asFoundResult,
                readOnly: asFoundResult
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F785AltConfirmSwitchActivatesCorrectly',
                label: 'Confirm Switch Activates Correctly',
                value: asFoundResult ? '' : formData.F785AltConfirmSwitchActivatesCorrectly,
                xs: 6,
                isRequired: !asFoundResult,
                width: '48.5%',
                labelWidth: '51.5%',
                isDropdown: !asFoundResult,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                textAlign: 'center',
                inputTextAlign: 'center',
                isDisabled: asFoundResult,
                readOnly: asFoundResult
            },
            {
                key: 'F786AltConfirmSwitchDeactivatesCorrectly',
                label: 'Confirm Switch Deactivates Correctly',
                value: asFoundResult ? '' : formData.F786AltConfirmSwitchDeactivatesCorrectly,
                isRequired: !asFoundResult,
                isDropdown: !asFoundResult,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                xs: 6,
                width: '48.5%',
                labelWidth: asFoundResult ? '60.5%' : '57.5%',
                marginRight: '0.5rem',
                direction: 'rtl',
                rootMarginRight: '4px',
                inputMarginRight: '4px',
                textAlign: 'center',
                inputTextAlign: 'center',
                borderRight: '0',
                borderBottom: '0',
                height: asFoundResult ? '1.24rem' : '1.5rem',
                isDisabled: asFoundResult,
                readOnly: asFoundResult
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'NoData1',
                    xs: 6,
                    isLabel: true,
                    height: '1.24rem',
                },
                {
                    key: 'F103AltResult',
                    label: 'As-Left Result',
                    isCalculatedNew: !asFoundResult,
                    xs: 6,
                    value: asFoundResult ? '' : FoundResultFormula(formData),
                    readOnly: true,
                    labelWidth: asFoundResult ? '67.8%' : '65.5%',
                    width: '54.2%',
                    marginRight: '0.5rem',
                    textAlign: 'center',
                    direction: 'rtl',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    height: '1.24rem',
                    borderBottom: '0',
                    marginBottom: '4px',
                    isDisabled: asFoundResult,
                }]
        }]
    };
};
