import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const RadioGroupWrapper = styled.div`
display: flex;
.MuiIconButton-colorSecondary {
    color: #498205;
}
.MuiRadio-colorSecondary.Mui-checked {
    color: #498205;
}
`;

export const RadioNames = styled.div`
display: flex;
font-size: 20px;
font-weight: 700;
max-width: ${props => props.maxWidth ? props.maxWidth : '606px'};
padding-bottom: 5px;
color: #498205;
.MuiTypography-body1 {
    font-size: 20px;
}
`;

export const RadioLabel = styled.div`
margin: ${props => props.margin ? props.margin : '0 1rem'};
padding: 10px 0;
font-size: 20px;
font-weight: 700;
color: #498205;
`;
export const useStyles = makeStyles(() => ({
    root: {
        width: '550px',
        alignSelf: 'center',
        '&.MuiLinearProgress-root .MuiLinearProgress-barColorPrimary': {
            backgroundColor: 'green'
        },
        '&.MuiLinearProgress-colorPrimary': {
            backgroundColor: '#B6BCE2'
        },
    },
}));
