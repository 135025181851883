import React from 'react';
import PropTypes from 'prop-types';
import { AssetImageBox } from './HomeImages.styled';

const HomeImages = (props) => (
    props.image
        ? (
            <div>
                <AssetImageBox>
                    <img width="200px" height="200px" src={`data:image/png;base64, ${props.image}`} alt="assetimage" />
                </AssetImageBox>
            </div>
        )
        : ''
);

export default HomeImages;

HomeImages.propTypes = {
    image: PropTypes.string
};
