import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import _ from 'lodash';
import { APIEndpoints } from 'helpers/APILists';
import { formatDate } from 'helpers/DateFormatter';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import DropdownInput from '../../elements/DropdownInput';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { trackErrors } from '../helper';
import AutocompleteInput from '../../elements/AutocompleteInput';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { strEquals } from '../../AdminScreen.helper';

const TrfMasterForm = ({ isOpen, title, onClose, rowData, onRecordSubmit, dropdownsData }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [trfIDExistError, setTrfIDExistError] = useState('');
    const [trfCountError, setTrfCountError] = useState('');
    const [cancelAlert, setCancelAlert] = useState(false);
    const [trfList, setTrfList] = useState([]);

    const { instance } = useMsal();
    const userName = instance.getActiveAccount();

    const FooterButtonVisible = !_.isEqual(rowData, createRowData)
        && !trfIDExistError
        && !trfCountError
        && createRowData.TrfId
        && createRowData.TrfLongDesc
        && createRowData.TrfShortDescReplaceWhenActive
        && createRowData.WorkType
        && createRowData.TrfType
        && createRowData.TrfCount !== null
        && createRowData.TrfCount !== ''
        && ((createRowData.Status === 'Active'
            && createRowData.MratTrfTableName)
            || createRowData.Status === 'Inactive');

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetTrfMasterLists).then((res) => {
            setTrfList(res.data.map((data) => data));
        }).catch((err) => {
            trackErrors({
                messageType: MESSAGE_TYPE.ERROR,
                message: err.message,
                exception: err
            });
        });
    }, []);

    useEffect(() => {
        if (!_.isEqual(rowData, createRowData)) {
            if (createRowData.TrfType === 'Single') {
                setCreateRowData(prev => ({
                    ...prev,
                    TrfCount: 1
                }));
            } else if (createRowData.TrfType === 'N/A') {
                setCreateRowData(prev => ({
                    ...prev,
                    TrfCount: 0
                }));
            } else {
                setCreateRowData(prev => ({
                    ...prev,
                    TrfCount: ''
                }));
            }
            setTrfCountError('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createRowData.TrfType]);

    const handleInputChange = ((e) => {
        if (e.target.name === 'TrfCount' && (e.target.value < 0 || e.target.value > 9) && e.target.value !== '') {
            return;
        }
        setCreateRowData((prev) => (
            {
                ...prev,
                [e.target.name]: e.target.value
            }
        ));
    });

    const handleAutocompleteChange = ((e, val) => {
        const value = val || null;
        const name = e.target.id.split('-')[0];
        setCreateRowData((prev) => (
            {
                ...prev,
                [name]: value
            }
        ));
    });

    const handleSubmitButton = () => {
        createRowData.TrfShortDesc = createRowData.Status !== 'Active'
            ? `${createRowData.TrfShortDescReplaceWhenActive} (Not Developed Yet)`
            : createRowData.TrfShortDescReplaceWhenActive;
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.RowId) {
            updateTRFId(createRowData);
        } else {
            createRowData.RowId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitTRFId(createRowData);
        }
    };

    const resetButtonHandler = () => {
        setTrfIDExistError('');
        setCreateRowData(rowData);
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const handleBlur = () => {
        setTrfIDExistError('');
        if (trfList.find(x => strEquals(x.TrfId, createRowData.TrfId)
            && x.RowId !== createRowData.RowId)) {
            setTrfIDExistError('*(TRF ID Already Exist)');
        }
    };

    const handleTrfTypeBlur = () => {
        setTrfCountError('');
        if (createRowData.TrfCount && createRowData.TrfCount < 2 && createRowData.TrfType === 'Multi') {
            setTrfCountError(' *(TRF Count should be greater than 1)');
        }
    };

    const submitTRFId = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_SubmitTrfID, dataRow)
            .then((res) => {
                if (res.data.TrfId) {
                    onClose();
                    onRecordSubmit(res.data.RowId);
                }
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateTRFId = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateTrfID, dataRow)
            .then((res) => {
                if (res.data.TrfId) {
                    onClose();
                    onRecordSubmit(res.data.RowId);
                }
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}

            <DialogContent>

                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="TrfId"
                        errorMsg={trfIDExistError}
                        title="TRF ID"
                        value={createRowData.TrfId}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        type="string"
                        maxLength="10"
                    />
                    <AutocompleteInput
                        name="MratTrfTableName"
                        title="TRF Database Table Name"
                        options={dropdownsData.trfTableNameList}
                        value={createRowData.MratTrfTableName}
                        handleInputChange={handleAutocompleteChange}
                        required={createRowData.Status === 'Active'}
                    />
                    <TextInput
                        name="TrfLongDesc"
                        title="TRF Long Desc"
                        value={createRowData.TrfLongDesc}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="65"
                        isMultiline
                    />
                    <TextInput
                        name="TrfShortDesc"
                        title="TRF Short Desc"
                        value={createRowData.Status !== 'Active'
                            ? `${createRowData.TrfShortDescReplaceWhenActive} (Not Developed Yet)`
                            : createRowData.TrfShortDescReplaceWhenActive}
                        disabled
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="55"
                    />
                    <TextInput
                        name="TrfShortDescReplaceWhenActive"
                        title="TRF Short Desc Replace When Active"
                        value={createRowData.TrfShortDescReplaceWhenActive}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        maxLength="35"
                    />
                    <DropdownInput
                        name="ApplicableRegions"
                        title="Applicable Regions"
                        value={createRowData.ApplicableRegions}
                        options={dropdownsData.regionsList}
                        handleInputChange={handleInputChange}
                        required
                    />
                    <DropdownInput
                        name="WorkType"
                        title="Work Type"
                        value={createRowData.WorkType}
                        options={['Corrective', 'Preventative']}
                        handleInputChange={handleInputChange}
                        required
                    />
                    <DropdownInput
                        name="TrfType"
                        title="TRF Type"
                        value={createRowData.TrfType}
                        options={['Multi', 'N/A', 'Single']}
                        handleBlur={handleTrfTypeBlur}
                        handleInputChange={handleInputChange}
                        required
                    />
                    <div />
                    <TextInput
                        name="TrfCount"
                        title="TRF Count"
                        errorMsg={trfCountError}
                        handleBlur={handleTrfTypeBlur}
                        disabled={createRowData.TrfType !== 'Multi'}
                        value={createRowData.TrfCount}
                        handleInputChange={handleInputChange}
                        type="number"
                        required
                    />
                    <DropdownInput
                        name="Status"
                        title="TRF List Status"
                        value={createRowData.Status}
                        options={['Active', 'Inactive']}
                        handleInputChange={handleInputChange}
                        required
                    />
                    <DropdownInput
                        name="DpsListStatus"
                        title="DPS List Status"
                        value={createRowData.DpsListStatus}
                        options={['Active', 'Inactive']}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.RowId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.RowId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData.LastModifiedDate || formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '10px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        type="string"
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i> Note: The TRF Database Table Name is in a sepcific format: ITF_FormName_FormNumber (Without Decimal) Ex: C1-3.2 -- ITF_C1_32, M6-9 -- ITF_M6_9</i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i>
                            If TRF Database Table Name is not available in the list either keep the TRF List Status as Inactive
                            and leave it as empty or create a new table in the Database.
                        </i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i> If Applicable Region is not available in the list please add a new record in Group Mapping table.</i>
                    </InputLabel>
                </div>
                <div style={{ display: 'flex', gap: '0 8px' }}>
                    {FooterButtonVisible && (
                        <Button
                            classes={{ root: classes.button }}
                            onClick={handleSubmitButton}
                            variant="outlined"
                        >
                            Submit
                        </Button>
                    )}
                    <Button
                        classes={{ root: classes.button }}
                        onClick={resetButtonHandler}
                        variant="outlined"
                    >
                        Reset
                    </Button>
                    <Button
                        classes={{ root: classes.button }}
                        onClick={cancelButtonHandler}
                        variant="outlined"
                    >
                        Close
                    </Button>
                </div>
            </DialogActions>
        </Dialog>

    );
};
export default TrfMasterForm;

TrfMasterForm.propTypes = {
    dropdownsData: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string,

};
