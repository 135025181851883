import { FunctionTestType } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';

export const FireSuppressionSectionDataM71 = (selectedTag, distinctTestMakeModelData, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'remark',
            label: `System type: This is a generic form which may be used for different
             types of fire suppression systems, including but not limited to firewater deluge, water mist, sprinkler, foam, CO2, and clean agent.`,
            isLabel: true,
            xs: 12,
            fontWeight: '600',
            labelWidth: '100%',
            padding: '15px 20px 5px 0',
            fontSize: '8.5pt',
            color: '#ea7c34'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'remark',
            label: `Test type: "Dry" - Extinguishant is NOT released to protected area.
              "Wet" - Extinguishant is released to protected area.`,
            isLabel: true,
            xs: 12,
            fontWeight: '600',
            labelWidth: '100%',
            padding: '0 20px 5px 0',
            fontSize: '8.5pt',
            color: '#ea7c34'
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'F662FireSuppressionSystemTypeLabel',
            label: 'Fire Suppression System Type',
            xs: 4,
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt'
        },
        {
            key: 'F662FireSuppressionSystemType',
            value: formData.F662FireSuppressionSystemType,
            xs: 2,
            maxLength: '24',
            isAutoGrow: true,
            isRequired: true,
            inputWidth: '100%',
            labelWidth: '0%',
            textAlign: 'center',
            marginRight: '0',
        },
        {
            key: 'F663FunctionTestTypeLabel',
            label: 'Function Test Type',
            xs: 4,
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            textAlign: 'right',
            padding: paddingLabel,
        },
        {
            key: 'F663FunctionTestType',
            value: formData.F663FunctionTestType,
            isDropdown: true,
            isRequired: true,
            placeholder: 'Select Function Test Type',
            options: [{ name: 'Select Function Test Type', value: '' },
            { name: FunctionTestType.DRY_TEST, value: FunctionTestType.DRY_TEST },
            { name: 'Wet Test', value: 'Wet Test' }],
            marginRight: '0.5rem',
            width: '100%',
            labelWidth: '0%',
            marginBottom: '15px',
            xs: 2,
            inputTextAlign: 'center',
            textAlignLabel: 'end',
        }]
    }]
});
