import React, { useState } from 'react';
import DataGridComponent from 'components/DataGrid/DataGridComponent';
import {
    DpsDataSourceHeaderData, DpsDataSourceInitialState,
    DpsDataSourceSearchState, dpsDataSourceApiEndpoint
} from './DpsSources.data';
import DpsSourcesForm from './DpsSourcesForm';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import { trackErrors } from '../helper';

const addTitle = 'Add New Record for DPS Data Sources';
const editTitle = 'Edit Record for DPS Data Sources';
const DpsSources = () => {
    const apiEndpoints = dpsDataSourceApiEndpoint;
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'DpssourceId',
        rowNumber: ''
    });
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(DpsDataSourceInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData,
        }));
    };

    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const result = data.map(item => ({
                    ...item,
                    Status: item?.Status ? 'Active' : 'Inactive',
                }));
            resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
                reject();
            }
        })();
    });

    const handleAddRecord = () => {
        setEditRowData(DpsDataSourceInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={DpsDataSourceHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                rowDetails={rowDetails}
                dataParser={parseData}
                initialData={DpsDataSourceSearchState}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <DpsSourcesForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};

export default DpsSources;
