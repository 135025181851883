import { ValidateField, conditionalValueSelector } from 'helpers/Validation';
import { AsFoundTestTableP716Formula } from './P716.formulas';

export const paddingLabel = '5px 0 0 8px';
export const placeholderSelectYes = 'Select YES';
export const placeholderStrokeTestResult = 'Select Stroke Test Result';
export const AsFoundTestTableSectionDataP716 = (selectedTag, formData) => {
    const isAftSolenoidSectionDisabled = !formData.F494Pstsetting
        || formData.F494Pstsetting === 'Smart Positioner';
    const isSolenoidTestingDisabled = isAftSolenoidSectionDisabled
        || formData.F743AftValveInitiatedPartialStrokeTest !== 'YES';
    const isAftSmartSectionDisabled = !formData.F494Pstsetting
        || formData.F494Pstsetting === 'Solenoid Valve';
    const isSmartTestingDisabled = isAftSmartSectionDisabled
        || formData.F747AftSmartInitiatedPartialStrokeTest !== 'YES';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'ASFOUNDTEST',
                    label: 'As-Found Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    width: '28%',
                    padding: paddingLabel
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'NodataP716',
                        xs: 3,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'TestviaSolenoidValve',
                        label: 'Test via Solenoid Valve',
                        isLabel: true,
                        xs: 2,
                        fontWeight: '700',
                        padding: paddingLabel,
                        textAlign: 'center',
                        marginLeft: '21px'
                    },
                    {
                        key: 'NodataP716',
                        xs: 4,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'TestviaSmartPositioner',
                        label: 'Test via Smart Positioner',
                        isLabel: true,
                        xs: 2,
                        fontWeight: '700',
                        padding: paddingLabel,
                        textAlign: 'center',
                        marginLeft: '22px'
                    }]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F743AftValveInitiatedPartialStrokeTest',
                        label: 'Initiated Partial Stroke Test',
                        value: conditionalValueSelector(isAftSolenoidSectionDisabled,
                            '', ValidateField(formData.F743AftValveInitiatedPartialStrokeTest)),
                        isRequired: (!isAftSolenoidSectionDisabled),
                        isDisabled: isAftSolenoidSectionDisabled,
                        readOnly: isAftSolenoidSectionDisabled,
                        isDropdown: !isAftSolenoidSectionDisabled,
                        placeholder: placeholderSelectYes,
                        options: [{ name: placeholderSelectYes, value: '' },
                        { name: 'YES', value: 'YES' }],
                        xs: 6,
                        width: conditionalValueSelector(isAftSolenoidSectionDisabled, '55.1%', '56.4%'),
                        labelWidth: '43%',
                        borderBottom: conditionalValueSelector(isSolenoidTestingDisabled, '', '0'),
                        marginLeft: '7px',
                        textAlign: 'center',
                        inputTextAlign: 'center',
                        height: conditionalValueSelector(isAftSolenoidSectionDisabled, '1.1rem', '1.5rem')
                    },
                    {
                        key: 'F747AftSmartInitiatedPartialStrokeTest',
                        label: 'Initiated Partial Stroke Test',
                        value: conditionalValueSelector(isAftSmartSectionDisabled, '',
                            ValidateField(formData.F747AftSmartInitiatedPartialStrokeTest)),
                        isRequired: (!isAftSmartSectionDisabled),
                        isDisabled: isAftSmartSectionDisabled,
                        readOnly: isAftSmartSectionDisabled,
                        isDropdown: !isAftSmartSectionDisabled,
                        placeholder: placeholderSelectYes,
                        options: [{ name: placeholderSelectYes, value: '' },
                        { name: 'YES', value: 'YES' }],
                        xs: 6,
                        width: conditionalValueSelector(isAftSmartSectionDisabled,
                            '52.7%', '53.4%'),
                        labelWidth: '43.5%',
                        borderBottom: conditionalValueSelector(isSmartTestingDisabled, '', '0'),
                        marginLeft: '7.8px',
                        textAlign: 'center',
                        borderRight: '0',
                        marginRight: '0.47rem',
                        direction: 'rtl',
                        inputTextAlign: 'center',
                        height: conditionalValueSelector(isAftSmartSectionDisabled, '1.1rem', '1.5rem')
                    }
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F744AftValveMeasuredPartialTravelAmount',
                        label: 'Measured Partial Travel Amount (%)',
                        value: conditionalValueSelector(isSolenoidTestingDisabled, '', ValidateField(formData.F744AftValveMeasuredPartialTravelAmount)),
                        isRequired: (!isSolenoidTestingDisabled),
                        isDisabled: isSolenoidTestingDisabled,
                        readOnly: isSolenoidTestingDisabled,
                        xs: 6,
                        width: '55.2%',
                        labelWidth: '43.3%',
                        type: 'number',
                        maxLength: '10',
                        marginLeft: '6px',
                        textAlign: 'center',
                        height: '1.1rem',
                    },
                    {
                        key: 'F748AftSmartMeasuredPartialTravelAmount',
                        label: 'Measured Partial Travel Amount (%)',
                        value: conditionalValueSelector(isSmartTestingDisabled, '', ValidateField(formData.F748AftSmartMeasuredPartialTravelAmount)),
                        isRequired: (!isSmartTestingDisabled),
                        isDisabled: isSmartTestingDisabled,
                        readOnly: isSmartTestingDisabled,
                        xs: 6,
                        borderRight: '0',
                        inputMarginRight: '4px',
                        marginRight: '0.5rem',
                        width: '55%',
                        labelWidth: '47%',
                        textAlign: 'center',
                        textAlignLabel: 'end',
                        height: '1.1rem',
                        marginLeft: '0',
                        type: 'number',
                        maxLength: '10',
                    },
                ]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F745AftValveTravelTimeToTargetPosition',
                        label: 'Travel Time to Target Position (sec)',
                        value: conditionalValueSelector(isSolenoidTestingDisabled,
                            '', ValidateField(formData.F745AftValveTravelTimeToTargetPosition)),
                        isDisabled: isSolenoidTestingDisabled,
                        readOnly: isSolenoidTestingDisabled,
                        xs: 6,
                        width: '55.2%',
                        labelWidth: '43.3%',
                        type: 'number',
                        maxLength: '10',
                        borderBottom: '0',
                        borderTop: '0',
                        marginLeft: '6px',
                        textAlign: 'center',
                        height: '1.1rem',
                    },
                    {
                        key: 'F749AftSmartTravelTimeToTargetPosition',
                        label: 'Travel Time to Target Position (sec)',
                        value: conditionalValueSelector(isSmartTestingDisabled, '',
                            ValidateField(formData.F749AftSmartTravelTimeToTargetPosition)),
                        isDisabled: isSmartTestingDisabled,
                        readOnly: isSmartTestingDisabled,
                        xs: 6,
                        borderRight: '0',
                        borderBottom: '0',
                        borderTop: '0',
                        inputMarginRight: '4px',
                        marginRight: '0.5rem',
                        width: '55%',
                        labelWidth: '47%',
                        direction: 'rtl',
                        textAlign: 'center',
                        height: '1.1rem',
                        marginLeft: '0',
                        type: 'number',
                        maxLength: '10',
                    },
                ]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'F746AftValvePartialStrokeTestResult',
                        label: 'Partial Stroke Test Result',
                        value: conditionalValueSelector(isSolenoidTestingDisabled, '',
                            ValidateField(formData.F746AftValvePartialStrokeTestResult)),
                        isRequired: (!isSolenoidTestingDisabled),
                        isDisabled: isSolenoidTestingDisabled,
                        readOnly: isSolenoidTestingDisabled,
                        isDropdown: !isSolenoidTestingDisabled,
                        placeholder: placeholderStrokeTestResult,
                        options: [{ name: placeholderStrokeTestResult, value: '' },
                        { name: 'Successful', value: 'Successful' },
                        { name: 'Abnormal', value: 'Abnormal' },
                        { name: 'Aborted', value: 'Aborted' }],
                        xs: 6,
                        width: conditionalValueSelector(isSolenoidTestingDisabled, '55.1%', '56.4%'),
                        labelWidth: '43%',
                        type: 'number',
                        maxLength: '10',
                        marginLeft: '7px',
                        textAlign: 'center',
                        inputTextAlign: 'center',
                    },
                    {
                        key: 'F750AftSmartPartialStrokeTestResult',
                        label: 'Partial Stroke Test Result',
                        value: conditionalValueSelector(isSmartTestingDisabled,
                            '', ValidateField(formData.F750AftSmartPartialStrokeTestResult)),
                        isRequired: (!isSmartTestingDisabled),
                        isDisabled: isSmartTestingDisabled,
                        readOnly: isSmartTestingDisabled,
                        isDropdown: !isSmartTestingDisabled,
                        placeholder: placeholderStrokeTestResult,
                        options: [{ name: placeholderStrokeTestResult, value: '' },
                        { name: 'Successful', value: 'Successful' },
                        { name: 'Abnormal', value: 'Abnormal' },
                        { name: 'Aborted', value: 'Aborted' }],
                        xs: 6,
                        width: conditionalValueSelector(isSmartTestingDisabled,
                            '52.4%', '53.4%'),
                        labelWidth: '43.5%',
                        type: 'number',
                        maxLength: '10',
                        marginLeft: '7.8px',
                        textAlign: 'center',
                        borderRight: '0',
                        marginRight: '0.47rem',
                        direction: 'rtl',
                        inputTextAlign: 'center',
                        height: '1.5rem'
                    }
                ]
            },
            {
                key: 7,
                fields: [
                    {
                        key: 'NodataP716',
                        xs: 6,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'F61AsFoundResult',
                        label: 'As-Found Result',
                        value: AsFoundTestTableP716Formula(formData),
                        isCalculatedNew: true,
                        xs: 6,
                        readOnly: true,
                        fontWeight: '700',
                        borderRight: '0',
                        inputMarginRight: '4px',
                        marginRight: '0.5rem',
                        width: '55%',
                        labelWidth: '47%',
                        direction: 'rtl',
                        textAlign: 'center',
                        height: '1.1rem',
                        marginLeft: '0',
                        isLabelBold: true,
                        borderTop: conditionalValueSelector(isSmartTestingDisabled,
                            '', '0'),
                        marginBottom: '4px',
                        borderBottom: '0'
                    }]
            }
        ]
    };
};
