import { placeholderName } from '../../../HMISection/Data/P733/P733.data';

export const TestResultSectionDataP611 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F446PhysicalPositionofContactirAt',
            label: 'Physical Position of Contactor After Test',
            value: formData.F446PhysicalPositionofContactirAt,
            isRequired: true,
            isDropDown: true,
            placeholder: placeholderName,
            options: [{ name: placeholderName, value: '' },
            { name: 'Open', value: 'Open' },
            { name: 'Closed', value: 'Closed' }],
            direction: 'rtl',
            xs: 6,
            width: '49%',
            labelWidth: '51.9%',
            inputTextAlign: 'center',
        },
        {
            key: 'F447HmipositionofConactorPtLabel',
            label: 'HMI Status of Motor After Test',
            xs: 3,
            labelWidth: '97%',
            padding: '5px 0 0 0',
            fontWeight: '600',
            fontSize: '9pt',
            textAlign: 'end',
            isLabel: true
        },
        {
            key: 'F447HmipositionofConactorPt',
            value: formData.F447HmipositionofConactorPt,
            isRequired: true,
            isDropDown: true,
            placeholder: 'Select HMI Status',
            options: [{ name: 'Select HMI Status', value: '' },
            { name: 'Running', value: 'Running' },
            { name: 'Stopped', value: 'Stopped' },
            { name: 'Stopped/tripped', value: 'Stopped/tripped' }],
            xs: 3,
            width: '100%',
            labelWidth: '0',
            direction: 'rtl',
            borderBottom: '0',
        }]
    }]
});
