import { LowFlowAlarmTest } from './Data/P8111/P8111.data';
import { ZeroFlowConditionsP721 } from './Data/P721/P721.data';
import { ZeroFlowCheckP6022 } from './Data/P6022/P6022.data';

export const selectZeroFlowConditions = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'P8-11.1') {
        filterViewData = LowFlowAlarmTest(selectedTag, formName, formData);
    } else if (formName === 'P7-21') {
        filterViewData = ZeroFlowConditionsP721(selectedTag, formName, formData);
    } else if (['P6-22', 'P7-22', 'P7-15', 'P7-14', 'P7-18', 'P6-12', 'P7-29', 'P8-8.1', 'P8-12.1', 'P7-30'].includes(formName)) {
        filterViewData = ZeroFlowCheckP6022(formData, formName);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
