import styled from 'styled-components';

export const TRFText = styled.div`
font-size: 25pt;
text-align: center; 
font-family: sans-serif; 
font-weight: bold;
margin-top: 8rem;
`;

export const DropDownElement = styled.div`
padding: 10px 0 0 0;
.Dropdown-option {
    min-height: 10px;
    padding: 4.8px 10px;
}
.Dropdown-menu {
    max-height: 175px;
}
.Dropdown-arrow {
    padding: 7px;
}
.BigSize .Dropdown-control {
    height: 3rem;
    padding: 10px;
 }
`;

export const ArrowElement = styled.div`
.MuiIconButton-root{
    padding: 10px 0;
}
`;
