import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInputMultiline } from 'elements/Inputs/FormInput/';
import { selectNoteTemplateForForm } from './NoteSection.data';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

const NoteSection = (props) => {
    const { selectedTag, formData, formName, changeHandler, isFormReadOnly } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.root} style={{ marginTop: '15px' }}>
            {
                selectNoteTemplateForForm(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key}>
                        {row.fields.map(field => (
                            <Grid item xs={12} key={field.key} style={{ textAlign: 'end' }}>
                                <FormInputMultiline
                                    rows={3}
                                    id={field.key}
                                    name={field.key}
                                    label={field.label}
                                    value={field.value}
                                    isRequired={field.isRequired}
                                    handleChange={changeHandler}
                                    width={field.width}
                                    inputWidth={field.inputWidth}
                                    padding={field.padding}
                                    marginRight={field.marginRight}
                                    marginLeft={field.marginLeft}
                                    readOnly={isFormReadOnly}
                                    direction={field.direction}
                                />
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms }) => ({
    selectedTag: Forms.selectedTag,
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoteSection);

NoteSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
};
