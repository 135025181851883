import { getAttentionLabel } from './AttentionLabel.formulas';

export const attentionLabelDisplay = (formName) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'attentionLabel',
            label: getAttentionLabel(formName),
            xs: 12,
            fontWeight: '600',
            isLabel: true,
            labelWidth: '100%',
            width: '0',
            fontSize: '8.5pt',
            color: '#ea7c34',
            padding: '0 0 10px 0',
            textAlign: 'center'
        }]
    }]
});
