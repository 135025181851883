import React from 'react';
import { FormControl, InputAdornment } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { DatePicker as DatePickerElement, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Colors } from 'helpers/Colors';
import PropTypes from 'prop-types';
import { FormControlDiv, FormTextField } from './DatePicker.styled';

const themeDark = createTheme({
  palette: {
    primary: { main: Colors.green },
    background: {
      default: '#000000'
    },
    text: {
      primary: '#000000'
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        height: '2px',
        cursor: 'pointer'
      }
    }
  }
});
const bgColor = (label, value) => {
  let color = '0';
  if (label === calLabel && value) {
    color = 'rgb(255, 229, 175)';
  } else if (label === calLabel) {
    color = 'rgb(255,255,0)';
  }
  return color;
};
export const calLabel = 'Cal.Due Date';
export const DatePicker = ({ name, label, width, height, fontSize, value, onChange }) => (
  <div style={{ marginBottom: '10px' }}>
    <FormControlDiv>
      <FormControl style={{ width: '100%', display: 'contents' }}>
        <label
          htmlFor={name}
          style={{
            fontSize: `${label === calLabel ? '10pt' : '11pt'}`,
            marginLeft: '1rem',
            marginRight: `${label === 'Start Date:' ? '20.8px' : '11px'}`,
            fontFamily: `${label === calLabel ? 'Open Sans, sans-serif' : 'Arial, sans-serif'}`,
            color: `${label === calLabel ? ' ' : 'rgb(73, 130, 5)'}`,
            fontWeight: 'bold',
            fontStyle: 'normal',
            textAlign: 'center',
            lineHeight: '1.2',
            overflowY: 'hidden',
          }}
        >
          {label}
        </label>
        <FormTextField>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={themeDark}>
              <DatePickerElement
                variant="inline"
                value={value}
                onChange={onChange}
                autoOk
                invalidDateMessage={null}
                inputVariant="outlined"
                format="dd-MMM-yyyy"
                keyboard
                InputAdornmentProps={{ position: 'start' }}
                InputProps={{
                  style: {
                    height: `${label === calLabel ? '2rem' : height || '25px'}`,
                    fontSize: '10pt',
                    width: `${label === calLabel ? '86.5%' : width || '55%'}`,
                    borderStyle: `${label === calLabel ? 'solid' : ' '}`,
                    borderColor: `${label === calLabel ? 'rgb(166,166,166)' : ' '}`,
                    cursor: 'pointer',
                    marginLeft: `${label === calLabel ? '1rem' : '0'}`,
                    backgroundColor: bgColor(label, value),
                  },
                  name,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayIcon style={{ fontSize: fontSize || '18pt' }} />
                    </InputAdornment>
                  )
                }}
              />
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </FormTextField>
      </FormControl>
    </FormControlDiv>
  </div>
);

DatePicker.propTypes = {
  fontSize: PropTypes.string,
  height: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  width: PropTypes.string
};
