import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataP79 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'RETURNTOSERVICE',
                label: 'RETURN TO SERVICE',
                isLabel: true,
                fontWeight: '700',
                labelWidth: '100%',
                xs: 6,
                padding: '0'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F110HmireadingAftertheTest',
                label: 'HMI Reading After the Test',
                value: formData.F110HmireadingAftertheTest,
                isRequired: true,
                isDropDown: true,
                placeholder: 'Select NORMAL or ALARM',
                options: [
                    { name: 'Select NORMAL or ALARM', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' }],
                xs: 5,
                width: '45%',
                labelWidth: '42%',
                direction: 'rtl',
            },
            {
                key: 'NoData9',
                isLabel: true,
                xs: 2,
                padding: '0'
            },
            {
                key: 'F85TestResult',
                label: 'Test Result',
                value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                isRequired: true,
                isDropDown: true,
                placeholder: 'Select Test Result',
                options: TestResultDropdownOptions(formData),
                xs: 5,
                labelWidth: '55%',
                marginRight: '0.5rem',
                fontWeight: '600',
                width: '45%'
            }]
        }
    ]
});
