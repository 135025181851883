import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { FormDropdown } from 'elements/Dropdown';
import Grid from '@material-ui/core/Grid';
import { StyledLabel } from 'elements/';
import CheckBox from 'elements/CheckBox/CheckBox';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ClearFiltersIcon from 'icons/ClearFilterButton/ClearFilter';
import { TagSelectionFilterWrapper, FilterToolTip } from './TagSelectionFilter.styled';
import { setTestStatusFilters, setSelectedFilters, setFilters, setCheckBoxFilters, deSelectFilters } from '../../TagSelectionMOScreen.actions';

const TagSelectionFilter = (props) => {
    const { filters, testStatusFilter, testStatusFilters, loadAllFilters, loadFilters,
        setTagSelectionMOScreenFilters, selectedTagSelectionMOScreenFilters, deSelectOption } = props;
    const [disableFilter, setDisableFilter] = useState({ DeckHullBldg: false, Quad: false, Room: false, Level: false });
    const checkBoxHandler = (obj) => {
        loadAllFilters(obj);
        setDisableFilter({ ...disableFilter, ...obj });
    };
    const clearFilters = () => {
        setDisableFilter({ DeckHullBldg: false, Quad: false, Room: false, Level: false });
        setTagSelectionMOScreenFilters();
    };
    return (
        <TagSelectionFilterWrapper id="TagSelectionFilterWrapper">
            <Grid container style={{ marginLeft: '55px' }}>
                <Grid xs={1} item>
                    <StyledLabel fontSize="8pt" marginLeft="5px">
                        Clear Filters
                    </StyledLabel>
                    <IconButton aria-label="delete" onClick={() => clearFilters()} style={{ marginLeft: '14px' }}>
                        <ClearFiltersIcon id="clearFilter" />
                    </IconButton>
                </Grid>
                <Grid xs={2} item>
                    <CheckBox id="checkBox_TestStatus" name="TestStatus" label="All" handleChange={(obj) => checkBoxHandler(obj)} visibility="hidden" />
                    <FormDropdown
                        id="Test Status"
                        name="Test Status"
                        label="Test Status"
                        noDefaultOption
                        value={testStatusFilter}
                        options={[{ name: 'All', value: 'All' }, { name: 'Tested', value: 'Tested' }, { name: 'Not Tested', value: 'Not Tested' }]}
                        width="95%"
                        fontWeight="400"
                        fontSize="8pt"
                        textAlign="center"
                        marginLeft="0"
                        handleChange={(obj) => testStatusFilters(obj['Test Status'])}
                    />
                </Grid>
                <Grid xs={2} item>
                    <CheckBox id="checkBox_DeckHullBldg" label="All" isChecked={disableFilter.DeckHullBldg} name="DeckHullBldg" handleChange={(obj) => checkBoxHandler(obj)} />
                    <FilterToolTip data-tip={selectedTagSelectionMOScreenFilters.DeckHullBldg.slice(0, 60)}>
                        <FormDropdown
                            id="DeckHullBldg"
                            name="DeckHullBldg"
                            label="Deck/Hull/Bldg"
                            width="95%"
                            textAlign="center"
                            marginLeft="0"
                            fontWeight="400"
                            fontSize="8pt"
                            placeholder=""
                            noDefaultOption
                            value={selectedTagSelectionMOScreenFilters.DeckHullBldg}
                            options={filters.DeckHullBldg.map((x) => x !== '' ? { name: x, value: x } : { name: x, value: ' ' }).sort((a, b) => a.name < b.name ? -1 : 1)}
                            handleChange={(obj) => selectedTagSelectionMOScreenFilters.DeckHullBldg === obj.DeckHullBldg ? deSelectOption(obj) : loadFilters(obj)}
                            readOnly={disableFilter.DeckHullBldg}
                        />
                    </FilterToolTip>
                </Grid>
                <Grid xs={2} item>
                    <CheckBox id="checkBox_Quad" name="Quad" label="All" isChecked={disableFilter.Quad} handleChange={(obj) => checkBoxHandler(obj)} />
                    <FilterToolTip data-tip={selectedTagSelectionMOScreenFilters.Quad.slice(0, 60)}>
                        <FormDropdown
                            id="Quad"
                            name="Quad"
                            label="Quad"
                            width="95%"
                            textAlign="center"
                            marginLeft="0"
                            fontWeight="400"
                            fontSize="8pt"
                            placeholder=""
                            noDefaultOption
                            value={selectedTagSelectionMOScreenFilters.Quad}
                            options={filters.Quad.map((x) => x !== '' ? { name: x, value: x } : { name: x, value: ' ' }).sort((a, b) => a.name < b.name ? -1 : 1)}
                            handleChange={(obj) => selectedTagSelectionMOScreenFilters.Quad === obj.Quad ? deSelectOption(obj) : loadFilters(obj)}
                            readOnly={disableFilter.Quad}
                        />
                    </FilterToolTip>
                </Grid>
                <Grid xs={2} item>
                    <CheckBox id="checkBox_Level" name="Level" label="All" isChecked={disableFilter.Level} handleChange={(obj) => checkBoxHandler(obj)} />
                    <FilterToolTip data-tip={selectedTagSelectionMOScreenFilters.Level.slice(0, 60)}>
                        <FormDropdown
                            id="Level"
                            name="Level"
                            label="Level"
                            width="95%"
                            textAlign="center"
                            marginLeft="0"
                            fontWeight="400"
                            fontSize="8pt"
                            placeholder=""
                            noDefaultOption
                            value={selectedTagSelectionMOScreenFilters.Level}
                            options={filters.Level.map((x) => x !== '' ? { name: x, value: x } : { name: x, value: ' ' }).sort((a, b) => a.name < b.name ? -1 : 1)}
                            handleChange={(obj) => selectedTagSelectionMOScreenFilters.Level === obj.Level ? deSelectOption(obj) : loadFilters(obj)}
                            readOnly={disableFilter.Level}
                        />
                    </FilterToolTip>
                </Grid>
                <Grid xs={2} item>
                    <CheckBox id="checkBox_Room" name="Room" label="All" isChecked={disableFilter.Room} handleChange={(obj) => checkBoxHandler(obj)} />
                    <FilterToolTip data-tip={selectedTagSelectionMOScreenFilters.Room.slice(0, 60)}>
                        <FormDropdown
                            id="Room"
                            name="Room"
                            label="Room"
                            width="95%"
                            textAlign="center"
                            marginLeft="0"
                            fontWeight="400"
                            fontSize="8pt"
                            placeholder=""
                            noDefaultOption
                            value={selectedTagSelectionMOScreenFilters.Room}
                            options={filters.Room.map((x) => x !== '' ? { name: x, value: x } : { name: x, value: ' ' }).sort((a, b) => a.name < b.name ? -1 : 1)}
                            handleChange={(obj) => selectedTagSelectionMOScreenFilters.Room === obj.Room ? deSelectOption(obj) : loadFilters(obj)}
                            readOnly={disableFilter.Room}
                        />
                    </FilterToolTip>
                </Grid>
                <Grid xs={2} item>
                    <CheckBox id="checkBox_Location" name="Location" label="All" handleChange={(obj) => checkBoxHandler(obj)} visibility="hidden" />
                    <FilterToolTip data-tip={selectedTagSelectionMOScreenFilters.Location2.slice(0, 60)} transform>
                        <FormDropdown
                            id="Location"
                            name="Location2"
                            label="Location"
                            width="95%"
                            textAlign="center"
                            marginLeft="0"
                            fontWeight="400"
                            fontSize="8pt"
                            placeholder=""
                            noDefaultOption
                            value={selectedTagSelectionMOScreenFilters.Location2}
                            options={filters.Location2.map((x) => x !== '' ? { name: x, value: x } : { name: x, value: ' ' }).sort((a, b) => a.name < b.name ? -1 : 1)}
                            handleChange={(obj) => selectedTagSelectionMOScreenFilters.Location2 === obj.Location2 ? deSelectOption(obj) : loadFilters(obj)}
                        />
                    </FilterToolTip>
                </Grid>
            </Grid>
        </TagSelectionFilterWrapper>
    );
};

const mapStateToProps = ({ TagSelectionMOScreen }) => ({
    filters: TagSelectionMOScreen.tagSelectionMOScreenFilters,
    testStatusFilter: TagSelectionMOScreen.testStatusFilter,
    selectedTagSelectionMOScreenFilters: TagSelectionMOScreen.selectedTagSelectionMOScreenFilters
});
const mapDispatchToProps = dispatch => ({
    dispatch,
    testStatusFilters: (filter) => dispatch(setTestStatusFilters(filter)),
    loadAllFilters: (filter) => dispatch(setCheckBoxFilters(filter)),
    deSelectOption: (filter) => dispatch(deSelectFilters(filter)),
    loadFilters: (filter) => dispatch(setSelectedFilters(filter)),
    setTagSelectionMOScreenFilters: () => dispatch(setFilters())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagSelectionFilter);

TagSelectionFilter.propTypes = {
    deSelectOption: PropTypes.func,
    filters: PropTypes.object,
    loadAllFilters: PropTypes.func,
    loadFilters: PropTypes.func,
    selectedTagSelectionMOScreenFilters: PropTypes.object,
    setTagSelectionMOScreenFilters: PropTypes.func,
    testStatusFilter: PropTypes.string,
    testStatusFilters: PropTypes.func
};
