import styled from 'styled-components';

export const StyledInput = styled.input`
    font-size: 12pt;
    font-family: "Segoe UI";
    color: rgb(51, 51, 51);
    font-weight: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.2;
    padding: 5px;
    min-width: 90%;
    background-color: ${props => props.isRequired ? 'rgb(255, 255, 0)' : 'rgb(255, 255, 255)'};
    border-style: solid;
    border-width: 2px;
    border-color: rgb(166, 166, 166);
    border-radius:0;
    -webkit-border-radius:0;
    -webkit-appearance:none;
    height: 34px;

    resize:none;
    outline:0;
    overflow-y:auto;
    margin:0;

    &:focus {
        border: 4px solid rgb(166, 166, 166) !important;
        margin:-2px;
    }
    
    &:hover {
        border: 2px solid rgb(51, 91, 3);    
    }
    
    &:active {
        border: 4px solid rgb(166, 166, 166) !important;
        margin:-2px;
    }

`;
export const InputWrapper = styled.div`
    padding-left: ${props => !props.paddingLeft ? '0' : '1rem'};
    margin-bottom: 5px;
    margin-top: ${props => props.marginTop ? props.marginTop : '0'};
    display: flex;
`;

export const StyledLabel = styled.div`
    width: ${props => props.labelWidth ? props.labelWidth : '7rem'}; // 9rem;
    font-weight: ${props => props.fontWeight ? props.fontWeight : '600'};
    font-size: ${props => props.labelfontSize ? props.labelfontSize : '9pt'};
    font-family: ${props => props.labelfontFamily ? props.labelfontFamily : 'Segoe UI'};
    font-size: ${props => props.labelfontSize ? props.labelfontSize : '9pt'};
    font-family: ${props => props.labelfontFamily ? props.labelfontFamily : 'Segoe UI'};
    text-align: ${props => props.labelTextAlign ? props.labelTextAlign : ''};
    font-style: ${props => props.labelFontStyle ? props.labelFontStyle : 'normal'};
    padding: ${props => props.labelPadding ? props.labelPadding : ''};
`;
export const ViewWrapper = styled.div`
    font-family: "Segoe UI", "Open Sans", sans-serif;
    font-size: ${props => props.fontSize ? props.fontSize : '9pt'};
    color: rgb(51, 51, 51);
    font-weight: normal;
    font-style: ${props => props.fontStyle ? props.fontStyle : 'normal'};
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    line-height: 1.2;
    margin-top: ${props => props.marginTop ? props.marginTop : ''};
    margin-left: ${props => props.marginLeft ? props.marginLeft : ''};
    width: ${props => props.width ? props.width : 'inherit'};
    height: ${props => props.height ? props.height : 'inherit'};
    word-break: ${props => props.wordBreak ? 'break-all' : ''};
    overflow: ${props => props.wordBreak ? 'auto' : ''};
    white-space: ${props => props.noWrap ? 'nowrap' : ''};
    padding: ${props => props.padding ? props.padding : ''};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : ''};
`;

export const StyledTextArea = styled.textarea`
    font-size: 12pt;
    color: rgb(51, 51, 51);
    font-weight: normal;
    font-style: normal;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    line-height: 1.2;
    padding: 5px;
    min-width: 90%;
    width: 97%;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: rgb(166, 166, 166);
    border-radius:0;
    -webkit-border-radius:0;
    -webkit-appearance:none;

    resize:none;
    outline:0;
    overflow-y:auto;
    margin:0;

    &:focus {
        border: 4px solid rgb(166, 166, 166) !important;
        margin:-2px;
    }
    
    &:hover {
        border: 2px solid rgb(51, 91, 3);    
    }
    
    &:active {
        border: 4px solid rgb(166, 166, 166) !important;
        margin:-2px;
    }

`;
