import { ValidateNumberField, FieldHasValue } from 'helpers/Validation';

export const AsLeftTableM613Formula = (formData) => {
    if (formData.F366DamperFullStrokePositionIndicationAlt === 'OPEN' || formData.F366DamperFullStrokePositionIndicationAlt === 'INTERMEDIATE') {
        return 'FAIL';
    }
    if (FieldHasValue(formData.F363DamperTravelTimeRequirement) && (formData.F366DamperFullStrokePositionIndicationAlt !== 'SELECT DAMPER POSITION')
        && FieldHasValue(formData.F367DamperFullStrokeTravelTimeAlt)) {
        if (formData.F366DamperFullStrokePositionIndicationAlt === 'CLOSE' && (ValidateNumberField(formData.F367DamperFullStrokeTravelTimeAlt)
            <= ValidateNumberField(formData.F363DamperTravelTimeRequirement))) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
