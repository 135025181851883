import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';
import { getAltDeleteIconStatus } from '../../AsLeftTestTableSection.helpers';

export const AsLeftTestDeviationFormula = (formData) => {
    if (formData.F722TransducerOutputSignal === 'Voltage') {
        return 'V';
    }
    if (formData.F722TransducerOutputSignal === 'Current') {
        return 'mA';
    }
    return '';
};

export const AsLeftResultMandatoryCheck = (formData, altTestPoint) => {
    let flag = false;
    if (!getAltDeleteIconStatus()) {
        for (let index = 2; index < Number(altTestPoint) + 2; index += 1) {
            if (!FieldHasValue(formData[`AltSimulatedGap${index}`]) || !FieldHasValue(formData[`AltDeviation${index}`])) {
                flag = false;
                break;
            }
            flag = true;
        }
    }
    return flag;
};

export const AsLeftResultFormula = (formData, altTestPoint) => {
    const deviationValue = [];
    for (let index = 2; index < Number(altTestPoint) + 2; index += 1) {
        deviationValue.push(Number(formData[`AltDeviation${index}`]));
    }
    if (FieldHasValue(formData.F101AftTolerance) && deviationValue.length > 0) {
        if (Math.max(...deviationValue) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const AltSystemReadingFormulaValueCheck = (formData, inputValue, altTestPoint) => {
    let flag = false;
    for (let index = 2; index < Number(altTestPoint) + 2; index += 1) {
        if (FieldHasValue(formData[`${inputValue}${index}`])) {
            flag = true;
            break;
        }
    }
    return flag;
};
