import { FieldHasValue } from 'helpers/Validation';

export const AsLeftResultM31Formula = (formData) => {
    if (FieldHasValue(formData.F406AltMacWasActivatedInField) && FieldHasValue(formData.F378AltHmistatus1)) {
        if ((formData.F406AltMacWasActivatedInField === 'YES') && (formData.F378AltHmistatus1 === 'ALARM')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
