import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataM65 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'DetectorReinstatement',
            label: 'Detector reinstatement',
            isLabel: true,
            xs: 12,
            fontWeight: '700'
        }]
    },
    {
        key: 2,
        fields: [
            {
                key: 'F382HmistatusAfterTestLabel',
                label: 'HMI Status after test',
                xs: 4,
                labelWidth: '98%',
                padding: '0',
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F382HmistatusAfterTest',
                value: formData.F382HmistatusAfterTest,
                isRequired: true,
                isDropDown: true,
                placeholder: Placeholder.HMI_STATUS_PLACEHOLDER,
                options: [
                    { name: Placeholder.HMI_STATUS_PLACEHOLDER, value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                xs: 2,
                inputTextAlign: 'center',
                labelWidth: '0',
                width: '100%',
                direction: 'rtl'
            },
            {
                key: 'F85TestResultLabel',
                label: 'Test Result',
                xs: 2,
                labelWidth,
                paddingLabel,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right'
            },
            {
                key: 'F85TestResult',
                value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                isRequired: true,
                isDropDown: true,
                placeholder: 'Select Test Result',
                options: TestResultDropdownOptions(formData),
                xs: 4,
                labelWidth: '0',
                width: '100%',
            }
        ]
    }
    ]

});
