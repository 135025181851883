import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
    select: {
        paddingTop: '4px',
        paddingBottom: 0,
        fontSize: '11px',
        height: '23px',
        marginTop: '0'
    },
    menuRoot: {
        fontSize: '11px',
    },
    menuPaper: {
        maxHeight: '140px',
    }
}));

export const DropdownWrapper = styled.div`
`;
