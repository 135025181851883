import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { conditionalValueSelector } from 'helpers/Validation';
import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { disableAsLeftTestRequired } from '../../../AsLeftTestTableSection/Data/P66/P66.data';

export const asLeftTestRequired = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'remark',
                label: `If there is deterioration in valve travel timings from previous valve function tests
                 (excluding test using historical timings), raise corrective.`,
                isLabel: true,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '12px 0 0 0',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F976AsLeftTestCompletedLabel',
                label: 'As-Left Test Required?',
                xs: 4,
                labelWidth,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '12px 25px 0 0'
            },
            {
                key: 'F976AsLeftTestCompleted',
                value: conditionalValueSelector(disableAsLeftTestRequired(formData), '', formData.F976AsLeftTestCompleted),
                isDisabled: disableAsLeftTestRequired(formData),
                readOnly: disableAsLeftTestRequired(formData),
                isDropdown: !disableAsLeftTestRequired(formData),
                placeholder: Placeholder.SELECT_YES_OR_NO,
                options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                labelWidth: '0%',
                width: '100%',
                inputTextAlign: 'center',
                xs: 2,
                marginTop: '12px'
            }]
        },
    ]
});
