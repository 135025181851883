import styled from 'styled-components';

export const SVGButton = styled.svg`
font-size: 13pt;
cursor: pointer;
margin-left: 5px;
margin-right: 5px;
 &:hover {
    path {
    stroke: #335B03;
    }
    g {
    stroke: #335B03;
    }
}
`;
