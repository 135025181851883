import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { SearchBoxWrapper } from './SearchBox.styled';

const SearchBox = ({ value, isClear, handleSearch, margin, marginLeft, name,
  width, height, fontSize, borderColor, maxLength, placeholder, padding }) => {
  const [inputValue, setInputValue] = useState(value);
  const debounceLoadData = useRef(_.debounce(handleInputDebounce, 1000)).current;
  const first = useRef(true);

  useEffect(() => {
    setInputValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClear]);

  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }
    debounceLoadData(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    debounceLoadData.cancel();
  }, [debounceLoadData]);

  function handleInputDebounce(searchText) {
    handleSearch(searchText.trim(), name);
  }

  return (
    <SearchBoxWrapper
      margin={margin}
      marginLeft={marginLeft}
      width={width}
      fontSize={fontSize}
      height={height}
      borderColor={borderColor}
      maxLength={maxLength}
      padding={padding}
    >
      <TextField
        id={name}
        type="search"
        variant="outlined"
        fullWidth
        placeholder={placeholder || 'Search'}
        value={inputValue}
        inputProps={{ maxLength }}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </SearchBoxWrapper>
  );
};

export default SearchBox;

SearchBox.propTypes = {
  borderColor: PropTypes.string,
  fontSize: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  height: PropTypes.string,
  isClear: PropTypes.bool,
  margin: PropTypes.string,
  marginLeft: PropTypes.string,
  maxLength: PropTypes.string,
  name: PropTypes.string,
  padding: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string
};

SearchBox.defaultProps = {
  name: 'SearchBox'
};
