import axios from 'axios';
import { get, set, values, clear } from 'idb-keyval';
import offlineStore from 'utils/OfflineDB';
import imageStore from 'utils/ImageDB';
import { APIEndpoints } from 'helpers/APILists';
import { Trim, MatchSRA } from 'helpers/Validation';
import { MESSAGE_TYPE } from '../../common/common.constants';
import { FORM_ACTIONS } from './Forms.constants';
import { formDataInitialState } from './Forms.data';
import { getAllDPSData } from '../SubSections/DPS/DPS.actions';
import { getTestMakeModelData } from '../TestEquipment/TestEquipment.actions';
import { getTotalRecordsSavedOffline } from './Forms.actions';
import { getAllTRFFormImage } from './TRFImaging.actions';

const processSRAData = (res, selectedTag) => {
    const { data } = res;
    const fetchFormData = typeof data === 'string'
        ? null
        : data.find((item) => (item.F89Mo === selectedTag.HdWorkOrder
            || item.F20Mo === selectedTag.HdWorkOrder)
            && (item.F86Mi === selectedTag.Mi
                || item.F17Mi === selectedTag.Mi));
    const resultFormData = fetchFormData ?? formDataInitialState;

    return {
        tableLoading: false,
        formData: resultFormData,
        formDataP81: resultFormData,
    };
};

const processP8FormData = (res, selectedTag, TRFId) => {
    const { data } = res;
    const fetchFormData = typeof data === 'string'
        ? data
        : data.find((item) => (item.F89Mo === selectedTag.HdWorkOrder
            || item.F20Mo === selectedTag.HdWorkOrder)
            && (item.F86Mi === selectedTag.Mi
                || item.F17Mi === selectedTag.Mi));
    const resultFormData = fetchFormData ?? formDataInitialState;
    return TRFId === 'P8-1' ? {
        tableLoading: false,
        formData: resultFormData,
        formDataP81: resultFormData,
    } : {
        tableLoading: false,
        formData: resultFormData,
        formDataSRA: resultFormData,
    };
};

export const GetSRAForm = (selectedTag) => async (dispatch, getState) => {
    const HdLocation = selectedTag?.HdLocation;
    const uniqueFormName = `${selectedTag.Mi}_${selectedTag.HdWorkOrder}_${selectedTag.HdLocation}`;
    const { AppData: { isMRATOnline } } = getState();
    const trfId = Trim(selectedTag?.TrfId);
    const filterDataP81 = { TrfId: 'P8-1', HdLocation };
    const filterData = { TrfId: trfId, HdLocation };
    return new Promise((resolve, reject) => {
        dispatch({
            type: FORM_ACTIONS.GET_ITF_FORM_PENDING,
            payload: {
                tableLoading: true
            }
        });
        dispatch(getTotalRecordsSavedOffline());
        if (isMRATOnline) {
            dispatch(getAllP8MOImages());
            dispatch(getAllTRFFormImage());
            dispatch(getAllDPSData());
            dispatch(getTestMakeModelData());
            axios.post(APIEndpoints.GetITFForm, filterDataP81)
                .then((res) => {
                    const result = processSRAData(res, selectedTag);
                    dispatch({
                        type: FORM_ACTIONS.GET_ITF_FORM_SUCCESS,
                        payload: result
                    });
                    resolve(result.formData);
                })
                .catch((err) => {
                    dispatch({
                        type: FORM_ACTIONS.GET_ITF_FORM_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                    reject();
                });
            axios.post(APIEndpoints.GetITFForm, filterData)
                .then((res) => {
                    const { data } = res;
                    const fetchFormData = typeof data === 'string'
                        ? data
                        : data.find((item) => (item.F89Mo === selectedTag.HdWorkOrder
                            || item.F20Mo === selectedTag.HdWorkOrder)
                            && (item.F86Mi === selectedTag.Mi
                                || item.F17Mi === selectedTag.Mi));
                    const resultFormData = fetchFormData ?? formDataInitialState;
                    dispatch({
                        type: FORM_ACTIONS.GET_ITF_FORM_SUCCESS,
                        payload: {
                            tableLoading: false,
                            formData: resultFormData,
                            formDataSRA: resultFormData,
                        }
                    });
                    resolve(resultFormData);
                })
                .catch((err) => {
                    dispatch({
                        type: FORM_ACTIONS.GET_ITF_FORM_FAILURE,
                        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                    });
                    reject();
                });
        } else if (MatchSRA(Trim(trfId))) {
            get(`Forms_${uniqueFormName}_SRA`, offlineStore).then((data) => {
                const resultSRAFormData = data?.body ?? formDataInitialState;
                dispatch({
                    type: FORM_ACTIONS.GET_ITF_FORM_OFFLINE_SUCCESS,
                    payload: {
                        tableLoading: false,
                        formDataSRA: resultSRAFormData,
                        formData: resultSRAFormData,
                    }
                });
                resolve(resultSRAFormData);
            });
            get(`Forms_${uniqueFormName}_P81`, offlineStore).then((data) => {
                const resultP8SRAFormData = data?.body ?? formDataInitialState;
                dispatch({
                    type: FORM_ACTIONS.GET_ITF_FORM_OFFLINE_SUCCESS,
                    payload: {
                        tableLoading: false,
                        formDataP81: resultP8SRAFormData,
                        formData: resultP8SRAFormData,
                    }
                });
                resolve(resultP8SRAFormData);
            });
        } else {
            get(`Forms_${uniqueFormName}`, offlineStore).then((data) => {
                const resultFormData = data?.body ?? formDataInitialState;
                dispatch({
                    type: FORM_ACTIONS.GET_ITF_FORM_OFFLINE_SUCCESS,
                    payload: {
                        tableLoading: false,
                        formData: resultFormData,
                    }
                });
                resolve(resultFormData);
            }
            );
        }
    });
};

export const GetP8ITFForm = ({ uniqueFormName, HdLocation, currentTrfId }) => async (dispatch, getState) => {
    const { AppData: { isMRATOnline }, Forms: { selectedTag, selectedTrfId }, Home: { currentView } } = getState();
    const newTrfId = selectedTrfId ?? Trim(selectedTag.TrfId);
    return new Promise((resolve, reject) => {
        dispatch({
            type: FORM_ACTIONS.GET_ITF_FORM_PENDING,
            payload: {
                tableLoading: true
            }
        });
        dispatch(getTotalRecordsSavedOffline());
        if (isMRATOnline) {
            dispatch(getAllP8MOImages());
            dispatch(getAllTRFFormImage());
            dispatch(getAllDPSData());
            dispatch(getTestMakeModelData());
            const TRFId = currentView === 'P8-1' && MatchSRA(Trim(newTrfId)) ? 'P8-1' : newTrfId.trim();
            const filterData = { TrfId: TRFId, HdLocation };
            axios.post(APIEndpoints.GetITFForm, filterData)
                .then((res) => {
                    const payload = processP8FormData(res, selectedTag, TRFId);
                    dispatch({
                        type: FORM_ACTIONS.GET_ITF_FORM_SUCCESS,
                        payload
                    });
                    resolve(payload.formData);
                });
        } else if (currentView === 'P8-1' && MatchSRA(Trim(newTrfId))) {
            get(`Forms_${uniqueFormName}_P81`, offlineStore).then((data) => {
                const resultFormData = data?.body ?? formDataInitialState;
                dispatch({
                    type: FORM_ACTIONS.GET_ITF_FORM_OFFLINE_SUCCESS,
                    payload: {
                        tableLoading: false,
                        formDataP81: resultFormData,
                        formData: resultFormData,
                    }
                });
                resolve(resultFormData);
            }
            );
        } else if (MatchSRA(currentView)) {
            get(`Forms_${uniqueFormName}_SRA`, offlineStore).then((data) => {
                const resultFormData = data?.body ?? formDataInitialState;
                dispatch({
                    type: FORM_ACTIONS.GET_ITF_FORM_OFFLINE_SUCCESS,
                    payload: {
                        tableLoading: false,
                        formDataSRA: resultFormData,
                        formData: resultFormData,
                    }
                });
                resolve(resultFormData);
            }
            );
        } else {
            get(`Forms_${uniqueFormName}`, offlineStore).then((data) => {
                const resultFormData = data?.body ?? formDataInitialState;
                dispatch({
                    type: FORM_ACTIONS.GET_ITF_FORM_OFFLINE_SUCCESS,
                    payload: {
                        tableLoading: false,
                        formData: resultFormData,
                    }
                });
                resolve(resultFormData);
            }
            );
        }
    });
};

export const getAllP8MOImages = () => async (dispatch, getState) => {
    const { AppData: { isMRATOnline }, Forms: { selectedTag } } = getState();
    dispatch({
        type: FORM_ACTIONS.GETIMAGES_PENDING
    });
    if (isMRATOnline) {
        const filterData = { MO: selectedTag.HdWorkOrder, Mi: selectedTag.Mi, HdLocation: selectedTag.HdLocation };
        await axios.post(APIEndpoints.GetImages, filterData)
            .then((res) => {
                res.data.map((data) => set(data.ImageId, data, imageStore));
                dispatch({
                    type: FORM_ACTIONS.GETIMAGES_SUCCESS
                });
            }).catch((err) => dispatch({
                type: FORM_ACTIONS.GETIMAGES_FAILURE,
                meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
            }));
    } else {
        const offlineStoreData = await values(offlineStore);
        for await (const data of offlineStoreData) {
            if (data.url.includes('SubmitImages')) {
                await set(data.body.ImageId, data.body, imageStore);
            }
        }
    }
};

export const setP81FormData = (formData) => (dispatch) => {
    dispatch({
        type: FORM_ACTIONS.SET_P81_FORMDATA,
        payload: {
            formDataP81: formData
        }
    });
};

export const setSRAFormData = (formData) => (dispatch, getState) => {
    const { Forms: { updatedFormDataObj } } = getState();
    dispatch({
        type: FORM_ACTIONS.SET_SRA_FORMDATA,
        payload: {
            formDataSRA: { ...formData, ...updatedFormDataObj }
        }
    });
};

export const clearSRAData = () => (dispatch) => {
    dispatch({
        type: FORM_ACTIONS.CLEAR_SRA_FORMDATA,
        payload: {
            formData: formDataInitialState,
            deleteExistingImageList: [],
            deleteNewImageList: [],
            newImageList: [],
            formDataP81: {},
            formDataSRA: {},
            P8TRFImage: {},
        }
    });
    clear(imageStore);
};
