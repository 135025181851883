import React from 'react';
import { FormControl, InputAdornment } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { DatePicker as DatePickerElement, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Colors } from 'helpers/Colors';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import { FormControlDiv, FormTextField } from './DatePicker.styled';

const themeDark = createTheme({
    palette: {
        primary: { main: Colors.green },
        background: {
            default: '#000000'
        },
        text: {
            primary: '#000000'
        },
    },
    overrides: {
        MuiInputBase: {
            input: {
                height: '2px',
                cursor: 'pointer'
            }
        }
    }
});

export const DatePickerForPrint = ({ name, label, width, height, value, onChange, handleInputChange }) => {
    function handleClr(e) {
        e.stopPropagation();
        handleInputChange();
    }
    return (
        <div style={{ marginBottom: '10px' }}>
            <FormControlDiv>
                <FormControl style={{ width: '100%', display: 'contents' }}>
                    <label
                        htmlFor={name}
                        style={{
                            fontSize: '14pt',
                            marginLeft: '1rem',
                            marginRight: '11px',
                            fontFamily: 'Arial, sans-serif',
                            color: 'rgb(73, 130, 5)',
                            fontWeight: 'bold',
                            fontStyle: 'normal',
                            textAlign: 'center',
                            lineHeight: '1.2',
                            overflowY: 'hidden',
                        }}
                    >
                        {label}
                    </label>
                    <FormTextField>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <MuiThemeProvider theme={themeDark}>
                                <DatePickerElement
                                    variant="inline"
                                    value={value}
                                    onChange={onChange}
                                    autoOk
                                    invalidDateMessage={null}
                                    inputVariant="outlined"
                                    format="dd-MMM-yyyy"
                                    keyboard
                                    InputAdornmentProps={{ position: 'start' }}
                                    InputProps={{
                                        style: {
                                            height: height || '35px',
                                            fontSize: '12pt',
                                            width: width || '55%',
                                            borderStyle: ' ',
                                            borderColor: ' ',
                                            cursor: 'pointer',
                                            marginLeft: '0',
                                            backgroundColor: '0'
                                        },
                                        name,
                                        endAdornment: (
                                            <>
                                                {value
                                                    ? (
                                                        <InputAdornment position="end" onClick={(e) => handleClr(e)}>
                                                            <ClearIcon
                                                                style={{
                                                                    fontSize: '0.9rem',
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    )
                                                    : null}
                                                <InputAdornment position="end">
                                                    <CalendarTodayIcon />
                                                </InputAdornment>
                                            </>
                                        )
                                    }}
                                />
                            </MuiThemeProvider>
                        </MuiPickersUtilsProvider>
                    </FormTextField>
                </FormControl>
            </FormControlDiv>
        </div>
    );
};

DatePickerForPrint.propTypes = {
    handleInputChange: PropTypes.func,
    height: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    width: PropTypes.string
};
