import { damperPlaceHolder } from '../../../ValveSizeSection/Data/M613/M613.data';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const HMISectionDataM613 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F361PhysicalDamperPositionPriortoTestLabel',
                label: 'Physical Damper Position Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F361PhysicalDamperPositionPriortoTest',
                value: formData.F361PhysicalDamperPositionPriortoTest,
                isDropdown: true,
                isRequired: true,
                placeholder: damperPlaceHolder,
                options: [{ name: damperPlaceHolder, value: '' },
                { name: 'OPEN', value: 'OPEN' },
                { name: 'CLOSE', value: 'CLOSE' },
                { name: 'INTERMEDIATE', value: 'INTERMEDIATE' }],
                labelWidth: '0%',
                width: '100%',
                height: '1.7rem',
                inputTextAlign: 'center',
                paddingRightPlaceholder: '15px',
                xs: 2
            },
            {
                key: 'F362HmidamperPositionPriortoTestLabel',
                label: 'HMI Damper Position Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
                labelWidth
            },
            {
                key: 'F362HmidamperPositionPriortoTest',
                value: formData.F362HmidamperPositionPriortoTest,
                isDropdown: true,
                placeholder: damperPlaceHolder,
                options: [{ name: damperPlaceHolder, value: '' },
                { name: 'OPEN', value: 'OPEN' },
                { name: 'CLOSE', value: 'CLOSE' },
                { name: 'INTERMEDIATE', value: 'INTERMEDIATE' }],
                width: '100%',
                labelWidth: '0%',
                marginBottom: '15px',
                xs: 2,
                inputTextAlign: 'center',
                textAlignLabel: 'end',
            }]
        }
    ]
});
