import { FieldHasValue, ValidateNumberField, ValidateField } from 'helpers/Validation';

export const SFResultFormula = (formData) => {
    if (FieldHasValue(formData.F743SpecifiedSf)
        && FieldHasValue(formData.F744Unit)
        && FieldHasValue(formData.F745SfTolerance)
        && FieldHasValue(formData.F748CalculatedSf)) {
        if (Math.max.apply(Math, [ValidateField(formData.F748CalculatedSf)]) >= ValidateNumberField(formData.F746MinSf)
            && (Math, ValidateField(formData.F748CalculatedSf)) <= ValidateNumberField(formData.F747MaxSf)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
export const MinSFFormula = (formData) => {
    if (FieldHasValue(formData.F743SpecifiedSf) && FieldHasValue(formData.F745SfTolerance)) {
        const result = (ValidateNumberField(formData.F743SpecifiedSf) - ((ValidateNumberField(formData.F743SpecifiedSf) * ValidateNumberField(formData.F745SfTolerance)) / 100));
        return result.toString();
    }
    return '';
};

export const MaxSFFormula = (formData) => {
    if (FieldHasValue(formData.F743SpecifiedSf) && FieldHasValue(formData.F745SfTolerance)) {
        return (ValidateNumberField(formData.F743SpecifiedSf) + (ValidateNumberField(formData.F743SpecifiedSf) * ValidateNumberField(formData.F745SfTolerance)) / 100);
    }
    return '';
};
