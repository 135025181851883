export const VisualInspectSectionDataM69 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect the length of the linear heat detector loop and ensure there have been no changes or 
                alterations that could affect the equipment performance. Ensure there are no signs of damage. 
                If damage is visible continue with function test so that an ‘as found’ condition can be achieved`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '600px',
                inputWidth: '57%',
                alignItems: 'inherit'
            }]
        }
    ]

});
