import { FieldHasValue, ValidateField, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';

export const AsLeftTableF63Hmi2Inst1Formula = (formData) => {
    let Hmi2Inst1;
    if (FieldHasValue(formData.F21HmiLrv)) {
        Hmi2Inst1 = '0% - '.concat(ValidateField(formData.F21HmiLrv))
            .concat(FieldHasValue(formData.F24HmiEngineeringUnit) ? ' ' : '')
            .concat(ValidateField(formData.F24HmiEngineeringUnit))
            .concat(' - ')
            .concat(ValidateField(formData.F25InstrumentLrv))
            .concat(FieldHasValue(formData.F118InstrumentEngineeringUnits) ? ' ' : '')
            .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
        return Hmi2Inst1.length > 255 ? Hmi2Inst1.slice(0, 255) : Hmi2Inst1;
    }
    return '0%';
};

export const AsLeftTableF64Hmi2Inst2Formula = (formData) => {
    let Hmi2Inst2;
    if (FieldHasValue(formData.F21HmiLrv) || FieldHasValue(formData.F22HmiUrv)) {
        if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv)) {
            Hmi2Inst2 = '50% - '.concat(ExponentialToDecimal(ValidateNumberField(formData.F21HmiLrv)
                + (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv)) * (0.5)))
                .concat(FieldHasValue(formData.F24HmiEngineeringUnit) ? ' ' : '')
                .concat(ValidateField(formData.F24HmiEngineeringUnit))
                .concat(' - ')
                .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
                    + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
                .concat(FieldHasValue(formData.F118InstrumentEngineeringUnits) ? ' ' : '')
                .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
            return Hmi2Inst2.length > 255 ? Hmi2Inst2.slice(0, 255) : Hmi2Inst2;
        }
        return '50%';
    }
    return '50%';
};

export const AsLeftTableF65Hmi2Inst3Formula = (formData) => {
    let Hmi2Inst3;
    if (FieldHasValue(formData.F22HmiUrv)) {
        Hmi2Inst3 = '100% - '.concat(ValidateField(formData.F22HmiUrv))
            .concat((FieldHasValue(formData.F24HmiEngineeringUnit)) ? ' ' : '')
            .concat(ValidateField(formData.F24HmiEngineeringUnit))
            .concat(' - ')
            .concat(ValidateField(formData.F26InstrumentUrv))
            .concat((FieldHasValue(formData.F118InstrumentEngineeringUnits)) ? ' ' : '')
            .concat(ValidateField(formData.F118InstrumentEngineeringUnits));
        return Hmi2Inst3.length > 255 ? Hmi2Inst3.slice(0, 255) : Hmi2Inst3;
    }
    return '100%';
};
export const AsLeftTableF78Deviation2Eu1Formula = (formData) => FieldHasValue(formData.F69Tx2Reading1) && FieldHasValue(formData.F66TestModule2Reading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F69Tx2Reading1) - ValidateNumberField(formData.F66TestModule2Reading1))) : '';
export const AsLeftTableF79Deviation2Eu2Formula = (formData) => FieldHasValue(formData.F70Tx2Reading2) && FieldHasValue(formData.F67TestModule2Reading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F70Tx2Reading2) - ValidateNumberField(formData.F67TestModule2Reading2))) : '';
export const AsLeftTableF80Deviation2Eu3Formula = (formData) => FieldHasValue(formData.F71Tx2Reading3) && FieldHasValue(formData.F68TestModule2Reading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F71Tx2Reading3) - ValidateNumberField(formData.F68TestModule2Reading3))) : '';
