export const VisualInspectSectionDataM71 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F193VisuallyInspect',
            label: `Perform visual inspections of the fire suppression equipment as described
             in the maintenance procedure and record results.`,
            value: formData.F193VisuallyInspect,
            isRequired: true,
            isMultiline: true,
            xs: 12,
            width: '50%',
            inputWidth: '50%',
            alignItems: 'inherit',
            marginTop: '15px'
        }]
    }]
});
