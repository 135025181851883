import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const unitsUsedSectionDataS73 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F977TxAsFoundLabel',
                label: 'Tx As Found',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
                marginTop: '20px',
            },
            {
                key: 'F977TxAsFound',
                value: formData.F977TxAsFound,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                height: '1.23rem',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                borderBottom: '0',
                marginTop: '20px',
            },
            {
                key: 'F978AftAlternativeTxLabel',
                label: 'Alternative Tx',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
                marginTop: '20px',
            },
            {
                key: 'F978AftAlternativeTx',
                value: formData.F978AftAlternativeTx,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlignLabel: 'end',
                height: '1.1rem',
                textAlign: 'center',
                marginTop: '20px',
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F987UsePtConversionLabel',
                label: 'Use PT100 conversion?',
                labelWidth,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F987UsePtConversion',
                value: formData.F987UsePtConversion ? formData.F987UsePtConversion : 'YES',
                options: [{ name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                isDropDown: true,
                labelWidth: '0%',
                width: '100%',
                inputTextAlign: 'center',
                noDefaultOption: true,
                textAlign: 'center',
                xs: 2,
                isRequired: true,
            },
            {
                key: 'TestGasConcentration',
                label: 'Conversion only works PT100 RTDs reading in Deg C. For other types of sensors, use look-up table or other method.',
                isLabel: true,
                padding: '5px 0 0 5px',
                xs: 6,
                color: '#ea7c34',
                fontSize: '8.5pt',
                fontStyle: 'italic',
                fontWeight: '600'
            }]
        },
    ]
});
