import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInput, FormInputMultiline } from 'elements/Inputs/FormInput/';
import { StyledLabel } from 'elements/Label/Label';
import { selectHighLowCurrentSectionData } from './HighLowCurrentSection.data';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const HMISection = (props) => {
    const { selectedTag, changeHandler, formData, formName, isFormReadOnly, userRole } = props;
    const classes = useStyles();

    const filterViewData = selectHighLowCurrentSectionData(selectedTag, formName, formData);
    const styleInput = (field) => field.isLabel ? (
        <StyledLabel
            id={field.key}
            color={field.color}
            fontWeight={field.fontWeight}
            fontSize={field.fontSize}
            direction={field.direction}
            marginTop={field.marginTop}
            marginLeft={field.marginLeft}
            top={field.top}
            labelWidth={field.labelWidth}
            textAlign={field.textAlign}
            padding={field.padding}
        >
            {field.label}
        </StyledLabel>
    )
        : (
            <FormInput
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                isRequired={field.isRequired}
                handleChange={changeHandler}
                type={field.type}
                labelWidth={field.labelWidth}
                width={field.width}
                readOnly={isFormReadOnly || field.readOnly}
                padding={field.padding}
                marginLeft={field.marginLeft}
                marginRight={field.marginRight}
                marginTop={field.marginTop}
                inputMarginRight={field.inputMarginRight}
                direction={field.direction}
                maxLength={field.maxLength}
                textAlign={field.textAlign}
                textAlignLabel={field.textAlignLabel}
                borderRight={field.borderRight}
                borderLeft={field.borderLeft}
                borderBottom={field.borderBottom}
                borderTop={field.borderTop}
                isCalculatedNew={field.isCalculatedNew}
                isDisabled={field.isDisabled}
                userRole={userRole}
                height={field.height}
            />
        );

    return (
        <Grid container spacing={1} className={classes.root}>
            {
                filterViewData.rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingBottom: '0', paddingTop: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isMultiline
                                    ? (
                                        <FormInputMultiline
                                            rows={3}
                                            id={field.key}
                                            name={field.key}
                                            label={field.label}
                                            value={field.value}
                                            isRequired={field.isRequired}
                                            handleChange={changeHandler}
                                            width={field.width}
                                            minWidth={field.minWidth}
                                            readOnly={isFormReadOnly}
                                        />
                                    )
                                    : styleInput(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, TagSelectionMOScreen, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HMISection);

HMISection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
