import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import RefreshIcon from 'icons/RefreshButton/Refresh';
import { useMsal } from '@azure/msal-react';
import { formatDate } from 'helpers/DateFormatter';
import { TestEquipmentForm } from './TestEquipmentForm';
import { testEquipmentFormState } from '../TestEquipment.data';
import { setDataForAddTestEquipment, resetDataForAddTestEquipment, updateDataForAddTestEquipment, PostAddTestEquipmentData } from './TestEquipmentForm.actions';
import { IsAddMandatory } from './mandatoryFieldsForAddEdit';
import { setCurrentView } from '../../Home/Home.actions';

const RefreshFormComponent = ({ buttonHandler }) => <RefreshIcon buttonHandler={buttonHandler} rotate={-90} tooltip="Reset Form" />;
RefreshFormComponent.propTypes = {
    buttonHandler: PropTypes.func.isRequired
};

const AddTestEquipmentForm = (props) => {
    const { setCurrentAppView, resetData, updateFormData, addTestEquipmentData, isMRATOnline,
        setInitialData, postAddTestEquipmentData, bpRegionNameAlias,
        selectedAssetAliasName } = props;
    const FooterButtonVisible = IsAddMandatory(isMRATOnline, addTestEquipmentData);
    const [disableAddTESubmit, setDisableAddTESubmit] = useState(false);
    const showRefreshButton = isMRATOnline;
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    useEffect(() => {
        setInitialData(testEquipmentFormState(selectedAssetAliasName, bpRegionNameAlias));
        return () => resetData(testEquipmentFormState(selectedAssetAliasName, bpRegionNameAlias));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAssetAliasName]);
    const UpdateFormDataHandler = (obj) => {
        const objWithPropertiesToBeUpdated = obj;
        objWithPropertiesToBeUpdated.CreatedBy = userName?.name;
        objWithPropertiesToBeUpdated.CreatedDate = formatDate(new Date());
        updateFormData(objWithPropertiesToBeUpdated);
    };
    const SubmitButtonHandler = async () => {
        setDisableAddTESubmit(true);
        const newFormedObject = addTestEquipmentData;
        newFormedObject.ModifiedBy = addTestEquipmentData.CreatedBy;
        newFormedObject.LastModifiedDate = addTestEquipmentData.CreatedDate;
        await postAddTestEquipmentData(newFormedObject);
        setCurrentAppView('testequipment');
    };
    return (
        <SiteWrapper
            headerText="Add New Test Equipment"
            ButtonSection={showRefreshButton && <RefreshFormComponent buttonHandler={() => resetData(testEquipmentFormState(selectedAssetAliasName, bpRegionNameAlias))} />}
            footerButtonHandler={SubmitButtonHandler}
            footerButtonText="Submit"
            FooterButtonVisible={FooterButtonVisible}
            goBack="testequipment"
            isDisabled={disableAddTESubmit}
            displayUnsavedChangesPopUp
        >
            {addTestEquipmentData
                ? (
                    <TestEquipmentForm
                        formId="AddTestEquipmentForm"
                        formValues={addTestEquipmentData}
                        formHandler={(obj) => UpdateFormDataHandler(obj)}
                        bpRegionNameAlias={bpRegionNameAlias}
                        selectedAssetAliasName={selectedAssetAliasName}
                    />
                )
                : null}
        </SiteWrapper>
    );
};

const mapStateToProps = ({ AppData, TestEquipment, Home }) => ({
    addTestEquipmentData: TestEquipment.addTestEquipmentData,
    trfMasterList: Home.trfMasterList,
    assetNameList: Home.assetNameList,
    isMRATOnline: AppData.isMRATOnline,
    selectedAssetAliasName: Home.selectedAssetAliasName,
    bpRegionNameAlias: Home.bpRegionNameAlias
});
const mapDispatchToProps = dispatch => ({
    dispatch,
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    setInitialData: (initialState) => dispatch(setDataForAddTestEquipment(initialState)),
    resetData: (initialState) => {
        dispatch(resetDataForAddTestEquipment(initialState));
    },
    updateFormData: (obj) => {
        dispatch(updateDataForAddTestEquipment(obj));
    },
    postAddTestEquipmentData: (obj) => dispatch(PostAddTestEquipmentData(obj)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTestEquipmentForm);

AddTestEquipmentForm.propTypes = {
    addTestEquipmentData: PropTypes.object.isRequired,
    bpRegionNameAlias: PropTypes.string,
    isMRATOnline: PropTypes.bool.isRequired,
    postAddTestEquipmentData: PropTypes.func.isRequired,
    resetData: PropTypes.func,
    selectedAssetAliasName: PropTypes.string,
    setCurrentAppView: PropTypes.func,
    setInitialData: PropTypes.func,
    updateFormData: PropTypes.func
};
