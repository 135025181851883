import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataP67P841P8101 = (selectedTag, formName, formData) => {
    let hmiIndicationLabel;
    if (formName === 'P6-18') {
        hmiIndicationLabel = 'HMI Indication After Test';
    } else {
        hmiIndicationLabel = 'HMI Reading After Test';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F110HmireadingAftertheTest',
                    label: hmiIndicationLabel,
                    value: formData.F110HmireadingAftertheTest,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select NORMAL or ALARM',
                    options: [
                        { name: 'Select NORMAL or ALARM', value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'ALARM', value: 'ALARM' }],
                    xs: 5,
                    width: '45.5%',
                    labelWidth: '43.3%',
                    direction: 'rtl',
                },
                {
                    key: 'NoData9',
                    isLabel: true,
                    xs: 2,
                    padding: '0'
                },
                {
                    key: 'F85TestResult',
                    label: 'Test Result',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 5,
                    labelWidth: '64%',
                    marginRight: '0.5rem',
                    fontWeight: '600',
                    width: '100%'
                }]
            }
        ]
    };
};
