import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

export const LoadingCell = withStyles(() => ({
    root: {
        width: '100%'
    },
}))(Skeleton);

export const tableCellStyle = makeStyles({
    noPaddingTopBottom: {
        // borderBottom: ({ color }) => color ? `2px solid ${color}` : '2px solid red',
        paddingBottom: props => props.hasActions && 0,
        paddingTop: props => props.hasActions && 0,
    },
});

export const redTableCellStyle = makeStyles({
    redTableCell: {
        color: 'red'
    }
});

export const useStyles = makeStyles({
    table: {
        minWidth: props => props.historicalPrintScreen ? 1200 : 700,
    },
    sortIcon: {
        '.MuiSvgIcon-root': {
            viewBox: '0 0 20 20'
        }
    },
    iconContainer: {
        '&:hover $icon': {
            color: '#335B03',
            borderColor: '#335B03'
        }
    },
    icon: {
        color: 'white',
    }
});

export const StyledTableCell = withStyles((theme) => ({
    root: {
        padding: '0 0 0 10px'
    },
    head: {
        backgroundColor: '#498205',
        color: theme.palette.common.white,
        borderRight: theme.palette.common.white,
        whiteSpace: 'nowrap',
        padding: '5px 0 5px 10px'
    },
    body: {
        fontSize: 12,
        borderRight: theme.palette.common.white
    },
})
)(TableCell);

export const StyledTableCell2 = withStyles((theme) => ({
    root: {
        padding: '0 10px 0 0',
        verticalAlign: 'middle'
    },
    head: {
        backgroundColor: '#498205',
        color: theme.palette.common.white,
        borderRight: theme.palette.common.white,
        whiteSpace: 'pre-wrap',
        padding: '5px 10px 5px 0',
        textAlign: 'left',
    },
    body: {
        fontSize: 12,
        borderRight: theme.palette.common.white,
        textAlign: 'left'
    },
})
)(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        height: '3rem'
    },
}))(TableRow);

export const SortIcon = styled.div`
.MuiSvgIcon-root {
    viewBox: "0 0 22 22"
}
`;

export const TableHeading = styled.h2`
    display: inline-block;
    span {
        display: inline-block;
        font-size: 12px;
        padding: 0 1em 0 1em;
    }
`;
