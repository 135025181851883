import { useAccount, useMsal } from '@azure/msal-react';
import { isMobile as isTablet } from 'react-device-detect';
import { getMratSecurityGroupInfo } from 'journeys/common/common.actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'authConfig';
import { useHistory } from 'react-router-dom';
import { getUserGroups } from 'utils/MsGraphApiCall';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole } from '../UserRoleSelector/UserRole';
import { SplashScreen } from '../../../elements/SplashScreen';
import AdminScreen from './AdminScreen';
import { saveUsersGroupDetails } from '../Home/Home.actions';

const processUserGroupsData = (userGroupsData, groups, dispatch, trackAIUserDetails, setShowSplash, history) => {
    if (userGroupsData && userGroupsData.length > 0) {
        const userGroupInfo = groups.SecurityGroupInfo
            .filter(group => userGroupsData.includes(group.Id.toLowerCase()));
        const userData = userGroupInfo.filter(user => getUserRole(user.RoleId).includes('Admin'));
        if (userData.length === 1 && !isTablet) {
            dispatch(saveUsersGroupDetails(userData[0].Id))
                .then(() => {
                    trackAIUserDetails(userGroupInfo);
                    setShowSplash(false);
                });
        } else {
            history.push('/');
        }
    } else {
        history.push('/');
    }
};

const AdminHandler = () => {
    const history = useHistory();
    const { instance, accounts } = useMsal();
    const msalInstance = new PublicClientApplication(msalConfig);
    const timeout = 14400000;
    const { getRemainingTime } = useIdleTimer({ timeout });
    const [showSplash, setShowSplash] = useState(true);
    const userRole = useSelector(({ Home }) => Home.userRole);
    const dispatch = useDispatch();
    const account = useAccount(accounts[0] || {});
    const appInsights = useAppInsightsContext();

    const handleLogOut = () => {
        const allAccounts = msalInstance.getAllAccounts();
        const logoutRequest = {
            account: allAccounts[0]
        };
        if (logoutRequest.account.username.toLowerCase().includes('bp')) {
            instance.logoutRedirect();
        }
        instance.logoutRedirect(logoutRequest);
    };

    const trackAIUserDetails = useCallback((userGroupInfo) => {
        const appInsightUserDetails = {
            userName: account.name,
            groupName: userGroupInfo[0].Name,
            userRole: getUserRole(userGroupInfo[0].RoleId),
            userEmailId: account.username
        };
        appInsights.trackEvent({ name: 'user_Details', properties: { appInsightUserDetails } });
    }, [account, appInsights]);

    const handleAdminPath = useCallback(() => {
        getMratSecurityGroupInfo().then((groups) => {
            const groupIds = groups.SecurityGroupInfo.map(group => group.Id);
            getUserGroups(groupIds)
                .then(userGroupsData => {
                    processUserGroupsData(userGroupsData, groups, dispatch, trackAIUserDetails, setShowSplash, history);
                });
        });
    }, [dispatch, history, trackAIUserDetails]);

    useEffect(() => {
        if (account && userRole?.includes('Admin')) {
            setShowSplash(false);
        } else if (account) {
            handleAdminPath();
        }
    }, [account, handleAdminPath, setShowSplash, userRole]);

    useEffect(() => {
        const id = !isTablet && setInterval(() => {
            if (getRemainingTime() === 0) {
                handleLogOut();
                clearInterval(id);
            }
        }, 1000);
        return () => clearInterval(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        showSplash ? <SplashScreen /> : <AdminScreen />
    );
};

export default AdminHandler;
