import { AsFoundResultFormulaM63andM64 } from '../M63/M63.formulas';

export const AsFoundTestTableSectionDataM64 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F430TestMethodTypeUsed',
                    label: 'Test Method Type Used',
                    value: formData.F430TestMethodTypeUsed,
                    xs: 6,
                    labelWidth: '57.5%',
                    width: '42.5%',
                    isDropdown: true,
                    placeholder: 'Select Test Method Type',
                    options: [{ name: 'Select Test Method Type', value: '' },
                    { name: 'TEST TORCH', value: 'TEST TORCH' },
                    { name: 'OI TEST', value: 'OI TEST' }],
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    inputTextAlign: 'center',
                    borderBottom: '0',
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F411AftConfirmTestTorch',
                    label: 'Confirm test Method is Applied to the Detector',
                    value: formData.F411AftConfirmTestTorch,
                    xs: 6,
                    isRequired: true,
                    labelWidth: '57.5%',
                    width: '42.5%',
                    isDropdown: true,
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    inputTextAlign: 'center',
                    borderBottom: '0',
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F372AftHmistatus1',
                    label: 'HMI Status',
                    value: formData.F372AftHmistatus1,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select HMI Status',
                    options: [{ name: 'Select HMI Status', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                    labelWidth: '57.5%',
                    width: '42.5%',
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    inputTextAlign: 'center',
                    xs: 6,
                    marginBottom: '15px'
                },
                {
                    key: 'nodata',
                    isLabel: true,
                    xs: 1
                },
                {
                    key: 'F102AftResult',
                    label: 'As-Found Result',
                    value: AsFoundResultFormulaM63andM64(formData),
                    readOnly: true,
                    xs: 5,
                    width: '55%',
                    labelWidth: '45%',
                    isCalculatedNew: true,
                    textAlign: 'center',
                    height: '1.25rem',
                    borderRight: '0',
                    marginRight: '8px',
                    direction: 'rtl',
                    inputMarginRight: '4px',
                    isLabelBold: true,
                    fontWeight: '700'
                }
            ]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F376CleanDetectorLensesIfVisiblyDirty',
                    label: 'Clean detector lenses if visibly dirty',
                    value: formData.F376CleanDetectorLensesIfVisiblyDirty,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select from dropdown list',
                    options: [{ name: 'Select from dropdown list', value: '' },
                    { name: 'CLEANED', value: 'CLEANED' },
                    { name: 'NOT REQUIRED', value: 'NOT REQUIRED' }],
                    labelWidth: '57.5%',
                    width: '42.5%',
                    marginLeft: '8px',
                    marginRight: '0.5rem',
                    inputTextAlign: 'center',
                    xs: 6,
                    fontSize: '8.9pt',
                    borderBottom: '0',
                    marginBottom: '4px'
                },
                {
                    key: 'detectorLenses',
                    label: 'If detector lenses cleaned, repeat the test',
                    isLabel: true,
                    padding: '5px 0 5px 4px',
                    xs: 6,
                    color: '#ea7c34',
                    fontSize: '8.5pt',
                    fontStyle: 'italic',
                    fontWeight: '600'
                },
            ]
        }
    ]
});
