import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useState } from 'react';
import InformationForm from './InformationForm';
import {
    informationApiEndpoint, informationInitialState,
    informationSearchState, informationHeaderData
} from './Information.data';
import { trackErrors } from '../helper';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';

const addTitle = 'Add New Record for Group Mapping';
const editTitle = 'Edit Record for Group Mapping';

const Information = () => {
    const apiEndpoints = informationApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(informationInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'Id',
        rowNumber: ''
    });
    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(informationInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const result = data.map((res) => ({
                    ...res,
                    Status: res.Pdfstatus === true ? 'Active' : 'Inactive'
                }));
                resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            }
        })();
    });

    return (
        <>
            <DataGridComponent
                headerData={informationHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                rowDetails={rowDetails}
                initialData={informationSearchState}
                refreshDatagrid={refreshDatagrid}
                dataParser={parseData}
            />

            {showModal
                && (
                    <InformationForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};

export default Information;
