import { parseFormDataObj, parseMandatoryFormDataObj } from 'helpers/FormHelpers/helper';
import { IsANumber } from 'helpers/Validation';
import {
    UserInfoSectionData,
    selectManufacturerModelTemplateForForm,
    selectAsFoundTestTableSectionData,
    selectAsFoundFireTestSectionData,
    selectAsLeftFireTestSectionData,
    selectAsLeftTestTableSectionData,
    selectTestResultSectionData,
    selectDeviceTypesSectionData,
    selectHighLowCurrentSectionData,
    selectHMITemplateForForm,
    selectVisualInspectionTemplateForForm,
    selectNoteTemplateForForm,
    selectAdditionalInformationData,
    selectFoundResultTemplateForForm,
    selectValveTravelSectionData,
    selectAftDensityTemplateForForm,
    selectAltDensityTemplateForForm,
    selectFailureModeSectionData,
    selectMagneticFloatCheckData,
    selectDiagnostics,
    selectFoundThresholdForForm,
    selectValveSizeSection,
    selectSandAlarmTestSectionData,
    SelectFailToleranceAlarmSection,
    selectValveSizeTravelSectionData,
    SelectDetectorLenseSection,
    selectPreTestSectionData,
    selectAsFoundTestWorkshopSectionData,
    selectAsLeftTestWorkshopSectionData,
    selectDetectorreinstatementSectionData,
    selectReCalibrationData,
    SelectSraDeviceSection,
    selectZeroFlowConditions,
    selectSignalToTrigger,
    selectTestResultComponentData,
    selectDielectricConstant,
    selectTemperatureSensor,
    selectFireSuppressionData,
    selectPositionSwitch,
    selectToleranceInstrumentEUComment,
    selectSetpointSectionData,
    selectSmartMeterSectionData,
    selectAuxiliaryProcessSectionData,
    selectFlowChangeSectionData,
    selectFlowAlarmTestData,
    selectSerialNumberSectionData,
    selectAsFoundSolenoidLimitSectionData,
    selectAsLeftSolenoidLimitSectionData,
    selectAsFoundIPTransmitterSectionData,
    selectAsLeftIPTransmitterSectionData,
    selectUnitsUsedSectionData,
    selectTxAsLeftSectionData,
    selectFullPartialStrokeTestSection,
    asFoundPstMethodSectionData,
    asLeftTestRequiredSectionData
} from '../Sections';

export const buildDataObject = ({ selectedTag, userName, dpsData, distinctTestMakeModelData, formName, _formData, selectedTrfId, aftTestPoint, altTestPoint, flowAlarmTestPoint, multiTRFDetails }) => ({
    ...parseFormDataObj(UserInfoSectionData(selectedTag, userName, _formData)),
    ...parseFormDataObj(selectManufacturerModelTemplateForForm(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectDeviceTypesSectionData(selectedTag, distinctTestMakeModelData, formName, _formData)),
    ...parseFormDataObj(selectHMITemplateForForm(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectVisualInspectionTemplateForForm(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectHighLowCurrentSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAsFoundTestTableSectionData(selectedTag, formName, _formData, aftTestPoint)),
    ...parseFormDataObj(selectFireSuppressionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAsFoundFireTestSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAsLeftFireTestSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectFoundResultTemplateForForm(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAsLeftTestTableSectionData(selectedTag, formName, _formData, altTestPoint)),
    ...parseFormDataObj(selectTestResultSectionData(selectedTag, formName, _formData, selectedTrfId)),
    ...parseFormDataObj(selectNoteTemplateForForm(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAdditionalInformationData(selectedTag, formName, _formData, selectedTrfId)),
    ...parseFormDataObj(selectValveTravelSectionData(selectedTag, dpsData, formName, _formData)),
    ...parseFormDataObj(selectAftDensityTemplateForForm(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAltDensityTemplateForForm(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectFailureModeSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectDiagnostics(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectFoundThresholdForForm(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectMagneticFloatCheckData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectValveSizeSection(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectSandAlarmTestSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectValveSizeTravelSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(SelectDetectorLenseSection(selectedTag, formName, _formData)),
    ...parseFormDataObj(SelectFailToleranceAlarmSection(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectPreTestSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAsFoundTestWorkshopSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAsLeftTestWorkshopSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectDetectorreinstatementSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectReCalibrationData(selectedTag, formName, _formData)),
    ...parseFormDataObj(SelectSraDeviceSection(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectZeroFlowConditions(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectSignalToTrigger(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectTestResultComponentData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectDielectricConstant(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectTemperatureSensor(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectPositionSwitch(formName, _formData)),
    ...parseFormDataObj(selectToleranceInstrumentEUComment(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectSetpointSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectSmartMeterSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAuxiliaryProcessSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectFlowChangeSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectFlowAlarmTestData(formName, _formData, flowAlarmTestPoint)),
    ...parseFormDataObj(selectSerialNumberSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAsFoundSolenoidLimitSectionData(formName, _formData)),
    ...parseFormDataObj(selectAsLeftSolenoidLimitSectionData(formName, _formData)),
    ...parseFormDataObj(selectAsFoundIPTransmitterSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectAsLeftIPTransmitterSectionData(selectedTag, formName, _formData)),
    ...parseFormDataObj(selectUnitsUsedSectionData(formName, _formData)),
    ...parseFormDataObj(selectTxAsLeftSectionData(formName, _formData)),
    ...parseFormDataObj(selectFullPartialStrokeTestSection(selectedTag, formName, _formData, multiTRFDetails)),
    ...parseFormDataObj(asFoundPstMethodSectionData(formName, _formData)),
    ...parseFormDataObj(asLeftTestRequiredSectionData(formName, _formData)),

});

export const areFilledAllMandatoryDataObject = ({ selectedTag, userName, dpsData, distinctTestMakeModelData, formName,
    _formData, selectedTrfId, aftTestPoint, altTestPoint, flowAlarmTestPoint, multiTRFDetails }) => buildMandatoryDataObject({
        selectedTag,
        userName,
        dpsData,
        distinctTestMakeModelData,
        formName,
        _formData,
        selectedTrfId,
        aftTestPoint,
        altTestPoint,
        flowAlarmTestPoint,
        multiTRFDetails
    }).filter(x => x.isRequired).every(x => (x.value && (!x.value.trim || x.value.trim())) || x.value === 0);

export const dpsHasErrorDataObject = ({ selectedTag, userName, dpsData, distinctTestMakeModelData, formName,
    _formData, selectedTrfId, aftTestPoint, altTestPoint, flowAlarmTestPoint }) => buildMandatoryDataObject({
        selectedTag,
        userName,
        dpsData,
        distinctTestMakeModelData,
        formName,
        _formData,
        selectedTrfId,
        aftTestPoint,
        altTestPoint,
        flowAlarmTestPoint
    }).filter(x => x.hasDPSError).every(x => ((x.value && IsANumber(x?.value)) || x.value === 0));

export const dpsHasNotNullErrorDataObject = ({ selectedTag, userName, dpsData, distinctTestMakeModelData, formName,
    _formData, selectedTrfId, aftTestPoint, altTestPoint, flowAlarmTestPoint }) => buildMandatoryDataObject({
        selectedTag,
        userName,
        dpsData,
        distinctTestMakeModelData,
        formName,
        _formData,
        selectedTrfId,
        aftTestPoint,
        altTestPoint,
        flowAlarmTestPoint
    }).filter(x => x.hasDPSNotNullError).every(x => (x?.value));

export const buildMandatoryDataObject = ({ selectedTag, userName, dpsData, distinctTestMakeModelData, formName, _formData, selectedTrfId, aftTestPoint, altTestPoint, flowAlarmTestPoint, multiTRFDetails }) => ([
    ...parseMandatoryFormDataObj(UserInfoSectionData(selectedTag, userName, _formData)),
    ...parseMandatoryFormDataObj(selectManufacturerModelTemplateForForm(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectDeviceTypesSectionData(selectedTag, distinctTestMakeModelData, formName, _formData)),
    ...parseMandatoryFormDataObj(selectHMITemplateForForm(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectFireSuppressionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectVisualInspectionTemplateForForm(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectHighLowCurrentSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAsFoundTestTableSectionData(selectedTag, formName, _formData, aftTestPoint)),
    ...parseMandatoryFormDataObj(selectAsFoundFireTestSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAsLeftFireTestSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectFoundResultTemplateForForm(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAsLeftTestTableSectionData(selectedTag, formName, _formData, altTestPoint)),
    ...parseMandatoryFormDataObj(selectTestResultSectionData(selectedTag, formName, _formData, selectedTrfId)),
    ...parseMandatoryFormDataObj(selectNoteTemplateForForm(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAdditionalInformationData(selectedTag, formName, _formData, selectedTrfId)),
    ...parseMandatoryFormDataObj(selectValveTravelSectionData(selectedTag, dpsData, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAftDensityTemplateForForm(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAltDensityTemplateForForm(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectFailureModeSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectMagneticFloatCheckData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectDiagnostics(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectValveSizeSection(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectValveSizeTravelSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(SelectDetectorLenseSection(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(SelectFailToleranceAlarmSection(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectPreTestSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAsFoundTestWorkshopSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAsLeftTestWorkshopSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectDetectorreinstatementSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectReCalibrationData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(SelectSraDeviceSection(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectZeroFlowConditions(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectSignalToTrigger(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectTestResultComponentData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectDielectricConstant(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectTemperatureSensor(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectPositionSwitch(formName, _formData)),
    ...parseMandatoryFormDataObj(selectToleranceInstrumentEUComment(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectSetpointSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectSmartMeterSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAuxiliaryProcessSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectFlowChangeSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectFlowAlarmTestData(formName, _formData, flowAlarmTestPoint)),
    ...parseMandatoryFormDataObj(selectSerialNumberSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAsFoundSolenoidLimitSectionData(formName, _formData)),
    ...parseMandatoryFormDataObj(selectAsLeftSolenoidLimitSectionData(formName, _formData)),
    ...parseMandatoryFormDataObj(selectAsFoundIPTransmitterSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectAsLeftIPTransmitterSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectSandAlarmTestSectionData(selectedTag, formName, _formData)),
    ...parseMandatoryFormDataObj(selectUnitsUsedSectionData(formName, _formData)),
    ...parseMandatoryFormDataObj(selectTxAsLeftSectionData(formName, _formData)),
    ...parseMandatoryFormDataObj(selectFullPartialStrokeTestSection(selectedTag, formName, _formData, multiTRFDetails)),
    ...parseMandatoryFormDataObj(asFoundPstMethodSectionData(formName, _formData)),
    ...parseMandatoryFormDataObj(asLeftTestRequiredSectionData(formName, _formData)),
]);
