import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField } from 'helpers/Validation';

export const AsLeftTestTableHmiInst1Formula = (formData) => {
    let HmiInst1;
    if (FieldHasValue(formData.F21HmiLrv) || FieldHasValue(formData.F25InstrumentLrv)) {
        HmiInst1 = '0% - '.concat(ValidateField(formData.F21HmiLrv))
            .concat('% - ')
            .concat(ValidateField(formData.F25InstrumentLrv))
            .concat((FieldHasValue(formData.F28InstrumentEngineeringUnit)) ? ' ' : '')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        return HmiInst1.length > 255 ? HmiInst1.slice(0, 255) : HmiInst1;
    }
    return '0%';
};

export const AsLeftTestTableHmiInst2Formula = (formData) => {
    let HmiInst2;
    if (FieldHasValue(formData.F21HmiLrv) || FieldHasValue(formData.F22HmiUrv) || FieldHasValue(formData.F25InstrumentLrv) || FieldHasValue(formData.F26InstrumentUrv)) {
        HmiInst2 = '50% - '.concat(ExponentialToDecimal((ValidateNumberField(formData.F21HmiLrv)
            + (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv)) * 0.5)))
            .concat('% - ')
            .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
                + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
            .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        return HmiInst2.length > 255 ? HmiInst2.slice(0, 255) : HmiInst2;
    }
    return '50%';
};

export const AsLeftTestTableHmiInst3Formula = (formData) => {
    let HmiInst3;
    if (FieldHasValue(formData.F22HmiUrv) || FieldHasValue(formData.F26InstrumentUrv)) {
        HmiInst3 = '100% - '.concat(ValidateField(formData.F22HmiUrv))
            .concat('% - ')
            .concat(ValidateField(formData.F26InstrumentUrv))
            .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        return HmiInst3.length > 255 ? HmiInst3.slice(0, 255) : HmiInst3;
    }
    return '100%';
};

export const AsLeftTestTableDeviationEu1Formula = (formData) => {
    if (FieldHasValue(formData.F228AltIndependentReading1) || FieldHasValue(formData.F231AltTxreading1)) {
        if (FieldHasValue(formData.F228AltIndependentReading1) && FieldHasValue(formData.F231AltTxreading1)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F231AltTxreading1) - ValidateNumberField(formData.F228AltIndependentReading1)));
        }
        return '';
    }
    return '';
};

export const AsLeftTestTableDeviationEu2Formula = (formData) => {
    if (FieldHasValue(formData.F229AltIndependentReading2) || FieldHasValue(formData.F232AltTxreading2)) {
        if (FieldHasValue(formData.F229AltIndependentReading2) && FieldHasValue(formData.F232AltTxreading2)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F232AltTxreading2) - ValidateNumberField(formData.F229AltIndependentReading2)));
        }
        return '';
    }
    return '';
};

export const AsLeftTestTableDeviationEu3Formula = (formData) => {
    if (FieldHasValue(formData.F230AltIndependentReading3) || FieldHasValue(formData.F233AltTxreading3)) {
        if (FieldHasValue(formData.F230AltIndependentReading3) && FieldHasValue(formData.F233AltTxreading3)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F233AltTxreading3) - ValidateNumberField(formData.F230AltIndependentReading3)));
        }
        return '';
    }
    return '';
};
