import styled from 'styled-components';
import { bgColorWithData } from '../Inputs.styled';

export const StyledLabel = styled.div`
    font-size: ${props => props.labelFontSize ? props.labelFontSize : '9pt'};
    color: ${props => props.labelColor ? props.labelColor : ''};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '600'};
    font-family: ${props => props.fontFamily ? props.fontFamily : 'Segoe UI'};
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRight};
    width: ${props => props.labelWidth ? props.labelWidth : '10rem'};
    direction: ${props => props.direction ? props.direction : ''};
    text-align: ${props => props.textAlign};
`;
const solidValue = '2px solid rgb(120, 120, 120)';
export const SelectWrapper = styled.div`
align-items: center;
    display: flex;
    margin-bottom: ${props => props.marginBottom};
    margin-top: ${props => props.marginTop};
    .Dropdown-root {
        position: relative;
        height: 2rem;
    }
    .Dropdown-control {
      position: relative;
      overflow: hidden;
      border: 2px solid rgb(166, 166, 166);
      box-sizing: border-box;
      cursor: default;
      outline: none;
      transition: all 200ms ease;
      font-size: 10pt;
      color: rgb(51, 51, 51);
      font-weight: normal;
      font-style: normal;
      text-align: left;
      line-height: 1.5;
      padding: 5px;
      height: 2rem;
      border-radius: 0;
      -webkit-border-radius: 0;
      -webkit-appearance: none;
      width: 100%;
      margin-left: 1rem;
    }
    .isRequired .Dropdown-control {
        background-color: ${props => props.value ? bgColorWithData : 'rgb(255, 255, 0)'};
    }

    .Dropdown-arrow {
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-top: -ceil(2.5);
      position: absolute;
      right: 10px;
      top: 3px;
    }
    .isRequired .Dropdown-menu {
      background-color: ${props => props.value ? bgColorWithData : 'rgb(255, 255, 0)'};
    }
    .Dropdown-noresults {
      box-sizing: border-box;
      color: #ccc;
      cursor: default;
      display: block;
      padding: 8px 10px;
    }
    .Dropdown-option.Dropdown-placeholder {
      width: 93%;
    }
    .Dropdown-root {
        margin-right: ${props => props.marginRight ? props.marginRight : props.rootMarginRight};
        width: ${props => props.width ? props.width : '68.7%'};
        height: ${props => props.height ? props.height : '1.5rem'};
    }
    .Dropdown-control {
        width: 100%;
        margin-left: 0;
        height: ${props => props.height ? props.height : '1.5rem'};
        padding: 2px 1px;
        font-size: ${props => props.fontSize ? props.fontSize : '9pt'};
        border-top: ${props => props.borderTop ? props.borderTop : solidValue};
        border-left: ${props => props.borderLeft ? props.borderLeft : solidValue};
        border-bottom: ${props => props.borderBottom ? props.borderBottom : solidValue};
        border-right: ${props => props.borderRight ? props.borderRight : solidValue};
        text-align: ${props => props.inputTextAlign ? props.inputTextAlign : 'left'};
    }
    .Dropdown-noresults {
        font-size: 0pt;
        height: 35px;
        background-color: white;
        border: 2px solid rgb(51, 91, 3);
    }
    .Dropdown-menu {
        margin-left: 0;
    }
    .Dropdown-arrow {
        padding: 3px;
        margin-top: ${props => props.arrowMarginTop}
    }
    .Dropdown-option {
        min-height: 1.5rem;
        padding: 5px 3px;
    }
    .Dropdown-placeholder {
        padding-top: ${props => props.paddingPlaceholder};
        padding-right: ${props => props.paddingRightPlaceholder};
    }
    .readOnly .Dropdown-control {
        border: 0 !important;
        margin-left: 0;
    }
    .readOnly .Dropdown-control:hover{
        height:  ${props => props.height ? props.height : '1.5rem'};
    }
    .readOnly .Dropdown-control:focus{
        height: ${props => props.height ? props.height : '1.5rem'};
    }
    .readOnly .Dropdown-control:active{
        height: ${props => props.height ? props.height : '1.5rem'};
    }
    .readOnly .Dropdown-arrow {
        border: 0;
    }
    .readOnly .Dropdown-arrow {
        border: 0;
    }
`;
