export const AFTDensitySectionDataP65P831 = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsFoundTestHMIINST',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 3px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F197AftWaterDensityUsed',
                label: 'Water Density Used',
                value: formData.F197AftWaterDensityUsed,
                xs: 6,
                maxLength: '23',
                width: '32.4%',
                textAlign: 'center',
                labelWidth: '32.8%',
                marginLeft: '2px',
                borderBottom: '0',
            },
            {
                key: 'Nodata',
                isLabel: true,
                xs: 2,
            },
            {
                key: 'F200AftBackgroundCountLabel',
                label: 'Background Count',
                xs: 2,
                isLabel: 'true',
                labelWidth: '96%',
                textAlign: 'end',
                fontSize: '9pt',
                fontWeight: '600',
                padding: '4px 0 0 0',
            },
            {
                key: 'F200AftBackgroundCount',
                value: formData.F200AftBackgroundCount,
                xs: 2,
                maxLength: '23',
                labelWidth: '0',
                width: '100%',
                textAlign: 'center',
                borderBottom: '0',
                borderRight: '0',
                marginLeft: '2px'
            }
            ]
        },
        {
            key: 3,
            fields: [{
                key: 'F198AftOilDensityUsed',
                label: 'Oil Density Used',
                value: formData.F198AftOilDensityUsed,
                maxLength: '23',
                xs: 6,
                textAlign: 'center',
                width: '32.4%',
                labelWidth: '32.8%',
                marginLeft: '2px',
                borderBottom: '0',
                fontSize: '9pt',
                fontWeight: '600',
            },
            {
                key: 'Nodata',
                isLabel: true,
                xs: 2,
            },
            {
                key: 'F201AftVesselEmptyCountLabel',
                label: 'Vessel Empty Count',
                xs: 2,
                isLabel: 'true',
                labelWidth: '96%',
                textAlign: 'end',
                fontSize: '9pt',
                fontWeight: '600',
                padding: '4px 0 0 0',
            },
            {
                key: 'F201AftVesselEmptyCount',
                value: formData.F201AftVesselEmptyCount,
                maxLength: '23',
                xs: 2,
                labelWidth: '0',
                width: '100%',
                textAlign: 'center',
                borderBottom: '0',
                borderRight: '0',
                marginLeft: '2px'
            }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F199AftGasDensityUsed',
                    label: 'Gas Density Used',
                    value: formData.F199AftGasDensityUsed,
                    maxLength: '23',
                    xs: 6,
                    width: '32.4%',
                    textAlign: 'center',
                    labelWidth: '32.8%',
                    marginLeft: '2px',
                    fontSize: '9pt',
                    fontWeight: '600',
                },
                {
                    key: 'Nodata',
                    isLabel: true,
                    xs: 2,
                },
                {
                    key: 'F202AftVesselFullCountLabel',
                    label: 'Vessel Full Count',
                    xs: 2,
                    isLabel: 'true',
                    labelWidth: '96%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '4px 0 0 0',
                },
                {
                    key: 'F202AftVesselFullCount',
                    value: formData.F202AftVesselFullCount,
                    maxLength: '23',
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    borderRight: '0',
                    marginLeft: '2px'
                }
            ]
        }
    ]

});
