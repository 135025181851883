import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { FormInputViewOnly } from 'elements/Inputs/FormInput/FormInputViewOnly';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { del } from 'idb-keyval';
import imageStore from 'utils/ImageDB';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { connect } from 'react-redux';
import { deleteExistingImages, deleteNewImages, setIsEditimage } from 'journeys/portal/Forms/Forms.actions';
import { formatDate } from 'helpers/DateFormatter';
import { DisplayWrapper, ImageWrapper, InfoWrapper, DeleteButton, EditButton, Border } from './ImageGallery.styled';

const DisplayImage = (props) => {
    const { image, getImages, removeExistingImage, removeNewImage, readOnly, setIsEditImage } = props;
    const [deleteAlert, handleDeleteAlert] = useState(false);
    const [deleteImage, setDeleteImage] = useState('');
    const showDelete = !readOnly;
    const deleteImageHandler = (img) => {
        setDeleteImage(img);
        handleDeleteAlert(true);
    };
    const editImageHandler = (img) => {
        setIsEditImage(true, img);
    };
    const handleDeleteInformation = () => {
        del(deleteImage.ImageId, imageStore);
        if (deleteImage.RowId) {
            removeExistingImage(deleteImage.RowId);
        } else {
            removeNewImage(deleteImage.ImageId);
        }
        handleDeleteAlert(false);
        getImages();
    };
    return (
        <Border>
            <DisplayWrapper id={`displayWrapper-${image.ImageId}`} key={`display-${image.ImageId}`}>
                {deleteAlert && (
                    <AlertDialog
                        id={`alertDialog-${image.ImageId}`}
                        dialogMessage="Are you Sure you want to delete this Picture?"
                        imageId={deleteImage.ImageId}
                        dialogOpenState={deleteAlert}
                        handleClose={() => handleDeleteAlert(false)}
                        handleAlertFunction={() => handleDeleteInformation()}
                    />
                )}
                <ImageWrapper id={`imageWrapper-${image.ImageId}`}>
                    {!image.RowId
                        ? (
                            <img
                                src={image.base64Image}
                                width="100%"
                                height="100%"
                                alt={`capture-${image.ImageId}`}
                                key={`image-${image.ImageId}`}
                                id={`image-${image.ImageId}`}
                            />
                        ) : (
                            <img
                                src={`data:image/png;base64,${image.Image}`}
                                width="100%"
                                height="100%"
                                alt={`capture-${image.ImageId}`}
                                key={`image-${image.ImageId}`}
                                id={`image-${image.ImageId}`}
                            />
                        )}
                </ImageWrapper>
                <InfoWrapper id={`infoWrapper-${image.ImageId}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormInputViewOnly
                                id={`functional_location-${image.ImageId}`}
                                label="Functional Location:"
                                value={image.HdLocation}
                                labelWidth="18rem"
                                valueSize="10pt"
                                labelfontSize="10pt"
                                width="16rem"
                                fontWeight="bold"
                                paddingLeft={false}
                                padding="5px"
                                labelPadding="5px"
                                valueMarginTop="0.2rem"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInputViewOnly
                                id={`date-${image.ImageId}`}
                                label="Created Date:"
                                value={image.RowId ? formatDate(new Date(image.CreationDate)) : image.CreationDate}
                                labelWidth="18rem"
                                valueSize="10pt"
                                labelfontSize="10pt"
                                width="16rem"
                                fontWeight="bold"
                                paddingLeft={false}
                                padding="5px"
                                labelPadding="5px"
                                valueMarginTop="0.2rem"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInputViewOnly
                                id={`user-${image.ImageId}`}
                                label="Created By:"
                                value={image.CreatedBy}
                                labelWidth="18rem"
                                valueSize="10pt"
                                labelfontSize="10pt"
                                width="16rem"
                                fontWeight="bold"
                                paddingLeft={false}
                                padding="5px"
                                labelPadding="5px"
                                valueMarginTop="0.2rem"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInputViewOnly
                                id={`date-${image.ImageId}`}
                                label="Last Modified Date:"
                                value={image.RowId ? formatDate(new Date(image.DateLastModified)) : image.DateLastModified}
                                labelWidth="18rem"
                                valueSize="10pt"
                                labelfontSize="10pt"
                                width="16rem"
                                fontWeight="bold"
                                paddingLeft={false}
                                padding="5px"
                                labelPadding="5px"
                                valueMarginTop="0.2rem"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInputViewOnly
                                id={`user-${image.ImageId}`}
                                label="Modified By:"
                                value={image.ModifiedBy}
                                labelWidth="18rem"
                                valueSize="10pt"
                                labelfontSize="10pt"
                                width="16rem"
                                fontWeight="bold"
                                paddingLeft={false}
                                padding="5px"
                                labelPadding="5px"
                                valueMarginTop="0.2rem"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInputViewOnly
                                id={`Image_id-${image.ImageId}`}
                                label="IMG ID:"
                                value={image.ImageId}
                                labelWidth="18rem"
                                valueSize="10pt"
                                labelfontSize="10pt"
                                width="16rem"
                                fontWeight="bold"
                                paddingLeft={false}
                                padding="5px"
                                labelPadding="5px"
                                valueMarginTop="0.2rem"
                            />
                        </Grid>
                    </Grid>
                </InfoWrapper>
                {showDelete && (
                    <DeleteButton id={`deleteImage-${image.ImageId}`}>
                        <IconButton aria-label="delete" onClick={() => { deleteImageHandler(image); }}>
                            <DeleteIcon style={{ color: '#498205', fontSize: '2rem' }} id={`delete-${image.ImageId}`} />
                        </IconButton>
                    </DeleteButton>
                )}
                <EditButton id={`editImage-${image.ImageId}`}>
                    <IconButton aria-label="edit" onClick={() => { editImageHandler(image); }}>
                        <EditIcon style={{ color: '#498205', fontSize: '2rem' }} id={`edit-${image.ImageId}`} />
                    </IconButton>
                </EditButton>
            </DisplayWrapper>
            <Grid item xs={12}>
                <FormInputViewOnly
                    id={`note-${image.ImageId}`}
                    label="Note:"
                    value={image.ImageNotes}
                    labelWidth="19%"
                    valueSize="10pt"
                    labelfontSize="10pt"
                    width="81%"
                    fontWeight="bold"
                    paddingLeft={false}
                    valuePadding="true"
                    valueMarginTop="0.2rem"
                    padding="5px"
                    labelPadding="6px 5px 5px 6px"
                    valueHeight="7rem"
                    wordBreak
                />
            </Grid>
        </Border>
    );
};

const mapStateToProps = ({ Home, Forms }) => ({
    userRole: Home.userRole
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    removeExistingImage: (rowId) => dispatch(deleteExistingImages(rowId)),
    removeNewImage: (imgId) => dispatch(deleteNewImages(imgId)),
    setIsEditImage: (isEdit, img) => dispatch(setIsEditimage(isEdit, img))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplayImage);

DisplayImage.propTypes = {
    getImages: PropTypes.func,
    image: PropTypes.object,
    readOnly: PropTypes.bool,
    removeExistingImage: PropTypes.func,
    removeNewImage: PropTypes.func,
    setIsEditImage: PropTypes.func
};
