import { Setpoint } from 'journeys/portal/Forms/Sections.constants';
import {
    paddingLabel, setpointNote, highHighSetpointValue, highSetpointValue, lowSetpointValue, lowLowSetpointValue, highHighSetpointComment, lowSetpointComment,
    lowLowSetpointComment, highSetPointComment, highHighSetpointCommentValue, highSetpointCommentValue, lowLowSetpointCommentValue, lowSetpointCommentValue,
    borderBottomHHDisable, borderBottomDisable, borderBottomLDisable
} from '../C11/C11.data';

export const SetpointSectionDataP65P77P831 = (selectedTag, formName, formData) => ({
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'remark',
                    label: setpointNote,
                    isLabel: true,
                    xs: 12,
                    fontWeight: '600',
                    labelWidth: '99%',
                    padding: '5px 1px 10px 3px',
                    fontSize: '8.5pt',
                    color: '#ea7c34',
                    marginTop: '0'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F240HighHighSetpointLabel',
                        label: 'High High Setpoint (%)',
                        xs: 2,
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'left',
                        fontSize: '9pt',
                        fontWeight: '600',
                    },
                    {
                        key: 'F240HighHighSetpoint',
                        value: highHighSetpointValue(selectedTag, formData),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0',
                        width: '100%',
                        height: '1.5rem',
                        textAlign: 'center',
                        borderBottom: borderBottomHHDisable(selectedTag, formData)
                    },
                    {
                        key: 'F242IndependentLevelReading1',
                        label: Setpoint.INDEPENDENT_FLOW_READING,
                        value: formData.F242IndependentLevelReading1,
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        height: '1.25rem',
                        labelWidth: '77%',
                        marginRight: '0.1rem',
                        width: '54%',
                        textAlign: 'center',
                        borderBottom: borderBottomHHDisable(selectedTag, formData),
                    },
                    {
                        key: 'F51HmiReading1',
                        label: Setpoint.HMI_READING_PRECENT,
                        value: formData.F51HmiReading1,
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '50%',
                        width: '50%',
                        height: '1.5rem',
                        marginRight: '0.5rem',
                        textAlign: 'center',
                        borderRight: '0',
                        borderBottom: borderBottomHHDisable(selectedTag, formData)
                    }]
            },
            highHighSetpointComment(selectedTag, formData) ? {
                key: 3,
                fields: [
                    {
                        key: 'F448HighHighSetpointCommentLabel',
                        label: 'High High Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600'
                    },
                    {
                        key: 'F448HighHighSetpointComment',
                        value: highHighSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.4rem',
                        padding: paddingLabel,
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 3,
                fields: []
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F32HighSetpointLabel',
                        label: 'High Setpoint (%)',
                        xs: 2,
                        height: '1.4rem',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'left',
                        fontSize: '9pt',
                        fontWeight: '600',
                    },
                    {
                        key: 'F32HighSetpoint',
                        value: highSetpointValue(selectedTag, formData),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0',
                        width: '100%',
                        textAlign: 'center',
                        height: '1.5rem',
                        borderBottom: borderBottomDisable(selectedTag, formData)
                    },

                    {
                        key: 'F244IndependentLevelReading3',
                        label: Setpoint.INDEPENDENT_FLOW_READING,
                        value: formData.F244IndependentLevelReading3,
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '77%',
                        textAlign: 'center',
                        height: '1.25rem',
                        marginRight: '0.1rem',
                        width: '54%',
                        borderBottom: borderBottomDisable(selectedTag, formData),
                    },
                    {
                        key: 'F53HmiReading3',
                        label: Setpoint.HMI_READING_PRECENT,
                        value: formData.F53HmiReading3,
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '50%',
                        width: '50%',
                        textAlign: 'center',
                        height: '1.5rem',
                        marginRight: '0.5rem',
                        borderRight: '0',
                        borderBottom: borderBottomDisable(selectedTag, formData),
                    }]
            },
            highSetPointComment(selectedTag, formData) ? {
                key: 5,
                fields: [
                    {
                        key: 'F796HighSetpointCommentLabel',
                        label: 'High Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                    },
                    {
                        key: 'F796HighSetpointComment',
                        value: highSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.4rem',
                        padding: paddingLabel,
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 5,
                fields: []
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'F33LowSetpointLabel',
                        label: 'Low Setpoint (%)',
                        xs: 2,
                        height: '1.5rem',
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'left',
                        fontSize: '9pt',
                        fontWeight: '600',
                        padding: paddingLabel,
                    },
                    {
                        key: 'F33LowSetpoint',
                        value: lowSetpointValue(selectedTag, formData),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0',
                        width: '100%',
                        textAlign: 'center',
                        height: '1.5rem',
                        borderBottom: borderBottomLDisable(selectedTag, formData),
                    },
                    {
                        key: 'F245IndependentLevelReading4',
                        label: Setpoint.INDEPENDENT_FLOW_READING,
                        value: formData.F245IndependentLevelReading4,
                        type: 'number',
                        maxLength: '10',
                        textAlign: 'center',
                        xs: 4,
                        height: '1.25rem',
                        labelWidth: '77%',
                        width: '54%',
                        marginRight: '0.1rem',
                        borderBottom: borderBottomLDisable(selectedTag, formData),
                    },
                    {
                        key: 'F54HmiReading4',
                        label: Setpoint.HMI_READING_PRECENT,
                        value: formData.F54HmiReading4,
                        type: 'number',
                        maxLength: '10',
                        textAlign: 'center',
                        xs: 4,
                        height: '1.5rem',
                        labelWidth: '50%',
                        width: '50%',
                        marginRight: '0.5rem',
                        borderRight: '0',
                        borderBottom: borderBottomLDisable(selectedTag, formData),
                    }]
            },
            lowSetpointComment(selectedTag, formData) ? {
                key: 7,
                fields: [
                    {
                        key: 'F797LowSetpointCommentLabel',
                        label: 'Low Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                        fontStyle: 'italic'
                    },
                    {
                        key: 'F797LowSetpointComment',
                        value: lowSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.4rem',
                        padding: paddingLabel,
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 7,
                fields: []
            },
            {
                key: 8,
                fields: [
                    {
                        key: 'F241LowLowSetpointLabel',
                        label: 'Low Low Setpoint (%)',
                        xs: 2,
                        isLabel: 'true',
                        labelWidth: '96%',
                        textAlign: 'left',
                        fontSize: '9pt',
                        fontWeight: '600',
                        marginLeft: '0.2rem',
                    },
                    {
                        key: 'F241LowLowSetpoint',
                        value: lowLowSetpointValue(selectedTag, formData),
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0',
                        width: '100%',
                        height: '1.5rem',
                        textAlign: 'center',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                        marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px',
                    },
                    {
                        key: 'F243IndependentLevelReading2',
                        label: Setpoint.INDEPENDENT_FLOW_READING,
                        value: formData.F243IndependentLevelReading2,
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '77%',
                        width: '54%',
                        marginRight: '0.1rem',
                        height: '1.25rem',
                        textAlign: 'center',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                        marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px',
                    },
                    {
                        key: 'F52HmiReading2',
                        label: Setpoint.HMI_READING_PRECENT,
                        value: formData.F52HmiReading2,
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '50%',
                        width: '50%',
                        height: '1.5rem',
                        marginRight: '0.5rem',
                        textAlign: 'center',
                        borderRight: '0',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                        marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px',
                    }]
            },
            lowLowSetpointComment(selectedTag, formData) ? {
                key: 9,
                fields: [
                    {
                        key: 'F449LowLowSetpointCommentLabel',
                        label: 'Low Low Setpoint Comment',
                        xs: 2,
                        textAlign: 'left',
                        padding: '5px 0 3px 3px',
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                        fontStyle: 'italic'
                    },
                    {
                        key: 'F449LowLowSetpointComment',
                        value: lowLowSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.4rem',
                        padding: '5px 0 3px 3px',
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 9,
                fields: []
            }
        ]
    });
