import styled from 'styled-components';

export const StyleSRADropdown = styled.div`
.Dropdown-placeholder {
    width: 100%;
}
.Dropdown-menu {
    text-align: center;
}
`;
