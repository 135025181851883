import { AsFoundResultFormula, ReCalibrationFormula } from './M611.formula';
import { checkTGCGreaterThanSetpointHH, checkTGCLessThanSetpointLL } from 'helpers/Validation';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const tgcHighLabel = 'Concentration must be greater than or equal to HH Setpoint';
export const tgcLowLabel = 'Concentration must be less than or equal to LL Setpoint';
export const paddingLabel = '4px 0 0 8px';

export const AsFoundTestTableSectionDataM611M614M615M61 = (selectedTag, formData, formName) => {
    let aftTestGasConcentration;
    if (formName === 'M6-11' || formName === 'M6-1' || formName === 'M6-14') {
        aftTestGasConcentration = tgcHighLabel;
    } else if (formName === 'M6-15') {
        aftTestGasConcentration = tgcLowLabel;
    }
    let checkTCGFunction;
    if (formName === 'M6-11' || formName === 'M6-1' || formName === 'M6-14') {
        checkTCGFunction = checkTGCGreaterThanSetpointHH(formData?.F240HighHighSetpoint, formData?.F424AftAppliedTestGasConcentration);
    } else if (formName === 'M6-15') {
        checkTCGFunction = checkTGCLessThanSetpointLL(formData?.F241LowLowSetpoint, formData?.F424AftAppliedTestGasConcentration);
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'As-FoundTest',
                    label: 'As-Found Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F431AftZeroreadingvalueLabel',
                        label: 'Reading Without Test Gas',
                        xs: 4,
                        labelWidth,
                        padding: paddingLabel,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F431AftZeroreadingvalue',
                        value: formData.F431AftZeroreadingvalue,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        textAlign: 'center',
                        height: '1.28rem',
                        borderBottom: '0'
                    },
                ]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F424AftAppliedTestGasConcentrationLabel',
                        label: 'Applied Test Gas Concentration',
                        xs: 4,
                        labelWidth,
                        padding: paddingLabel,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F424AftAppliedTestGasConcentration',
                        value: formData.F424AftAppliedTestGasConcentration,
                        isRequired: true,
                        xs: 2,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        textAlign: 'center',
                        height: '1.3rem',
                        borderBottom: '0',
                        hasAftTestGasConcentrationError: !checkTCGFunction,
                        testGasConcentrationMessage: aftTestGasConcentration
                    },
                    {
                        key: 'TestGasConcentration',
                        label: aftTestGasConcentration,
                        isLabel: true,
                        padding: '5px 0 5px 4px',
                        xs: 6,
                        color: '#ea7c34',
                        fontSize: '8.5pt',
                        fontStyle: 'italic',
                        fontWeight: '600'
                    }]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F433DetectorvaluewithTestGasAppliedAFTLabel',
                        label: 'Detector Value With Test Gas Applied',
                        xs: 4,
                        labelWidth,
                        padding: paddingLabel,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F433DetectorvaluewithTestGasAppliedAFT',
                        value: formData.F433DetectorvaluewithTestGasAppliedAFT,
                        xs: 2,
                        type: 'number',
                        isRequired: true,
                        maxLength: '10',
                        labelWidth: '0%',
                        width: '100%',
                        textAlign: 'center',
                        height: '1.28rem',
                        borderBottom: '0'
                    },
                    {
                        key: 'F425ReCalibrationRequiredLabel',
                        label: 'Re-Calibration Required',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: '4px 5px 0 0',
                        labelWidth,
                    },
                    {
                        key: 'F425ReCalibrationRequired',
                        value: ReCalibrationFormula(formData),
                        readOnly: true,
                        isLabelBold: true,
                        isCalculatedNew: true,
                        xs: 2,
                        labelWidth: '0%',
                        width: '100%',
                        height: '1.28rem',
                        textAlignLabel: 'end',
                        borderBottom: '0',
                        borderRight: '0',
                        textAlign: 'center',
                        inputMarginRight: '4px',
                        marginBottom: '0',
                        fontWeight: '700'
                    }
                ]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F372AftHmistatus1Label',
                        label: 'HMI Status',
                        xs: 4,
                        labelWidth,
                        padding: paddingLabel,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F372AftHmistatus1',
                        value: formData.F372AftHmistatus1,
                        isRequired: true,
                        isDropdown: true,
                        placeholder: 'Select HMI Status',
                        options: [{ name: 'Select HMI Status', value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'ALARM', value: 'ALARM' },
                        { name: 'FAULT', value: 'FAULT' }],
                        xs: 2,
                        inputTextAlign: 'center',
                        labelWidth: '0%',
                        width: '100%',
                        marginBottom: '0',
                        borderBottom: '0'
                    },
                    {
                        key: 'F102AftResultLabel',
                        label: 'As-Found Result',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                        textAlign: 'right',
                        labelWidth,
                    },
                    {
                        key: 'F102AftResult',
                        value: AsFoundResultFormula(formData),
                        readOnly: true,
                        isLabelBold: true,
                        isCalculatedNew: true,
                        xs: 2,
                        labelWidth: '0%',
                        width: '100%',
                        height: '1.25rem',
                        textAlignLabel: 'end',
                        borderBottom: '0',
                        borderRight: '0',
                        textAlign: 'center',
                        inputMarginRight: '4px',
                        marginBottom: '4px',
                    }]
            }]
    };
};
