import { leadingZero, MatchNumber } from './Validation';

export const HandleTextValue = (e, type, handleValue, maxLength) => {
    const newTextValue = e.target.value?.replace(/^\s+/, '');
    if (type === 'number' && (e.nativeEvent.inputType === 'deleteContentBackward')) {
        handleValue(newTextValue);
    } else if (newTextValue.length <= (maxLength ?? 255)) {
        if (type === 'number') {
            if (newTextValue.startsWith('-.') || newTextValue.startsWith('+.') || newTextValue.startsWith('.')) {
                handleValue(leadingZero(newTextValue));
            } else if (MatchNumber(newTextValue) || newTextValue.match(/^[-+]?\d+[.]$/)) {
                handleValue((newTextValue));
            }
        } else if (type !== 'number') {
            handleValue(newTextValue);
        }
    }
};

export const handleBlur = (e, handleChange, name) => {
    if (!e.target.value.match(/\d/)) {
        const val = '';
        handleChange({ [name]: val });
    } else if (e.target.value.match(/^[-+]?\d+[.]$/)) {
        handleChange({ [name]: leadingZero(e.target.value.replace(/\.$/, '')) });
    } else {
        handleChange({ [name]: leadingZero(e.target.value) });
    }
};
