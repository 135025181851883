import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { useStyles } from './ImageUploader.styled';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { PageViewer } from 'components/InformationPage/InformationPage.styled';
import { backgroundColor } from 'journeys/portal/Admin/AdminScreen.helper';

const PdfUploader = ({ onSubmit, required, value }) => {
    const classes = useStyles();
    const inputRef = useRef(null);
    const [showUploader, setShowUploader] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    const [uploadError, setUploadError] = useState(false);
    const [cancelAlert, setCancelAlert] = useState(false);
    const bgColor = backgroundColor(value, required);
    const [pages, setPages] = useState(null);
    const [scale, setScale] = useState(0.9);
    const [displayZoomButton, setDisplayZoomButton] = useState(true);
    const handleDocumentLoadSuccess = ({ numPages }) => {
        setPages(numPages);
        setDisplayZoomButton(true);
    };

    const handleButtonClick = () => {
        setShowUploader(true);
    };

    const handleOnClose = () => {
        setCancelAlert(false);
        setPdfData(null);
        inputRef.current.value = null;
        setUploadError(false);
        setShowUploader(false);
        setScale(0.9);
    };

    const handleAlert = () => {
        if (pdfData) {
            setCancelAlert(true);
        } else {
            handleOnClose();
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const handleUploadFile = (e) => {
        const file = e.target.files[0];
        const allowedExtensions = /(\.pdf)$/i;
        if (!allowedExtensions.exec(e.target.value)[0]) {
            setUploadError(true);
            inputRef.current.value = null;
            setPdfData(null);
            setScale(0.9);
        } else {
            setUploadError(false);
            setPdfData(file);
            setScale(0.9);
        }
    };

    const handleDeleteFile = () => {
        setUploadError(false);
        inputRef.current.value = null;
        setPdfData(null);
        setDisplayZoomButton(false);
        setScale(0.9);
    };

    const handleSubmit = () => {
        onSubmit(pdfData);
        handleOnClose();
    };

    return (
        <>
            <Button
                variant="outlined"
                size="small"
                style={{ textTransform: 'capitalize', fontSize: '11px', color: 'black', backgroundColor: bgColor, maxHeight: '27px' }}
                onClick={handleButtonClick}
            >
                Upload PDF
            </Button>
            {showUploader
                && (
                    <Dialog
                        open
                        scroll="paper"
                        maxWidth="md"
                        fullWidth
                        PaperProps={{
                            style: {
                                maxHeight: '120vh',
                            },
                        }}
                    >
                        {cancelAlert && (
                            <AlertDialog
                                dialogMessage={AdminConstants.DIALOG_MESSAGE}
                                dialogOpenState={cancelAlert}
                                handleClose={() => handleUnsaveChanges()}
                                handleAlertFunction={handleOnClose}
                            />
                        )}
                        <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>
                            Upload PDF
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ display: 'flex', marginTop: '15px' }}>
                                <div style={{ flex: '1', marginTop: '100px' }}>
                                    <div style={{ display: 'grid', gap: '20px', minWidth: '30px', marginRight: '10px', alignContent: 'center', justifyItems: 'center' }}>
                                        <InputLabel style={{ fontSize: '13px', color: 'black', fontWeight: '600' }}>
                                            Please select a PDF file
                                        </InputLabel>
                                        <input
                                            ref={inputRef}
                                            type="file"
                                            accept=".pdf"
                                            onChange={handleUploadFile}
                                            style={{ maxHeight: '320px', maxWidth: '180px', height: '30px', marginTop: '40px', marginBottom: '40px' }}
                                        />
                                        {uploadError && (
                                            <InputLabel style={{ fontSize: '12px', color: 'red', fontWeight: '600' }}>
                                                The selected file must be in .pdf format
                                            </InputLabel>
                                        )}
                                    </div>
                                </div>
                                <div style={{ flex: '3', height: '400px', padding: '5px', border: '2px dashed grey', overflow: 'auto' }}>
                                    {pdfData && (
                                        <Document
                                            file={(pdfData)}
                                            onLoadSuccess={handleDocumentLoadSuccess}
                                            error="Invalid or corrupted PDF file"
                                            onLoadError={() => setDisplayZoomButton(false)}
                                        >
                                            {[...Array(pages)].map((x, i) => i + 1).map((page, i) => (
                                                <PageViewer key={page}>
                                                    <Page scale={scale} className="page" pageNumber={page} />
                                                    <br />
                                                </PageViewer>
                                            ))}
                                        </Document>
                                    )}
                                </div>
                            </div>
                        </DialogContent>

                        <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px' }}>
                            {displayZoomButton && pdfData && (
                                <div style={{ position: 'fixed', left: '55%', bottom: '8.5%', zIndex: 1 }}>
                                    <Button classes={{ root: classes.button }} style={{ marginRight: '5px' }} onClick={() => { setScale(prevScale => prevScale + 0.1); }}><ZoomInIcon /></Button>
                                    {scale > 0.9 && <Button classes={{ root: classes.button }} onClick={() => { setScale(prevScale => prevScale - 0.1); }}><ZoomOutIcon /></Button>}
                                </div>
                            )}
                            {pdfData
                                && (
                                    <Button
                                        classes={{ root: classes.button }}
                                        variant="outlined"
                                        onClick={handleSubmit}
                                    >
                                        Upload
                                    </Button>
                                )}
                            <Button
                                classes={{ root: classes.button }}
                                onClick={handleDeleteFile}
                                variant="outlined"
                            >
                                Reset
                            </Button>
                            <Button
                                classes={{ root: classes.button }}
                                onClick={handleAlert}
                                variant="outlined"
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
        </>
    );
};
export default PdfUploader;

PdfUploader.propTypes = {
    onSubmit: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.any
};
