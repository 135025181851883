export const VisualInspectSectionDataM611M614M615 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F29VisuallyInspectInstrumentTaggingNote',
                label: `Visually inspect instrument tagging,condition, instrument wiring connection/termination, 
                instrument condition, general deterioration`,
                value: formData.F29VisuallyInspectInstrumentTaggingNote,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit',
                marginRight: '0'
            }]
        }
    ]
});
