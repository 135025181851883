export const strEquals = (str1, str2) => str1?.toLowerCase() === str2?.toLowerCase();

export const backgroundColor = (val, required) => {
    const bgColorWithData = 'rgb(255, 229, 175)';
    let bgColor = 'white';
    if (required && (val || val === 0 || val === false)) {
        bgColor = bgColorWithData;
    } else if (required) {
        bgColor = 'yellow';
    }
    return bgColor;
};
