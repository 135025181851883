import {
    AsLeftTableF63Hmi2Inst1Formula,
    AsLeftTableF65Hmi2Inst3Formula,
    AsFoundTestTableF78Deviation2Eu1Formula,
    AsFoundTestTableF79Deviation2Eu2Formula,
    AsFoundTestTableF80Deviation2Eu3Formula
} from './P622.formulas';
import { TestResultF81AsLeftResultFormula } from '../../../TestResultSection/Data/P61/P61.formulas';
import { paddingAF } from '../M71/M71.data';
import { AsLeftTableF64Hmi2Inst2Formula } from '../P62/P62.formulas';
import { isEnabledcondition } from '../M611/M611.data';

export const AsLeftTestTableSectionDataP622 = (selectedTag, formData) => {
    const disableField = formData.F102AftResult === 'PASS' && formData.F62SensorTrimNeed === 'NO';
    return ({
        rows: [
            {
                key: 1,
                fields: [{
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'AsLeftTestHMIINST',
                    label: '% - HMI - Inst.',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: '5px 0 5px 8px',
                    fontSize: '9pt'
                },
                {
                    key: 'Test Module Reading',
                    label: 'Test Module Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'TXReading',
                    label: 'TX Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    textAlign: 'center',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'HMIReading',
                    label: 'HMI Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'mAReading',
                    label: 'mA Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt',
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F63Hmi2Inst1',
                    value: isEnabledcondition(AsLeftTableF63Hmi2Inst1Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F66TestModule2Reading1',
                    value: isEnabledcondition(formData.F66TestModule2Reading1, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F69Tx2Reading1',
                    value: isEnabledcondition(formData.F69Tx2Reading1, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F72Hmi2Reading1',
                    value: isEnabledcondition(formData.F72Hmi2Reading1, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F75MA2Reading1',
                    value: isEnabledcondition(formData.F75MA2Reading1, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F78Deviation2Eu1',
                    value: isEnabledcondition(AsFoundTestTableF78Deviation2Eu1Formula(formData), disableField),
                    isDisabled: disableField,
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F64Hmi2Inst2',
                    value: isEnabledcondition(AsLeftTableF64Hmi2Inst2Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F67TestModule2Reading2',
                    value: isEnabledcondition(formData.F67TestModule2Reading2, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F70Tx2Reading2',
                    value: isEnabledcondition(formData.F70Tx2Reading2, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F73Hmi2Reading2',
                    value: isEnabledcondition(formData.F73Hmi2Reading2, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F76MA2Reading2',
                    value: isEnabledcondition(formData.F76MA2Reading2, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F79Deviation2Eu2',
                    value: isEnabledcondition(AsFoundTestTableF79Deviation2Eu2Formula(formData), disableField),
                    isDisabled: disableField,
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F65Hmi2Inst3',
                    value: isEnabledcondition(AsLeftTableF65Hmi2Inst3Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F68TestModule2Reading3',
                    value: isEnabledcondition(formData.F68TestModule2Reading3, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F71Tx2Reading3',
                    value: isEnabledcondition(formData.F71Tx2Reading3, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F74Hmi2Reading3',
                    value: isEnabledcondition(formData.F74Hmi2Reading3, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F77MA2Reading3',
                    value: isEnabledcondition(formData.F77MA2Reading3, disableField),
                    isRequired: !(disableField),
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F80Deviation2Eu3',
                    value: isEnabledcondition(AsFoundTestTableF80Deviation2Eu3Formula(formData), disableField),
                    isDisabled: disableField,
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px',
                }]
            },
            {
                key: 6,
                fields: [{
                    key: 'Nodata',
                    xs: 4,
                    isLabel: true,
                    padding: '5px 25px 18.5px 0'
                },
                {
                    key: 'F81AsLeftResultLabel',
                    value: 'As-Left Result',
                    readOnly: true,
                    xs: 2,
                    isLabelBold: true,
                    textAlign: 'end',
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderRight: '0',
                    borderTop: '0',
                    borderBottom: '0',
                    borderLeft: '0'
                },
                {
                    key: 'F81AsLeftResult',
                    value: TestResultF81AsLeftResultFormula(formData),
                    readOnly: true,
                    isDisabled: disableField,
                    isCalculatedNew: true,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    inputMarginRight: '-2px',
                    borderBottom: '0',
                    borderTop: disableField ? '' : '0',
                    marginBottom: '4px',
                    textAlign: 'center',
                }]
            }]
    });
};
