export const dpsFieldData = [
    {
        field: 'DpsfieldId',
        headerName: 'DPS Field ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'DpsfieldColumnName',
        headerName: 'DPS Field Column Name',
        width: '220',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'DpsfieldDisplayName',
        headerName: 'DPS Field Display Name',
        width: '220',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'DpsfieldDatatype',
        headerName: 'DPS Field Datatype',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'DpsfieldMaxlength',
        headerName: 'DPS Field Max Length',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comment',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const dpsFieldInitialState = {
    DpsfieldId: '',
    DpsfieldColumnName: '',
    DpsfieldDisplayName: '',
    DpsfieldDatatype: '',
    DpsfieldMaxlength: '',
    Status: 'Inactive',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comment: '',
};

export const searchDataInitialState = {
    DpsfieldId: '',
    DpsfieldColumnName: '',
    DpsfieldDisplayName: '',
    DpsfieldDatatype: '',
    DpsfieldMaxlength: '',
    Status: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comment: '',
};

export const dpsFieldApiEndpoint = {
    read: 'ADMIN_GetDpsFields'
};
