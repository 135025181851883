import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: '#498205',
        padding: '3px',
        color: 'white',
        maxHeight: '25px',
        maxWidth: '30px',
        fontSize: '11px',
        '&:hover': {
            backgroundColor: '#497205'
        }
    },
    header: {
        backgroundColor: '#498205',
        color: 'white',
        paddingTop: '8px',
        paddingBottom: '8px',
        marginBottom: '8px'
    },
    headerRoot: {
        fontSize: '12px'
    }
}));
