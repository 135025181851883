import React, { useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { MatchNumber, leadingZero } from 'helpers/Validation';
import { HandleTextValue as handleTextValue } from 'helpers/HandleTextValue';
import { InputWrapper, StyledInput, StyledLabel } from './Inputs.styled';
import { connect } from 'react-redux';

const FormInput = (props) => {
    const { id, label, type, inputMaxWidth, name, value, handleChange,
        isCalculated, isCalculatedNew, isDisabled, isRequired, readOnly,
        marginLeft, width, minWidth, labelWidth, marginRight, marginBottom,
        marginTop, direction, padding, textAlign, maxLength, borderTop, height,
        borderBottom, borderRight, borderLeft, inputMarginRight, isLabelNew,
        isLabelBold, textAlignLabel, userRole, fontWeight, hasHmiError, hasAftTestGasConcentrationError,
        hasAltTestGasConcentrationError, testGasConcentrationMessage,
        hasInstError, hasDPSError, hasDPSNotNullError, color, fontSize,
        inputMarginLeft, inputFontSize, fontColor, isDeleteLabel, trfRowId } = props;
    const [inputValue, handleValue] = useState(value || '');
    const isFirstRun = useRef(true);
    let dataTipId = '';
    if (hasHmiError) {
        dataTipId = 'hmiRegisterTip';
    } else if (hasInstError) {
        dataTipId = 'instRegisterTip';
    } else if (hasAltTestGasConcentrationError) {
        dataTipId = 'altRegisterTip';
    } else if (hasAftTestGasConcentrationError) {
        dataTipId = 'aftRegisterTip';
    }

    useEffect(() => {
        let timeOutId;
        if (isFirstRun.current) {
            isFirstRun.current = false;
        } else {
            timeOutId = setTimeout(() => handleChange({ [name]: inputValue }), 500);
        }
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);
    useEffect(() => {
        handleValue(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <InputWrapper
            marginBottom={marginBottom}
            marginTop={marginTop}
        >
            <StyledLabel
                textAlignLabel={textAlignLabel}
                marginLeft={marginLeft}
                labelWidth={labelWidth}
                direction={direction}
                marginRight={marginRight}
                padding={padding}
                fontWeight={fontWeight}
                color={color}
                fontSize={fontSize}
            >
                {label}
            </StyledLabel>
            <>
                <StyledInput
                    id={id}
                    type={type === 'number' ? 'tel' : 'text'}
                    onWheel={type === 'number' ? (e) => e.target.blur() : null}
                    onPaste={type === 'number' ? (e) => (e.clipboardData.getData('Text').includes('e')
                        || e.clipboardData.getData('Text').includes('E') || !MatchNumber(e.clipboardData.getData('Text')))
                        && e.preventDefault() : null}
                    onBlur={type === 'number' ? (e) => {
                        if (!e.target.value.match(/\d/)) {
                            const val = '';
                            handleValue(val);
                        } else if (e.target.value.match(/^[-+]?\d+[.]$/)) {
                            handleValue(leadingZero(e.target.value.replace(/\.$/, '')));
                        } else {
                            handleValue(leadingZero(e.target.value));
                        }
                        return e.target.value;
                    } : null}
                    value={typeof inputValue === 'number' ? inputValue ?? '' : inputValue?.replace(/^\s+/, '') ?? ''}
                    onChange={(e) => handleTextValue(e, type, handleValue, maxLength)}
                    isRequired={isRequired}
                    isCalculated={isCalculated}
                    isCalculatedNew={isCalculatedNew}
                    hasDPSError={hasDPSError}
                    hasDPSNotNullError={hasDPSNotNullError}
                    hasHmiError={hasHmiError}
                    hasInstError={hasInstError}
                    hasAftTestGasConcentrationError={hasAftTestGasConcentrationError}
                    hasAltTestGasConcentrationError={hasAltTestGasConcentrationError}
                    isLabelNew={isLabelNew}
                    isLabelBold={isLabelBold}
                    isDisabled={isDisabled}
                    readOnly={readOnly}
                    inputMaxWidth={inputMaxWidth}
                    width={width}
                    minWidth={minWidth}
                    autoComplete="off"
                    textAlign={textAlign}
                    borderTop={borderTop}
                    borderBottom={borderBottom}
                    borderRight={borderRight}
                    height={height}
                    borderLeft={borderLeft}
                    inputMarginRight={inputMarginRight}
                    userRole={userRole}
                    data-tip={hasInstError || hasHmiError
                        || hasAftTestGasConcentrationError || hasAltTestGasConcentrationError}
                    data-for={dataTipId}
                    inputMarginLeft={inputMarginLeft}
                    inputFontSize={inputFontSize}
                    fontColor={fontColor}
                    isDeleteLabel={isDeleteLabel}
                    trfRowId={trfRowId}
                />
                {hasAftTestGasConcentrationError
                    && (
                        <ReactTooltip disable={hasAltTestGasConcentrationError} id="aftRegisterTip" place="top" effect="solid">
                            {testGasConcentrationMessage}
                        </ReactTooltip>
                    )}
                {hasAltTestGasConcentrationError
                    && (
                        <ReactTooltip disable={hasAftTestGasConcentrationError} id="altRegisterTip" place="top" effect="solid">
                            {testGasConcentrationMessage}
                        </ReactTooltip>
                    )}
                {hasHmiError
                    && (
                        <ReactTooltip disable={hasInstError} id="hmiRegisterTip" place="top" effect="solid">
                            LRV must be less than URV
                        </ReactTooltip>
                    )}
                {hasInstError
                    && (
                        <ReactTooltip disable={hasHmiError} id="instRegisterTip" place="top" effect="solid">
                            LRV must be less than URV
                        </ReactTooltip>
                    )}

            </>
        </InputWrapper>
    );
};

const mapStateToProps = ({ Forms }) => ({
    trfRowId: Forms.selectedTag.TrfRowId
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(FormInput);

FormInput.propTypes = {
    borderBottom: PropTypes.string,
    borderLeft: PropTypes.string,
    borderRight: PropTypes.string,
    borderTop: PropTypes.string,
    color: PropTypes.string,
    direction: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    hasAftTestGasConcentrationError: PropTypes.bool,
    hasAltTestGasConcentrationError: PropTypes.bool,
    hasDPSError: PropTypes.bool,
    hasDPSNotNullError: PropTypes.bool,
    hasHmiError: PropTypes.bool,
    hasInstError: PropTypes.bool,
    height: PropTypes.string,
    id: PropTypes.string,
    inputFontSize: PropTypes.string,
    inputMarginLeft: PropTypes.string,
    inputMarginRight: PropTypes.string,
    inputMaxWidth: PropTypes.string,
    isCalculated: PropTypes.bool,
    isCalculatedNew: PropTypes.bool,
    isDeleteLabel: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLabelBold: PropTypes.bool,
    isLabelNew: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    labelWidth: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    marginTop: PropTypes.string,
    maxLength: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    minWidth: PropTypes.string,
    name: PropTypes.string.isRequired,
    padding: PropTypes.string,
    readOnly: PropTypes.any,
    testGasConcentrationMessage: PropTypes.string,
    textAlign: PropTypes.string,
    textAlignLabel: PropTypes.string,
    trfRowId: PropTypes.number,
    type: PropTypes.string,
    userRole: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.string
};
