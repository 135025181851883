import { FieldHasValue } from 'helpers/Validation';
import { AutomaticActivationFireSuppression } from 'journeys/portal/Forms/Sections.constants';

export const AsFoundResultFormulaM71 = (formData) => {
    if (formData.F664AftTst1AutomaticActivationFireSuppression === AutomaticActivationFireSuppression.DISCARD
        && FieldHasValue(formData.F677AftTst2Result)) {
        if (formData.F677AftTst2Result === 'PASS') {
            return 'PASS';
        }
        return 'FAIL';
    }

    if (formData.F665AftTst2ManualActivationFireSuppression === AutomaticActivationFireSuppression.DISCARD
        && FieldHasValue(formData.F676AftTst1Result)) {
        if (formData.F676AftTst1Result === 'PASS') {
            return 'PASS';
        }
        return 'FAIL';
    }

    if (FieldHasValue(formData.F676AftTst1Result) && FieldHasValue(formData.F677AftTst2Result)) {
        if (formData.F676AftTst1Result === 'PASS' && formData.F677AftTst2Result === 'PASS') {
            return 'PASS';
        }
        return 'FAIL';
    }

    return '';
};
