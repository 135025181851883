import { AsFoundTestTableM613Formula } from './M613.formulas';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const AsFoundTestTableSectionDataM613 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'ASFOUNDTEST',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                width: '28%',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F364DamperFullStrokePositionIndicationAftLabel',
                    label: 'Damper Full Stroke Position Indication',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F364DamperFullStrokePositionIndicationAft',
                    value: formData.F364DamperFullStrokePositionIndicationAft,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'SELECT DAMPER POSITION',
                    options: [{ name: 'SELECT DAMPER POSITION', value: '' },
                    { name: 'OPEN', value: 'OPEN' },
                    { name: 'CLOSE', value: 'CLOSE' },
                    { name: 'INTERMEDIATE', value: 'INTERMEDIATE' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: formData.F364DamperFullStrokePositionIndicationAft === 'OPEN'
                        || formData.F364DamperFullStrokePositionIndicationAft === 'INTERMEDIATE' ? '' : '0',
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                },
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F365DamperFullStrokeTravelTimeAftLabel',
                    label: 'Damper Full Stroke Travel Time (sec)',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F365DamperFullStrokeTravelTimeAft',
                    value: formData.F364DamperFullStrokePositionIndicationAft === 'OPEN'
                        || formData.F364DamperFullStrokePositionIndicationAft === 'INTERMEDIATE' ? '' : formData.F365DamperFullStrokeTravelTimeAft,
                    isDisabled: (formData.F364DamperFullStrokePositionIndicationAft === 'OPEN'
                        || formData.F364DamperFullStrokePositionIndicationAft === 'INTERMEDIATE'),
                    readOnly: (formData.F364DamperFullStrokePositionIndicationAft === 'OPEN'
                        || formData.F364DamperFullStrokePositionIndicationAft === 'INTERMEDIATE'),
                    xs: 2,
                    isRequired: !(formData.F364DamperFullStrokePositionIndicationAft === 'OPEN'
                        || formData.F364DamperFullStrokePositionIndicationAft === 'INTERMEDIATE'),
                    width: '100%',
                    labelWidth: '0',
                    type: 'number',
                    maxLength: '10',
                    borderBottom: '0',
                    textAlign: 'center',
                    marginBottom: '4px',
                },
                {
                    key: 'F61AsFoundResultLabel',
                    label: 'As-Found Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F61AsFoundResult',
                    value: AsFoundTestTableM613Formula(formData),
                    isCalculatedNew: true,
                    xs: 2,
                    readOnly: true,
                    fontWeight: '700',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    marginBottom: '4px',
                    width: '100%',
                    labelWidth: '0',
                    direction: 'rtl',
                    textAlign: 'center',
                },
            ]
        }
    ]
});
