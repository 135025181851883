import { checkURVGreaterThanLRV } from 'helpers/Validation';
import { HMISectionCulatedRangeFormula } from '../P78/P78.formulas';
import { EngineeringUnit } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const HMISectionDataP891 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F572ProcessTemperatureReadingPriorToTestLabel',
                label: 'Process Temperature Reading Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel,
            },
            {
                key: 'F572ProcessTemperatureReadingPriorToTest',
                value: formData.F572ProcessTemperatureReadingPriorToTest,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0%',
                xs: 2,
                height: '1.2rem',
                borderBottom: '0',
                textAlign: 'center',
            },
            {
                key: 'Nodata',
                isLabel: true,
                xs: 6,
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F116InstrumentCalibratedLrvLabel',
                    label: 'Instrument LRV',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F116InstrumentCalibratedLrv',
                    value: selectedTag.DateTested ? formData?.F116InstrumentCalibratedLrv : formData?.F116InstrumentCalibratedLrv ?? selectedTag?.TsprInstrumentCalibratedLrv,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    height: '1.2rem',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasInstError: !checkURVGreaterThanLRV(formData.F116InstrumentCalibratedLrv, formData.F117InstrumentCalibratedUrv)
                },
                {
                    key: 'F117InstrumentCalibratedUrvLabel',
                    label: 'Instrument URV',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel
                },
                {
                    key: 'F117InstrumentCalibratedUrv',
                    value: selectedTag.DateTested ? formData?.F117InstrumentCalibratedUrv : formData?.F117InstrumentCalibratedUrv ?? selectedTag?.InstrumentCalibratedUrv,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderBottom: '0',
                    hasInstError: !checkURVGreaterThanLRV(formData.F116InstrumentCalibratedLrv, formData.F117InstrumentCalibratedUrv)
                }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F27TemperatureMeasurementRangeLabel',
                    label: 'Temperature Measurement Range',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F27TemperatureMeasurementRange',
                    value: HMISectionCulatedRangeFormula(formData.F116InstrumentCalibratedLrv, formData.F117InstrumentCalibratedUrv, formData.F118InstrumentEngineeringUnits),
                    xs: 2,
                    inputWidth: '100%',
                    labelWidth: '0%',
                    readOnly: true,
                    isCalculatedNew: true,
                    borderTop: '0',
                    textAlign: 'center',
                    marginBottom: '15px',
                    isAutoGrow: true,
                    marginRight: '0',
                },
                {
                    key: 'F118InstrumentEngineeringUnitsLabel',
                    label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F118InstrumentEngineeringUnits',
                    value: selectedTag.DateTested
                        ? formData?.F118InstrumentEngineeringUnits
                        : formData?.F118InstrumentEngineeringUnits ?? selectedTag?.TsprInstrumentEngineeringUnits,
                    xs: 2,
                    isRequired: true,
                    inputWidth: '100%',
                    labelWidth: '0%',
                    textAlignLabel: 'end',
                    marginRight: '0',
                    isAutoGrow: true,
                    marginTop: '0',
                    borderTop: '0',
                    textAlign: 'center',
                    marginBottom: '15px',
                    maxLength: '17',
                    direction: 'rtl',
                }]
        }
    ]
});
