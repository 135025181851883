export const TestResultSectionDataP732 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F597RelayCoilStatusAfterTest',
            label: 'Relay Coil Status After Test',
            value: formData.F597RelayCoilStatusAfterTest,
            isDropDown: true,
            placeholder: 'Select Relay Coil Status',
            options: [{ name: 'Select Relay Coil Status', value: '' },
            { name: 'Energized', value: 'Energized' },
            { name: 'De-energized', value: 'De-energized' }],
            direction: 'rtl',
            xs: 6,
            width: '49%',
            labelWidth: '51.9%',
            inputTextAlign: 'center',
        },
        {
            key: 'F589RelayContactPositionAfterTestLabel',
            label: 'Relay Contact Position After Test',
            xs: 3,
            labelWidth: '97%',
            padding: '5px 0 0 0',
            fontWeight: '600',
            fontSize: '9pt',
            textAlign: 'end',
            isLabel: 'true'
        },
        {
            key: 'F598RelayContactPositionAfterTest',
            value: formData.F598RelayContactPositionAfterTest,
            isDropDown: true,
            placeholder: 'Select Open or Closed',
            options: [{ name: 'Select Open or Closed', value: '' },
            { name: 'Open', value: 'Open' },
            { name: 'Closed', value: 'Closed' }],
            xs: 3,
            width: '100%',
            labelWidth: '0',
            direction: 'rtl',
            borderBottom: '0',
        }]
    }]
});
