import { FieldHasValue } from 'helpers/Validation';
import { FunctionTestType } from 'journeys/portal/Forms/Sections.constants';

export const Test1Result = (formData) => {
    if (formData.F663FunctionTestType === FunctionTestType.DRY_TEST
        && FieldHasValue(formData.F698AltTst1ActivatedFireSuppressionSystem)
        && FieldHasValue(formData.F700AltTst1ConfirmDischargeValveOperatesCorrectly)) {
        if (formData.F698AltTst1ActivatedFireSuppressionSystem === 'YES'
            && formData.F700AltTst1ConfirmDischargeValveOperatesCorrectly === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }

    if (FieldHasValue(formData.F698AltTst1ActivatedFireSuppressionSystem)
        && FieldHasValue(formData.F700AltTst1ConfirmDischargeValveOperatesCorrectly)
        && FieldHasValue(formData.F702AltTst1ConfirmExtinguishantReleasedProtectedArea)) {
        if (formData.F698AltTst1ActivatedFireSuppressionSystem === 'YES'
            && formData.F700AltTst1ConfirmDischargeValveOperatesCorrectly === 'YES'
            && formData.F702AltTst1ConfirmExtinguishantReleasedProtectedArea === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const Test2Result = (formData) => {
    if (formData.F663FunctionTestType === FunctionTestType.DRY_TEST
        && FieldHasValue(formData.F699AltTst2ActivatedFireSuppressionSystem)
        && FieldHasValue(formData.F701AltTst2ConfirmDischargeValveOperatesCorrectly)) {
        if (formData.F699AltTst2ActivatedFireSuppressionSystem === 'YES'
            && formData.F701AltTst2ConfirmDischargeValveOperatesCorrectly === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }

    if (FieldHasValue(formData.F699AltTst2ActivatedFireSuppressionSystem)
        && FieldHasValue(formData.F701AltTst2ConfirmDischargeValveOperatesCorrectly)
        && FieldHasValue(formData.F703AltTst2ConfirmExtinguishantReleasedProtectedArea)) {
        if (formData.F699AltTst2ActivatedFireSuppressionSystem === 'YES'
            && formData.F701AltTst2ConfirmDischargeValveOperatesCorrectly === 'YES'
            && formData.F703AltTst2ConfirmExtinguishantReleasedProtectedArea === 'YES') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
