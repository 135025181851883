import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const FoundResultF317CalibrationResultFormula = (formData) => {
  if (
    FieldHasValue(formData.F60ToleranceEu)
    && FieldHasValue(formData.F42GaugeReading1)
    && FieldHasValue(formData.F43GaugeReading2)
    && FieldHasValue(formData.F44GaugeReading3)
    && FieldHasValue(formData.F45TxReading1)
    && FieldHasValue(formData.F46TxReading2)
    && FieldHasValue(formData.F47TxReading3)
    && FieldHasValue(formData.F48HmiReading1)
    && FieldHasValue(formData.F49HmiReading2)
    && FieldHasValue(formData.F50HmiReading3)) {
    if (Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1), ValidateNumberField(formData.F55DeviationEu2),
    ValidateNumberField(formData.F56DeviationEu3)]) <= ValidateNumberField(formData.F60ToleranceEu)) {
      return 'PASS';
    }
    return 'FAIL';
  }
  return '';
};
