import { AsFoundResultM31Formula } from './M31.formula';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const AsFoundTestTableSectionDataM31 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F405AftMacWasActivatedInFieldLabel',
                    label: 'MAC was activated in field?',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F405AftMacWasActivatedInField',
                    value: formData.F405AftMacWasActivatedInField,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    labelWidth: '0',
                    width: '100%',
                    marginRight: '0',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    height: '1.5rem',
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F372AftHmistatus1Label',
                    label: 'HMI Status',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F372AftHmistatus1',
                    value: formData.F372AftHmistatus1,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select HMI Status',
                    options: [{ name: 'Select HMI Status', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    marginBottom: '4px',
                    height: '1.5rem',
                },
                {
                    key: 'F102AftResultLabel',
                    label: 'As-Found Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F102AftResult',
                    value: AsFoundResultM31Formula(formData),
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    height: '1.25rem',
                    borderRight: '0',
                    borderBottom: '0',
                    direction: 'rtl',
                    inputMarginRight: '4px',
                    marginBottom: '4px',
                    isLabelBold: true,
                    fontWeight: '700'
                }
            ]
        },
    ]
});
