import React from 'react';
import PropTypes from 'prop-types';

const NetworkIcon = (props) => (
  <svg id="networkIcon" xmlns="http://www.w3.org/2000/svg" width="35.375" height="32.716" viewBox="0 0 16.375 16.716">
    <g id="Group_161910" data-name="Group 161910" transform="translate(-1081 -305.284)">
      <line id="Line_17" data-name="Line 17" y2="15" transform="translate(1093.5 306.5)" fill="none" stroke={props.style.stroke} strokeLinecap="round" strokeWidth="1" />
      <line id="Line_18" data-name="Line 18" y2="10" transform="translate(1089.5 311.5)" fill="none" stroke={props.style.stroke} strokeLinecap="round" strokeWidth="1" />
      <line id="Line_19" data-name="Line 19" y2="6" transform="translate(1085.5 315.5)" fill="none" stroke={props.style.stroke} strokeLinecap="round" strokeWidth="1" />
      <line id="Line_20" data-name="Line 20" y2="1" transform="translate(1081.5 320.5)" fill="none" stroke={props.style.stroke} strokeLinecap="round" strokeWidth="1" />
      <path
        id="select"
        d="M3886.877,527.709l3.229-3.216,3.107,3.216"
        transform="translate(-2796.546 -218.709)"
        fill="none"
        stroke={props.style.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default NetworkIcon;

NetworkIcon.propTypes = {
  style: PropTypes.object
};
