import styled from 'styled-components';
import { bgColorWithData } from '../Inputs.styled';

export const InputWrapper = styled.div`
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    margin-bottom:  ${props => props.marginBottom};
    margin-top:  ${props => props.marginTop};
    display: flex;
    .label {
        font-weight: 600;
        font-size: 9pt;
        font-family: "Open Sans", sans-serif;
    }
`;

export const StyledMultilineLabel = styled.div`
    padding: ${props => props.padding ? props.padding : '0'};
    margin-Top: 3px;
    font-weight: 600;
    font-size: 9pt;
    font-family: "Segoe UI";
    width: ${props => props.width ? props.width : '120px'};
    margin-left: ${props => props.marginLeft ? props.marginLeft : '0'};
    margin-right: ${props => props.marginRight ? props.marginRight : '16px'};
    direction: ${props => props.direction ? props.direction : ''};
`;

export const TextInputWrapper = styled.div`
width: ${props => props.width ? props.width : '57%'};
min-height: 17px;
text-align: ${props => props.textAlign ? props.textAlign : 'left'};

.MuiOutlinedInput-notchedOutline {
    border-color: rgb(120, 120, 120) !important;
    border-width: ${props => props.readOnly ? '0' : '2px'};
}


.MuiInputBase-input.Mui-disabled{
    color: rgb(0, 0, 0);
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
}
.MuiOutlinedInput-multiline{
    padding: 4px;
    font-size: 9pt;
    border-radius: 0;
    font-family: Segoe UI;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    font-weight: ${props => props.isCalculatedNew ? 'bold;' : 'normal;'};
    font-style: normal;
}

.MuiOutlinedInput-root {
    border-radius:0;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
}

.MuiInputBase-input {
    min-height: 17px;
    cursor: text;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
background-color: ${props => {
        if (props.isCalculatedNew) {
            return 'rgb(170, 170, 170);';
        }
        if (props.isRequired && (props.value || props.value === 0)) {
            return bgColorWithData;
        }
        if (props.isRequired) {
            return 'rgb(255, 255, 0);';
        }
        return 'rgb(255, 255, 255);';
    }}
`;
