import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormInput } from 'elements/Inputs/FormInput/';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { StyledLabel } from 'elements/Label/Label';
import { FormInputViewOnly } from 'elements/Inputs/FormInput/FormInputViewOnly';
import { selectTestResultSectionData } from './TestResultSection.data';
import { StyleTestDropdown } from './TestResultSection.styled';

const useStyles = formName => makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

const TestResultSection = (props) => {
    const { selectedTag, changeHandler, formData, formName, isFormReadOnly, userRole, selectedTrfId } = props;
    const classes = useStyles(formName)();
    const formInputView = (field) => !field.isInfo ? (
        <FormInput
            id={field.key}
            name={field.key}
            label={field.label}
            value={field.value}
            isRequired={field.isRequired}
            isCalculated={field.isCalculated}
            isCalculatedNew={field.isCalculatedNew}
            handleChange={changeHandler}
            readOnly={field.readOnly || isFormReadOnly}
            labelWidth={field.labelWidth}
            width={field.width}
            marginLeft={field.marginLeft}
            marginBottom={field.marginBottom}
            direction={field.direction}
            padding={field.padding}
            textAlign={field.textAlign}
            type={field.type}
            isDisabled={field.isDisabled}
            maxLength={field.maxLength}
            marginRight={field.marginRight}
            marginTop={field.marginTop}
            borderBottom={field.borderBottom}
            borderTop={field.borderTop}
            height={field.height}
            userRole={userRole}
            textAlignLabel={field.textAlignLabel}
            hasDPSError={field.hasDPSError}
            borderLeft={field.borderLeft}
            borderRight={field.borderRight}
            fontStyle={field.fontStyle}
        />
    ) : (
        <FormInputViewOnly
            id={field.key}
            name={field.key}
            label={field.label}
            value={field.value}
            fontStyle={field.fontStyle}
            labelFontStyle={field.labelFontStyle}
            width={field.width}
            height={field.height}
            wordBreak={field.wordBreak}
            labelWidth={field.labelWidth}
            noWrap={field.noWrap}
            field={field.padding}
            padding={field.padding}
            marginBottom={field.marginBottom}
            marginTop={field.marginTop}
            valueMarginTop={field.valueMarginTop}
            textAlign={field.textAlign}
            labelTextAlign={field.labelTextAlign}
        />
    );
    const styleFormInputView = (field) => field.isLabel ? (
        <StyledLabel
            id={field.key}
            fontWeight={field.fontWeight}
            textAlign={field.textAlign}
            labelWidth={field.labelWidth}
            padding={field.padding}
            fontSize={field.fontSize}
            fontStyle={field.fontStyle}
            color={field.color}
        >
            {field.label}
        </StyledLabel>
    )
        : formInputView(field);
    return (
        <Grid container spacing={1} className={classes.root} style={{ marginTop: '20px' }}>
            {
                selectTestResultSectionData(selectedTag, formName, formData, selectedTrfId).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingBottom: '0', paddingTop: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key} style={{ textAlign: 'end' }}>
                                {field.isDropDown
                                    ? (
                                        <StyleTestDropdown
                                            formName={formName}
                                        >
                                            <FormDropdown
                                                id={field.key}
                                                name={field.key}
                                                label={field.label}
                                                value={field.value}
                                                placeholder={field.placeholder}
                                                handleChange={changeHandler}
                                                options={field.options}
                                                isRequired={field.isRequired}
                                                labelWidth={field.labelWidth}
                                                width={field.width}
                                                marginLeft={field.marginLeft}
                                                marginRight={field.marginRight}
                                                paddingTop={field.paddingTop}
                                                className="MenuOpensOnTop"
                                                readOnly={field.readOnly || isFormReadOnly}
                                                textAlign={field.textAlign}
                                                direction={field.direction}
                                                borderTop={field.borderTop}
                                                borderBottom={field.borderBottom}
                                                borderRight={field.borderRight}
                                                fontWeight={field.fontWeight}
                                                fontSize={field.fontSize}
                                                inputTextAlign={field.inputTextAlign}
                                                marginTop={field.marginTop}
                                                height={field.height}
                                                arrowMarginTop={field.arrowMarginTop}
                                                paddingPlaceholder={field.paddingPlaceholder}
                                            />
                                        </StyleTestDropdown>
                                    )
                                    : styleFormInputView(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ TagSelectionMOScreen, Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole,
    selectedTrfId: Forms.selectedTrfId
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestResultSection);

TestResultSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    selectedTrfId: PropTypes.string,
    userRole: PropTypes.string,
};
