import { ValidateField } from 'helpers/Validation';
import { AsLeftTestTableF237AltDeviation1Formula, F103AltResultFormula } from './P8111.formula';
import { paddingAF } from '../M71/M71.data';

export const AsLeftTestTableSectionDataP8111 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsLeftTest',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'ReferenceTestPoint',
                label: 'Reference Test Point (EU)',
                isLabel: true,
                xs: 2,
                fontWeight: '700',
                labelWidth: '100%',
                textAlign: 'center',
                fontSize: '9pt'
            },
            {
                key: 'Analyzer Reading',
                label: 'Analyzer Reading (EU)',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'HMI Reading',
                label: 'HMI Reading (HMI EU)',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'mAReading',
                label: 'mA Reading',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'PressureValue',
                label: 'Pressure Value',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Deviation',
                label: 'Deviation (EU)',
                isLabel: true,
                xs: 2,
                textAlign: 'center',
                fontWeight: '700',
                labelWidth: '100%',
                padding: paddingAF,
                fontSize: '9pt'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F654AltReferenceTestPoint',
                value: (formData.F102AftResult !== 'PASS') ? ValidateField(formData.F654AltReferenceTestPoint) : '',
                isRequired: (formData.F102AftResult !== 'PASS'),
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                textAlign: 'center',
                borderRight: '0',
                borderLeft: '0',
                marginLeft: '4px'
            },
            {
                key: 'F357AnalyzerReading',
                xs: 2,
                value: (formData.F102AftResult !== 'PASS') ? ValidateField(formData.F357AnalyzerReading) : '',
                isRequired: (formData.F102AftResult !== 'PASS'),
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F109AltHmireading1',
                value: (formData.F102AftResult !== 'PASS') ? ValidateField(formData.F109AltHmireading1) : '',
                isRequired: (formData.F102AftResult !== 'PASS'),
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F52MAReading2',
                value: (formData.F102AftResult !== 'PASS') ? ValidateField(formData.F52MAReading2) : '',
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                type: 'number',
                maxLength: '10',
            },
            {
                key: 'F656AltPressureValue',
                value: (formData.F102AftResult !== 'PASS') ? ValidateField(formData.F656AltPressureValue) : '',
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                xs: 2,
                maxLength: '30',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0'
            },
            {
                key: 'F237AltDeviation1',
                value: (formData.F102AftResult !== 'PASS')
                    ? AsLeftTestTableF237AltDeviation1Formula(formData) : '',
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: true,
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                inputMarginRight: '4px'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'NoData2',
                xs: 8,
                isLabel: true
            },
            {
                key: 'F81AsLeftResultLabel',
                value: 'As-Left Result',
                readOnly: true,
                xs: 2,
                isLabelBold: true,
                textAlign: 'end',
                labelWidth: '0',
                width: '97%',
                height: '1.1rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                borderTop: '0',
                marginBottom: '4px'
            },
            {
                key: 'F103AltResult',
                value: formData.F102AftResult === 'PASS' ? '' : F103AltResultFormula(formData),
                readOnly: true,
                isCalculatedNew: true,
                isDisabled: formData.F102AftResult === 'PASS',
                xs: 2,
                labelWidth: '0',
                width: '100%',
                height: '1.2rem',
                borderTop: formData.F102AftResult === 'PASS' ? '' : '0',
                textAlign: 'center',
                borderBottom: '0',
                marginBottom: '4px',
                borderRight: '0',
                inputMarginRight: '4px'
            }
            ]
        }]
});
