import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useState } from 'react';
import GroupMappingForm from './GroupMappingForm';
import {
    groupMappingApiEndpoint, groupMappingInitialState,
    groupMappingSearchState, groupMappingHeaderData
} from './GroupMapping.data';

const addTitle = 'Add New Record for Group Mapping';
const editTitle = 'Edit Record for Group Mapping';

const GroupMapping = () => {
    const apiEndpoints = groupMappingApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(groupMappingInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'RowId',
        rowNumber: ''
    });
    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(groupMappingInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={groupMappingHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                rowDetails={rowDetails}
                initialData={groupMappingSearchState}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <GroupMappingForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                    />
                )}
        </>
    );
};

export default GroupMapping;
