import { TestPointRange } from 'helpers/Validation';
import { getFlowAlarmDeleteIconStatus } from '../FlowAlarmTest.helpers';
import { paddingValue } from '../../AsFoundTestTableSection/Data/P720/P720.data';
import { Label, Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { dropdownPlaceholder } from '../../AFT_FireTestSection/Data/M71/M71.data';

const createField = ({ key, value, isDropdown, placeholder, options, borderBottom, borderRight, marginBottom }) => ({
    key,
    value,
    isDropdown,
    placeholder,
    options,
    inputTextAlign: 'center',
    width: '100%',
    labelWidth: '0',
    height: '1.5rem',
    borderBottom,
    borderRight,
    xs: 2,
    marginBottom
});

export const FlowAlarmTestChangeM610 = (formData, flowAlarmTestPoint) => {
    const rows = [];
    const remark = 'Remark: This is an optional test to validate that low and high air flow rates activates flow alarms.';
    rows.push({
        key: 1,
        fields: [{
            key: 'FlowAlarmTest',
            label: 'Flow Alarm Test',
            isLabel: true,
            xs: 5,
            fontWeight: '700',
            padding: '8px 0 0 8px',
            borderTop: '0',
        },
        {
            key: 'F816NumberOfTestPoints',
            label: 'Number of Test Points',
            value: formData?.F816NumberOfTestPoints,
            isBlur: true,
            type: 'number',
            maxLength: '10',
            xs: 3,
            labelWidth: '80.2%',
            width: '64%',
            height: '1.26rem',
            isRequired: true,
            marginRight: '0.5rem',
            textAlign: 'center',
            marginTop: '4px',
            borderTop: '0',
            marginBottom: '10px',
            direction: 'rtl',
            readOnly: getFlowAlarmDeleteIconStatus(),
            hasTestPointError: !TestPointRange(formData.F816NumberOfTestPoints, 8)
        }]
    },
        {
            key: 2,
            fields: [{
                key: 'remark',
                label: remark,
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '0px 1px 10px 9px',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'flowDelete',
                    value: getFlowAlarmDeleteIconStatus() ? 'Delete' : '',
                    textAlign: 'center',
                    fontColor: 'red',
                    isDeleteLabel: getFlowAlarmDeleteIconStatus(),
                    width: '100%',
                    labelWidth: '0',
                    xs: 2,
                    borderLeft: '0',
                    borderRight: '0',
                    borderBottom: '0',
                    borderTop: '0',
                    readOnly: true,
                    marginLeft: '4px'
                },
                {
                    key: 'FlowObstructionLabel',
                    label: 'Flow Obstruction',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    padding: paddingValue,
                    fontSize: '9pt',
                    width: '100%'
                },
                {
                    key: 'HMIStatusLowLabel',
                    label: Label.HMI_STATUS_LABEL,
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                    width: '100%'
                },
                {
                    key: 'FlowIncreaseLabel',
                    label: 'Flow Increase',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                    width: '100%'
                },
                {
                    key: 'HMIStatusHighLabel',
                    label: Label.HMI_STATUS_LABEL,
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    fontSize: '9pt',
                    padding: paddingValue,
                    width: '100%'
                }]
        });
    if (TestPointRange(flowAlarmTestPoint ?? formData?.F816NumberOfTestPoints, 8)) {
        for (let index = 2; index < Number(flowAlarmTestPoint ?? formData?.F816NumberOfTestPoints) + 2; index += 1) {
            const marginBottom = index === Number(flowAlarmTestPoint ?? formData?.F816NumberOfTestPoints) + 1 ? '4px' : '0';
            rows.push(
                {
                    key: 4 + index,
                    fields: [{
                        key: index,
                        xs: 2,
                        borderTop: '0',
                        borderBottom: '0',
                        borderLeft: '0',
                        borderRight: '0',
                        readOnly: true,
                        isDelete: getFlowAlarmDeleteIconStatus(),
                        width: '100%'
                    },
                    createField({
                        key: `FlowObstruction${index}`,
                        value: formData[`FlowObstruction${index}`],
                        isDropdown: true,
                        placeholder: dropdownPlaceholder,
                        options: [{ name: dropdownPlaceholder, value: '' },
                        { name: 'YES', value: 'YES' },
                        { name: 'NO', value: 'NO' }],
                        borderBottom: '0',
                        borderRight: '0',
                        marginBottom
                    }),
                    createField({
                        key: `HmiStatusLow${index}`,
                        value: formData[`HmiStatusLow${index}`],
                        isDropdown: true,
                        placeholder: Placeholder.HMI_STATUS_PLACEHOLDER,
                        options: [{ name: Placeholder.HMI_STATUS_PLACEHOLDER, value: '' },
                        { name: 'LOW FLOW ALARM', value: 'LOW FLOW ALARM' },
                        { name: 'FAULT', value: 'FAULT' }, { name: 'NORMAL', value: 'NORMAL' }],
                        borderBottom: '0',
                        borderRight: '0',
                        marginBottom
                    }),
                    createField({
                        key: `FlowIncrease${index}`,
                        value: formData[`FlowIncrease${index}`],
                        isDropdown: true,
                        placeholder: dropdownPlaceholder,
                        options: [{ name: dropdownPlaceholder, value: '' },
                        { name: 'YES', value: 'YES' }, { name: 'NO', value: 'NO' }],
                        borderBottom: '0',
                        borderRight: '0',
                        marginBottom
                    }),
                    createField({
                        key: `HmiStatusHigh${index}`,
                        value: formData[`HmiStatusHigh${index}`],
                        isDropdown: true,
                        placeholder: Placeholder.HMI_STATUS_PLACEHOLDER,
                        options: [{ name: Placeholder.HMI_STATUS_PLACEHOLDER, value: '' },
                        { name: 'HIGH FLOW ALARM', value: 'HIGH FLOW ALARM' },
                        { name: 'FAULT', value: 'FAULT' },
                        { name: 'NORMAL', value: 'NORMAL' }],
                        borderBottom: '0',
                        borderRight: '',
                        marginBottom })]
                });
        }
    }
    return { rows };
};
