import { FireSuppressionSectionDataM71 } from './Data/M71/M71.data';
import { IPLDataP6142 } from './Data/P6142/P6142';

export const selectFireSuppressionData = (selectedTag, formName, formData, key) => {
    let filterViewData = { rows: [] };
    if (formName === 'M7-1') {
        filterViewData = FireSuppressionSectionDataM71(selectedTag, formName, formData);
    } else if (['P6-14.2', 'P7-31', 'P6-23.2', 'P6-14'].includes(formName)) {
        filterViewData = IPLDataP6142(formName, formData);
    }
    return filterViewData;
};
