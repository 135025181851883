export const multiTrfDetailsHeaderData = [
    {
        field: 'RowId',
        headerName: 'Row ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'TrfmasterId',
        headerName: 'TRF ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'Trftag',
        headerName: 'TRF Type',
        width: '100',
        hideInTable: false,
        isDisabled: false,
        sortable: false
    },
    {
        field: 'Trftitle',
        headerName: 'TRF Title',
        width: '250',
        hideInTable: false,
        isDisabled: false,
        sortable: false
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '85',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '140',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'ModifiedBy',
        headerName: 'Last Modified By',
        width: '140',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '150',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'Comment',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    }
];

export const multiTrfDetailsInitialState = {
    RowId: '',
    TrfmasterId: '',
    Trftag: '',
    Trftitle: '',
    Status: 'Inactive',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comment: '',
    Trfmaster: null
};

export const multiTrfDetailsSearchState = {
    RowId: '',
    TrfmasterId: '',
    Trftag: '',
    Trftitle: '',
    Status: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: '',
    Comment: '',
    Trfmaster: null
};

export const multiTrfDetailsApiEndpoint = {
    read: 'ADMIN_GetMultiTRFDetails'
};
