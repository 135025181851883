import { FieldHasValue } from 'helpers/Validation';

export const AsFoundResultFormulaP615 = (formData) => {
    if (FieldHasValue(formData.F572SolenoidValvePositionPriorToTest)
        && FieldHasValue(formData.F573AftSolenoidValveActionUponTrip)
        && FieldHasValue(formData.F574AftSolenoidValvePositionAfterTrip)) {
        if (formData.F573AftSolenoidValveActionUponTrip === 'Quick'
            && formData.F574AftSolenoidValvePositionAfterTrip !== formData.F572SolenoidValvePositionPriorToTest) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
