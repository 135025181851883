import { ValveSizeSectionDataC12 } from './Data/C12/C12.data';
import { DamperSectionDataM613 } from './Data/M613/M613.data';
import { ValveSizeSectionDataP610 } from './Data/P610/P610.data';
import { ValveSizeSectionData716 } from './Data/P716/P716.data';
import { ValveSizeSectionDataP727 } from './Data/P727/P727.data';
import { ValveGreasedSectionDataS71 } from './Data/S71/S71.data';

export const selectValveSizeSection = (selectedTag, formName, formData) => {
    let filterViewData = { rows: [] };
    if (['P6-10', 'P7-6', 'P6-14.2', 'P7-31', 'P6-23.2', 'P6-14'].includes(formName)) {
        filterViewData = ValveSizeSectionDataP610(formName, formData);
    } else if (formName === 'M6-13') {
        filterViewData = DamperSectionDataM613(selectedTag, formData);
    } else if (formName === 'P7-16' || formName === 'P6-13') {
        filterViewData = ValveSizeSectionData716(selectedTag, formData);
    } else if (formName === 'C1-2') {
        filterViewData = ValveSizeSectionDataC12(selectedTag, formData);
    } else if (formName === 'P7-27') {
        filterViewData = ValveSizeSectionDataP727(selectedTag, formData);
    } else if (['S7-1'].includes(formName)) {
        filterViewData = ValveGreasedSectionDataS71(selectedTag, formData);
    }
    return filterViewData;
};
