import React from 'react';
import PropTypes from 'prop-types';
import { InputWrapper, ViewWrapper, StyledLabel } from './FormInputViewOnly.styled';

export const FormInputViewOnly = (props) => {
    const { label, value, labelWidth, id, valueSize, width, fontWeight, paddingLeft,
        valueMarginTop, valueHeight, wordBreak, marginTop, labelfontSize, labelfontFamily,
        labelFontStyle, labelPadding, marginLeft,
        padding, marginBottom, noWrap, fontStyle, textAlign, labelTextAlign } = props;

    return (
        <InputWrapper paddingLeft={paddingLeft} marginTop={marginTop}>
            <StyledLabel
                labelWidth={labelWidth}
                fontWeight={fontWeight}
                labelFontStyle={labelFontStyle}
                labelfontFamily={labelfontFamily}
                labelfontSize={labelfontSize}
                labelTextAlign={labelTextAlign}
                labelPadding={labelPadding}
            >
                {label}
            </StyledLabel>
            <ViewWrapper
                id={id}
                fontSize={valueSize}
                marginTop={valueMarginTop}
                marginLeft={marginLeft}
                width={width}
                height={valueHeight}
                padding={padding}
                wordBreak={wordBreak}
                marginBottom={marginBottom}
                noWrap={noWrap}
                fontStyle={fontStyle}
                textAlign={textAlign}
            >
                {value}
            </ViewWrapper>
        </InputWrapper>
    );
};

FormInputViewOnly.propTypes = {
    fontStyle: PropTypes.string,
    fontWeight: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelfontFamily: PropTypes.string,
    labelfontSize: PropTypes.string,
    labelFontStyle: PropTypes.string,
    labelPadding: PropTypes.string,
    labelTextAlign: PropTypes.string,
    labelWidth: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginTop: PropTypes.string,
    noWrap: PropTypes.bool,
    padding: PropTypes.string,
    paddingLeft: PropTypes.bool,
    textAlign: PropTypes.string,
    value: PropTypes.any,
    valueHeight: PropTypes.string,
    valueMarginTop: PropTypes.string,
    valueSize: PropTypes.string,
    width: PropTypes.string,
    wordBreak: PropTypes.bool,
};
