import axios from 'axios';
import { getReferenceDate } from 'helpers/DateFormatter';
import { APIEndpoints } from 'helpers/APILists';
import { MOACTIONS } from './MOSelection.constants';
import { sortFunction } from '../SubSections/DPS/DPS.helpers';
import { MESSAGE_TYPE } from '../../common/common.constants';
import { moSelectionInitialState } from './MOSelection.data';
import _ from 'lodash';

export function exampleAction(text) {
    return dispatch => {
        dispatch({
            type: 'AUTH_SUCCESS',
            payload: { success: true },
            meta: { message: text }
        });
    };
}

export const getAllMOData = () => async (dispatch, getState) => {
    const { Home: { selectedAssetName, selectedBarrierType, regionName }, AppData: { isMRATOnline } } = getState();
    if (isMRATOnline) {
        dispatch({
            type: MOACTIONS.GET_MORECORDS_PENDING,
            payload: {
                tableLoading: true,
                searchCriteria: { ...moSelectionInitialState.searchCriteria },
                moDataAll: []
            }
        });
        const filterData = { Region: regionName, Asset: selectedAssetName, BarrierType: selectedBarrierType };
        await axios.post(APIEndpoints.GetVmSearchMo, filterData)
            .then((res) => {
                const moData = res.data;
                const totalCount = res.data.length;
                sortFunction(moData, 'ScheduleFinishDate', true).then(sortedData => dispatch({
                    type: MOACTIONS.GET_MORECORDS_SUCCESS,
                    payload: {
                        tableLoading: false,
                        moDataAll: sortedData,
                        searchCriteria: { ...moSelectionInitialState.searchCriteria },
                        totalCount
                    }
                }));
            })
            .catch(error => {
                dispatch({
                    type: MOACTIONS.GET_MORECORDS_FAILURE,
                    payload: {
                        tableLoading: false,
                    },
                    meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
                });
            });
    }
};

export const getAllMODataForSearch = () => async (dispatch, getState) => {
    const { Home: { selectedAssetName, selectedBarrierType, regionName }, AppData: { isMRATOnline } } = getState();
    if (isMRATOnline) {
        dispatch({
            type: MOACTIONS.GET_MORECORDS_PENDING,
            payload: {
                tableLoading: true,
                moDataAll: []
            }
        });
        const filterData = { Region: regionName, Asset: selectedAssetName, BarrierType: selectedBarrierType };
        await axios.post(APIEndpoints.GetVmSearchMo, filterData)
            .then((res) => {
                const moData = res.data;
                const totalCount = res.data.length;
                sortFunction(moData, 'ScheduleFinishDate', true).then(sortedData => dispatch({
                    type: MOACTIONS.GET_MORECORDS_SUCCESS,
                    payload: {
                        tableLoading: false,
                        moDataAll: sortedData,
                        totalCount
                    }
                }));
            })
            .catch(error => {
                dispatch({
                    type: MOACTIONS.GET_MORECORDS_FAILURE,
                    payload: {
                        tableLoading: false,
                    },
                    meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
                });
            });
    }
};

export const setDatePicker = (type, customStartDate, customEndDate) => (dispatch) => {
    let startDate;
    let endDate;
    const currentDate = new Date();
    if (type === 'Today') {
        startDate = currentDate;
        endDate = currentDate;
    } else if (type === 'Tomorrow') {
        startDate = getReferenceDate(currentDate, 1);
        endDate = getReferenceDate(currentDate, 1);
    } else if (type === 'Today - 7 Days') {
        startDate = getReferenceDate(currentDate, -7);
        endDate = currentDate;
    } else if (type === 'Today + 7 Days') {
        startDate = currentDate;
        endDate = getReferenceDate(currentDate, 7);
    } else if (type === 'Today - 30 Days') {
        startDate = getReferenceDate(currentDate, -30);
        endDate = currentDate;
    } else if (type === 'Today + 30 Days') {
        startDate = currentDate;
        endDate = getReferenceDate(currentDate, 30);
    } else if (type === 'All') {
        startDate = getReferenceDate(currentDate, -3000);
        endDate = getReferenceDate(currentDate, 3500);
    } else if (type === 'Custom') {
        startDate = customStartDate;
        endDate = customEndDate;
    }
    return dispatch(
        {
            type: MOACTIONS.SET_DATE_FOR_MO_SCREEN_SUCCESS,
            payload: {
                startDate,
                endDate
            }
        }
    );
};

export const reSetDateFilter = () => (dispatch) => {
    const dateObj = new Date();
    dispatch({
        type: MOACTIONS.RESET_DATE_FOR_MO_SCREEN_SUCCESS,
        payload: {
            startDate: new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - 30),
            endDate: new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() + 35)
        }
    });
};

export const setDataForTagSelectionMOScreen = (existingState) => (dispatch) => {
    dispatch({
        type: MOACTIONS.SET_MORECORD_ROW,
        payload: {
            selectedRow: existingState
        }
    });
};

export const sortMO = (colForSorting, sortAscOrder) => (dispatch, getState) => {
    dispatch({
        type: MOACTIONS.GET_SORTMORECORDS_PENDING,
        payload: {
            tableLoading: true
        }
    });

    const { MOSelection: { moDataAll } } = getState();

    sortFunction(moDataAll, colForSorting, sortAscOrder)
        .then(sortedData => {
            dispatch({
                type: MOACTIONS.GET_SORTMORECORDS_SUCCESS,
                payload: {
                    tableLoading: false,
                    moDataAll: sortedData
                }
            });
        }
        )
        .catch(error => {
            dispatch({
                type: MOACTIONS.GET_SORTMORECORDS_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
        }
        );
};

function searchMODescriptionFunction(searchObj = moSelectionInitialState.searchCriteria, searchSpace = []) {
    if (_.isEqual(searchObj, moSelectionInitialState.searchCriteria)) {
        return searchSpace;
    }
    return searchSpace.filter(
        row => row.HdWorkOrderDescription?.toLowerCase().includes(searchObj.moDescription.toLowerCase())
            && row.HdWorkOrder?.toLowerCase().includes(searchObj.moNumber.toLowerCase())
            && row.HdLocation?.toLowerCase().includes(searchObj.functionalLocation.toLowerCase())
    );
}

export const searchMODescription = (searchVal, name) => async (dispatch, getState) => {
    const { MOSelection: { moDataAll, searchCriteria }, AppData: { isMRATOnline } } = getState();
    isMRATOnline && await dispatch(getAllMODataForSearch());
    const filterData = {
        ...searchCriteria,
        [name]: searchVal
    };
    dispatch({
        type: MOACTIONS.GET_SEARCHMORECORDS_PENDING,
        payload: {
            tableLoading: true,
            searchCriteria: { ...filterData }
        }
    });

    const searchResult = searchMODescriptionFunction(filterData, moDataAll);
    dispatch({
        type: MOACTIONS.GET_SEARCHMORECORDS_SUCCESS,
        payload: {
            tableLoading: false,
            searchedMoData: searchResult,
            totalCount: searchResult.length
        }
    });
};
