import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { StyledLabel } from 'elements/Label/Label';
import { FormInput } from 'elements/Inputs/FormInput/';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { selectValveSizeSection } from './ValveSizeSection.data';
import { StyleValveSizeDropdown } from '../HMISection/HMISection.styled';
import { SearchableDropdown } from 'elements/Inputs/FormInput/SearchableDropdown';

const useStyles = formName => makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

const ValveSizeSection = (props) => {
    const { selectedTag, changeHandler, formData, formName, isFormReadOnly, userRole } = props;
    const classes = useStyles(formName)();
    const styleFormInput = (field) => field.isLabel ? (
        <StyledLabel
            id={field.key}
            fontWeight={field.fontWeight}
            textAlign={field.textAlign}
            labelWidth={field.labelWidth}
            padding={field.padding}
            marginLeft={field.marginLeft}
            fontSize={field.fontSize}
            color={field.color}
        >
            {field.label}
        </StyledLabel>
    )
        : (
            <FormInput
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                isRequired={field.isRequired}
                isCalculatedNew={field.isCalculatedNew}
                handleChange={changeHandler}
                readOnly={field.readOnly || isFormReadOnly}
                labelWidth={field.labelWidth}
                width={field.width}
                marginRight={field.marginRight}
                marginLeft={field.marginLeft}
                marginTop={field.marginTop}
                direction={field.direction}
                padding={field.padding}
                type={field.type}
                maxLength={field.maxLength}
                isDisabled={field.isDisabled}
                borderBottom={field.borderBottom}
                height={field.height}
                textAlign={field.textAlign}
                hasDPSError={field.hasDPSError}
            />
        );
    const failedDropdownInput = (field) => field.isFailedDropDown ? (
        <StyleValveSizeDropdown
            formName={formName}
        >
            <SearchableDropdown
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                handleChange={changeHandler}
                options={field.options}
                isRequired={field.isRequired}
                labelWidth={field.labelWidth}
                width={field.width}
                marginLeft={field.marginLeft}
                marginRight={field.marginRight}
                readOnly={isFormReadOnly}
                direction={field.direction}
                borderTop={field.borderTop}
                borderLeft={field.borderLeft}
                isDisabled={field.isDisabled}
                borderBottom={field.borderBottom}
                height={field.height}
                textAlign={field.textAlign}
                userRole={userRole}
                marginTop={field.marginTop}
                menuOnTop
                placeholder={field.placeholder}
                paddingLeft={field.paddingLeft}
            />
        </StyleValveSizeDropdown>
    )
        : styleFormInput(field);
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                selectValveSizeSection(selectedTag, formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingBottom: '0', paddingTop: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isDropDown
                                    ? (
                                        <StyleValveSizeDropdown
                                            formName={formName}
                                        >
                                            <FormDropdown
                                                id={field.key}
                                                name={field.key}
                                                label={field.label}
                                                value={field.value}
                                                placeholder={field.placeholder}
                                                handleChange={changeHandler}
                                                options={field.options}
                                                isRequired={field.isRequired}
                                                labelWidth={field.labelWidth}
                                                width={field.width}
                                                marginLeft={field.marginLeft}
                                                marginRight={field.marginRight}
                                                className="MenuOpensOnTop"
                                                readOnly={isFormReadOnly}
                                                direction={field.direction}
                                                padding={field.padding}
                                                type={field.type}
                                                borderTop={field.borderTop}
                                                borderLeft={field.borderLeft}
                                                fontSize={field.fontSize}
                                                maxLength={field.maxLength}
                                                isDisabled={field.isDisabled}
                                                borderBottom={field.borderBottom}
                                                height={field.height}
                                                textAlign={field.textAlign}
                                                userRole={userRole}
                                                marginTop={field.marginTop}
                                                inputTextAlign={field.inputTextAlign}
                                                noDefaultOption={field.noDefaultOption}
                                            />
                                        </StyleValveSizeDropdown>
                                    )
                                    : failedDropdownInput(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ TagSelectionMOScreen, Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ValveSizeSection);

ValveSizeSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
    userRole: PropTypes.string,
};
