import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import DataGridComponent from 'components/DataGrid/DataGridComponent';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import React, { useState } from 'react';
import { trackErrors } from '../helper';
import {
    correctiveDPSMappingData, correctiveDPSMappingApiEndpoint,
    searchDataInitialState, correctiveDPSMappingInitialState
} from './CorrectiveDPSMapping.data';

import CorrectiveDPSMappingForm from './CorrectiveDPSMappingForm';
import { strEquals } from '../../AdminScreen.helper';

const addTitle = 'Add New Record for DPS Source Mapping';
const editTitle = 'Edit Record for DPS Source Mapping';

const CorrectiveDPSMapping = () => {
    const apiEndpoints = correctiveDPSMappingApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(correctiveDPSMappingInitialState);
    const [modalTitle, setModalTitile] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [trfDetails, setTrfDetails] = useState([]);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'CorrectiveDpsmappingId',
        rowNumber: ''
    });
    const [dropdownsData, setDpsSourceMappingData] = useState({
        bpRegionOptions: [],
        assetOptions: [],
        dpsFieldOptions: [],
        dpsSourceOptions: []
    });

    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const trfIdDetails = await axios.get(APIEndpoints.ADMIN_GetTrfMasterLists);
                setTrfDetails(trfIdDetails.data);
                const result = data?.map((res) => ({
                    ...res,
                    Trf: trfIdDetails.data.find(x => x?.RowId === res?.Trfid)?.TrfId,
                    CorrectiveTrf: trfIdDetails.data.find(x => x?.RowId === res?.CorrectiveTrfid)?.TrfId,
                    Status: res.Status ? 'Active' : 'Inactive',
                }));
                setDpsSourceMappingData(prev => ({
                    ...prev,
                    trfIdOptions: trfIdDetails.data.sort((a, b) => a.TrfId.localeCompare(b.TrfId, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })).filter(y => strEquals(y.WorkType, 'Preventative') && !strEquals(y.TrfId, 'NA')).map(x => x.TrfId),
                    correctiveTrfIdOptions: trfIdDetails.data.sort((a, b) => a.TrfId.localeCompare(b.TrfId, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })).filter(y => strEquals(y.WorkType, 'Corrective')).map(x => x.TrfId),
                }));
                resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
                reject();
            }
        })();
    });

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitile(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(correctiveDPSMappingInitialState);
        setModalTitile(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
        setRefreshDatagrid(prev => !prev);
    };

    return (
        <>
            <DataGridComponent
                headerData={correctiveDPSMappingData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={searchDataInitialState}
                rowDetails={rowDetails}
                dataParser={parseData}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <CorrectiveDPSMappingForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                        dropdownsData={dropdownsData}
                        trfDetails={trfDetails}
                    />
                )}
        </>
    );
};

export default CorrectiveDPSMapping;
