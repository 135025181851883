import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useStyles } from '../AdminComponents/AdminForm.styled';

const ModalFooterActions = ({ submitButtonVisible, submitButtonHandler, resetButtonHandler, cancelButtonHandler }) => {
    const classes = useStyles();
    return (
        <div style={{ display: 'flex', gap: '0 8px' }}>
            {submitButtonVisible
                && (
                    <Button
                        classes={{ root: classes.button }}
                        onClick={submitButtonHandler}
                        variant="outlined"
                    >
                        Submit
                    </Button>
                )}
            <Button
                classes={{ root: classes.button }}
                onClick={resetButtonHandler}
                variant="outlined"
            >
                Reset
            </Button>
            <Button
                classes={{ root: classes.button }}
                onClick={cancelButtonHandler}
                variant="outlined"
            >

                Close
            </Button>
        </div>
    );
};
export default ModalFooterActions;

ModalFooterActions.propTypes = {
    cancelButtonHandler: PropTypes.func,
    resetButtonHandler: PropTypes.func,
    submitButtonHandler: PropTypes.func,
    submitButtonVisible: PropTypes.bool
};
