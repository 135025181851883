export const dpsHeaderData = [
    {
        field: 'DpsMappingDetailId',
        headerName: 'Row ID',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'FormName',
        headerName: 'Form Name',
        width: '80',
        hideInTable: false,
        isDisabled: false,
        sortable: true
    },
    {
        field: 'FormHeading',
        headerName: 'Form Heading',
        width: '250',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Dpsfield',
        headerName: 'DPS Field',
        width: '220',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'DpsfieldDataType',
        headerName: 'DPS Field Data Type',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'DpsFieldMaxLength',
        headerName: 'DPS Field Max Length',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'IsRequired',
        headerName: 'Is Required',
        width: '80',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'VwName',
        headerName: 'View Name',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'VwFieldName',
        headerName: 'View Field Name',
        width: '220',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'InputFieldType',
        headerName: 'Input Field Type',
        width: '210',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'UserInputType',
        headerName: 'User Input Type',
        width: '150',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'MratTrfTableNameForAgMrat',
        headerName: 'MRAT TRF Table Name For AG MRAT',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'MratTrfFieldNameForAgMrat',
        headerName: 'MRAT TRF Field Name For AG MRAT',
        width: '200',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'Status',
        headerName: 'Status',
        width: '100',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedBy',
        headerName: 'Created By',
        width: '110',
        hideInTable: false,
        isDisabled: false,
    },
    {
        field: 'CreatedDate',
        headerName: 'Created Date',
        width: '140',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'ModifiedBy',
        headerName: 'Modified By',
        width: '110',
        hideInTable: false,
        isDisabled: false
    },
    {
        field: 'LastModifiedDate',
        headerName: 'Last Modified Date',
        width: '140',
        hideInTable: false,
        isDisabled: false,
        type: 'date'
    },
    {
        field: 'Comments',
        headerName: 'Comments',
        width: '350',
        hideInTable: false,
        isDisabled: false,
    },
];

export const dpsMappingInitialState = {
    DpsMappingDetailId: '',
    FormName: null,
    FormHeading: null,
    Dpsfield: null,
    DpsfieldDataType: '',
    DpsFieldMaxLength: '',
    IsRequired: '',
    VwName: 'vwSearchTag',
    VwFieldName: null,
    InputFieldTypeId: '',
    UserInputTypeId: '',
    Status: 'Inactive',
    MratTrfTableNameForAgMrat: null,
    MratTrfFieldNameForAgMrat: null,
    Comments: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: ''
};

export const dpsMappingSearchState = {
    DpsMappingDetailId: '',
    FormName: '',
    FormHeading: '',
    Dpsfield: '',
    DpsfieldDataType: '',
    DpsFieldMaxLength: '',
    IsRequired: '',
    VwName: '',
    VwFieldName: '',
    InputFieldTypeId: '',
    UserInputTypeId: '',
    Status: '',
    MratTrfTableNameForAgMrat: '',
    MratTrfFieldNameForAgMrat: '',
    Comments: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    LastModifiedDate: ''
};

export const dpsMappingApiEndpoint = {
    read: 'ADMIN_GetDpsMappingDetails',
};
