import { signalToTriggerIsErrorCheck } from '../../../SignalToTrigger/SignalToTrigger.data';

export const SmartMeterSectionDataP6022 = (formData, formName) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F799AutoDiagnosticsTestResult',
                label: 'Smart Meter Verification / Auto-Diagnostics Test Result',
                value: !signalToTriggerIsErrorCheck(formName, formData) ? formData.F799AutoDiagnosticsTestResult : '',
                isRequired: !signalToTriggerIsErrorCheck(formName, formData),
                isDropdown: !signalToTriggerIsErrorCheck(formName, formData),
                placeholder: 'Select Test Result',
                options: [{ name: 'Select Test Result', value: '' },
                { name: 'PASS', value: 'PASS' },
                { name: 'FAIL', value: 'FAIL' },
                { name: 'N/A', value: 'N/A' }],
                inputTextAlign: 'center',
                labelWidth: ['P8-8.1', 'P6-12', 'P6-22', 'P8-12.1'].includes(formName) ? '100%' : '76%',
                width: signalToTriggerIsErrorCheck(formName, formData) ? '35%' : '50%',
                xs: 6,
                marginTop: '15px',
                textAlign: 'left',
                isDisabled: signalToTriggerIsErrorCheck(formName, formData),
                readOnly: signalToTriggerIsErrorCheck(formName, formData),
                height: signalToTriggerIsErrorCheck(formName, formData) ? '1.1rem' : '1.55rem',
                marginLeft: (formName === 'P7-30') ? '4px' : '',
            }]
        }
    ]
});
