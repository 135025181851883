import { ExponentialToDecimal, FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const AsLeftTestTableF237AltDeviation1Formula = (formData) => {
    if (FieldHasValue(formData.F654AltReferenceTestPoint) || FieldHasValue(formData.F357AnalyzerReading)) {
        if (FieldHasValue(formData.F654AltReferenceTestPoint) && FieldHasValue(formData.F357AnalyzerReading)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F654AltReferenceTestPoint) - ValidateNumberField(formData.F357AnalyzerReading)));
        }
        return '';
    }
    return '';
};

export const F103AltResultFormula = (formData) => {
    if (FieldHasValue(formData.F654AltReferenceTestPoint)
        && FieldHasValue(formData.F357AnalyzerReading) && FieldHasValue(formData.F109AltHmireading1) && FieldHasValue(formData.F101AftTolerance)) {
        return (Math.max.apply(Math, [ValidateNumberField(formData.F237AltDeviation1)])
            <= ValidateNumberField(formData.F101AftTolerance)
            ? 'PASS' : 'FAIL');
    }
    return '';
};
