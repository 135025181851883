import { paddingLabel } from '../HMISection/Data/C11/C11.data';
import { labelWidth } from '../HMISection/Data/P710/P710.data';

export const DielectricConstant = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'DIELECTRICCONSTANTINFORMATIONLabel',
                label: 'DIELECTRIC CONSTANT INFORMATION',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                width: '28%',
                padding: '15px 0 0 0'
            }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F492TransmitterToMeasureInterfaceLevelLabel',
                    label: 'Is transmitter used to measure interface level?',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F492TransmitterToMeasureInterfaceLevel',
                    value: formData?.F492TransmitterToMeasureInterfaceLevel,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select Yes or No',
                    options: [{ name: 'Select Yes or No', value: '' },
                    { name: 'Yes', value: 'Yes' },
                    { name: 'No', value: 'No' }],
                    xs: 2,
                    inputTextAlign: 'center',
                    width: '100%',
                    height: '1.56rem',
                    labelWidth: '0%',
                    textAlign: 'center',
                },
                {
                    key: 'F495ConfiguredDielectricConstantLabel',
                    label: 'Configured Dielectric Constant (µS/cm)',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F495ConfiguredDielectricConstant',
                    value: formData?.F495ConfiguredDielectricConstant,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                }
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F493MeasuredLiquid1Label',
                    label: 'Measured Liquid #1',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F493MeasuredLiquid1',
                    value: formData?.F493MeasuredLiquid1,
                    isRequired: true,
                    xs: 2,
                    inputTextAlign: 'center',
                    width: '100%',
                    height: '1.2rem',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderTop: '0',
                    maxLength: '20'
                },
                {
                    key: 'F496DielectricConstantLiquid1Label',
                    label: 'Dielectric Constant of Liquid #1 (µS/cm)',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F496DielectricConstantLiquid1',
                    value: formData?.F496DielectricConstantLiquid1,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderTop: '0'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F494MeasuredLiquid2Label',
                    label: 'Measured Liquid #2',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F494MeasuredLiquid2',
                    value: formData?.F492TransmitterToMeasureInterfaceLevel !== 'No' ? formData?.F494MeasuredLiquid2 : '',
                    isRequired: formData?.F492TransmitterToMeasureInterfaceLevel !== 'No',
                    xs: 2,
                    inputTextAlign: 'center',
                    width: '100%',
                    height: '1.2rem',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderTop: '0',
                    maxLength: '20',
                    isDisabled: formData?.F492TransmitterToMeasureInterfaceLevel === 'No',
                    readOnly: formData?.F492TransmitterToMeasureInterfaceLevel === 'No'
                },
                {
                    key: 'F497DielectricConstantLiquid2Label',
                    label: 'Dielectric Constant of Liquid #2 (µS/cm)',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F497DielectricConstantLiquid2',
                    value: formData?.F492TransmitterToMeasureInterfaceLevel !== 'No' ? formData?.F497DielectricConstantLiquid2 : '',
                    isRequired: formData?.F492TransmitterToMeasureInterfaceLevel !== 'No',
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    borderTop: '0',
                    isDisabled: formData?.F492TransmitterToMeasureInterfaceLevel === 'No',
                    readOnly: formData?.F492TransmitterToMeasureInterfaceLevel === 'No'
                }]
        },
    ]
}
);

export const selectDielectricConstant = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'P7-28') {
        filterViewData = DielectricConstant(formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
