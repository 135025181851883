import { test1Result, test4Result } from './AsFoundSolenoidLimitSection.formulas';
import { commonConstants, controlValveToLouverDamper } from '../../Sections.constants';
import { conditionalValueSelector } from 'helpers/Validation';
import { asFoundSolenoid } from './Data/S71/S71.data';

export const placeholderOpenClosed = 'Select Open/Closed';
export const placeholderYesNo = 'Select Yes/No';
export const paddingTopLeft = '4px 0 0 4px';
export const placeholderPerformDiscard = 'Select Perform/Discard';
export const disableTestSectionAft = (key) => key === commonConstants.Discard;
export const disableConditionAft = (key, output) => disableTestSectionAft(key) ? '' : output;
export const calculateResult = (key) => key === 'Yes';

export const asFoundSolenoidLimit = (formName, formData) => {
    const disableTest1Key = formData.F840AftTest1Solenoid;
    const disableTest4Key = formData.F845AftTest4LimitSwitch;
    const valvePositionToLouverDamperLabel = controlValveToLouverDamper(formName);
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsFoundTest',
                    label: 'As-Found Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 4px'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F840AftTest1SolenoidLabel',
                        label: 'Test 1 - Solenoid',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        padding: '14px 0 0 4px'
                    },
                    {
                        key: 'F840AftTest1Solenoid',
                        value: conditionalValueSelector(calculateResult(formData.F836IplelementSolenoid), commonConstants.Perform, disableTest1Key),
                        isDropdown: !calculateResult(formData.F836IplelementSolenoid),
                        readOnly: calculateResult(formData.F836IplelementSolenoid),
                        isRequired: true,
                        placeholder: placeholderPerformDiscard,
                        options: [
                            { name: placeholderPerformDiscard, value: '' },
                            { name: commonConstants.Perform, value: commonConstants.Perform },
                            { name: commonConstants.Discard, value: commonConstants.Discard }],
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        xs: 2,
                        inputTextAlign: 'center',
                        marginTop: '10px',
                        height: conditionalValueSelector(calculateResult(formData.F836IplelementSolenoid), '1.14rem', '1.5rem')
                    },
                    {
                        key: 'Nodata',
                        isLabel: 'true',
                        xs: 1,
                        marginBottom: '3.5px'
                    },
                    {
                        key: 'F845AftTest4LimitSwitchLabel',
                        label: 'Test 4 - Limit Switch',
                        xs: 3,
                        isLabel: true,
                        fontWeight: '700',
                        fontSize: '9pt',
                        textAlign: 'right',
                        padding: '14px 0 0 0',
                        labelWidth: '98%'
                    },
                    {
                        key: 'F845AftTest4LimitSwitch',
                        value: conditionalValueSelector(calculateResult(formData.F839IplelementLimitSwitch), commonConstants.Perform, disableTest4Key),
                        isDropdown: !calculateResult(formData.F839IplelementLimitSwitch),
                        readOnly: calculateResult(formData.F839IplelementLimitSwitch),
                        isRequired: true,
                        placeholder: placeholderPerformDiscard,
                        options: [
                            { name: placeholderPerformDiscard, value: '' },
                            { name: commonConstants.Perform, value: commonConstants.Perform },
                            { name: commonConstants.Discard, value: commonConstants.Discard }],
                        width: '100%',
                        labelWidth: '0%',
                        textAlign: 'center',
                        xs: 2,
                        marginTop: '10px',
                        borderRight: '0',
                        inputTextAlign: 'center',
                        height: conditionalValueSelector(calculateResult(formData.F839IplelementLimitSwitch), '1.14rem', '1.5rem')
                    }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F841AftControlValveFailSafePositionLabel',
                    label: `${valvePositionToLouverDamperLabel} Fail Safe Position`,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingTopLeft
                },
                {
                    key: 'F841AftControlValveFailSafePosition',
                    value: disableConditionAft(disableTest1Key, formData.F841AftControlValveFailSafePosition),
                    isDropdown: !disableTestSectionAft(disableTest1Key),
                    isRequired: !disableTestSectionAft(disableTest1Key),
                    isDisabled: disableTestSectionAft(disableTest1Key),
                    readOnly: disableTestSectionAft(disableTest1Key),
                    xs: 2,
                    placeholder: placeholderOpenClosed,
                    options: [
                        { name: placeholderOpenClosed, value: '' },
                        { name: commonConstants.Open, value: commonConstants.Open },
                        { name: commonConstants.Closed, value: commonConstants.Closed }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    inputTextAlign: 'center',
                    borderTop: '0',
                    height: conditionalValueSelector(disableTestSectionAft(disableTest1Key), '1.37rem', '1.5rem')
                },
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 1,
                    marginBottom: '3.5px'
                },
                {
                    key: 'F846AftLimitSwitchSettingLabel',
                    label: 'Limit Switch Setting (%)',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '3px 0 0 0',
                    labelWidth: '98%'
                },
                {
                    key: 'F846AftLimitSwitchSetting',
                    value: disableConditionAft(disableTest4Key, formData.F846AftLimitSwitchSetting),
                    xs: 2,
                    isRequired: !disableTestSectionAft(disableTest4Key),
                    isDisabled: disableTestSectionAft(disableTest4Key),
                    readOnly: disableTestSectionAft(disableTest4Key),
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    inputTextAlign: 'center',
                    height: '1.375rem',
                    borderTop: '0',
                    type: 'number',
                    maxLength: '10'
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F842AftSolenoidMovementUponTripLabel',
                    label: 'Solenoid Movement Upon Trip',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingTopLeft
                },
                {
                    key: 'F842AftSolenoidMovementUponTrip',
                    value: disableConditionAft(disableTest1Key, formData.F842AftSolenoidMovementUponTrip),
                    xs: 2,
                    isDropdown: !disableTestSectionAft(disableTest1Key),
                    isRequired: !disableTestSectionAft(disableTest1Key),
                    isDisabled: disableTestSectionAft(disableTest1Key),
                    readOnly: disableTestSectionAft(disableTest1Key),
                    placeholder: 'Select Quick/Sticking',
                    options: [
                        { name: 'Select Quick/Sticking', value: '' },
                        { name: commonConstants.Quick, value: commonConstants.Quick },
                        { name: commonConstants.Sticking, value: commonConstants.Sticking }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    inputTextAlign: 'center',
                    height: conditionalValueSelector(disableTestSectionAft(disableTest1Key), '1.25rem', '1.5rem')
                },
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 1,
                },
                {
                    key: 'F847AftControlValveMovedToLimitLabel',
                    label: `${valvePositionToLouverDamperLabel} Moved to Limit`,
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '3px 0 0 0',
                    labelWidth: '98%'
                },
                {
                    key: 'F847AftControlValveMovedToLimit',
                    value: disableConditionAft(disableTest4Key, formData.F847AftControlValveMovedToLimit),
                    xs: 2,
                    isDropdown: !disableTestSectionAft(disableTest4Key),
                    isRequired: !disableTestSectionAft(disableTest4Key),
                    isDisabled: disableTestSectionAft(disableTest4Key),
                    readOnly: disableTestSectionAft(disableTest4Key),
                    placeholder: placeholderYesNo,
                    options: [
                        { name: placeholderYesNo, value: '' },
                        { name: commonConstants.Yes, value: commonConstants.Yes },
                        { name: commonConstants.No, value: commonConstants.No }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    inputTextAlign: 'center',
                    height: conditionalValueSelector(disableTestSectionAft(disableTest4Key), '1.25rem', '1.5rem')
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F843AftControlValvePositionAfterTripLabel',
                    label: `${valvePositionToLouverDamperLabel} Position After Trip`,
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingTopLeft
                },
                {
                    key: 'F843AftControlValvePositionAfterTrip',
                    value: disableConditionAft(disableTest1Key, formData.F843AftControlValvePositionAfterTrip),
                    xs: 2,
                    isDropdown: !disableTestSectionAft(disableTest1Key),
                    isRequired: !disableTestSectionAft(disableTest1Key),
                    isDisabled: disableTestSectionAft(disableTest1Key),
                    readOnly: disableTestSectionAft(disableTest1Key),
                    placeholder: placeholderOpenClosed,
                    options: [
                        { name: placeholderOpenClosed, value: '' },
                        { name: commonConstants.Open, value: commonConstants.Open },
                        { name: commonConstants.Closed, value: commonConstants.Closed }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    inputTextAlign: 'center',
                    height: conditionalValueSelector(disableTestSectionAft(disableTest1Key), '1.25rem', '1.5rem')
                },
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 1,
                },
                {
                    key: 'F848AftLimitSwitchActivatedLabel',
                    label: 'Limit Switch Activated',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '3px 0 0 0',
                    labelWidth: '98%'
                },
                {
                    key: 'F848AftLimitSwitchActivated',
                    value: disableConditionAft(disableTest4Key, formData.F848AftLimitSwitchActivated),
                    xs: 2,
                    isDropdown: !disableTestSectionAft(disableTest4Key),
                    isRequired: !disableTestSectionAft(disableTest4Key),
                    isDisabled: disableTestSectionAft(disableTest4Key),
                    readOnly: disableTestSectionAft(disableTest4Key),
                    placeholder: placeholderYesNo,
                    options: [
                        { name: placeholderYesNo, value: '' },
                        { name: commonConstants.Yes, value: commonConstants.Yes },
                        { name: commonConstants.No, value: commonConstants.No }],
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderBottom: '0',
                    borderRight: '0',
                    inputTextAlign: 'center',
                    height: conditionalValueSelector(disableTestSectionAft(disableTest4Key), '1.25rem', '1.5rem')
                }]
            },
            {
                key: 6,
                fields: [{
                    key: 'F844AftTest1ResultLabel',
                    label: 'Test 1 Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingTopLeft
                },
                {
                    key: 'F844AftTest1Result',
                    value: test1Result(formData),
                    xs: 2,
                    isCalculatedNew: true,
                    isDisabled: disableTestSectionAft(disableTest1Key),
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0%',
                    marginBottom: '25px',
                    textAlign: 'center',
                    inputTextAlign: 'center'
                },
                {
                    key: 'Nodata',
                    isLabel: 'true',
                    xs: 1,
                },
                {
                    key: 'F849AftTest4ResultLabel',
                    label: 'Test 4 Result',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: '3px 0 0 0',
                    labelWidth: '98%'
                },
                {
                    key: 'F849AftTest4Result',
                    value: test4Result(formData),
                    isDisabled: disableTestSectionAft(disableTest4Key),
                    readOnly: true,
                    xs: 2,
                    isCalculatedNew: true,
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                    borderRight: '0',
                    inputTextAlign: 'center',
                }]
            }
        ]
    };
};

export const selectAsFoundSolenoidLimitSectionData = (formName, formData) => {
    let filterViewData = { rows: [] };
    if (['P6-14.2', 'P7-31', 'P6-23.2', 'P6-14'].includes(formName)) {
        filterViewData = asFoundSolenoidLimit(formName, formData);
    } else if (['S7-1'].includes(formName) && formData.F956TrfTypeSelection === 'Full') {
        filterViewData = asFoundSolenoid(formData);
    }
    return filterViewData;
};
