import DataGridComponent from 'components/DataGrid/DataGridComponent';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BPRegionForm from './BpRegionForm';
import { APIEndpoints } from 'helpers/APILists';
import {
    bpRegionApiEndpoint, bpRegionHeaderData,
    bpRegionInitialState, bpRegionSearchState
} from './BpRegion.data';
import { formatDate } from 'helpers/DateFormatter';
import { trackErrors } from '../helper';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import { AdminConstants } from '../../AdminScreen.constants';

const addTitle = 'Add New Record for BP Region';
const editTitle = 'Edit Record for BP Region';

const BpRegion = () => {
    const apiEndpoints = bpRegionApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(bpRegionInitialState);
    const [modalTitle, setModalTitle] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [bpRegionOptions, setbpRegionOptions] = useState([]);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'RegionId',
        rowNumber: ''
    });

    useEffect(() => {
        axios.get(APIEndpoints.GetMratGroupMappings)
            .then((res) => {
                setbpRegionOptions((res.data.filter(y => y.Status === AdminConstants.LIVE_STATUS).map(x => ({ BpRegion: x.BpRegion, BpRegionAlias: x.BpRegionNameAlias }))));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const result = data.map(item => ({
                    ...item,
                    MratDpsLastRefreshDate: item.MratDpsLastRefreshDate && formatDate(new Date(item.MratDpsLastRefreshDate)),
                    SapLastRefreshDate: item.SapLastRefreshDate && formatDate(new Date(item.SapLastRefreshDate)),
                    Active: item?.Active ? 'Active' : 'Inactive',
                    MratDpsActive: item?.MratDpsActive ? 'Active' : 'Inactive',
                }));
                resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
                reject();
            }
        })();
    });

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitle(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(bpRegionInitialState);
        setModalTitle(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRefreshDatagrid(prev => !prev);
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
    };

    return (
        <>
            <DataGridComponent
                headerData={bpRegionHeaderData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                rowDetails={rowDetails}
                dataParser={parseData}
                initialData={bpRegionSearchState}
                refreshDatagrid={refreshDatagrid}
            />

            {showModal
                && (
                    <BPRegionForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                        bpRegionOptions={bpRegionOptions}
                    />
                )}
        </>
    );
};

export default BpRegion;
