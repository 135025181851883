export const VisualInspectSectionDataP8111 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect components inside the analyzer cabinet for signs of physical damage, water ingress, corrosion and
                security of mounting. Inspect condition of cabling, glanding and earthing. Inspect all seals, gaskets and faces are clean
                and in good condition. Check small bore tubing for damage, security and general condition. Check all sample, vent and
                return lines for damage, leaks, security, corrosion and general deterioration.`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F120Diagnostics',
                label: 'Retrieve any diagnostic messages',
                value: formData.F120Diagnostics,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                marginTop: '15px',
                alignItems: 'inherit'
            }]
        }
    ]
});
