import { AsFoundTestTableF61AsFoundResultFormula, isDisabledAsFoundFields } from './P727.formulas';

export const fullyOpenLabel = 'Fully Opened';
export const failToOpenLabel = 'Failed to Open';
export const abnormalWarning = 'Corrective work may be required to fix the abnormal valve movement.';
export const AsFoundTestTableSectionDataP727 = (selectedTag, formData) => {
    const disableAsFoundFields = isDisabledAsFoundFields(formData)
        || formData.F733AftValveActionUponTrip === failToOpenLabel;
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'As-FoundTest',
                    label: 'As-Found Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F733AftValveActionUponTrip',
                        label: 'Valve Action Upon Trip',
                        value: !isDisabledAsFoundFields(formData) ? formData.F733AftValveActionUponTrip : '',
                        isDisabled: isDisabledAsFoundFields(formData),
                        readOnly: isDisabledAsFoundFields(formData),
                        isDropdown: !isDisabledAsFoundFields(formData),
                        placeholder: 'Select Valve Action',
                        options: [
                            { name: 'Select Valve Action', value: '' },
                            { name: fullyOpenLabel, value: fullyOpenLabel },
                            { name: failToOpenLabel, value: failToOpenLabel }],
                        isRequired: !isDisabledAsFoundFields(formData),
                        height: isDisabledAsFoundFields(formData) ? '1.24rem' : '',
                        width: isDisabledAsFoundFields(formData) ? '39.5%' : '40.9%',
                        labelWidth: '49.6%',
                        marginLeft: '8px',
                        textAlign: 'center',
                        borderBottom: disableAsFoundFields ? '' : '0',
                        xs: 5,
                        rootMarginRight: '0px',
                        inputTextAlign: 'center'
                    },
                    {
                        key: 'NoDataP610',
                        xs: 1,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'F736AftValveHmiPositionIndication',
                        label: 'Valve HMI Position Indication Upon Trip',
                        value: !isDisabledAsFoundFields(formData) ? formData.F736AftValveHmiPositionIndication : '',
                        readOnly: isDisabledAsFoundFields(formData),
                        isDisabled: isDisabledAsFoundFields(formData),
                        isDropdown: !isDisabledAsFoundFields(formData),
                        options: [
                            { name: 'Select HMI Position', value: '' },
                            { name: fullyOpenLabel, value: fullyOpenLabel },
                            { name: failToOpenLabel, value: failToOpenLabel }],
                        xs: 6,
                        marginRight: '0.5rem',
                        direction: 'rtl',
                        placeholder: 'Select HMI Position',
                        width: isDisabledAsFoundFields(formData) ? '39.5%' : '40.6%',
                        labelWidth: '59.4%',
                        textAlign: 'center',
                        borderRight: '0',
                        rootMarginRight: '3.6px',
                        inputMarginRight: '4px',
                        inputTextAlign: 'center',
                        height: isDisabledAsFoundFields(formData) ? '1.24rem' : '',
                    },
                ]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F734AftValveMovementUponTrip',
                        label: 'Valve Movement Upon Trip',
                        value: !disableAsFoundFields ? formData.F734AftValveMovementUponTrip : '',
                        isDisabled: disableAsFoundFields,
                        readOnly: disableAsFoundFields,
                        width: disableAsFoundFields ? '39.5%' : '40.9%',
                        isDropdown: !disableAsFoundFields,
                        placeholder: 'Select Valve Movement',
                        options: [
                            { name: 'Select Valve Movement', value: '' },
                            { name: 'Smooth', value: 'Smooth' },
                            { name: 'Abnormal', value: 'Abnormal' }],
                        isRequired: !disableAsFoundFields,
                        height: !disableAsFoundFields ? '1.24rem' : '',
                        labelWidth: '49.6%',
                        marginLeft: '8px',
                        textAlign: 'center',
                        borderBottom: '0',
                        xs: 5,
                        rootMarginRight: '0px',
                        inputTextAlign: 'center'
                    },
                    {
                        key: 'warning',
                        label: formData.F734AftValveMovementUponTrip === 'Abnormal'
                            ? abnormalWarning
                            : '',
                        width: '0',
                        marginTop: '2px',
                        color: '#ea7c34',
                        borderBottom: '0',
                        xs: 7,
                        borderLeft: '0',
                        borderTop: '0',
                        borderRight: '0',
                        labelWidth: '100%',
                        inputMarginRight: '6px',
                        fontSize: '8.5pt'
                    },
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F735AftMeasuredValveTravelTime',
                        label: 'Measured Valve Travel Time (sec)',
                        value: (disableAsFoundFields) ? '' : formData.F735AftMeasuredValveTravelTime,
                        isDisabled: disableAsFoundFields,
                        readOnly: disableAsFoundFields,
                        isRequired: formData.F344ValveTravelTimeRequirement !== '' && !disableAsFoundFields,
                        width: disableAsFoundFields ? '39.4%' : '39.5%',
                        height: '1.24rem',
                        labelWidth: '49.6%',
                        type: 'number',
                        maxLength: '10',
                        marginLeft: '8px',
                        textAlign: 'center',
                        xs: 5,
                        borderBottom: '0',
                        rootMarginRight: '0px',
                        inputTextAlign: 'center',
                    },
                    {
                        key: 'NodataP610',
                        xs: 1,
                        isLabel: true,
                        padding: '0'
                    },
                    {
                        key: 'F61AsFoundResult',
                        label: 'As-Found Result',
                        value: AsFoundTestTableF61AsFoundResultFormula(formData),
                        xs: 6,
                        isCalculatedNew: true,
                        readOnly: true,
                        marginRight: '0.5rem',
                        direction: 'rtl',
                        height: '1.24rem',
                        width: '40%',
                        labelWidth: '60%',
                        textAlign: 'center',
                        borderRight: '0',
                        borderBottom: '0',
                        marginBottom: '4px',
                        inputMarginRight: '4px'
                    }
                ]
            }
        ]
    };
};
