import { Trim, MatchSRA } from 'helpers/Validation';
import { TestResultSectionData } from './Data/P71/P71.data';
import { TestResultSectionDataP72 } from './Data/P72/P72.data';
import { TestResultSectionDataP73 } from './Data/P73/P73.data';
import { TestResultSectionDataP74 } from './Data/P74/P74.data';
import { TestResultSectionDataP75 } from './Data/P75/P75.data';
import { TestResultSectionDataP77P65P831P652 } from './Data/P77/P77.data';
import { TestResultSectionDataP78 } from './Data/P78/P78.data';
import { TestResultSectionDataP79 } from './Data/P79/P79.data';
import { TestResultSectionDataP710andP68 } from './Data/P710/P710.data';
import { TestResultSectionDataP712 } from './Data/P712/P712.data';
import { TestResultSectionDataP64 } from './Data/P64/P64.data';
import { TestResultSectionDataP62 } from './Data/P62/P62.data';
import { TestResultSectionDataP61 } from './Data/P61/P61.data';
import { TestResultSectionDataP63 } from './Data/P63/P63.data';
import { TestResultSectionDataP642 } from './Data/P642/P642.data';
import { TestResultSectionDataP632 } from './Data/P632/P632.data';
import { TestResultSectionDataP81 } from './Data/P81/P81.data';
import { TestResultSectionDataP81StandAlone } from './Data/P81/P81StandAlone.data';
import { TestResultSectionDataP612 } from './Data/P612/P612.data';
import { TestResultSectionDataP622 } from './Data/P622/P622.data';
import { TestResultSectionDataP67P841P8101 } from './Data/P67/P67.data';
import { TestResultSectionDataP66 } from './Data/P66/P66.data';
import { TestResultSectionDataP861P69 } from './Data/P69/P69.data';
import { TestResultSectionDataM67 } from './Data/M67/M67.data';
import { TestResultSectionDataP851 } from './Data/P851/P851.data';
import { TestResultSectionDataM69 } from './Data/M69/M69.data';
import { TestResultSectionDataM611M614M615M61 } from './Data/M611/M611.data';
import { TestResultSectionDataM63andM64 } from './Data/M63/M63.data';
import { TestResultSectionDataM610 } from './Data/M610/M610.data';
import { TestResultSectionDataM66 } from './Data/M66/M66.data';
import { TestResultSectionDataM65 } from './Data/M65/M65.data';
import { TestResultSectionDataM31P723 } from './Data/M31/M31.data';
import { TestResultSectionDataM612 } from './Data/M612/M612.data';
import { TestResultSectionDataP617 } from './Data/P617/P617.data';
import { TestResultSectionDataP611 } from './Data/P611/P611.data';
import { TestResultSectionDataP724 } from './Data/P724/P724.data';
import { TestResultSectionDataC11C13C14 } from './Data/C11/C11.data';
import { TestResultSectionDataP615 } from './Data/P615/P615.data';
import { TestResultSectionDataP891 } from './Data/P891/P891.data';
import { TestResultSectionDataP732 } from './Data/P732/P732.data';
import { TestResultSectionDataP733P620 } from './Data/P733/P733.data';
import { TestResultSectionDataP8111 } from './Data/P8111/P8111.data';
import { TestResultSectionDataM616 } from './Data/M616/M616.data';
import { TestResultSectionDataM71 } from './Data/M71/M71.data';
import { TestResultSectionDataP720 } from './Data/P720/P720.data';
import { TestResultSectionDataP721 } from './Data/P721/P721.data';
import { TestResultSectionDataP711 } from './Data/P711/P711.data';
import { TestResultSectionDataP8141 } from './Data/P8141/P8141.data';

export const selectTestResultSectionData = (selectedTag, formName, formData, selectedTrfId) => {
    const newTrfId = selectedTrfId ?? Trim(selectedTag.TrfId);
    const formNameToFunctionMap = {
        'P7-1': () => TestResultSectionData(selectedTag, formData),
        'P7-2': () => TestResultSectionDataP72(selectedTag, formData),
        'P7-3': () => TestResultSectionDataP73(selectedTag, formData),
        'P7-4': () => TestResultSectionDataP74(selectedTag, formData),
        'P7-4.1': () => TestResultSectionDataP74(selectedTag, formData),
        'P7-5': () => TestResultSectionDataP75(selectedTag, formData),
        'P7-7': () => TestResultSectionDataP77P65P831P652(selectedTag, formData),
        'P6-5': () => TestResultSectionDataP77P65P831P652(selectedTag, formData),
        'P8-3.1': () => TestResultSectionDataP77P65P831P652(selectedTag, formData),
        'P6-5.2': () => TestResultSectionDataP77P65P831P652(selectedTag, formData),
        'P7-8': () => TestResultSectionDataP78(selectedTag, formData),
        'P7-9': () => TestResultSectionDataP79(selectedTag, formData),
        'P6-7': () => TestResultSectionDataP67P841P8101(selectedTag, formName, formData),
        'P8-4.1': () => TestResultSectionDataP67P841P8101(selectedTag, formName, formData),
        'P6-18': () => TestResultSectionDataP67P841P8101(selectedTag, formName, formData),
        'P8-10.1': () => TestResultSectionDataP67P841P8101(selectedTag, formName, formData),
        'P7-13': () => TestResultSectionDataP67P841P8101(selectedTag, formName, formData),
        'P6-16': () => TestResultSectionDataP67P841P8101(selectedTag, formName, formData),
        'P7-10': () => TestResultSectionDataP710andP68(selectedTag, formData),
        'P6-8': () => TestResultSectionDataP710andP68(selectedTag, formData),
        'P7-12': () => TestResultSectionDataP712(selectedTag, formData),
        'P6-4': () => TestResultSectionDataP64(selectedTag, formData),
        'P6-2': () => TestResultSectionDataP62(selectedTag, formData),
        'P8-1.1': () => TestResultSectionDataP62(selectedTag, formData),
        'P8-2.1': () => TestResultSectionDataP62(selectedTag, formData),
        'P6-1': () => TestResultSectionDataP61(selectedTag, formData, formName),
        'P8-7.1': () => TestResultSectionDataP61(selectedTag, formData, formName),
        'P8-5.1': () => TestResultSectionDataP851(selectedTag, formData),
        'P6-1.2': () => TestResultSectionDataP612(selectedTag, formData),
        'P6-3': () => TestResultSectionDataP63(selectedTag, formData),
        'P6-4.2': () => TestResultSectionDataP642(selectedTag, formData),
        'P6-3.2': () => TestResultSectionDataP632(selectedTag, formData),
        'P6-17': () => TestResultSectionDataP617(selectedTag, formData),
        'P7-28': () => TestResultSectionDataP617(selectedTag, formData),
        'P8-1': () => {
            if (MatchSRA(Trim(newTrfId))) {
                return TestResultSectionDataP81(selectedTag, formData);
            }
            return TestResultSectionDataP81StandAlone(selectedTag, formData);
        },
        'P6-2.2': () => TestResultSectionDataP622(selectedTag, formData),
        'P6-6': () => TestResultSectionDataP66(selectedTag, formData),
        'P8-17.1': () => TestResultSectionDataP66(selectedTag, formData),
        'P8-6.1': () => TestResultSectionDataP861P69(selectedTag, formData),
        'P6-9': () => TestResultSectionDataP861P69(selectedTag, formData),
        'M6-9': () => TestResultSectionDataM69(selectedTag, formData),
        'M6-11': () => TestResultSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-14': () => TestResultSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-15': () => TestResultSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-1': () => TestResultSectionDataM611M614M615M61(selectedTag, formData, formName),
        'M6-3': () => TestResultSectionDataM63andM64(selectedTag, formData),
        'M6-4': () => TestResultSectionDataM63andM64(selectedTag, formData),
        'M6-12': () => TestResultSectionDataM612(selectedTag, formData),
        'M6-6': () => TestResultSectionDataM66(selectedTag, formData),
        'M6-7': () => TestResultSectionDataM67(selectedTag, formData),
        'M6-10': () => TestResultSectionDataM610(selectedTag, formData),
        'M6-5': () => TestResultSectionDataM65(selectedTag, formData),
        'M3-1': () => TestResultSectionDataM31P723(selectedTag, formData, formName),
        'P7-23': () => TestResultSectionDataM31P723(selectedTag, formData, formName),
        'P6-11': () => TestResultSectionDataP611(selectedTag, formData),
        'P7-17': () => TestResultSectionDataP611(selectedTag, formData),
        'P7-24': () => TestResultSectionDataP724(selectedTag, formData),
        'C1-1': () => TestResultSectionDataC11C13C14(selectedTag, formData),
        'C1-3': () => TestResultSectionDataC11C13C14(selectedTag, formData),
        'C1-3.2': () => TestResultSectionDataC11C13C14(selectedTag, formData),
        'C1-4': () => TestResultSectionDataC11C13C14(selectedTag, formData),
        'P6-15': () => TestResultSectionDataP615(selectedTag, formData),
        'P8-9.1': () => TestResultSectionDataP891(selectedTag, formData),
        'P7-19': () => TestResultSectionDataP891(selectedTag, formData),
        'P7-32': () => TestResultSectionDataP732(selectedTag, formData),
        'P7-33': () => TestResultSectionDataP733P620(selectedTag, formData),
        'P6-20': () => TestResultSectionDataP733P620(selectedTag, formData),
        'P8-11.1': () => TestResultSectionDataP8111(selectedTag, formData),
        'M6-16': () => TestResultSectionDataM616(selectedTag, formData),
        'M7-1': () => TestResultSectionDataM71(selectedTag, formData),
        'P7-20': () => TestResultSectionDataP720(selectedTag, formData),
        'P7-21': () => TestResultSectionDataP721(selectedTag, formData),
        'P7-11': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P6-22': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P7-22': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P7-15': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P7-14': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P7-18': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P6-12': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P7-29': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P8-8.1': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P8-12.1': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P8-13.1': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P7-30': () => TestResultSectionDataP711(selectedTag, formName, formData),
        'P8-14.1': () => TestResultSectionDataP8141(selectedTag, formData)
    };
    let filterViewData = { rows: [] };
    const functionToExecute = formNameToFunctionMap[formName];
    if (functionToExecute) {
        filterViewData = functionToExecute();
    }
    return filterViewData;
};
