import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import { useMsal } from '@azure/msal-react';
import RefreshIcon from '../../../../icons/RefreshButton/Refresh';
import { DpsForm } from './DPSForm';
import { resetDataForEditDPS, updateDataForEditDPS, PutEditDPSData } from '../DPS/DPS.actions';
import { MRAT_USER_ROLE } from '../../Home/Home.constants';
import { setCurrentView } from '../../Home/Home.actions';

const RefreshFormComponent = ({ buttonHandler }) => <RefreshIcon buttonHandler={buttonHandler} rotate={-90} tooltip="Reset Form" />;
RefreshFormComponent.propTypes = {
    buttonHandler: PropTypes.func.isRequired
};

const AddDpsForm = (props) => {
    const { resetData, updateFormData, editDPSData, trfMasterList,
        putEditDPSData, isMRATOnline, selectedBarrierType, userRole,
        setCurrentAppView, sapFloc, unSavedChanges, dpsLockedFields, correctiveDPSMapping } = props;
    const formName = sapFloc;
    const isViewer = MRAT_USER_ROLE.View === userRole;
    const isEngineer = MRAT_USER_ROLE.Engineer === userRole;
    const selectedBarrierTypeIsM6 = selectedBarrierType === ('M6 F&G').toLowerCase();
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const FooterButtonVisible = isMRATOnline
        && !isViewer
        && (isEngineer || selectedBarrierType === ('M6 F&G').toLowerCase())
        && editDPSData.SapFloc
        && editDPSData.SapFloc.length > 0
        && editDPSData.TrfId
        && editDPSData.TrfId.length > 0
        && unSavedChanges;
    const readOnly = !(isMRATOnline && (isEngineer || selectedBarrierTypeIsM6)) || isViewer;
    const showRefreshButton = isMRATOnline && !isViewer && (isEngineer || selectedBarrierTypeIsM6);
    const [disableEditDPSSubmit, setDisableEditDPSSubmit] = useState(false);
    const SubmitButtonHandler = async () => {
        setDisableEditDPSSubmit(true);
        setTimeout(async () => {
            await putEditDPSData(userName);
            setCurrentAppView('dps');
        }, 500);
    };
    const UpdateFormDataHandler = (obj) => {
        const objWithPropertiesToBeUpdated = obj;
        if (obj.TrfId !== undefined) {
            const selectedTrfId = trfMasterList.filter(x => x.ApplicableRegions && obj.TrfId === x.TrfId)[0];
            const selectedCorrectiveTrfId = correctiveDPSMapping?.find(x => obj.TrfId === x.Trfid && x.Status);
            if (selectedTrfId) {
                objWithPropertiesToBeUpdated.TrfDesc = selectedTrfId.TrfShortDesc;
                objWithPropertiesToBeUpdated.CorrectiveTrfId = selectedCorrectiveTrfId ? selectedCorrectiveTrfId?.CorrectiveTrfid : '';
                objWithPropertiesToBeUpdated.CorrectiveTrfDesc = objWithPropertiesToBeUpdated.CorrectiveTrfId
                    ? trfMasterList.find(x => x.ApplicableRegions && obj.CorrectiveTrfId === x.TrfId).TrfShortDesc : '';
            } else {
                objWithPropertiesToBeUpdated.TrfDesc = '';
            }
        }
        if (obj.CorrectiveTrfId) {
            const correctiveTrfId = trfMasterList.filter(x => x.ApplicableRegions && obj.CorrectiveTrfId === x.TrfId)[0];
            objWithPropertiesToBeUpdated.CorrectiveTrfDesc = correctiveTrfId.TrfShortDesc;
        }
        objWithPropertiesToBeUpdated.CreatedBy = editDPSData.CreatedBy;
        objWithPropertiesToBeUpdated.CreatedDate = editDPSData.CreatedDate;
        updateFormData(objWithPropertiesToBeUpdated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => resetData(), []);
    return (
        <SiteWrapper
            headerText={
                (
                    <span style={{ textAlign: 'center' }}>
                        Edit Device Performance Standards for:
                        <br />
                        {formName}
                    </span>
                )
            }
            ButtonSection={showRefreshButton && <RefreshFormComponent buttonHandler={() => resetData()} />}
            footerButtonHandler={SubmitButtonHandler}
            footerButtonText="Submit"
            FooterButtonVisible={FooterButtonVisible}
            goBack="dps"
            isDisabled={disableEditDPSSubmit}
            displayUnsavedChangesPopUp
        >
            <DpsForm
                formId="EditDpsForm"
                isEditDPS
                formValues={editDPSData}
                trfMasterList={trfMasterList}
                formHandler={(obj) => UpdateFormDataHandler(obj)}
                readOnly={readOnly}
                dpsLockedFields={dpsLockedFields}
            />
        </SiteWrapper>
    );
};

const mapStateToProps = ({ AppData, DPS, Home }) => ({
    editDPSData: DPS.editDPSData,
    unSavedChanges: DPS.unSavedChanges,
    trfMasterList: Home.trfMasterList,
    isMRATOnline: AppData.isMRATOnline,
    selectedBarrierType: Home.selectedBarrierType,
    userRole: Home.userRole,
    sapFloc: DPS.sapFloc,
    dpsLockedFields: DPS.dpsLockedFields,
    correctiveDPSMapping: DPS.correctiveDPSMapping
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    resetData: () => dispatch(resetDataForEditDPS()),
    updateFormData: (obj) => dispatch(updateDataForEditDPS(obj)),
    putEditDPSData: (obj) => dispatch(PutEditDPSData(obj)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDpsForm);

AddDpsForm.propTypes = {
    correctiveDPSMapping: PropTypes.array,
    dpsLockedFields: PropTypes.array,
    editDPSData: PropTypes.object.isRequired,
    isMRATOnline: PropTypes.bool,
    putEditDPSData: PropTypes.func.isRequired,
    resetData: PropTypes.func,
    sapFloc: PropTypes.string,
    selectedBarrierType: PropTypes.string,
    setCurrentAppView: PropTypes.func,
    trfMasterList: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object]),
    unSavedChanges: PropTypes.bool,
    updateFormData: PropTypes.func,
    userRole: PropTypes.string,
};
