import { FieldHasValue } from 'helpers/Validation';

export const AsFoundResultFormula = (formData) => {
    if (!FieldHasValue(formData.F942AftSimulatedSandPresence) || !FieldHasValue(formData.F944AftObservedNoticeableSpike)) {
        return '';
    }
    if (FieldHasValue(formData.F942AftSimulatedSandPresence) === 'YES' && FieldHasValue(formData.F944AftObservedNoticeableSpike) === 'YES') {
        return 'PASS';
    }
    return 'FAIL';
};
export const isAftRawValuePeakReadingRequired = (formData) => {
    const isRawValueFilled = FieldHasValue(formData?.F941AftRawValuePeakReading);
    const isSandRateFilled = FieldHasValue(formData?.F943AftSandRatePeakReading);
    return (!isRawValueFilled && !isSandRateFilled) || (isRawValueFilled && isSandRateFilled) || (isRawValueFilled && !isSandRateFilled);
  };

  export const isAftSandRatePeakReadingRequired = (formData) => {
    const isRawValueFilled = FieldHasValue(formData?.F941AftRawValuePeakReading);
    const isSandRateFilled = FieldHasValue(formData?.F943AftSandRatePeakReading);
    return (!isRawValueFilled && !isSandRateFilled) || (isRawValueFilled && isSandRateFilled) || (!isRawValueFilled && isSandRateFilled);
  };
