import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { StyledLabel } from 'elements/Label/Label';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { FormInput } from 'elements/Inputs/FormInput';
import { StyleDropdown } from '../AsFoundTestTableSection/AsFoundTestSection.styled';
import { selectUnitsUsedSectionData } from './UnitsUsedSection.data';

const UnitsUsedSection = (props) => {
    const { changeHandler, formName, isFormReadOnly, userRole, formData } = props;
    const dropdownInput = (field) => field.isDropDown ? (
        <StyleDropdown
            formName={formName}
        >
            <FormDropdown
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                placeholder={field.placeholder}
                handleChange={changeHandler}
                options={field.options}
                isRequired={field.isRequired}
                width={field.width}
                inputTextAlign={field.inputTextAlign}
                labelWidth={field.labelWidth}
                direction={field.direction}
                readOnly={field.readOnly || isFormReadOnly}
                isDisabled={field.isDisabled}
                marginBottom={field.marginBottom}
                marginLeft={field.marginLeft}
                marginRight={field.marginRight}
                borderBottom={field.borderBottom}
                height={field.height}
                marginTop={field.marginTop}
                borderTop={field.borderTop}
                paddingPlaceholder={field.paddingPlaceholder}
                arrowMarginTop={field.arrowMarginTop}
                fontSize={field.fontSize}
                borderRight={field.borderRight}
                rootMarginRight={field.rootMarginRight}
                noDefaultOption={field.noDefaultOption}
                inputMarginRight={field.inputMarginRight}
                borderLeft={field.borderLeft}
                subMenu={field.subMenu}
            />
        </StyleDropdown>
    )
        : (
            <FormInput
                id={field.key}
                name={field.key}
                label={field.label}
                value={field.value}
                isRequired={field.isRequired}
                isCalculated={field.isCalculated}
                isCalculatedNew={field.isCalculatedNew}
                handleChange={changeHandler}
                readOnly={field.readOnly || isFormReadOnly}
                labelWidth={field.labelWidth}
                width={field.width}
                marginLeft={field.marginLeft}
                marginRight={field.marginRight}
                direction={field.direction}
                padding={field.padding}
                type={field.type}
                marginBottom={field.marginBottom}
                maxLength={field.maxLength}
                borderRight={field.borderRight}
                borderTop={field.borderTop}
                borderBottom={field.borderBottom}
                textAlign={field.textAlign}
                userRole={userRole}
                isDisabled={field.isDisabled}
                height={field.height}
                hasDPSError={field.hasDPSError}
                marginTop={field.marginTop}
                textAlignLabel={field.textAlignLabel}
                borderLeft={field.borderLeft}
                isLabelNew={field.isLabelNew}
                fontWeight={field.fontWeight}
                isLabelBold={field.isLabelBold}
            />
        );
    return (
        <div>
            {
                selectUnitsUsedSectionData(formName, formData).rows.map(row => (
                    <Grid item container xs={12} key={row.key}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isLabel
                                    ? (
                                        <StyledLabel
                                        id={field.key}
                                        label={field.label}
                                        color={field.color}
                                        fontWeight={field.fontWeight}
                                        fontSize={field.fontSize}
                                        textAlign={field.textAlign}
                                        fontStyle={field.fontStyle}
                                        labelWidth={field.labelWidth}
                                        marginLeft={field.marginLeft}
                                        marginTop={field.marginTop}
                                        padding={field.padding}
                                        >
                                            {field.label}
                                        </StyledLabel>
                                    )
                                    : dropdownInput(field)}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </div>
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnitsUsedSection);

UnitsUsedSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    userRole: PropTypes.string,
};
