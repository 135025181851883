import {
    AsLeftTableF78Deviation2Eu1Formula, AsLeftTableF79Deviation2Eu2Formula, AsLeftTableF80Deviation2Eu3Formula,
    AsLeftTableF63Formula2Inst1Formula, AsLeftTableF64Formula2Inst2Formula, AsLeftTableF65Formula2Inst3Formula
} from './P75.formulas';
import { AsLeftResultFormulaP75 } from '../../../TestResultSection/Data/P75/P75.formulas';
import { isEnabledcondition } from '../M611/M611.data';

export const asLeftTestTableSectionDataP75 = (selectedTag, formData) => {
    const disableField = formData.F62SensorTrimNeed === 'NO';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsLeftTest',
                    label: 'As Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'HMIINST',
                    label: '% HMI INST',
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: '5px 0 5px 8px',
                    fontSize: '9pt',
                    xs: 2
                },
                {
                    key: 'TestDeviceIndication',
                    label: 'Test Device Indication',
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth: '100%',
                    fontSize: '9pt',
                    xs: 2,
                    textAlign: 'center'
                },
                {
                    key: 'TXReading',
                    label: 'TX Reading',
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth: '100%',
                    fontSize: '9pt',
                    xs: 2,
                    textAlign: 'center'
                },
                {
                    key: 'HMIReading',
                    label: 'HMI Reading',
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth: '100%',
                    fontSize: '9pt',
                    xs: 2,
                    textAlign: 'center'
                },
                {
                    key: 'mAReading',
                    label: 'mA Reading',
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth: '100%',
                    fontSize: '9pt',
                    xs: 2,
                    textAlign: 'center'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation(EU)',
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth: '100%',
                    fontSize: '9pt',
                    xs: 2,
                    textAlign: 'center'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F63Formula2Inst1',
                    value: isEnabledcondition(AsLeftTableF63Formula2Inst1Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F66Rtd2SimulatorReading1',
                    value: isEnabledcondition(formData.F66Rtd2SimulatorReading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F69Tx2Reading1',
                    value: isEnabledcondition(formData.F69Tx2Reading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F72Hmi2Reading1',
                    value: isEnabledcondition(formData.F72Hmi2Reading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F75MA2Reading1',
                    value: isEnabledcondition(formData.F75MA2Reading1, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    borderLeft: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F78Deviation2Eu1',
                    value: isEnabledcondition(AsLeftTableF78Deviation2Eu1Formula(formData), disableField),
                    isDisabled: disableField,
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F64Formula2Inst2',
                    value: isEnabledcondition(AsLeftTableF64Formula2Inst2Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F67Rtd2SimulatorReading2',
                    value: isEnabledcondition(formData.F67Rtd2SimulatorReading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F70Tx2Reading2',
                    value: isEnabledcondition(formData.F70Tx2Reading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F73Hmi2Reading2',
                    value: isEnabledcondition(formData.F73Hmi2Reading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F76MA2Reading2',
                    value: isEnabledcondition(formData.F76MA2Reading2, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    borderLeft: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F79Deviation2Eu2',
                    value: isEnabledcondition(AsLeftTableF79Deviation2Eu2Formula(formData), disableField),
                    isDisabled: disableField,
                    textAlign: 'center',
                    xs: 2,
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F65Formula2Inst3',
                    value: isEnabledcondition(AsLeftTableF65Formula2Inst3Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F68Rtd2SimulatorReading3',
                    value: isEnabledcondition(formData.F68Rtd2SimulatorReading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F71Tx2Reading3',
                    value: isEnabledcondition(formData.F71Tx2Reading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F74Hmi2Reading3',
                    value: isEnabledcondition(formData.F74Hmi2Reading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F77MA2Reading3',
                    value: isEnabledcondition(formData.F77MA2Reading3, disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    xs: 2,
                    maxLength: '10'
                },
                {
                    key: 'F80Deviation2Eu3',
                    value: isEnabledcondition(AsLeftTableF80Deviation2Eu3Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 1,
                fields: [{
                    key: 'NoData2',
                    xs: 4,
                    isLabel: true,
                    padding: '5px 25px 18.5px 0'
                },
                {
                    key: 'AsLeftResultLabel',
                    value: 'As Left Result',
                    readOnly: true,
                    xs: 2,
                    isLabelBold: true,
                    textAlign: 'end',
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderRight: '0',
                    borderTop: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                },
                {
                    key: 'F81AsLeftResult',
                    value: AsLeftResultFormulaP75(formData),
                    readOnly: true,
                    isDisabled: disableField,
                    isCalculatedNew: true,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderTop: disableField ? '' : '0',
                    borderBottom: '0',
                    marginBottom: '4px',
                    textAlign: 'center'
                }]
            }]
    };
};
