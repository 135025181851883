export const VisualInspectSectionDataP723 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect damper tagging,  wiring connection/terminations, damper condition, pressure regulator setting and condition, 
                quarter turn indication position correct if applicable, bug screen orientation with no blockage, general deterioration and no leak sign`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]
});
