export const pFPlaceHolder = 'Select Pass or Fail or N/A';

export const TestResultSectionDataP77P65P831P652 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F244RadioactiveSourceShutterClosureTestLabel',
                    label: 'Radioactive source shutter closure test',
                    xs: 4,
                    isLabel: 'true',
                    labelWidth: '96%',
                    textAlign: 'left',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '5px 0 0 0'
                },
                {
                    key: 'F244RadioactiveSourceShutterClosureTest',
                    value: formData.F244RadioactiveSourceShutterClosureTest,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: pFPlaceHolder,
                    options: [{ name: pFPlaceHolder, value: '' },
                    { name: 'Pass', value: 'Pass' },
                    { name: 'Fail', value: 'Fail' },
                    { name: 'N/A', value: 'N/A' }],
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    fontSize: '8.5pt'
                },
                {
                    key: 'F245SteelPlateInsertionTestLabel',
                    label: 'Steel plate insertion test (i.e. to block radiation)',
                    xs: 4,
                    isLabel: 'true',
                    labelWidth: '98%',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '4px 0 0 0'
                },
                {
                    key: 'F245SteelPlateInsertionTest',
                    value: formData.F245SteelPlateInsertionTest,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: pFPlaceHolder,
                    options: [{ name: pFPlaceHolder, value: '' },
                    { name: 'Pass', value: 'Pass' },
                    { name: 'Fail', value: 'Fail' },
                    { name: 'N/A', value: 'N/A' }],
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    fontSize: '8.5pt'
                },
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F143HmireadingAfterTestLabel',
                    label: 'HMI Reading After Test (%)',
                    labelWidth: '96%',
                    xs: 4,
                    isLabel: 'true',
                    textAlign: 'left',
                    fontSize: '9pt',
                    fontWeight: '600',
                    padding: '0',
                },
                {
                    key: 'F143HmireadingAfterTest',
                    value: formData.F143HmireadingAfterTest,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '0',
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    borderTop: '0'
                },
                {
                    key: 'F246IndependentLevelReadingAfterTestLabel',
                    label: 'Field Instrument Reading After Test',
                    labelWidth: '98%',
                    xs: 4,
                    height: '1.2rem',
                    padding: '0',
                    isLabel: 'true',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600',
                    borderBottom: '0',
                    borderTop: '0',
                },
                {
                    key: 'F246IndependentLevelReadingAfterTest',
                    value: formData.F246IndependentLevelReadingAfterTest,
                    type: 'number',
                    maxLength: '10',
                    isRequired: true,
                    labelWidth: '0',
                    xs: 2,
                    height: '1.2rem',
                    padding: '0',
                    textAlign: 'center',
                    width: '100%',
                    borderTop: '0',
                    borderBottom: '0'
                }
            ]
        }]
}
);
