import { AsLeftResultFormulaM616 } from './M616.formulas';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsLeftTestTableSectionDataM616 = (selectedTag, formData) => {
    const dropdownPlaceholder2 = 'Select YES, NO or N/A';
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'As-LeftTest',
                label: 'As-Left Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F657AltActivatedNotificationAppliances',
                label: 'Activated Notification Appliances',
                value: formData.F102AftResult !== 'PASS' ? formData.F657AltActivatedNotificationAppliances : '',
                xs: 6,
                labelWidth: '51%',
                isDisabled: formData.F102AftResult === 'PASS',
                isDropdown: formData.F102AftResult !== 'PASS',
                isRequired: formData.F102AftResult !== 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                placeholder: dropdownPlaceholder,
                width: formData.F102AftResult !== 'PASS' ? '49%' : '47.5%',
                height: formData.F102AftResult !== 'PASS' ? '1.5rem' : '1.2rem',
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0',
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F658AltConfirmAudibleAlarmsFunctionCorrectly',
                label: 'Confirm Audible Alarms Function Correctly',
                value: formData.F102AftResult !== 'PASS' ? formData.F658AltConfirmAudibleAlarmsFunctionCorrectly : '',
                isDisabled: formData.F102AftResult === 'PASS',
                isDropdown: formData.F102AftResult !== 'PASS',
                isRequired: formData.F102AftResult !== 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                width: formData.F102AftResult !== 'PASS' ? '49%' : '47.5%',
                height: formData.F102AftResult !== 'PASS' ? '1.5rem' : '1.2rem',
                placeholder: dropdownPlaceholder2,
                options: [{ name: dropdownPlaceholder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                labelWidth: '51%',
                marginLeft: '8px',
                inputTextAlign: 'center',
                xs: 6,
                borderBottom: '0'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F659AltConfirmVisualAlarmsFunctionCorrectly',
                label: 'Confirm Visual Alarms Function Correctly',
                value: formData.F102AftResult !== 'PASS' ? formData.F659AltConfirmVisualAlarmsFunctionCorrectly : '',
                xs: 6,
                marginLeft: '8px',
                isDisabled: formData.F102AftResult === 'PASS',
                isDropdown: formData.F102AftResult !== 'PASS',
                isRequired: formData.F102AftResult !== 'PASS',
                readOnly: formData.F102AftResult === 'PASS',
                placeholder: dropdownPlaceholder2,
                options: [{ name: dropdownPlaceholder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                inputTextAlign: 'center',
                labelWidth: '51%',
                width: formData.F102AftResult !== 'PASS' ? '49%' : '47.5%',
                height: formData.F102AftResult !== 'PASS' ? '1.5rem' : '1.2rem',
                borderBottom: '0',
                marginBottom: '4px'
            },
            {
                key: 'F81AsLeftResult',
                label: 'As-Left Result',
                value: formData.F102AftResult !== 'PASS' ? AsLeftResultFormulaM616(formData) : '',
                isDisabled: formData.F102AftResult === 'PASS',
                readOnly: true,
                isCalculatedNew: true,
                textAlign: 'center',
                borderRight: '0',
                marginRight: '8px',
                direction: 'rtl',
                height: formData.F102AftResult !== 'PASS' ? '1.26rem' : '1.2rem',
                inputMarginRight: '4px',
                isLabelBold: true,
                fontWeight: '700',
                xs: 6,
                width: '50.2%',
                labelWidth: '49.8%',
                borderBottom: '0'
            }]
        }]
    };
};
