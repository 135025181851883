export const tagSelectionMoScreenInitialState = {
    selectedRowTags: [],
    selectedRowTagsTableData: [],
    searchedRowTagsTableData: [],
    totalCount: 0,
    flocSearchString: '',
    tagSelectionMOScreenFilters: { DeckHullBldg: [], Quad: [], Level: [], Room: [], Location2: [] },
    selectedTagSelectionMOScreenFilters: { DeckHullBldg: '', Quad: '', Level: '', Room: '', Location2: '' },
    testStatusFilter: 'All',
    clearFilters: true,
    sortHdLocationAscOrder: true
};
