import {
    FailureModeLowPassFailResult,
    FailureModeHighPassFailResult,
    isFailModeHighCurrentDisabled,
    isFailModeLowCurrentDisabled,
    isFailureModeHighCurrentRequired,
    isFailureModeLowCurrentRequired,
    isFaultAlarmDisplayedHcRequired,
    isFaultAlarmDisplayedLcRequired
} from './P722.formulas';
import { sraPlaceHolder } from '../../../SraDeviceSection/SraDeviceSection.data';
import { FailureMode } from 'journeys/portal/Forms/Sections.constants';
import { disableDpsFields, signalToTriggerIsErrorCheck } from '../../../SignalToTrigger/SignalToTrigger.data';
import { formulaCondition } from '../../../HMISection/Data/P711/P711.data';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

const disableConditionHighFailureMode = (formData, formName) => isFailModeHighCurrentDisabled(formData) || signalToTriggerIsErrorCheck(formName, formData);
const disableConditionLowFailureMode = (formData, formName) => isFailModeLowCurrentDisabled(formData) || signalToTriggerIsErrorCheck(formName, formData);
const failureModeLCurrentValue = (formData, selectedTag, formName) => formulaCondition(selectedTag, formName, formData) ? formData?.F36FailureModeLc
    : disableDpsFields(formData.F472SignalToTriggerLevelPriortoTest, selectedTag?.FailureModeLowCurrentMA, formData?.F36FailureModeLc, formName);
const failureModeHCurrentValue = (formData, selectedTag, formName) => formulaCondition(selectedTag, formName, formData) ? formData?.F33FailureModeHc
    : disableDpsFields(formData.F472SignalToTriggerLevelPriortoTest, selectedTag?.FailureModeHighCurrentMA, formData?.F33FailureModeHc, formName);

export const FailureModeSectionDataP722 = (selectedTag, formData, formName) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'remark',
                label: FailureMode.FAILURE_MODE_LABEL,
                isLabel: true,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '15px 20px 5px 0',
                fontSize: '8.5pt',
                color: '#ea7c34'
            },
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F36FailureModeLcLabel',
                    label: 'Failure Mode Low Current (mA)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F36FailureModeLc',
                    value: failureModeLCurrentValue(formData, selectedTag, formName),
                    isRequired: disableConditionLowFailureMode(formData, formName)
                        ? false : isFailureModeLowCurrentRequired(selectedTag, formData),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    isDisabled: disableConditionLowFailureMode(formData, formName),
                    readOnly: disableConditionLowFailureMode(formData, formName),
                },
                {
                    key: 'F33FailureModeHcLabel',
                    label: 'Failure Mode High Current (mA)',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F33FailureModeHc',
                    value: failureModeHCurrentValue(formData, selectedTag, formName),
                    xs: 2,
                    isRequired: (disableConditionHighFailureMode(formData, formName))
                        ? false : isFailureModeHighCurrentRequired(selectedTag, formData),
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    textAlign: 'center',
                    isDisabled: disableConditionHighFailureMode(formData, formName),
                    readOnly: disableConditionHighFailureMode(formData, formName),
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F814FaultAlarmHmi1Label',
                    label: 'Fault Alarm Displayed on HMI',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F814FaultAlarmHmi1',
                    value: signalToTriggerIsErrorCheck(formName, formData) ? '' : formData?.F814FaultAlarmHmi1,
                    isRequired: disableConditionLowFailureMode(formData, formName)
                        ? false : isFaultAlarmDisplayedLcRequired(selectedTag, formData),
                    isDropdown: disableConditionLowFailureMode(formData, formName)
                        ? false : isFaultAlarmDisplayedLcRequired(selectedTag, formData),
                    placeholder: sraPlaceHolder,
                    options: [{ name: sraPlaceHolder, value: '' },
                    { name: 'Yes', value: 'Yes' },
                    { name: 'No', value: 'No' }],
                    xs: 2,
                    borderTop: '0',
                    inputTextAlign: 'center',
                    width: '100%',
                    height: disableConditionLowFailureMode(formData) ? '1.25rem' : '1.5rem',
                    labelWidth: '0%',
                    textAlign: 'center',
                    isDisabled: disableConditionLowFailureMode(formData, formName),
                    readOnly: disableConditionLowFailureMode(formData, formName),
                },
                {
                    key: 'F815FaultAlarmHmi2Label',
                    label: 'Fault Alarm Displayed on HMI',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F815FaultAlarmHmi2',
                    value: !signalToTriggerIsErrorCheck(formName, formData) ? formData?.F815FaultAlarmHmi2 : '',
                    isRequired: (disableConditionHighFailureMode(formData, formName))
                        ? false : isFaultAlarmDisplayedHcRequired(selectedTag, formData),
                    isDropdown: (disableConditionHighFailureMode(formData, formName))
                        ? false : isFaultAlarmDisplayedHcRequired(selectedTag, formData),
                    placeholder: sraPlaceHolder,
                    options: [{ name: sraPlaceHolder, value: '' },
                    { name: 'Yes', value: 'Yes' },
                    { name: 'No', value: 'No' }],
                    xs: 2,
                    borderTop: '0',
                    inputTextAlign: 'center',
                    width: '100%',
                    height: disableConditionHighFailureMode(formData) ? '1.25rem' : '1.5rem',
                    labelWidth: '0%',
                    textAlign: 'center',
                    isDisabled: disableConditionHighFailureMode(formData, formName),
                    readOnly: disableConditionHighFailureMode(formData, formName),
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F35FormulaCalculatedPf1Label',
                    label: 'Failure Mode High Pass/Fail Result',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F35FormulaCalculatedPf1',
                    value: signalToTriggerIsErrorCheck(formName, formData) ? '' : FailureModeLowPassFailResult(formData),
                    isCalculatedNew: true,
                    readOnly: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    height: '1.2rem',
                    borderTop: '0',
                    textAlign: 'center',
                    isDisabled: disableConditionLowFailureMode(formData)
                },
                {
                    key: 'F38FormulaCalculatedPf2Label',
                    label: 'Failure Mode Low Pass/Fail Result',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F38FormulaCalculatedPf2',
                    value: signalToTriggerIsErrorCheck(formName, formData) ? '' : FailureModeHighPassFailResult(formData),
                    isCalculatedNew: true,
                    readOnly: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0%',
                    borderTop: '0',
                    height: '1.2rem',
                    textAlign: 'center',
                    isDisabled: disableConditionHighFailureMode(formData)
                },
            ]
        },
    ]
});
