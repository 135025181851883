import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormDropdown } from 'elements/Inputs/FormInput/Dropdown/Dropdown';
import { StyledLabel } from 'elements/Label/Label';
import { selectPreTestSectionData } from './Pre-Test.data';
import { StyleDropdown } from '../AsFoundTestWorkshop/AsFoundTestWorkshop.styled';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

const PreTestSection = (props) => {
    const { selectedTag, changeHandler, formName, formData, isFormReadOnly } = props;
    const classes = useStyles();
    const filterViewData = selectPreTestSectionData(selectedTag, formName, formData);
    return (
        <Grid container spacing={1} className={classes.root}>
            {
                filterViewData.rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ padding: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {
                                    field.isLabel
                                        ? (
                                            <StyledLabel
                                                id={field.key}
                                                fontWeight={field.fontWeight}
                                                textAlign={field.textAlign}
                                                labelWidth={field.labelWidth}
                                                padding={field.padding}
                                                fontSize={field.fontSize}
                                            >
                                                {field.label}
                                            </StyledLabel>
                                        )
                                        : (
                                            <StyleDropdown
                                                formName={formName}
                                            >
                                                <FormDropdown
                                                    id={field.key}
                                                    name={field.key}
                                                    label={field.label}
                                                    value={field.value}
                                                    placeholder={field.placeholder}
                                                    handleChange={changeHandler}
                                                    options={field.options}
                                                    isRequired={field.isRequired}
                                                    width={field.width}
                                                    labelWidth={field.labelWidth}
                                                    readOnly={isFormReadOnly}
                                                    direction={field.direction}
                                                    marginLeft={field.marginLeft}
                                                    marginTop={field.marginTop}
                                                    borderTop={field.borderTop}
                                                    inputTextAlign={field.inputTextAlign}
                                                    marginBottom={field.marginBottom}
                                                    borderBottom={field.borderBottom}
                                                    height={field.height}
                                                    paddingPlaceholder={field.paddingPlaceholder}
                                                    arrowMarginTop={field.arrowMarginTop}
                                                />
                                            </StyleDropdown>
                                        )
                                }
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};

const mapStateToProps = ({ Forms, Home, TestEquipment }) => ({
    selectedTag: Forms.selectedTag,
    testMakeModelData: TestEquipment.testEquipmentDataAll
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreTestSection);

PreTestSection.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
};
