import { AsFoundResultFormulaP732 } from './P732.formula';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataP732 = (selectedTag, formData) => ({
        rows: [{
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F591AftForcedTripSignal',
                label: 'Forced Trip Signal',
                value: formData.F591AftForcedTripSignal,
                xs: 6,
                isRequired: true,
                labelWidth: '51%',
                width: '49%',
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0',
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F592AftConfirmRelayOperatesCorrectly',
                label: 'Confirm Relay Operates Correctly',
                value: formData.F592AftConfirmRelayOperatesCorrectly,
                isRequired: true,
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                labelWidth: '51%',
                width: '49%',
                marginLeft: '8px',
                inputTextAlign: 'center',
                xs: 6,
                borderBottom: '0'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F593AftConfirmCorrectHmiIndicationOfSignal',
                label: 'Confirm Correct HMI Indication of Signal',
                value: formData.F593AftConfirmCorrectHmiIndicationOfSignal,
                xs: 6,
                marginLeft: '8px',
                isRequired: true,
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                inputTextAlign: 'center',
                labelWidth: '51%',
                width: '49%',
                borderBottom: '0',
                marginBottom: '4px'
            },
            {
                key: 'F102AftResult',
                label: 'As-Found Result',
                value: AsFoundResultFormulaP732(formData),
                readOnly: true,
                xs: 6,
                width: '50.2%',
                labelWidth: '49.8%',
                isCalculatedNew: true,
                textAlign: 'center',
                height: '1.25rem',
                borderRight: '0',
                marginRight: '8px',
                direction: 'rtl',
                inputMarginRight: '4px',
                isLabelBold: true,
                fontWeight: '700',
                borderBottom: '0',
            }]
        }]
    });
