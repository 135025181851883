import { FieldHasValue } from 'helpers/Validation';
import { commonConstants } from '../../Sections.constants';

export const disableAsLeftSection = (formData) => (formData.F61AsFoundResult === commonConstants.Pass || formData.F619AsLeftTestRequired === commonConstants.No);

export const test1Result = (formData) => {
    if (FieldHasValue(formData.F891AltControlValveFailSafePosition)
        && FieldHasValue(formData.F892AltSolenoidMovementUponTrip)
        && FieldHasValue(formData.F893AltControlValvePositionAfterTrip)) {
        if (formData.F892AltSolenoidMovementUponTrip === 'Quick'
            && (formData.F891AltControlValveFailSafePosition === formData.F893AltControlValvePositionAfterTrip)) {
            return commonConstants.Pass;
        }
        return commonConstants.Fail;
    }
    return '';
};

export const test4Result = (formData) => {
    if (FieldHasValue(formData.F896AltLimitSwitchSetting)
        && FieldHasValue(formData.F897AltControlValveMovedToLimit)
        && FieldHasValue(formData.F898AltLimitSwitchActivated)) {
        if (formData.F897AltControlValveMovedToLimit === commonConstants.Yes
            && formData.F898AltLimitSwitchActivated === commonConstants.Yes) {
            return commonConstants.Pass;
        }
        return commonConstants.Fail;
    }
    return '';
};
