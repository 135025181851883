import { FieldHasValue } from 'helpers/Validation';

export const AsLeftResultFormula = (formData) => {
    if (
        FieldHasValue(formData.F403AltJumperWireAppliedandShorted)
        && FieldHasValue(formData.F378AltHmistatus1)) {
        if (formData.F403AltJumperWireAppliedandShorted === 'YES' && formData.F378AltHmistatus1 === 'ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
