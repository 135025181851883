import styled from 'styled-components';

export const InformationPageWrapper = styled.div`
    height: inherit;
`;

export const PDFSelector = styled.div`
    flex-direction: column;
    position: absolute;
    width: 30%;
    float: left;
    margin-left: 11px;
    .MuiButtonBase-root {
        padding: 5px;
        margin: 5px;
    }
`;

export const PDFItem = styled.div`
    width: 30%;
    background-color: white;
    font-size: 12px;
    text-align: left;
    display: inline-flex;
    border-bottom-color: green;    
    border-bottom-width: 1.5px;
    border-bottom-style: solid;
`;

export const PDFViewer = styled.object`
    width: 80%;
    float: right;
    background-color: #ececec;
    border: 1px solid black;
    .react-pdf__Document {
        overflow: overlay;
    }
`;
export const SelectPdf = styled.div`
    font-weight: bold;
`;

export const PdfButton = styled.button`
   background-color: #498205 !important;
   color: white;
   font-weight: bold;
   padding: 3px 5px;
   border-radius: 6px;
   background-color: grey;

`;

export const PDFButton = styled.div`
position: fixed;
left: 55%;
bottom: 3.5%;
background-color: #498205;
z-index: 1;
 `;

export const PageViewer = styled.div`
      .react-pdf__Page__canvas {
        margin: 0 auto;
      }
 `;
