export const VisualInspectSectionDataM610 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Visually inspect the condition of the device:
                a.Inspect the enclosure mounting & cabling for mechanical damage, corrosion and general deterioration
                b.Inspect the sample pipes for damage, clip security & check for obstructed sampling points
                Record all faults found during the visual inspection`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]

});
