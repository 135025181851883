import styled from 'styled-components';
import { MRAT_USER_ROLE } from 'journeys/portal/Home/Home.constants';

export const disabledValue = 'rgb(230, 230, 230);';
export const calculatedValue = 'rgb(128, 128, 128);';
export const requiredValue = 'rgb(255, 255, 0);';
export const bgColorWithData = 'rgb(255, 229, 175);';
export const borderValue = 'border-width: 0;';
export const StyledInput = styled.input`
    font-size: ${props => props.inputFontSize ? props.inputFontSize : '9pt'};
    font-family: "Segoe UI";
    color: ${props => props.fontColor ? props.fontColor : 'rgb(51, 51, 51)'};
    font-weight: ${props => {
        if (props.isCalculatedNew || props.isCalculated || props.isDeleteLabel) {
            return 'bold;';
        }
        if (props.isLabelNew) {
            return '600;';
        }
        if (props.isLabelBold) {
            return '700;';
        }
        return 'normal;';
    }}
    font-style: normal;
    line-height: 1.2;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    width: ${props => props.width ? props.width : '63%'};
    min-width: ${props => props.minWidth ? props.minWidth : ''};
    max-width: ${props => props.inputMaxWidth ?? ''};
    background-color: ${props => {
        if (props.isDisabled) {
            return disabledValue;
        }
        if (props.hasDPSError || props.hasDPSNotNullError || props.hasHmiError || props.hasInstError || props.hasTestPointError
            || props.hasAftTestGasConcentrationError || props.hasAltTestGasConcentrationError) {
            return 'rgb(127,251,152);';
        }
        if (props.isRequired && (props.value || props.value === 0)) {
            return bgColorWithData;
        }
        if (props.isRequired) {
            return requiredValue;
        }
        if (props.isCalculated) {
            return calculatedValue;
        }
        if (props.isCalculatedNew) {
            return 'rgb(170, 170, 170);';
        }
        return 'rgb(255, 255, 255);';
    }}
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        ${props => (props.type === 'number' ? '-webkit-appearance: none; margin: 0;' : '')}
    }

    ${props => (props.type === 'number' ? '/* Firefox */ -moz-appearance: textfield;' : '')}

    border-style: solid;
    border-width: '2px;';
    border-color: ${props => {
        if (props.isDisabled) {
            return disabledValue;
        }
        if (props.isCalculated) {
            return calculatedValue;
        }
        if ((MRAT_USER_ROLE.Engineer === props.userRole && props.trfRowId) || (MRAT_USER_ROLE.View === props.userRole)) {
            if (props.isDisabled) {
                return disabledValue;
            }
            if (props.isCalculated) {
                return calculatedValue;
            }
            if (props.isCalculatedNew) {
                return 'rgb(170, 170, 170);';
            }
            if (props.isRequired) {
                return 'rgb(255, 229, 175);';
            }
            return 'rgb(255,255,255);';
        }
        return 'rgb(120, 120, 120);';
    }} 
    border-radius:0;
    -webkit-border-radius:0;
    -webkit-appearance:none;
    height: ${props => props.height ? props.height : '1rem'};
    resize:none;
    outline:0;
    overflow-y:auto;
    border-top: ${props => props.borderTop};
    border-bottom: ${props => props.borderBottom};
    border-right: ${props => props.borderRight};
    border-left: ${props => props.borderLeft};
    margin-right: ${props => props.inputMarginRight ? props.inputMarginRight : '0'};
    margin-left: ${props => props.inputMarginLeft ? props.inputMarginLeft : '0'};
    &:hover {
        ${props => !props.isCalculated && !props.isCalculatedNew && !props.isDisabled && !props.readOnly
        ? 'background-color: white;' : 'border-width: 2px;'}
    }
`;
export const InputWrapper = styled.div`
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    margin-bottom:  ${props => props.marginBottom};
    margin-top:  ${props => props.marginTop};
    display: flex;
    .label {
        font-weight: 600;
        font-size: 9pt;
        font-family: "Open Sans", sans-serif;
    }
`;

export const ViewWrapper = styled.div`
    font-family: "Segoe UI", "Open Sans", sans-serif;
    font-size: 9pt;
    color: rgb(51, 51, 51);
    font-weight: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.2;
`;

export const StyledTextArea = styled.textarea`
    font-size: 9pt;
    color: rgb(51, 51, 51);
    font-weight: normal;
    font-style: normal;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    line-height: 1.2;
    width:  ${props => props.inputWidth ? props.inputWidth : '64%'};
    background-color: ${props => {
        if (props.isRequired && (props.value || props.value === 0)) {
            return bgColorWithData;
        }
        if (props.isRequired) {
            return requiredValue;
        }
        if (props.isDisabled) {
            return 'rgb(230, 230, 230);';
        }
        return 'rgb(255, 255, 255);';
    }}
    border-style: solid;
    border-width: ${props => {
        if (props.readOnly) {
            return '0;';
        }
        return '2px;';
    }}
    border-color: rgb(120, 120, 120);
    border-radius:0;
    border-top: ${props => props.borderTop ? props.borderTop : ''};
    border-bottom: ${props => props.borderBottom ? props.borderBottom : ''};
    border-right: ${props => props.borderRight};
    border-left: ${props => props.borderLeft};
    -webkit-border-radius:0;
    -webkit-appearance:none;
    resize:none;
    outline:0;
    overflow-y:auto;
    margin:0;
    border-right: ${props => props.borderRight};
    margin-left:  ${props => props.marginLeft ? props.marginLeft : '0'};
    margin-right:  ${props => props.marginRight ? props.marginRight : '0'};
    
`;
export const StyledLabel = styled.div`
    color: ${props => props.color ? props.color : ''};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '600'};
    font-family: "Segoe UI";
    font-size: ${props => props.fontSize ? props.fontSize : '9pt'};
    width: ${props => props.labelWidth ? props.labelWidth : '6.3rem'};
    margin-right: ${props => props.marginRight ? props.marginRight : '0'};
    direction: ${props => props.direction ? props.direction : ''};
    margin-left:  ${props => props.marginLeft ? props.marginLeft : '0'};
    text-align: ${props => props.textAlignLabel};
  
`;
export const StyledSpan = styled.span`
    font-size: 9pt;
    color: ${props => props.passed ? 'green' : 'red'};
    font-weight: bold;
    font-style: normal;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    padding: 10px;
    padding-left:120px;
`;

export const StyledMultilineLabel = styled.div`
    padding: ${props => props.padding ? props.padding : '0'};
    font-weight: 600;
    font-size: 9pt;
    font-family: "Segoe UI";
    width: ${props => props.width ? props.width : '7.5rem'};
    margin-left: ${props => props.marginLeft ? props.marginLeft : '0'};
    margin-right: ${props => props.marginRight ? props.marginRight : '1rem'};
    direction: ${props => props.direction ? props.direction : ''};
`;
