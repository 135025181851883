import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const AsLeftResultFormula = (formData) => {
    if (
        FieldHasValue(formData.F300SwitchSetpoint)
        && FieldHasValue(formData.F184AltTestEquipmentIndication)
        && FieldHasValue(formData.F185AltHmireading)
        && FieldHasValue(formData.F101AftTolerance)) {
        if (ValidateNumberField(formData.F186AltDeviation)
            <= ValidateNumberField(formData.F101AftTolerance) && (formData.F185AltHmireading === 'ALARM')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
