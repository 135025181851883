import { Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import { APIEndpoints } from 'helpers/APILists';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import DropdownInput from '../../elements/DropdownInput';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import AutocompleteInput from '../../elements/AutocompleteInput';
import { trackErrors } from '../helper';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import ModalFooterActions from '../../elements/ModalFooterActions';

const DpsMappingForm = ({ isOpen, title, onClose, rowData, dropdownsData, onRecordSubmit }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [mratTrfFieldNameOptions, setMratTrfFieldNameOptions] = useState([]);
    const noOption = 'Please select a value for Form name to get the list of options..';
    const [noOptionText, setNoOptionText] = useState(noOption);
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const submitButtonVisible = !_.isEqual(rowData, createRowData) && createRowData.FormName
        && createRowData.FormHeading && createRowData.DpsFieldMaxLength
        && createRowData.Dpsfield && createRowData.DpsfieldDataType
        && createRowData.IsRequired !== '' && createRowData.VwName
        && createRowData.VwFieldName && createRowData.InputFieldTypeId
        && createRowData.UserInputTypeId && createRowData.Status
        && createRowData.MratTrfFieldNameForAgMrat && createRowData.MratTrfTableNameForAgMrat;

    useEffect(() => {
        if (createRowData.FormName) {
            const tableName = dropdownsData.mratTrfTableNameOptions
                .filter(x => x.TrfId === createRowData.FormName)
                .map(y => y.TableName)[0];
            setCreateRowData(prev => ({
                ...prev,
                MratTrfTableNameForAgMrat: tableName
            }));
            setNoOptionText('Fetching options.. Please wait!');
            axios.get(`${APIEndpoints.ADMIN_GetColumnNames}/${tableName}`)
                .then((res) => {
                    setMratTrfFieldNameOptions(res.data.sort());
                })
                .catch((err) => {
                    trackErrors({
                        messageType: MESSAGE_TYPE.ERROR,
                        message: err.message,
                        exception: err
                    });
                });
        } else {
            setCreateRowData(prev => ({
                ...prev,
                MratTrfTableNameForAgMrat: null
            }));
            setNoOptionText(noOption);
            setMratTrfFieldNameOptions([]);
        }
    }, [createRowData.FormName, dropdownsData.mratTrfTableNameOptions]);

    useEffect(() => {
        if (!_.isEqual(rowData, createRowData) && createRowData.DpsfieldDataType === 'Num') {
            setCreateRowData(prev => ({
                ...prev,
                DpsFieldMaxLength: 10
            }));
        } else {
            !_.isEqual(rowData, createRowData) && setCreateRowData(prev => ({
                ...prev,
                DpsFieldMaxLength: ''
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createRowData.DpsfieldDataType]);

    const handleInputChange = ((e) => {
        if (e.target.name === 'DpsFieldMaxLength' && (e.target.value < 1 || e.target.value > 255) && e.target.value !== '') {
            return;
        }
        setCreateRowData((prev) => (
            {
                ...prev,
                [e.target.name]: e.target.value
            }
        ));
    });

    const handleAutocompleteChange = ((e, val) => {
        let value = val || null;
        const name = e.target.id.split('-')[0];
        if (value && value.includes('Add "')) {
            value = value.substring(
                value.indexOf('"') + 1,
                value.lastIndexOf('"')
            );
        }
        setCreateRowData((prev) => (
            {
                ...prev,
                [name]: value
            }
        ));
        if (name === 'FormName') {
            setCreateRowData(prev => ({
                ...prev,
                MratTrfFieldNameForAgMrat: ''
            }));
            if (!value) {
                setMratTrfFieldNameOptions([]);
                setNoOptionText(noOption);
            }
        }
    });

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };
    const resetButtonHandler = () => {
        setCreateRowData(rowData);
    };
    const submitButtonHandler = () => {
        createRowData.InputFieldType = null;
        createRowData.UserInputType = null;
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.DpsMappingDetailId) {
            updateRecord(createRowData);
        } else {
            createRowData.DpsMappingDetailId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddDpsMappingRecord, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.DpsMappingDetailId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateDpsMappingRecord, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.DpsMappingDetailId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <AutocompleteInput
                        name="FormName"
                        title="Form Name (TRF ID)"
                        value={createRowData.FormName}
                        options={dropdownsData.formNameOptions}
                        handleInputChange={handleAutocompleteChange}
                        required
                    />
                    <AutocompleteInput
                        name="FormHeading"
                        title="Form Heading (UI Label)"
                        options={dropdownsData.formHeadingOptions}
                        value={createRowData.FormHeading}
                        handleInputChange={handleAutocompleteChange}
                        required
                        addNewOption
                    />
                    <AutocompleteInput
                        name="Dpsfield"
                        title="DPS Field"
                        options={dropdownsData.dpsFieldOptions}
                        value={createRowData.Dpsfield}
                        handleInputChange={handleAutocompleteChange}
                        required
                    />
                    <DropdownInput
                        name="DpsfieldDataType"
                        title="DPS Field Data Type"
                        value={createRowData.DpsfieldDataType}
                        handleInputChange={handleInputChange}
                        options={['Num', 'Text']}
                        required
                    />
                    <TextInput
                        name="DpsFieldMaxLength"
                        title="DPS Field Max Length"
                        value={createRowData.DpsFieldMaxLength}
                        handleInputChange={handleInputChange}
                        type="number"
                        required
                    />
                    <DropdownInput
                        name="VwName"
                        title="View Name"
                        value={createRowData.VwName}
                        handleInputChange={handleInputChange}
                        options={['vwSearchTag']}
                        required
                    />
                    <AutocompleteInput
                        name="VwFieldName"
                        title="View Field Name"
                        value={createRowData.VwFieldName}
                        options={dropdownsData.vwFieldNameOptions}
                        handleInputChange={handleAutocompleteChange}
                        required
                    />
                    <DropdownInput
                        name="InputFieldTypeId"
                        title="Input Field Type"
                        value={createRowData.InputFieldTypeId}
                        handleInputChange={handleInputChange}
                        options={dropdownsData.inputFieldTypeIdOptions}
                        required
                    />
                    <DropdownInput
                        name="UserInputTypeId"
                        title="User Input Type"
                        value={createRowData.UserInputTypeId}
                        handleInputChange={handleInputChange}
                        options={dropdownsData.userInputTypeIdOptions.filter(x => x.InputFieldTypeId === createRowData.InputFieldTypeId)}
                        required
                    />
                    <DropdownInput
                        name="IsRequired"
                        title="Is Required"
                        value={createRowData.IsRequired}
                        handleInputChange={handleInputChange}
                        options={[{ label: 'False', value: false }, { label: 'True', value: true }]}
                        required
                    />
                    <TextInput
                        name="MratTrfTableNameForAgMrat"
                        title="MRAT TRF Table Name For AG MRAT"
                        value={createRowData.MratTrfTableNameForAgMrat}
                        handleInputChange={handleInputChange}
                        disabled
                        required
                    />

                    <AutocompleteInput
                        name="MratTrfFieldNameForAgMrat"
                        title="MRAT TRF Field Name For AG MRAT"
                        noOptionText={noOptionText}
                        value={createRowData.MratTrfFieldNameForAgMrat}
                        handleInputChange={handleAutocompleteChange}
                        options={mratTrfFieldNameOptions}
                        required
                    />
                    <div style={{ gridColumnEnd: 5 }}>
                        <DropdownInput
                            name="Status"
                            title="Status"
                            value={createRowData.Status}
                            options={['Active', 'Inactive']}
                            handleInputChange={handleInputChange}
                            required
                        />
                    </div>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData.CreatedBy || userName?.name}
                        disabled
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData.CreatedDate || formatDate(new Date())}
                        disabled
                    />
                    <TextInput
                        name="LastModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData.LastModifiedDate || formatDate(new Date())}
                        disabled
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i>Note: If Form Name (TRF ID) is not available in the list please add a new record in the TRF Master List table.</i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i>Select Input Field Type first to get the list of User Input Type.</i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i>If Input Field Type is not available in the list please add a new record in the Input Field Type LKUP table.</i>
                    </InputLabel>
                    <InputLabel className={classes.inputLabelWithPadding}>
                        <i>If User Input Type is not available in the list please add a new record in the User Input Type LKUP table.</i>
                    </InputLabel>
                </div>
                <ModalFooterActions
                    submitButtonVisible={submitButtonVisible}
                    submitButtonHandler={submitButtonHandler}
                    resetButtonHandler={resetButtonHandler}
                    cancelButtonHandler={cancelButtonHandler}
                />
            </DialogActions>
        </Dialog>

    );
};

export default DpsMappingForm;

DpsMappingForm.propTypes = {
    dropdownsData: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
