export const DiagnosticsP728 = (formData) => ({
    rows: [
        {
            key: 3,
            fields: [{
                key: 'DIAGNOSTICSLabel',
                label: 'DIAGNOSTICS',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '15px 0 0 0'
            }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F120Diagnostics',
                    label: 'Retrieve Diagnostic Message Information:',
                    isMultiline: true,
                    value: formData.F120Diagnostics,
                    isRequired: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    alignItems: 'inherit'
                }]
        }
    ]

});
