import { savedTRFPrintScreenInitialState } from './SavedTRFPrintScreen.data';
import { TRF_ACTIONS } from './SavedTRFPrintScreen.constants';

export default function SavedTRFPrintScreenReducer(state = savedTRFPrintScreenInitialState, action) {
    switch (action.type) {
        case TRF_ACTIONS.GET_TRFRECORDS_PENDING:
        case TRF_ACTIONS.GET_TRFRECORDS_SUCCESS:
        case TRF_ACTIONS.GET_TRFRECORDS_FAILURE:
        case TRF_ACTIONS.GET_SEARCHTRFRECORDS_PENDING:
        case TRF_ACTIONS.GET_SEARCHTRFRECORDS_SUCCESS:
        case TRF_ACTIONS.GET_SEARCHTRFRECORDS_FAILURE:
        case TRF_ACTIONS.CLEAR_FILTERS:
        case TRF_ACTIONS.SET_FILTER_VALUE:
        case TRF_ACTIONS.MO_NUMBER_SORTORDER:
        case TRF_ACTIONS.TAG_FUNCTIONAL_LOCATION_SORTORDER:
        case TRF_ACTIONS.TAG_DATE_TESTED_SORTORDER:
        case TRF_ACTIONS.GET_SORTTAGRECORDS_PENDING:
        case TRF_ACTIONS.GET_SORTTAGRECORDS_SUCCESS:
        case TRF_ACTIONS.GET_SORTTAGRECORDS_FAILURE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
