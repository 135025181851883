import { FieldHasValue, ValidateField, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';

export const AsLeftTestTableHmiInst1Formula = (formData) => {
    let CalibrationPoint1;
    if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F25InstrumentLrv)) {
        CalibrationPoint1 = '0% - '.concat(ValidateField(formData.F21HmiLrv))
            .concat('% - ')
            .concat(ValidateField(formData.F25InstrumentLrv))
            .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        if (CalibrationPoint1.length > 255) {
            return CalibrationPoint1.slice(0, 255);
        }
        return CalibrationPoint1;
    }
    return '0%';
};

export const AsLeftTestTableHmiInst2Formula = (formData) => {
    let CalibrationPoint2;
    if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(formData.F26InstrumentUrv)) {
        CalibrationPoint2 = '50% - '.concat(ExponentialToDecimal((ValidateNumberField(formData.F21HmiLrv)
            + (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv)) * 0.5)))
            .concat('% - ')
            .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
                + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
            .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        if (CalibrationPoint2.length > 255) {
            return CalibrationPoint2.slice(0, 255);
        }
        return CalibrationPoint2;
    }
    return '50%';
};

export const AsLeftTestTableHmiInst3Formula = (formData) => {
    let CalibrationPoint3;
    if (FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F26InstrumentUrv)) {
        CalibrationPoint3 = '100% - '.concat(ValidateField(formData.F22HmiUrv))
            .concat('% - ')
            .concat(ValidateField(formData.F26InstrumentUrv))
            .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        if (CalibrationPoint3.length > 255) {
            return CalibrationPoint3.slice(0, 255);
        }
        return CalibrationPoint3;
    }
    return '100%';
};

export const AsLeftTestTableDeviationEu1Formula = (formData) => FieldHasValue(formData.F228AltIndependentReading1) && FieldHasValue(formData.F231AltTxreading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F231AltTxreading1) - ValidateNumberField(formData.F228AltIndependentReading1))) : '';

export const AsLeftTestTableDeviationEu2Formula = (formData) => FieldHasValue(formData.F229AltIndependentReading2) && FieldHasValue(formData.F232AltTxreading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F232AltTxreading2) - ValidateNumberField(formData.F229AltIndependentReading2))) : '';

export const AsLeftTestTableDeviationEu3Formula = (formData) => FieldHasValue(formData.F230AltIndependentReading3) && FieldHasValue(formData.F233AltTxreading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F233AltTxreading3) - ValidateNumberField(formData.F230AltIndependentReading3))) : '';

export const AsLeftResultFormulaP65 = (formData) => {
    if (FieldHasValue(formData.F101AftTolerance)
        && FieldHasValue(formData.F228AltIndependentReading1)
        && FieldHasValue(formData.F229AltIndependentReading2)
        && FieldHasValue(formData.F230AltIndependentReading3)
        && FieldHasValue(formData.F231AltTxreading1)
        && FieldHasValue(formData.F232AltTxreading2)
        && FieldHasValue(formData.F233AltTxreading3)
        && FieldHasValue(formData.F109AltHmireading1)
        && FieldHasValue(formData.F113AltHmireading2)
        && FieldHasValue(formData.F114AltHmireading3)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F237AltDeviation1),
        ValidateNumberField(formData.F238AltDeviation2), ValidateNumberField(formData.F239AltDeviation3)])
            <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
