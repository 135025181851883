import { Setpoint } from '../../../../Sections.constants';
import {
    setpointNote, paddingLabel, highHighSetpointValue, highSetpointValue, lowSetpointValue, lowLowSetpointValue, highHighSetpointComment, lowSetpointComment,
    lowLowSetpointComment, highSetPointComment, highHighSetpointCommentValue, highSetpointCommentValue, lowLowSetpointCommentValue, lowSetpointCommentValue,
    borderBottomHHDisable, borderBottomDisable, borderBottomLDisable
} from '../C11/C11.data';

export const SetpointSectionDataAnalyzerReading = (selectedTag, formData, formName) => {
    let HighHighSetpointLabel;
    let HighSetpointLabel;
    let LowLowSetpointLabel;
    let LowSetpointLabel;
    if (['P6-9', 'P8-6.1'].includes(formName)) {
        HighHighSetpointLabel = Setpoint.HIGH_HIGH_SETPOINT;
        HighSetpointLabel = Setpoint.HIGH_SETPOINT;
        LowLowSetpointLabel = Setpoint.LOW_LOW_SETPOINT;
        LowSetpointLabel = Setpoint.LOW_SETPOINT;
    } else {
        HighHighSetpointLabel = Setpoint.HIGH_HIGH_SETPOINT_EU;
        HighSetpointLabel = Setpoint.HIGH_SETPOINT_EU;
        LowLowSetpointLabel = Setpoint.LOW_LOW_SETPOINT_EU;
        LowSetpointLabel = Setpoint.LOW_SETPOINT_EU;
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'remark',
                    label: setpointNote,
                    isLabel: true,
                    xs: 12,
                    fontWeight: '600',
                    labelWidth: '99%',
                    padding: '5px 1px 10px 3px',
                    fontSize: '8.5pt',
                    color: '#ea7c34',
                    marginTop: '0'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F240HighHighSetpoint',
                        label: HighHighSetpointLabel,
                        value: highHighSetpointValue(selectedTag, formData),
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '50%',
                        width: '50%',
                        direction: 'rtl',
                        textAlign: 'center',
                        marginRight: '0.5rem',
                        marginLeft: '0.2rem',
                        borderBottom: borderBottomHHDisable(selectedTag, formData)
                    },
                    {
                        key: 'F657AnalyzerReading1',
                        label: Setpoint.ANALYZER_READING,
                        value: formData.F657AnalyzerReading1,
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '50%',
                        width: '50%',
                        marginRight: '0.5rem',
                        textAlign: 'center',
                        borderBottom: borderBottomHHDisable(selectedTag, formData)
                    },
                    {
                        key: 'F51HmiReading1',
                        label: Setpoint.HMI_READING,
                        value: formData.F51HmiReading1,
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '50%',
                        width: '50%',
                        marginRight: '0.5rem',
                        textAlign: 'center',
                        borderRight: '0',
                        borderBottom: borderBottomHHDisable(selectedTag, formData)
                    }]
            },
            highHighSetpointComment(selectedTag, formData) ? {
                key: 3,
                fields: [
                    {
                        key: 'F448HighHighSetpointComment',
                        label: Setpoint.HIGH_HIGH_SETPOINT_COMMENT,
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                    },
                    {
                        key: 'F448HighHighSetpointComment',
                        value: highHighSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: paddingLabel,
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 3,
                fields: []
            },
            {
                key: 4,
                fields: [{
                    key: 'F32HighSetpoint',
                    label: HighSetpointLabel,
                    value: highSetpointValue(selectedTag, formData),
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '50%',
                    direction: 'rtl',
                    width: '50%',
                    height: '1.2rem',
                    textAlign: 'center',
                    marginRight: '0.5rem',
                    marginLeft: '0.2rem',
                    borderBottom: borderBottomDisable(selectedTag, formData)
                },
                {
                    key: 'F660AnalyzerReading3',
                    label: Setpoint.ANALYZER_READING,
                    value: formData.F660AnalyzerReading3,
                    type: 'number',
                    maxLength: '10',
                    xs: 4,
                    labelWidth: '50%',
                    width: '50%',
                    textAlign: 'center',
                    height: '1.2rem',
                    marginRight: '0.5rem',
                    borderBottom: borderBottomDisable(selectedTag, formData)
                },
                {
                    key: 'F53HmiReading3',
                    label: Setpoint.HMI_READING,
                    value: formData.F53HmiReading3,
                    type: 'number',
                    maxLength: '10',
                    xs: 4,
                    labelWidth: '50%',
                    width: '50%',
                    textAlign: 'center',
                    height: '1.2rem',
                    marginRight: '0.5rem',
                    borderRight: '0',
                    borderBottom: borderBottomDisable(selectedTag, formData)
                }]
            },
            highSetPointComment(selectedTag, formData) ? {
                key: 5,
                fields: [
                    {
                        key: 'F796HighSetpointComment',
                        label: Setpoint.HIGH_SETPOINT_COMMENT,
                        xs: 2,
                        textAlign: 'left',
                        fontStyle: 'italic',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                    },
                    {
                        key: 'F796HighSetpointComment',
                        value: highSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: paddingLabel,
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 5,
                fields: []
            },
            {
                key: 6,
                fields: [{
                    key: 'F33LowSetpoint',
                    label: LowSetpointLabel,
                    value: lowSetpointValue(selectedTag, formData),
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    direction: 'rtl',
                    labelWidth: '50%',
                    width: '50%',
                    height: '1.2rem',
                    textAlign: 'center',
                    marginRight: '0.5rem',
                    marginLeft: '0.2rem',
                    borderBottom: borderBottomLDisable(selectedTag, formData),
                },
                {
                    key: 'F661AnalyzerReading4',
                    label: Setpoint.ANALYZER_READING,
                    value: formData.F661AnalyzerReading4,
                    type: 'number',
                    maxLength: '10',
                    textAlign: 'center',
                    xs: 4,
                    height: '1.2rem',
                    labelWidth: '50%',
                    width: '50%',
                    borderBottom: borderBottomLDisable(selectedTag, formData),
                    marginRight: '0.5rem',
                },
                {
                    key: 'F54HmiReading4',
                    label: Setpoint.HMI_READING,
                    value: formData.F54HmiReading4,
                    type: 'number',
                    maxLength: '10',
                    textAlign: 'center',
                    xs: 4,
                    height: '1.2rem',
                    labelWidth: '50%',
                    width: '50%',
                    marginRight: '0.5rem',
                    borderRight: '0',
                    borderBottom: borderBottomLDisable(selectedTag, formData),
                }]
            },
            lowSetpointComment(selectedTag, formData) ? {
                key: 7,
                fields: [
                    {
                        key: 'F797LowSetpointCommentLabel',
                        label: Setpoint.LOW_SETPOINT_COMMENT,
                        xs: 2,
                        textAlign: 'left',
                        padding: paddingLabel,
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                        fontStyle: 'italic'
                    },
                    {
                        key: 'F797LowSetpointComment',
                        value: lowSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: paddingLabel,
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 7,
                fields: []
            },
            {
                key: 8,
                fields: [
                    {
                        key: 'F241LowLowSetpoint',
                        label: LowLowSetpointLabel,
                        value: lowLowSetpointValue(selectedTag, formData),
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '50%',
                        width: '50%',
                        direction: 'rtl',
                        textAlign: 'center',
                        marginRight: '0.5rem',
                        marginLeft: '0.2rem',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                        marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px',
                    },
                    {
                        key: 'F658AnalyzerReading2',
                        label: Setpoint.ANALYZER_READING,
                        value: formData.F658AnalyzerReading2,
                        xs: 4,
                        type: 'number',
                        maxLength: '10',
                        labelWidth: '50%',
                        width: '50%',
                        marginRight: '0.5rem',
                        textAlign: 'center',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                        marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px',
                    },
                    {
                        key: 'F52HmiReading2',
                        label: Setpoint.HMI_READING,
                        value: formData.F52HmiReading2,
                        type: 'number',
                        maxLength: '10',
                        xs: 4,
                        labelWidth: '50%',
                        width: '50%',
                        marginRight: '0.5rem',
                        textAlign: 'center',
                        borderRight: '0',
                        borderTop: !lowSetpointComment(selectedTag, formData),
                        borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                        marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px',
                    }]
            },
            lowLowSetpointComment(selectedTag, formData) ? {
                key: 9,
                fields: [
                    {
                        key: 'F449LowLowSetpointCommentLabel',
                        label: Setpoint.LOW_LOW_SETPOINT_COMMENT,
                        xs: 2,
                        textAlign: 'left',
                        padding: '5px 0 3px 3px',
                        isLabel: 'true',
                        labelWidth: '97%',
                        fontSize: '9pt',
                        fontWeight: '600',
                        fontStyle: 'italic'
                    },
                    {
                        key: 'F449LowLowSetpointComment',
                        value: lowLowSetpointCommentValue(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: '5px 0 3px 3px',
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 9,
                fields: []
            }
        ]

    };
};
