import React from 'react';
import { connect } from 'react-redux';
import Form from '../../FormsTemplate';
import { FormNames } from '../../Forms.constants';
import UserInfoSection from '../../Sections/UserInfoSection/UserInfoSection';
import ManufacturerModelSection from '../../Sections/ManufacturerModelSection/ManufacturerModelSection';
import DeviceTypesSection from '../../Sections/DeviceTypesSection/DeviceTypesSection';
import HMISection from '../../Sections/HMISection/HMISection';
import VisualInspectSection from '../../Sections/VisualInspectSection/VisualInspectSection';
import TestResultSection from '../../Sections/TestResultSection/TestResultSection';
import AsLeftTestTableSection from '../../Sections/AsLeftTestTableSection/AsLeftTestTableSection';
import AsFoundTestTableSection from '../../Sections/AsFoundTestTableSection/AsFoundTestTableSection';
import NoteSection from '../../Sections/NoteSection/NoteSection';
import BorderedBox from '../../BorderedBox';
import ToleranceInstrumentEUComment from '../../Sections/ToleranceInstrumentEUComment/ToleranceInstrumentEUComment';

const P67Form = (props) => (
    <Form
        formName={FormNames.P67}
    >
        <UserInfoSection />
        <ManufacturerModelSection />
        <DeviceTypesSection />
        <HMISection />
        <VisualInspectSection />
        <BorderedBox>
            <AsFoundTestTableSection />
        </BorderedBox>
        <ToleranceInstrumentEUComment />
        <BorderedBox>
            <AsLeftTestTableSection />
        </BorderedBox>
        <TestResultSection />
        <NoteSection />
    </Form>
);

const mapStateToProps = ({ Forms }) => ({
    selectedTag: Forms.selectedTag,
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(P67Form);
