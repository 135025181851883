import { FieldHasValue, ValidateField, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';

export const isF24HmiEUHasValue = (formData) => FieldHasValue(formData.F24HmiEngineeringUnit) ? ' ' : '';
export const isF118InstEUHasValue = (formData) => FieldHasValue(formData.F118InstrumentEngineeringUnits) ? ' ' : '';

export const AsFoundTestTableF39HmiInst3Formula = (formData) => FieldHasValue(formData.F22HmiUrv) ? '0% - '.concat(ValidateField(formData.F21HmiLrv))
    .concat(isF24HmiEUHasValue(formData))
    .concat(ValidateField(formData.F24HmiEngineeringUnit))
    .concat(' - ')
    .concat(ValidateField(formData.F25InstrumentLrv))
    .concat(isF118InstEUHasValue(formData))
    .concat(ValidateField(formData.F118InstrumentEngineeringUnits)) : '0%';

export const AsFoundTestTableF41HmiInst1Formula = (formData) => (FieldHasValue(formData.F22HmiUrv) ? '100% - '.concat(ValidateField(formData.F22HmiUrv))
    .concat(isF24HmiEUHasValue(formData))
    .concat(ValidateField(formData.F24HmiEngineeringUnit))
    .concat(' - ')
    .concat(ValidateField(formData.F26InstrumentUrv))
    .concat(isF118InstEUHasValue(formData))
    .concat(ValidateField(formData.F118InstrumentEngineeringUnits)) : '100%');

export const AsFoundTestTableP871DeviationEu1Formula = (formData) => FieldHasValue(formData.F45TxReading1) && FieldHasValue(formData.F320AftRtdSimulatorReading1)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F45TxReading1) - ValidateNumberField(formData.F320AftRtdSimulatorReading1))) : '';
export const AsFoundTestTableP871DeviationEu2Formula = (formData) => FieldHasValue(formData.F46TxReading2) && FieldHasValue(formData.F321AftRtdSimulatorReading2)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F46TxReading2) - ValidateNumberField(formData.F321AftRtdSimulatorReading2))) : '';
export const AsFoundTestTableP871DeviationEu3Formula = (formData) => FieldHasValue(formData.F47TxReading3) && FieldHasValue(formData.F322AftRtdSimulatorReading3)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F47TxReading3) - ValidateNumberField(formData.F322AftRtdSimulatorReading3))) : '';
