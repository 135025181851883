import { AsFoundResultFormula, isAftRawValuePeakReadingRequired, isAftSandRatePeakReadingRequired } from './P66.formula';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';

export const paddingVal = '4px 0 0 8px';
export const AsFoundTestTableSectionDataP66 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsFoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'remark',
                label: 'Remark: Simulate presence of sand particles in the pipe and observe detector response on HMI. High spike must be observed on the trend (typically, raw value > 10,000).',
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '0px 1px 10px 9px',
                fontSize: '8.5pt',
                color: '#ea7c34'
            }]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F940AftSandPresenceSimulationMethodLabel',
                    label: 'Sand Presence Simulation Method',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F940AftSandPresenceSimulationMethod',
                    value: formData.F940AftSandPresenceSimulationMethod,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select Method',
                    options: [{ name: 'Select Method', value: '' },
                    { name: 'Wire Brush Rubbing', value: 'Wire Brush Rubbing' },
                    { name: 'Sand Injection', value: 'Sand Injection' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    height: '1.61rem'
                },
                {
                    key: 'F941AftRawValuePeakReadingLabel',
                    label: 'Raw Value Peak Reading',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    padding: paddingLabel,
                    fontSize: '9pt',
                    textAlign: 'right',
                },
                {
                    key: 'F941AftRawValuePeakReading',
                    value: formData.F941AftRawValuePeakReading,
                    isRequired: isAftRawValuePeakReadingRequired(formData),
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    fontWeight: '700',
                    height: '1.25rem',
                    type: 'number',
                    maxLength: '10'
                }]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F942AftSimulatedSandPresenceLabel',
                    label: 'Simulated Sand Presence',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F942AftSimulatedSandPresence',
                    value: formData.F942AftSimulatedSandPresence,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: Placeholder.SELECT_YES_OR_NO,
                    options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderTop: '0',
                    height: '1.5rem'
                },
                {
                    key: 'F943AftSandRatePeakReadingLabel',
                    label: 'Sand Rate Peak Reading (gram/sec)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    padding: paddingLabel,
                    fontSize: '9pt',
                    textAlign: 'right',
                },
                {
                    key: 'F943AftSandRatePeakReading',
                    value: formData.F943AftSandRatePeakReading,
                    isRequired: isAftSandRatePeakReadingRequired(formData),
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    fontWeight: '700',
                    borderTop: '0',
                    height: '1.26rem',
                    type: 'number',
                    maxLength: '10'
                }]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'F944AftObservedNoticeableSpikeLabel',
                    label: 'Observed Noticeable Spike',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F944AftObservedNoticeableSpike',
                    value: formData.F944AftObservedNoticeableSpike,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: Placeholder.SELECT_YES_OR_NO,
                    options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderTop: '0',
                    borderBottom: '0',
                    marginBottom: '4px',
                    height: '1.5rem'
                },
                {
                    key: 'F102AftResultLabel',
                    label: 'As-Found Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    padding: paddingLabel,
                    fontSize: '9pt',
                    textAlign: 'right',
                },
                {
                    key: 'F102AftResult',
                    value: AsFoundResultFormula(formData),
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0%',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    fontWeight: '700',
                    isLabelBold: true,
                    borderTop: '0',
                    borderBottom: '0',
                    height: '1.35rem',
                    marginBottom: '4px'
                }]
        },
    ]

});
