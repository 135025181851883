import { FoundResultF102AftResultFormula, FoundResultF62SensorTrimNeedFormula } from './P61.formulas';

export const FoundResultSectionDataP61 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F101AftToleranceLabel',
            value: 'Tolerance (EU)',
            xs: 2,
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            borderRight: '0',
            borderBottom: '0',
            marginLeft: '0.26rem',
            borderLeft: '0',
            isLabelNew: true,
            marginBottom: '4px',
            marginRight: '0'
        },
        {
            key: 'F101AftTolerance',
            value: selectedTag.DateTested ? formData?.F101AftTolerance : formData?.F101AftTolerance ?? selectedTag?.ToleranceInInstrumentEu,
            xs: 2,
            width: '100%',
            labelWidth: '0',
            type: 'number',
            maxLength: '10',
            textAlign: 'center',
            height: '1.1rem',
            borderBottom: '0',
            borderRight: '0',
            isRequired: true

        },
        {
            key: 'F102AftResultLabel',
            value: 'As-Found Result',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            xs: 2,
            borderRight: '0',
            borderBottom: '0',
            isLabelBold: true,
            textAlign: 'end'
        },
        {
            key: 'F102AftResult',
            value: FoundResultF102AftResultFormula(formData),
            readOnly: true,
            isCalculatedNew: true,
            xs: 2,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.1rem',
            borderRight: '0',
            borderBottom: '0'
        },
        {
            key: 'F62SensorTrimNeedLabel',
            value: 'Sensor Trim Needed?',
            width: '100%',
            readOnly: true,
            labelWidth: '0',
            height: '1.1rem',
            xs: 2,
            borderBottom: '0',
            borderRight: '0',
            isLabelBold: true,
            textAlign: 'end'
        },
        {
            key: 'F62SensorTrimNeed',
            value: FoundResultF62SensorTrimNeedFormula(formData),
            readOnly: true,
            isCalculatedNew: true,
            xs: 2,
            width: '100%',
            labelWidth: '0',
            textAlign: 'center',
            height: '1.1rem',
            inputMarginRight: '4px',
            borderBottom: '0',
            borderRight: '0'
        }]
    }]
});
