import { ExponentialToDecimal, FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const AsFoundTestTableDeviationFormula = (formData) => {
    if (FieldHasValue(formData.F653AftReferenceTestPoint) || FieldHasValue(formData.F356AftAnalyzerReading)) {
        if (FieldHasValue(formData.F653AftReferenceTestPoint) && FieldHasValue(formData.F356AftAnalyzerReading)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F653AftReferenceTestPoint) - ValidateNumberField(formData.F356AftAnalyzerReading)));
        }
        return '';
    }
    return '';
};

export const FoundResultF102AftResultFormula = (formData) => {
    if (FieldHasValue(formData.F653AftReferenceTestPoint)
        && FieldHasValue(formData.F356AftAnalyzerReading) && FieldHasValue(formData.F106AftHmireading1) && FieldHasValue(formData.F101AftTolerance)) {
        return (Math.max.apply(Math, [ValidateNumberField(formData.F215AftDeviation1)])
            <= ValidateNumberField(formData.F101AftTolerance)
            ? 'PASS' : 'FAIL');
    }
    return '';
};
