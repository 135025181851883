import styled from 'styled-components';

export const StyleInput = styled.div`
#F676AftTst1Result, #F708AltTst1Result{
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '39.4%' : props.width};
    }
   @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '39.5%' : props.width};
    }
}
#F677AftTst2Result, #F709AltTst2Result{
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '41.5%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '42.5%' : props.width};
    }
}
#F664AftTst1AutomaticActivationFireSuppression{
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '39.4%' : props.width};
    }
   @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '37.7%' : props.width};
    }
}
#F696AltTst1AutomaticActivationFireSuppression {
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '40.6%' : props.width};
    }
   @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '41.5%' : props.width};
    }
}
#F665AftTst2ManualActivationFireSuppression {
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '42.3%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '43.3%' : props.width};
    }
}
#F697AltTst2ManualActivationFireSuppression{
    @media only screen and (max-width: 960px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '40.3%' : props.width};
    }
    @media only screen and (min-width: 961px) {
        width: ${props => (props.formName === 'M7-1' && !props.isDisabled) ? '41.5%' : props.width};
    }
`;
