import { ValidateNumberField, FieldHasValue } from 'helpers/Validation';

export const ReCalibrationFormula = (formData) => {
    if (FieldHasValue(formData.F424AftAppliedTestGasConcentration)
        && FieldHasValue(formData.F433DetectorvaluewithTestGasAppliedAFT)
        && FieldHasValue(formData.F30ReCalibrationTolerance)) {
        if (Math.abs(ValidateNumberField(formData.F424AftAppliedTestGasConcentration) - ValidateNumberField(formData.F433DetectorvaluewithTestGasAppliedAFT))
            > ValidateNumberField(formData.F30ReCalibrationTolerance)) {
            return 'YES';
        }
        return 'NO';
    }
    return '';
};
export const AsFoundResultFormula = (formData) => {
    if (
        FieldHasValue(formData.F433DetectorvaluewithTestGasAppliedAFT)
        && FieldHasValue(formData.F372AftHmistatus1)
        && FieldHasValue(formData.F424AftAppliedTestGasConcentration)
        && FieldHasValue(formData.F31FailTolerance)) {
        if (Math.abs(ValidateNumberField(formData.F424AftAppliedTestGasConcentration) - ValidateNumberField(formData.F433DetectorvaluewithTestGasAppliedAFT))
            <= ValidateNumberField(formData.F31FailTolerance) && formData.F372AftHmistatus1 === 'ALARM') {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
