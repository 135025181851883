import { AsFoundResultFormulaM68 } from './M68.formulas';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const AsFoundTestTableSectionDataM68 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'ASFOUNDTEST',
                label: 'As-Found Test - Field',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F384AftConfirmThatExternalHeatAppliedFieldLabel',
                    label: 'Confirm that external heat has been applied to the detector in the field (any heat source such as hot air gun or magnet)',
                    xs: 4,
                    labelWidth: '98%',
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F384AftConfirmThatExternalHeatAppliedField',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'YES') ? formData.F384AftConfirmThatExternalHeatAppliedField : '',
                    isRequired: (formData.F383IsThereHazardousAreaRestriction !== 'YES'),
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'YES'),
                    readOnly: (formData.F383IsThereHazardousAreaRestriction === 'YES'),
                    isDropdown: (formData.F383IsThereHazardousAreaRestriction !== 'YES'),
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    borderBottom: '0',
                    labelWidth: '0%',
                    width: '100%',
                    height: '3.24rem',
                    inputTextAlign: 'center',
                    paddingRightPlaceholder: '15px',
                    xs: 2,
                    paddingPlaceholder: '13px',
                    arrowMarginTop: '13px'
                },
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F106AftHmireading1Label',
                    label: 'HMI Reading',
                    xs: 4,
                    padding: '0 0 0 8px',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F106AftHmireading1',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'YES') ? formData.F106AftHmireading1 : '',
                    isRequired: (formData.F383IsThereHazardousAreaRestriction !== 'YES'),
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'YES'),
                    readOnly: (formData.F383IsThereHazardousAreaRestriction === 'YES'),
                    isDropdown: (formData.F383IsThereHazardousAreaRestriction !== 'YES'),
                    height: (formData.F383IsThereHazardousAreaRestriction === 'YES') ? '1.38rem' : '1.5rem',
                    xs: 2,
                    borderBottom: '0',
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    placeholder: 'Select HMI Reading',
                    options: [{ name: 'Select HMI Reading', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                    marginBottom: '4px'
                },
                {
                    key: 'F102AftResultLabel',
                    label: 'As-Found Field Result',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F102AftResult',
                    value: (formData.F383IsThereHazardousAreaRestriction !== 'YES') ? AsFoundResultFormulaM68(formData) : '',
                    isDisabled: (formData.F383IsThereHazardousAreaRestriction === 'YES'),
                    isCalculatedNew: true,
                    xs: 2,
                    readOnly: true,
                    fontWeight: '700',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    labelWidth: '0',
                    width: '100%',
                    height: '1.25rem',
                    textAlign: 'center',
                    direction: 'rtl'
                },
            ]
        }
    ]
});
