import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledLabel } from 'elements/Label/Label';
import { FormInputViewOnly } from 'elements/Inputs/FormInput/FormInputViewOnly';

const InputControls = (props) => {
    const { field } = props;
    return (
        field.isLabel ? (
            <StyledLabel
                id={field.key}
                fontWeight={field.fontWeight}
                textAlign={field.textAlign}
                labelWidth={field.labelWidth}
                padding={field.padding}
                fontSize={field.fontSize}
                fontStyle={field.fontStyle}
                color={field.color}
            >
                {field.label}
            </StyledLabel>
        )
            : (
                <FormInputViewOnly
                    id={field.key}
                    name={field.key}
                    label={field.label}
                    value={field.value}
                    fontStyle={field.fontStyle}
                    labelFontStyle={field.labelFontStyle}
                    width={field.width}
                    height={field.height}
                    wordBreak={field.wordBreak}
                    labelWidth={field.labelWidth}
                    noWrap={field.noWrap}
                    field={field.padding}
                    padding={field.padding}
                    marginBottom={field.marginBottom}
                    marginTop={field.marginTop}
                    valueMarginTop={field.valueMarginTop}
                    textAlign={field.textAlign}
                    labelTextAlign={field.labelTextAlign}
                />
            )
    );
};

const mapStateToProps = ({ Forms, Home }) => ({
    selectedTag: Forms.selectedTag,
    dpsData: Forms.dpsData,
    userRole: Home.userRole
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputControls);

InputControls.propTypes = {
    field: PropTypes.object
};
