import {
    AFTCalibration2Points3Formula,
    AFTCalibration2Points2Formula,
    AFTCalibration2Points1Formula,
    AsFoundTestTableF54DeviationEu1Formula,
    AsFoundTestTableF55DeviationEu2Formula,
    AsFoundTestTableF56DeviationEu3Formula
} from './P710.formulas';
import { paddingAF } from '../../../AsLeftTestTableSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataP710andP68 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: 700,
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: '%-HMI-Inst.',
                label: '% - HMI - Inst.',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                labelWidth: '100%',
                padding: '5px 0 5px 8px',
                fontSize: '9pt'
            },
            {
                key: 'GaugeReading',
                label: 'Gauge Reading',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'TransmitterReading',
                label: 'Transmitter Reading',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                textAlign: 'center',
                labelWidth: '100%',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'HMIReading',
                label: 'HMI Reading',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'mAReading',
                label: 'mA Reading',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                labelWidth: '100%',
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Deviation',
                label: 'Deviation (EU)',
                isLabel: true,
                xs: 2,
                fontWeight: 700,
                textAlign: 'center',
                marginTop: '15px',
                padding: paddingAF,
                fontSize: '9pt'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F39HmiInst1',
                value: AFTCalibration2Points3Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F124AftGaugeReading1',
                value: formData.F124AftGaugeReading1,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F147AftTransmitterReading1',
                value: formData.F147AftTransmitterReading1,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F106AftHmireading1',
                value: formData.F106AftHmireading1,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F187AftMeasuredmAReading1',
                value: formData.F187AftMeasuredmAReading1,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
            },
            {
                key: 'F54DeviationEu1',
                value: AsFoundTestTableF54DeviationEu1Formula(formData),
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F40HmiInst2',
                value: AFTCalibration2Points2Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F125AftGaugeReading2',
                value: formData.F125AftGaugeReading2,
                isRequired: true,
                maxLength: '10',
                xs: 2,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F148AftTransmitterReading2',
                value: formData.F148AftTransmitterReading2,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F107AftHmireading2',
                value: formData.F107AftHmireading2,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0'
            },
            {
                key: 'F188AftMeasuredmAReading2',
                value: formData.F188AftMeasuredmAReading2,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
            },
            {
                key: 'F55DeviationEu2',
                value: AsFoundTestTableF55DeviationEu2Formula(formData),
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F41HmiInst3',
                value: AFTCalibration2Points1Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                marginLeft: '4px'
            },
            {
                key: 'F126AftGaugeReading3',
                value: formData.F126AftGaugeReading3,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F149AftTransmitterReading3',
                value: formData.F149AftTransmitterReading3,
                isRequired: true,
                xs: 2,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F108AftHmireading3',
                value: formData.F108AftHmireading3,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F189AftMeasuredmAReading3',
                value: formData.F189AftMeasuredmAReading3,
                isRequired: true,
                type: 'number',
                maxLength: '10',
                xs: 2,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F56DeviationEu3',
                value: AsFoundTestTableF56DeviationEu3Formula(formData),
                xs: 2,
                textAlign: 'center',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
            }]
        }
    ]
});
