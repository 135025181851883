import { ValidateField } from 'helpers/Validation';
import {
    AsLeftTableF63Hmi2Inst1Formula, AsLeftTableF64Hmi2Inst2Formula, AsLeftTableF65Hmi2Inst3Formula,
    AsLeftTableF78Deviation2Eu1Formula, AsLeftTableF79Deviation2Eu2Formula, AsLeftTableF80Deviation2Eu3Formula
} from './P71.formulas';
import { AsLeftResultFormula } from '../../../TestResultSection/Data/P71/P71.formulas';
import { isEnabledcondition } from '../M611/M611.data';

export const asLeftTestTableSectionData = (selectedTag, formData) => {
    const disableField = formData.F62SensorTrimNeed === 'NO';
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'AsLeftTest',
                label: 'As Left Test',
                isLabel: true,
                fontWeight: '700',
                padding: '8px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'HMIINST',
                label: '% HMI INST',
                isLabel: true,
                fontWeight: '700',
                padding: '5px 0 5px 8px',
                fontSize: '9pt',
                xs: 2
            },
            {
                key: 'GaugeReading',
                label: 'Gauge Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                fontSize: '9pt',
                textAlign: 'center'
            },
            {
                key: 'TXReading',
                label: 'TX Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                fontSize: '9pt',
                textAlign: 'center'
            },
            {
                key: 'HMIReading',
                label: 'HMI Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                fontSize: '9pt',
                textAlign: 'center'
            },
            {
                key: 'mAReading',
                label: 'mA Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                fontSize: '9pt',
                textAlign: 'center'
            },
            {
                key: 'Deviation',
                label: 'Deviation(EU)',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                fontSize: '9pt',
                textAlign: 'center'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F63Hmi2Inst1',
                xs: 2,
                value: isEnabledcondition(AsLeftTableF63Hmi2Inst1Formula(formData), disableField),
                isDisabled: disableField,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F66Gauge2Reading1',
                value: isEnabledcondition(ValidateField(formData.F66Gauge2Reading1), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F69Tx2Reading1',
                value: isEnabledcondition(ValidateField(formData.F69Tx2Reading1), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderLeft: '0',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F72Hmi2Reading1',
                value: isEnabledcondition(ValidateField(formData.F72Hmi2Reading1), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F75MA2Reading1',
                value: isEnabledcondition(ValidateField(formData.F75MA2Reading1), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                borderLeft: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F78Deviation2Eu1',
                value: isEnabledcondition(AsLeftTableF78Deviation2Eu1Formula(formData), disableField),
                isDisabled: disableField,
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F64Hmi2Inst2',
                xs: 2,
                value: isEnabledcondition(AsLeftTableF64Hmi2Inst2Formula(formData), disableField),
                isDisabled: disableField,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F67Gauge2Reading2',
                value: isEnabledcondition(ValidateField(formData.F67Gauge2Reading2), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F70Tx2Reading2',
                value: isEnabledcondition(ValidateField(formData.F70Tx2Reading2), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderLeft: '0',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F73Hmi2Reading2',
                value: isEnabledcondition(ValidateField(formData.F73Hmi2Reading2), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                width: '100%',
                labelWidth: '0',
                type: 'number',
                textAlign: 'center',
                height: '1.2rem',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F76MA2Reading2',
                value: isEnabledcondition(ValidateField(formData.F76MA2Reading2), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderLeft: '0',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F79Deviation2Eu2',
                value: isEnabledcondition(AsLeftTableF79Deviation2Eu2Formula(formData), disableField),
                isDisabled: disableField,
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F65Hmi2Inst3',
                xs: 2,
                value: isEnabledcondition(AsLeftTableF65Hmi2Inst3Formula(formData), disableField),
                isDisabled: disableField,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                marginLeft: '4px'
            },
            {
                key: 'F68Gauge2Reading3',
                value: isEnabledcondition(ValidateField(formData.F68Gauge2Reading3), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F71Tx2Reading3',
                value: isEnabledcondition(ValidateField(formData.F71Tx2Reading3), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F74Hmi2Reading3',
                value: isEnabledcondition(ValidateField(formData.F74Hmi2Reading3), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F77MA2Reading3',
                value: isEnabledcondition(ValidateField(formData.F77MA2Reading3), disableField),
                isRequired: !disableField,
                isDisabled: disableField,
                readOnly: disableField,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderLeft: '0',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F80Deviation2Eu3',
                value: isEnabledcondition(AsLeftTableF80Deviation2Eu3Formula(formData), disableField),
                isDisabled: disableField,
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'noData',
                isLabel: true,
                padding: '5px 25px 18.5px 0',
                xs: 4
            },
            {
                key: 'F81AsLeftResultLabel',
                value: 'As Left Result',
                readOnly: true,
                xs: 2,
                isLabelBold: true,
                textAlign: 'end',
                labelWidth: '0',
                width: '100%',
                height: '1.1rem',
                borderRight: '0',
                borderTop: '0',
                borderBottom: '0',
                borderLeft: '0'
            },
            {
                key: 'F81AsLeftResult',
                value: AsLeftResultFormula(formData),
                readOnly: true,
                isDisabled: disableField,
                isCalculatedNew: true,
                xs: 2,
                labelWidth: '0',
                width: '100%',
                height: '1.1rem',
                borderTop: disableField ? '' : '0',
                marginBottom: '4px',
                borderBottom: '0',
                textAlign: 'center',
            }]
        }]
    };
};
