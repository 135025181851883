export const getUserRole = (roleId) => {
    let userRole;
    switch (roleId) {
        case 1:
            userRole = 'Viewer';
            break;
        case 2:
            userRole = 'Technician';
            break;
        case 3:
            userRole = 'Engineer';
            break;
        case 4:
            userRole = 'Administrator';
            break;
        case 5:
            userRole = 'Admin Viewer';
            break;
        default:
            userRole = '';
    }
    return userRole;
};
