export const VisualInspectSectionDataM66 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F193VisuallyInspect',
                label: `Check condition of weather protection cover (Where Fitted) for signs of damage or excessive build-up of dirt.
                 Check orientation of detector weather protection cover to ensure flow direction indicator is pointing in the correct direction.`,
                value: formData.F193VisuallyInspect,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '600px',
                inputWidth: '57%',
                alignItems: 'inherit'
            }]
        }
    ]

});
