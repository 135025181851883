import { FoundResultF61AsFoundResultFormula, FoundResultF281AftCalibrationNeededFormula } from './P851.formulas';

export const FoundResultSectionDataP851 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F60ToleranceEu',
                    value: 'Tolerance (EU)',
                    xs: 2,
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    height: '1.1rem',
                    borderRight: '0',
                    borderBottom: '0',
                    marginLeft: '0.26rem',
                    borderLeft: '0',
                    isLabelNew: true,
                    marginBottom: '4px',
                    marginRight: '0'
                },
                {
                    key: 'F60ToleranceEu',
                    value: selectedTag.DateTested ? formData?.F60ToleranceEu : formData?.F60ToleranceEu ?? selectedTag?.ToleranceInInstrumentEu,
                    isRequired: true,
                    type: 'number',
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    maxLength: '10',
                    textAlign: 'center',
                    height: '1.1rem',
                    borderBottom: '0',
                    borderRight: '0'
                },
                {
                    key: 'F61AsFoundResult',
                    value: 'As-Found Result',
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    height: '1.1rem',
                    xs: 2,
                    borderRight: '0',
                    borderBottom: '0',
                    isLabelBold: true,
                    textAlign: 'end'
                },
                {
                    key: 'F61AsFoundResult',
                    value: FoundResultF61AsFoundResultFormula(formData),
                    readOnly: true,
                    isCalculatedNew: true,
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    textAlign: 'center',
                    height: '1.1rem',
                    borderRight: '0',
                    borderBottom: '0',
                },
                {
                    key: 'F281AftCalibrationNeededLabel',
                    value: 'Calibration Needed?',
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0',
                    height: '1.1rem',
                    xs: 2,
                    borderBottom: '0',
                    borderRight: '0',
                    isLabelBold: true,
                    textAlign: 'end',
                },
                {
                    key: 'F281AftCalibrationNeeded',
                    value: FoundResultF281AftCalibrationNeededFormula(formData),
                    readOnly: true,
                    isCalculatedNew: true,
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.1rem',
                    inputMarginRight: '4px',
                    borderBottom: '0',
                    borderRight: '0'
                }]
        }]
});
