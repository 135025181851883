import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import DataGridComponent from 'components/DataGrid/DataGridComponent';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import _ from 'lodash';
import React, { useState } from 'react';
import { trackErrors } from '../helper';
import {
    userInputTypeData, userInputTypeApiEndpoint,
    searchDataInitialState, userInputTypeInitialState
} from './UserInputType.data';

import UserInputTypeForm from './UserInputTypeForm';

const addTitle = 'Add New Record for User Input Type';
const editTitle = 'Edit Record for User Input Type';

const UserInputType = () => {
    const apiEndpoints = userInputTypeApiEndpoint;
    const [showModal, setShowModal] = useState(false);
    const [editRowData, setEditRowData] = useState(userInputTypeInitialState);
    const [modalTitle, setModalTitile] = useState(addTitle);
    const [refreshDatagrid, setRefreshDatagrid] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        columnName: 'UserInputTypeId',
        rowNumber: ''
    });
    const [dropdownsData, setDropdownsData] = useState({
        inputFieldTypeIdOptions: []
    });

    const parseData = (data) => new Promise((resolve, reject) => {
        (async () => {
            try {
                const inputFieldResponse = await axios.get(APIEndpoints.ADMIN_GetInputFieldTypes);
                const result = data.map((res) => ({
                    ...res,
                    InputFieldType: inputFieldResponse.data.find(x => x.InputFieldTypeId === res.InputFieldTypeId)?.Type
                }));
                setDropdownsData(prev => ({
                    ...prev,
                    inputFieldTypeIdOptions: _.sortBy(inputFieldResponse.data.filter(y => y.Status === 'Active').map(x => ({
                        label: x.Type,
                        value: x.InputFieldTypeId
                    })), 'label')
                }));
                resolve(result);
            } catch (err) {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
                reject();
            }
        })();
    });

    const handleEditRecord = (obj) => {
        const rowData = obj.row;
        setModalTitile(editTitle);
        setShowModal(true);
        setEditRowData((prev) => ({
            ...prev,
            ...rowData
        }));
    };
    const handleAddRecord = () => {
        setEditRowData(userInputTypeInitialState);
        setModalTitile(addTitle);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleRecordSubmit = (newId) => {
        setRowDetails(prev => ({ ...prev, rowNumber: newId }));
        setRefreshDatagrid(prev => !prev);
    };

    return (
        <>
            <DataGridComponent
                headerData={userInputTypeData}
                apiEndpoint={apiEndpoints.read}
                editRecord={handleEditRecord}
                addRecord={handleAddRecord}
                initialData={searchDataInitialState}
                rowDetails={rowDetails}
                refreshDatagrid={refreshDatagrid}
                dataParser={parseData}
            />

            {showModal
                && (
                    <UserInputTypeForm
                        isOpen={showModal}
                        onClose={handleModalClose}
                        title={modalTitle}
                        rowData={editRowData}
                        onRecordSubmit={handleRecordSubmit}
                        dropdownsData={dropdownsData}
                    />
                )}
        </>
    );
};

export default UserInputType;
