import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataM66 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'DetectorReinstatement',
                label: 'Detector reinstatement',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 0'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F382HmistatusAfterTestLabel',
                    label: 'HMI Status After Test',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F382HmistatusAfterTest',
                    isDropDown: true,
                    value: formData.F382HmistatusAfterTest,
                    isRequired: true,
                    placeholder: 'Select HMI Status',
                    options: [{ name: 'Select HMI Status', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'left'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F429DetectorCleanedFromDustLabel',
                    label: 'Detector cleaned from dust ',
                    xs: 4,
                    labelWidth: '100%',
                    padding: '0',
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F429DetectorCleanedFromDust',
                    value: formData.F429DetectorCleanedFromDust,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select YES or NO',
                    options: [{ name: 'Select YES or NO', value: '' },
                    { name: 'YES', value: 'YES' },
                    { name: 'NO', value: 'NO' }],
                    inputTextAlign: 'center',
                    xs: 2,
                    marginBottom: '15px',
                    width: '100%',
                    labelWidth: '0',
                    direction: 'rtl'
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    labelWidth,
                    paddingLabel,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right'
                },
                {
                    key: 'F85TestResult',

                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 4,
                    labelWidth: '0',
                    width: '100%'
                }
            ]
        }
    ]

});
