import { ValidateField } from 'helpers/Validation';

export const TestResultSectionData = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F82HmiReadingAt',
            label: 'HMI Reading after Test',
            value: ValidateField(formData.F82HmiReadingAt),
            isRequired: true,
            xs: 4,
            type: 'number',
            maxLength: '10',
            labelWidth: '50%',
            width: '50%',
            direction: 'rtl',
            textAlign: 'center',
            height: '1.2rem'
        },
        {
            key: 'noData',
            isLabel: true,
            xs: 1
        },
        {
            key: 'ProcessGaugeReadingatLabel',
            label: 'Process Gauge Reading after Test',
            xs: 3,
            isLabel: true,
            fontSize: '9pt',
            fontWeight: '600',
            labelWidth: '97.3%',
            textAlign: 'end',
            padding: '5px 0 0 0'
        },
        {
            key: 'F83ProcessGaugeReadingAt',
            value: ValidateField(formData.F83ProcessGaugeReadingAt),
            isRequired: true,
            type: 'number',
            maxLength: '10',
            xs: 4,
            labelWidth: '0',
            width: '100%',
            textAlign: 'center',
            height: '1.2rem',
            marginLeft: '0.8px'
        }]
    }]
});
