import {
    PassFailFormula1,
    PassFailFormula2,
    isFailModeHighCurrentDisabled,
    isActualFailHighRequired,
    isFailModeLowCurrentDisabled,
    isActualFailLowRequired,
    isFailureModeHighCurrentRequired,
    isFailureModeLowCurrentRequired
} from './C11.formulas';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { FailureMode } from 'journeys/portal/Forms/Sections.constants';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const HighLowCurrentSectionDataC11 = (selectedTag, formName, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'remark',
            label: FailureMode.FAILURE_MODE_LABEL,
            isLabel: true,
            fontWeight: '600',
            labelWidth: '100%',
            padding: '15px 20px 5px 0',
            fontSize: '8.5pt',
            color: '#ea7c34'
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F33FailureModeHcLabel',
            label: 'Failure Mode High Current (mA)',
            xs: 4,
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            padding: paddingLabel
        },
        {
            key: 'F33FailureModeHc',
            value: selectedTag.DateTested ? formData?.F33FailureModeHc : formData?.F33FailureModeHc ?? selectedTag?.FailureModeHighCurrentMA,
            isRequired: isFailModeHighCurrentDisabled(formData) ? false : isFailureModeHighCurrentRequired(selectedTag, formData),
            xs: 2,
            type: 'number',
            maxLength: '10',
            width: '100%',
            labelWidth: '0%',
            height: '1.2rem',
            textAlign: 'center',
            isDisabled: isFailModeHighCurrentDisabled(formData),
            readOnly: isFailModeHighCurrentDisabled(formData)
        },
        {
            key: 'F195ActualFailHighLabel',
            label: ['C1-1'].includes(formName) ? 'Actual Fail High (mA)' : 'Actual Fail High',
            isLabel: true,
            labelWidth,
            textAlign: 'right',
            fontWeight: '600',
            fontSize: '9pt',
            padding: paddingLabel,
            xs: 2,
        },
        {
            key: 'F195ActualFailHigh',
            value: formData.F195ActualFailHigh,
            isRequired: isFailModeHighCurrentDisabled(formData) ? false : isActualFailHighRequired(selectedTag, formData),
            xs: 2,
            type: 'number',
            maxLength: '10',
            width: '100%',
            labelWidth: '0%',
            height: '1.2rem',
            textAlign: 'center',
            isDisabled: isFailModeHighCurrentDisabled(formData),
            readOnly: isFailModeHighCurrentDisabled(formData)
        },
        {
            key: 'F35FormulaCalculatedPf1',
            label: PassFailFormula1(formData),
            value: PassFailFormula1(formData),
            xs: 2,
            width: '100%',
            labelWidth: '0%',
            height: '1.2rem',
            textAlign: 'center',
            fontSize: '16pt',
            borderLeft: '0',
            readOnly: true,
            isDisabled: isFailModeHighCurrentDisabled(formData),
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'F36FailureModeLcLabel',
            label: 'Failure Mode Low Current (mA)',
            xs: 4,
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            padding: paddingLabel
        },
        {
            key: 'F36FailureModeLc',
            value: selectedTag.DateTested ? formData?.F36FailureModeLc : formData?.F36FailureModeLc ?? selectedTag?.FailureModeLowCurrentMA,
            isRequired: isFailModeLowCurrentDisabled(formData) ? false : isFailureModeLowCurrentRequired(selectedTag, formData),
            xs: 2,
            type: 'number',
            maxLength: '10',
            width: '100%',
            labelWidth: '0%',
            height: '1.2rem',
            textAlign: 'center',
            borderTop: isFailModeHighCurrentDisabled(formData) ? '' : '0',
            isDisabled: isFailModeLowCurrentDisabled(formData),
            readOnly: isFailModeLowCurrentDisabled(formData),
        },
        {
            key: 'F196ActualFailLowLabel',
            label: ['C1-1'].includes(formName) ? 'Actual Fail Low (mA)' : 'Actual Low Low',
            isLabel: true,
            labelWidth,
            textAlign: 'right',
            fontWeight: '600',
            fontSize: '9pt',
            padding: paddingLabel,
            xs: 2,
        },
        {
            key: 'F196ActualFailLow',
            value: formData.F196ActualFailLow,
            isRequired: isFailModeLowCurrentDisabled(formData) ? false : isActualFailLowRequired(selectedTag, formData),
            xs: 2,
            type: 'number',
            maxLength: '10',
            width: '100%',
            labelWidth: '0%',
            height: '1.2rem',
            textAlign: 'center',
            borderTop: isFailModeHighCurrentDisabled(formData) ? '' : '0',
            isDisabled: isFailModeLowCurrentDisabled(formData),
            readOnly: isFailModeLowCurrentDisabled(formData),
        },
        {
            key: 'F38FormulaCalculatedPf2',
            label: PassFailFormula2(formData),
            value: PassFailFormula2(formData),
            xs: 2,
            width: '100%',
            labelWidth: '0%',
            height: '1.2rem',
            textAlign: 'center',
            fontSize: '16pt',
            borderLeft: '0',
            readOnly: true,
            borderTop: isFailModeHighCurrentDisabled(formData) ? '' : 0,
            isDisabled: isFailModeLowCurrentDisabled(formData),
        }]
    }]
});
