export const IsAddMandatory = (isMRATOnline, addTestEquipmentData) => isMRATOnline && addTestEquipmentData.DeviceType && addTestEquipmentData.DeviceType.length > 0
&& addTestEquipmentData.TestEqManufacturer && addTestEquipmentData.TestEqManufacturer.length > 0
&& addTestEquipmentData.TestEqModel && addTestEquipmentData.TestEqModel.length > 0
&& addTestEquipmentData.TestEqSn && addTestEquipmentData.TestEqSn.length > 0
&& addTestEquipmentData.CalDueDate;

export const IsEditMandatory = (isMRATOnline, editTestEquipmentData) => isMRATOnline
&& editTestEquipmentData.TestEqManufacturer && editTestEquipmentData.TestEqManufacturer.length > 0
&& editTestEquipmentData.TestEqModel && editTestEquipmentData.TestEqModel.length > 0
&& editTestEquipmentData.TestEqSn && editTestEquipmentData.TestEqSn.length > 0
&& editTestEquipmentData.CalDueDate;
