import { TestPointRange, conditionalValueSelector } from 'helpers/Validation';
import { TestPassFailFormula } from '../../../AsFoundTestTableSection/Data/M610/M610.formulas';
import { AsLeftResultMandatoryCheck, AsLeftResultFormula, setAltTestPoint } from './M610.formulas';
import { getAltDeleteIconStatus } from '../../AsLeftTestTableSection.helpers';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';
import { Label } from 'journeys/portal/Forms/Sections.constants';
import { notRequiredVal } from '../../../DetectorLenseSection/DetectorLense.data';

export const paddingValue = '0 0 0px 8px';
export const placeholderUnit = 'Select Unit';
export const AsLeftTestTableSectionDataM610 = (formData, altTestPoint) => {
    const rows = [];
    const isDisabled = formData.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal;
    const hideDeleteLabel = isDisabled || !getAltDeleteIconStatus();
    rows.push({
        key: 1,
        fields: [{
            key: 'AsLeftTest',
            label: 'As-Left Test (in field repair attempt)',
            isLabel: true,
            xs: 5,
            fontWeight: '700',
            padding: '8px 0 0 8px',
            borderTop: '0',
        },
        {
            key: 'F721NumberOfTestPoints',
            label: 'Number of Test Points',
            value: setAltTestPoint(formData, altTestPoint),
            isRequired: !isDisabled,
            isDisabled,
            readOnly: isDisabled || getAltDeleteIconStatus(),
            type: 'number',
            isBlur: true,
            maxLength: '10',
            xs: 3,
            labelWidth: '80.2%',
            width: '64%',
            height: '1.26rem',
            marginRight: '0.5rem',
            textAlign: 'center',
            marginTop: '4px',
            borderTop: '0',
            marginBottom: '10px',
            direction: 'rtl',
            hasTestPointError: !TestPointRange(formData?.F721NumberOfTestPoints, 8)
        }]
    },
        {
            key: 2,
            fields: [{
                key: 'AltDeleteLabel',
                value: conditionalValueSelector(hideDeleteLabel, '', 'Delete'),
                textAlign: 'center',
                fontColor: 'red',
                isDeleteLabel: getAltDeleteIconStatus(),
                width: '100%',
                labelWidth: '0',
                xs: 2,
                borderLeft: '0',
                borderRight: '0',
                borderBottom: '0',
                borderTop: '0',
                readOnly: true,
                marginLeft: '4px'
            },
            {
                key: 'AltSmokeConfirmation',
                label: Label.SMOKE_CONFIRMATION_LABEL,
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                padding: paddingValue,
                fontSize: '9pt',
                width: '100%'
            },
            {
                key: 'AltHmiStatus',
                label: 'HMI Status',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
                width: '100%'
            },
            {
                key: 'AltTransitTime',
                label: 'Record transit time (sec)',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
                width: '100%'
            },
            {
                key: 'AltResultReading',
                label: '',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                textAlign: 'center',
                fontSize: '9pt',
                padding: paddingValue,
                width: '100%'
            }]
        });
    if (TestPointRange(altTestPoint ?? formData?.F721NumberOfTestPoints, 8)) {
        for (let index = 2; index < Number(altTestPoint ?? formData?.F721NumberOfTestPoints) + 2; index += 1) {
            rows.push(
                {
                    key: 3 + index,
                    fields: [{
                        key: index,
                        xs: 2,
                        borderTop: '0',
                        borderBottom: '0',
                        borderLeft: '0',
                        borderRight: '0',
                        readOnly: true,
                        isDelete: conditionalValueSelector(isDisabled, false, getAltDeleteIconStatus()),
                        width: '100%'
                    },
                    {
                        key: `AltSmokeConfirmation${index}`,
                        value: conditionalValueSelector(isDisabled, '', formData[`AltSmokeConfirmation${index}`]),
                        isDisabled,
                        readOnly: isDisabled,
                        isDropdown: !isDisabled,
                        isRequired: !isDisabled,
                        placeholder: dropdownPlaceholder,
                        options: [
                            { name: dropdownPlaceholder, value: '' },
                            { name: 'YES', value: 'YES' },
                            { name: 'NO', value: 'NO' }],
                        inputTextAlign: 'center',
                        width: '100%',
                        labelWidth: '0',
                        height: conditionalValueSelector(isDisabled, '1.26rem', '1.5rem'),
                        borderRight: '0',
                        borderBottom: '0',
                        xs: 2
                    },
                    {
                        key: `AltHmiStatus${index}`,
                        value: conditionalValueSelector(isDisabled, '', formData[`AltHmiStatus${index}`]),
                        isDisabled,
                        readOnly: isDisabled,
                        isDropdown: !isDisabled,
                        isRequired: !isDisabled,
                        placeholder: 'Select HMI Status',
                        options: [{ name: 'Select HMI Status', value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'ALARM', value: 'ALARM' },
                        { name: 'FAULT', value: 'FAULT' }],
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: conditionalValueSelector(isDisabled, '1.26rem', '1.5rem'),
                        xs: 2,
                        maxLength: '10',
                        borderBottom: '0'
                    },
                    {
                        key: `AltRecordTransitTime${index}`,
                        value: conditionalValueSelector(isDisabled, '', formData[`AltRecordTransitTime${index}`]),
                        isDisabled,
                        readOnly: isDisabled,
                        type: 'number',
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.26rem',
                        xs: 2,
                        maxLength: '10',
                        borderBottom: '0',
                        borderRight: '0',
                        borderLeft: '0',
                    },
                    {
                        key: `AltResultReading${index}`,
                        value: TestPassFailFormula(formData[`AltSmokeConfirmation${index}`], formData[`AltHmiStatus${index}`]),
                        readOnly: true,
                        isDisabled,
                        isCalculatedNew: !isDisabled,
                        width: '100%',
                        labelWidth: '0',
                        textAlign: 'center',
                        height: '1.26rem',
                        xs: 2,
                        borderBottom: '0',
                        fontWeight: 700
                    }]
                });
        }
    }
    rows.push({
        key: 14,
        fields: [
            !isDisabled ? {
                key: 'NoData2',
                xs: 8,
                height: '0.5rem',
                value: '',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                borderBottom: '0',
                borderLeft: '0',
                marginLeft: '4px',
                borderRight: '0',
            } : {
                key: 'NoData2',
                xs: 8,
                isLabel: true,
                height: '1.1rem',
            },
            {
                key: 'AsLeftResultLabel',
                value: 'As-Left Result',
                xs: 2,
                textAlign: 'end',
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                height: '1.1rem',
                marginBottom: '4px',
                borderBottom: '0',
                borderTop: !isDisabled ? '' : '0',
                borderRight: !isDisabled ? '' : '0',
                borderLeft: '0',
                isLabelBold: true,
            },
            {
                key: 'F103AltResult',
                value: (!isDisabled && AsLeftResultMandatoryCheck(formData, altTestPoint))
                    ? AsLeftResultFormula(formData, altTestPoint) : '',
                isCalculatedNew: !isDisabled,
                isDisabled,
                readOnly: true,
                xs: 2,
                labelWidth: '0',
                textAlign: 'center',
                width: '100%',
                borderBottom: '0',
                borderLeft: '0',
                height: !isDisabled ? '1.1rem' : '1rem',
                borderRight: '0',
                inputMarginRight: '4px'
            }]
    });
    return { rows };
};
