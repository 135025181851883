import { ValidateNumberField, FieldHasValue, ExponentialToDecimal } from 'helpers/Validation';
import { commonConstants } from '../../Sections.constants';

export const performTest = (key) => key !== commonConstants.Discard;

export const calculateDeviation = (valveLocalPosition, valvePosition) => {
    if (FieldHasValue(valveLocalPosition)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(valveLocalPosition) - ValidateNumberField(valvePosition)));
    }
    return '';
};

export const test2Result = (formData) => {
    if (FieldHasValue(formData.F851AftValveLocalPosition1)
        && FieldHasValue(formData.F852AftValveLocalPosition2)
        && FieldHasValue(formData.F853AftValveLocalPosition3)
        && FieldHasValue(formData.F854AftValveLocalPosition4)
        && FieldHasValue(formData.F855AftValveLocalPosition5)
        && FieldHasValue(formData.F856AftValveLocalPosition6)
        && FieldHasValue(formData.F857AftValveLocalPosition7)
        && FieldHasValue(formData.F858AftValveLocalPosition8)
        && FieldHasValue(formData.F859AftValveLocalPosition9) && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F860AftTest2Deviation1), ValidateNumberField(formData.F861AftTest2Deviation2),
        ValidateNumberField(formData.F862AftTest2Deviation3), ValidateNumberField(formData.F863AftTest2Deviation4),
        ValidateNumberField(formData.F864AftTest2Deviation5), ValidateNumberField(formData.F865AftTest2Deviation6),
        ValidateNumberField(formData.F866AftTest2Deviation7), ValidateNumberField(formData.F867AftTest2Deviation8),
        ValidateNumberField(formData.F868AftTest2Deviation9)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return commonConstants.Pass;
        }
        return commonConstants.Fail;
    }
    return '';
};

export const test3Result = (formData) => {
    if (FieldHasValue(formData.F871AftHmiPositionIndication1)
        && FieldHasValue(formData.F872AftHmiPositionIndication2)
        && FieldHasValue(formData.F873AftHmiPositionIndication3)
        && FieldHasValue(formData.F874AftHmiPositionIndication4)
        && FieldHasValue(formData.F875AftHmiPositionIndication5)
        && FieldHasValue(formData.F876AftHmiPositionIndication6)
        && FieldHasValue(formData.F877AftHmiPositionIndication7)
        && FieldHasValue(formData.F878AftHmiPositionIndication8)
        && FieldHasValue(formData.F879AftHmiPositionIndication9) && FieldHasValue(formData.F101AftTolerance)) {
        if (Math.max.apply(Math, [ValidateNumberField(formData.F880AftTest3Deviation1), ValidateNumberField(formData.F881AftTest3Deviation2),
        ValidateNumberField(formData.F882AftTest3Deviation3), ValidateNumberField(formData.F883AftTest3Deviation4),
        ValidateNumberField(formData.F884AftTest3Deviation5), ValidateNumberField(formData.F885AftTest3Deviation6),
        ValidateNumberField(formData.F886AftTest3Deviation7), ValidateNumberField(formData.F887AftTest3Deviation8),
        ValidateNumberField(formData.F888AftTest3Deviation9)]) <= ValidateNumberField(formData.F101AftTolerance)) {
            return commonConstants.Pass;
        }
        return commonConstants.Fail;
    }
    return '';
};

export const asFoundTest1 = (formData) => {
    if (formData.F836IplelementSolenoid === commonConstants.No || !performTest(formData.F840AftTest1Solenoid)) {
        return true;
    }
    if (formData.F836IplelementSolenoid === commonConstants.Yes && performTest(formData.F840AftTest1Solenoid)) {
        return (formData.F844AftTest1Result === commonConstants.Pass);
    }
    return false;
};
export const asFoundTest2 = (formData) => {
    if (formData.F837IplelementIppositioner === commonConstants.No || !performTest(formData.F850AftTest2IpPositioner)) {
        return true;
    }
    if (formData.F837IplelementIppositioner === commonConstants.Yes && performTest(formData.F850AftTest2IpPositioner)) {
        return (formData.F869AftTest2Result === commonConstants.Pass);
    }
    return false;
};
export const asFoundTest3 = (formData) => {
    if (formData.F838IplelementPositionTransmitter === commonConstants.No || !performTest(formData.F870AftTest3PositionTransmitter)) {
        return true;
    }
    if (formData.F838IplelementPositionTransmitter === commonConstants.Yes && performTest(formData.F870AftTest3PositionTransmitter)) {
        return (formData.F889AftTest3Result === commonConstants.Pass);
    }
    return false;
};
export const asFoundTest4 = (formData) => {
    if (formData.F839IplelementLimitSwitch === commonConstants.No || !performTest(formData.F845AftTest4LimitSwitch)) {
        return true;
    }
    if (formData.F839IplelementLimitSwitch === commonConstants.Yes && performTest(formData.F845AftTest4LimitSwitch)) {
        return (formData.F849AftTest4Result === commonConstants.Pass);
    }
    return false;
};

export const asFoundResultFormula = (formData) => {
    if (((performTest(formData.F840AftTest1Solenoid) && FieldHasValue(formData.F844AftTest1Result)) || !performTest(formData.F840AftTest1Solenoid))
        && ((performTest(formData.F850AftTest2IpPositioner) && FieldHasValue(formData.F869AftTest2Result)) || !performTest(formData.F850AftTest2IpPositioner))
        && ((performTest(formData.F870AftTest3PositionTransmitter) && FieldHasValue(formData.F889AftTest3Result)) || !performTest(formData.F870AftTest3PositionTransmitter))
        && ((performTest(formData.F845AftTest4LimitSwitch) && FieldHasValue(formData.F849AftTest4Result)) || !performTest(formData.F845AftTest4LimitSwitch))
        && FieldHasValue(formData.F836IplelementSolenoid) && FieldHasValue(formData.F837IplelementIppositioner) && FieldHasValue(formData.F838IplelementPositionTransmitter) && FieldHasValue(formData.F839IplelementLimitSwitch)) {
        if (asFoundTest1(formData) && asFoundTest2(formData) && asFoundTest3(formData) && asFoundTest4(formData)) {
            return commonConstants.Pass;
        }
        return commonConstants.Fail;
    }
    return '';
};
