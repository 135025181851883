import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormInputMultiline } from 'elements/Inputs/FormInput/';
import { StyledLabel } from 'elements/Label/Label';
import { selectDiagnostics } from './Diagnostics.data';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    }
}));

const DiagnosticsData = (props) => {
    const { selectedTag, changeHandler, formData, formName, isFormReadOnly } = props;
    const classes = useStyles();
    const filterViewData = selectDiagnostics(selectedTag, formName, formData);
    return (
        <Grid container spacing={2} className={classes.root}>
            {
                filterViewData.rows.map(row => (
                    <Grid item container xs={12} key={row.key} style={{ paddingBottom: '0' }}>
                        {row.fields.map(field => (
                            <Grid item xs={field.xs} key={field.key}>
                                {field.isLabel
                                    ? (
                                        <StyledLabel
                                            id={field.key}
                                            fontWeight={field.fontWeight}
                                            textAlign={field.textAlign}
                                            labelWidth={field.labelWidth}
                                            padding={field.padding}
                                            marginLeft={field.marginLeft}
                                        >
                                            {field.label}
                                        </StyledLabel>
                                    )
                                    : (
                                        <FormInputMultiline
                                            rows={3}
                                            id={field.key}
                                            name={field.key}
                                            label={field.label}
                                            value={field.value}
                                            isRequired={field.isRequired}
                                            handleChange={changeHandler}
                                            width={field.width}
                                            marginLeft={field.marginLeft}
                                            readOnly={isFormReadOnly}
                                            inputWidth={field.inputWidth}
                                            alignItems={field.alignItems}
                                            padding={field.padding}
                                            marginTop={field.marginTop}
                                        />
                                    )}
                            </Grid>
                        )
                        )}
                    </Grid>
                ))
            }
        </Grid>
    );
};
const mapStateToProps = ({ Forms }) => ({
    selectedTag: Forms.selectedTag,
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DiagnosticsData);

DiagnosticsData.propTypes = {
    changeHandler: PropTypes.func,
    formData: PropTypes.object,
    formName: PropTypes.string,
    isFormReadOnly: PropTypes.bool,
    selectedTag: PropTypes.object,
};
