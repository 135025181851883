import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const FoundResultAsFoundResultFormulaM68 = (formData) => {
    if (FieldHasValue(formData.F386AftMeasureDetectorMaVOutputWithMultimeter)
        && FieldHasValue(formData.F387AftRecordConfirmedFireMaVOutputLevel)) {
        if (FieldHasValue(formData.F385AftConfirmThatExternalHeatAppliedWorkshop === 'YES')
            && Math.max.apply(Math, [ValidateNumberField(formData.F386AftMeasureDetectorMaVOutputWithMultimeter)])
            >= ValidateNumberField(formData.F387AftRecordConfirmedFireMaVOutputLevel)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
