import { FormNames } from 'journeys/portal/Forms/Forms.constants';
import { strEquals } from 'journeys/portal/Admin/AdminScreen.helper';
import { paddingLabel } from '../HMISection/Data/C11/C11.data';

export const disableDpsFields = (signalToTriggerValue, dpsValue, formValue, formName) => {
    if (strEquals(FormNames.P730, formName) && (signalToTriggerValue === 'Error')) {
        return '';
    }
    return formValue ?? dpsValue;
};

export const signalToTriggerIsErrorCheck = (formName, key) => {
    if (strEquals(FormNames.P730, formName)) {
        return (key?.F472SignalToTriggerLevelPriortoTest === 'Error');
    }
    return false;
};

export const SignalToTriggerP730 = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'remark',
                label: 'Remark: If applicable, read and determine if Signal-to-Trigger (Sig/Tr) level is in a healthy or error state under normal flowing conditions.',
                isLabel: true,
                fontWeight: '600',
                labelWidth: '100%',
                padding: '15px 20px 5px 0',
                fontSize: '8.5pt',
                color: '#ea7c34'
            },
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F472SignalToTriggerLevelPriortoTestLabel',
                    label: 'Signal-to-Trigger (Sig/Tr) Level Prior to Test',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel
                },
                {
                    key: 'F472SignalToTriggerLevelPriortoTest',
                    value: formData.F472SignalToTriggerLevelPriortoTest,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: 'Select from list',
                    options: [{ name: 'Select from list', value: '' },
                    { name: 'N/A', value: 'N/A' },
                    { name: 'Healthy', value: 'Healthy' },
                    { name: 'Error', value: 'Error' }],
                    xs: 2,
                    borderTop: '0',
                    inputTextAlign: 'center',
                    width: '100%',
                    labelWidth: '0%',
                    textAlign: 'center',
                },
            ]
        }
    ]
});

export const selectSignalToTrigger = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'P7-30') {
        filterViewData = SignalToTriggerP730(selectedTag, formName, formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
