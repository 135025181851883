import { conditionalValueSelector } from 'helpers/Validation';
import { paddingValue } from '../../../AsFoundTestTableSection/Data/P720/P720.data';
import { SafetyFunctionDescription, SafetyFunctionDescriptionP623 } from './P6142.formula';
import { commonConstants, controlValveToLouverDamper2 } from 'journeys/portal/Forms/Sections.constants';

const dropdowns = [
    { key: 'F836IplelementSolenoid', label: '1. Solenoid' },
    { key: 'F837IplelementIppositioner', label: '2. I/P Positioner' },
    { key: 'F838IplelementPositionTransmitter', label: '3. Position Transmitter' },
    { key: 'F839IplelementLimitSwitch', label: '4. Limit Switch' }
];

const validateDropdowns = (formData, key) => {
    const noCount = dropdowns.reduce((count, dropdown) => formData[dropdown.key] === commonConstants.No ? count + 1 : count, 0);
    if (noCount === 3) {
        return dropdowns.find(dropdown => formData[dropdown.key] !== commonConstants.No).key === key;
    }
    return null;
};

const dropdownPlaceholder = 'Select Yes/No';
export const IPLDataP6142 = (formName, formData) => {
    const fields = dropdowns.map((dropdown, index) => {
        const { key, label } = dropdown;
        const isDropdown = !validateDropdowns(formData, key);
        const isReadOnly = validateDropdowns(formData, key);
        const value = conditionalValueSelector(isReadOnly, commonConstants.Yes, formData[key]);
        const commonProps = {
            key,
            value,
            isRequired: true,
            xs: 2,
            labelWidth: '0%',
            textAlign: 'center',
            width: '100%',
            borderTop: conditionalValueSelector(index === 0, undefined, '0'),
            height: conditionalValueSelector(isDropdown, '1.5rem', '1.25rem'),
        };

        const dropdownProps = {
            ...commonProps,
            isDropdown: true,
            placeholder: dropdownPlaceholder,
            readOnly: isReadOnly,
            options: [
                { name: dropdownPlaceholder, value: '' },
                { name: commonConstants.Yes, value: commonConstants.Yes },
                { name: commonConstants.No, value: commonConstants.No }
            ],
            inputTextAlign: 'center',
            labelWidth: '0%',
            marginTop: conditionalValueSelector(index === 0, '10px', '0px'),
            borderBottom: conditionalValueSelector(index === (dropdowns.length - 1), '0', undefined),
            textAlign: 'center',
        };

        const readOnlyProps = {
            ...commonProps,
            readOnly: true,
            borderBottom: conditionalValueSelector(index === (dropdowns.length - 1), '0', undefined),
            labelWidth: '0%',
            width: '100%',
            marginTop: conditionalValueSelector(index === 0, '10px', '0px'),
        };

        return {
            key: index + 3,
            fields: [
                {
                    key: `${key}Label`,
                    value: label,
                    xs: 4,
                    labelWidth: '0%',
                    textAlign: 'left',
                    width: '100%',
                    borderRight: '0',
                    borderBottom: '0',
                    isLabelNew: true,
                    marginBottom: '4px',
                    marginTop: conditionalValueSelector(index === 0, '10px', '0'),
                    borderTop: '0',
                    borderLeft: '0',
                    fontWeight: '600',
                    readOnly: true,
                    marginLeft: '5px'
                },
                conditionalValueSelector(isDropdown, dropdownProps, readOnlyProps),
                {
                    key: `${key}Description`,
                    label: conditionalValueSelector(['P6-23.2'].includes(formName), SafetyFunctionDescriptionP623(formData, key), SafetyFunctionDescription(formData, key)),
                    xs: 5,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    marginTop: conditionalValueSelector(index === 0, '10px', '0'),
                    padding: conditionalValueSelector(index === 0, '15px 25px 0 25px', '5px 25px 0 25px')
                }
            ]
        };
    });

    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'IPLElements',
                    label: 'IPL Elements',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 5px 4px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'remark',
                    label: `Remark: Select which components of the ${controlValveToLouverDamper2(formName)} act as IPL element.
                     Note that test results of only IPL elements will impact the "As-Found Result" / "As-Left Result".`,
                    isLabel: true,
                    xs: 12,
                    fontWeight: '600',
                    labelWidth: '100%',
                    padding: '0 0 10px 4px',
                    fontSize: '8.5pt',
                    color: '#ea7c34'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'IPLComponent',
                    label: 'Component',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 4,
                    textAlign: 'left',
                    padding: paddingValue,
                    fontSize: '9pt'
                },
                {
                    key: 'IPLElement',
                    label: 'IPL Element',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    padding: paddingValue,
                    fontSize: '9pt'
                },
                {
                    key: 'SafetyFunctionDescription',
                    label: 'Safety Function Description',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 4,
                    textAlign: 'center',
                    padding: paddingValue,
                    fontSize: '9pt'
                }]
            },
            ...fields
        ]
    };
};
