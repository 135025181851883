import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { formatDate } from 'helpers/DateFormatter';
import { APIEndpoints } from 'helpers/APILists';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { trackErrors } from '../helper';
import DropdownInput from '../../elements/DropdownInput';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { strEquals } from '../../AdminScreen.helper';
import ModalFooterActions from '../../elements/ModalFooterActions';

const DpsSourcesForm = ({ isOpen, title, onClose, rowData, onRecordSubmit }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [newDpsSourceId, setDpsSourceId] = useState(rowData);
    const [dpsSourcesList, setDpsSourcesList] = useState([]);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [dpsSourcesExistError, setDpsSourcesExistError] = useState('');
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const submitButtonVisible = !_.isEqual(rowData, createRowData) && dpsSourcesExistError === ''
        && createRowData.DpssourceName;

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetDPSDataSources)
            .then((res) => {
                setDpsSourcesList(res.data.map(dpsSourceData => ({ DpssourceId: dpsSourceData.DpssourceId, DpssourceName: dpsSourceData.DpssourceName })));
                const maxDpsSourceId = _.maxBy(res.data, 'DpssourceId')?.DpssourceId || 0;
                setDpsSourceId((prev) => ({
                    ...prev,
                    DpssourceId: maxDpsSourceId + 1,
                }));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleInputChange = (e) => {
        setCreateRowData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleBlur = () => {
        setDpsSourcesExistError('');
        if (dpsSourcesList.find(x => strEquals(x.DpssourceName, createRowData.DpssourceName) && x.DpssourceId !== createRowData.DpssourceId)) {
            setDpsSourcesExistError(' *(DPS Source Name already exists)');
        }
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setCreateRowData(rowData);
    };

    const submitButtonHandler = () => {
        createRowData.Status = createRowData.Status === 'Active';
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());

        if (createRowData.DpssourceId) {
            updateRecord(createRowData);
        } else {
            createRowData.DpssourceId = newDpsSourceId.DpssourceId;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddDPSDataSources, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.DpssourceId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateDPSDataSources, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.DpssourceId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" className={classes.header}>
                {title}
            </DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={handleUnsaveChanges}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="DpssourceName"
                        title="DPS Source Name"
                        errorMsg={dpsSourcesExistError}
                        value={createRowData.DpssourceName}
                        required
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        type="string"
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        handleInputChange={handleInputChange}
                        options={[
                            { label: 'Active', value: 'Active' },
                            { label: 'Inactive', value: 'Inactive' },
                        ]}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData?.DpssourceId ? createRowData.CreatedBy : userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData?.DpssourceId ? formatDate(new Date(createRowData.CreatedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData?.DpssourceId ? formatDate(new Date(createRowData.LastModifiedDate)) : formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comment"
                        title="Comments"
                        value={createRowData.Comment}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <ModalFooterActions
                    submitButtonVisible={submitButtonVisible}
                    submitButtonHandler={submitButtonHandler}
                    resetButtonHandler={resetButtonHandler}
                    cancelButtonHandler={cancelButtonHandler}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DpsSourcesForm;

DpsSourcesForm.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
