import { FullPartialStrokeTestSectionDataS71 } from './Data/S71/S71.data';
import { FlowLevelTestSectionDataS74 } from './Data/S74/S74.data';

export const selectFullPartialStrokeTestSection = (selectedTag, formName, formData, multiTRFDetails) => {
    let filterViewData = { rows: [] };
    if (['S7-1'].includes(formName)) {
        filterViewData = FullPartialStrokeTestSectionDataS71(formName, formData, multiTRFDetails);
    } else if (['S7-4'].includes(formName)) {
        filterViewData = FlowLevelTestSectionDataS74(formName, formData, multiTRFDetails);
    }
    return filterViewData;
};
