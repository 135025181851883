import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField } from 'helpers/Validation';

export const AsFoundTestTableF39FormulaInst1Formula = (formData) => FieldHasValue(formData.F25InstrumentLrv)
    ? `0% - ${ValidateField(formData.F25InstrumentLrv)} ${ValidateField(formData.F28InstrumentEngineeringUnit)}` : '0%';

export const AsFoundTestTableF40FormulaInst2Formula = (formData) => (FieldHasValue(formData.F25InstrumentLrv) || FieldHasValue(formData.F26InstrumentUrv))
    ? `50% - ${ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
        + (ValidateNumberField(formData.F26InstrumentUrv)
            - ValidateNumberField(formData.F25InstrumentLrv))
        * (0.5))} ${ValidateField(formData.F28InstrumentEngineeringUnit)}`
    : '50%';

export const AsFoundTestTableF41FormulaInst3Formula = (formData) => FieldHasValue(formData.F26InstrumentUrv)
    ? `100% - ${ValidateField(formData.F26InstrumentUrv)} ${ValidateField(formData.F28InstrumentEngineeringUnit)}` : '100%';

export const AsFoundTestTableF54DeviationEu1Formula = (formData) => {
    if (FieldHasValue(formData.F42RtdSimulatorReading1)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F45TxReading1) - ValidateNumberField(formData.F42RtdSimulatorReading1)));
    }
    return '';
};

export const AsFoundTestTableF55DeviationEu2Formula = (formData) => {
    if (FieldHasValue(formData.F43RtdSimulatorReading2)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F46TxReading2) - ValidateNumberField(formData.F43RtdSimulatorReading2)));
    }
    return '';
};

export const AsFoundTestTableF56DeviationEu3Formula = (formData) => {
    if (FieldHasValue(formData.F44RtdSimulatorReading3)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F47TxReading3) - ValidateNumberField(formData.F44RtdSimulatorReading3)));
    }
    return '';
};
