import styled from 'styled-components';

export const DropDownPanel = styled.div`
    position: absolute;
    display: flex;
    right: 5px;
    top: 50px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    z-index: 999;
    padding: .5rem 0;
    margin: .125rem 0 0;
    -webkit-box-shadow: 0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%);
    box-shadow: 0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%);
`;

export const DropDownItem = styled.a`
    font-size: medium;
    color: #333;
    cursor: pointer;
    padding: 10px 40px;
    width: max-content;
    font-size: 14px;
`;

export const UserSection = styled.div`
    position: relative;
`;
