import styled from 'styled-components';

export const SearchTab = styled.div`
input {
    width: 400px;
    height: 40px;
    border-radius: 0;
    margin-top: 1rem;
    border-width: 2px;
    border-color: #A6A6A6;
    font-size: 13px;
}
`;

export const FlocExist = styled.p`
font-family: "Segoe UI", "Open Sans", sans-serif;
font-size: 9pt;
color: rgb(255, 0, 0);
font-weight: bold;
position: absolute;
right: 10%;
bottom: 52%;
`;

export const StyleDropdown = styled.div`
.Dropdown-control{ 
    @media only screen and (max-width: 960px) {       
         width: 99%;
    }
    @media only screen and (min-width: 961px) {
        width: 100.8%;
    }
}`;
