import { conditionalValueSelector } from 'helpers/Validation';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { disableAsLeftTestRequired, disableAsLeftTestFields } from '../../../AsLeftTestTableSection/Data/P66/P66.data';

export const FailureModeSectionDataS71 = (formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F619AsLeftTestRequiredLabel',
            label: 'As-Left Test Required?',
            xs: 4,
            labelWidth,
            padding: '0 0 0 8px',
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            marginTop: '15px'
        },
        {
            key: 'F619AsLeftTestRequired',
            value: conditionalValueSelector(disableAsLeftTestRequired(formData), '', formData.F619AsLeftTestRequired),
            isDropdown: !disableAsLeftTestRequired(formData),
            isDisabled: disableAsLeftTestRequired(formData),
            readOnly: disableAsLeftTestRequired(formData),
            placeholder: Placeholder.SELECT_YES_OR_NO,
            options: [{ name: Placeholder.SELECT_YES_OR_NO, value: '' },
            { name: 'YES', value: 'YES' },
            { name: 'NO', value: 'NO' }],
            labelWidth: '0%',
            width: '100%',
            inputTextAlign: 'center',
            xs: 2,
            marginTop: '15px'
        },
        {
            key: 'F759AsLeftTestPstMethodLabel',
            label: 'As-Left Test PST Method',
            xs: 4,
            labelWidth,
            isLabel: true,
            fontWeight: '600',
            fontSize: '9pt',
            marginTop: '15px',
            textAlign: 'right'
        },
        {
            key: 'F759AsLeftTestPstMethod',
            value: conditionalValueSelector(disableAsLeftTestFields(formData), '', (formData.F759AsLeftTestPstMethod ? formData.F759AsLeftTestPstMethod : 'Both')),
            isDisabled: disableAsLeftTestFields(formData),
            isDropdown: !disableAsLeftTestFields(formData),
            isRequired: !disableAsLeftTestFields(formData),
            readOnly: disableAsLeftTestFields(formData),
            options: [{ name: 'Both', value: 'Both' },
            { name: 'Solenoid Valve', value: 'Solenoid Valve' },
            { name: 'Smart Positioner', value: 'Smart Positioner' }],
            labelWidth: '0%',
            width: '100%',
            inputTextAlign: 'center',
            xs: 2,
            marginTop: '15px',
            textAlignLabel: 'end',
            textAlign: 'end',
            noDefaultOption: true
        }]
    }]
});
