export const VisualInspectSectionDataP8101 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F179VisuallyInspection',
                label: `Visually inspect instrument tagging, wiring terminations, general instrument condition, mechanical damage,
                 corrosion, and for signs deterioration or fluid leakage.`,
                value: formData.F179VisuallyInspection,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                marginTop: '15px',
                alignItems: 'inherit'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F120Diagnostics',
                label: 'Retrieve Diagnostic Message Information:',
                value: formData.F120Diagnostics,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit',
                marginTop: '15px',
            }]
        }]
});
