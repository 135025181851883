import { DiagnosticsP728 } from './Data/P728.data';

export const Diagnostics = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'Diagnostics',
                label: 'DIAGNOSTICS',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                width: '28%',
                padding: '15px 0 0 0'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F120Diagnostics',
                label: 'Retrieve Diagnostic Message Information:',
                value: formData.F120Diagnostics,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit'
            }]
        }
    ]

});
export const selectDiagnostics = (selectedTag, formName, formData) => {
    let filterViewData;
    if (formName === 'P7-8') {
        filterViewData = Diagnostics(selectedTag, formName, formData);
    } else if (formName === 'P7-28') {
        filterViewData = DiagnosticsP728(formData);
    } else {
        filterViewData = { rows: [] };
    }
    return filterViewData;
};
