import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const AsLeftTestTableF61AsLeftResultFormula = (formData) => {
    if (FieldHasValue(formData.F737AltValveActionUponTrip) === 'Failed to Open') {
        return 'Fail';
    }
    if (!FieldHasValue(formData.F344ValveTravelTimeRequirement)
        && FieldHasValue(formData.F737AltValveActionUponTrip) === 'Fully Opened') {
        return 'Pass';
    }
    if (FieldHasValue(formData.F344ValveTravelTimeRequirement)
        && FieldHasValue(formData.F737AltValveActionUponTrip)
        && FieldHasValue(formData.F739AltMeasuredValveTravelTime)) {
        if (formData.F737AltValveActionUponTrip === 'Fully Opened'
            && ValidateNumberField(formData.F739AltMeasuredValveTravelTime) <= ValidateNumberField(formData.F344ValveTravelTimeRequirement)) {
            return 'Pass';
        }
        return 'Fail';
    }
    return '';
};

export const disableAsLeftSection = (formData) => (
    FieldHasValue(formData.F619AsLeftTestRequired) === 'NO'
    || FieldHasValue(formData.F61AsFoundResult) === 'Pass');
