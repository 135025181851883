import React from 'react';
import PropTypes from 'prop-types';
import RefreshIcon from '../../icons/RefreshButton/Refresh';

export const RefreshFormComponent = ({ resetButtonHandler }) => (
        <RefreshIcon
            buttonHandler={resetButtonHandler}
            tooltip="Reset Form"
        />
    );

RefreshFormComponent.propTypes = {
    resetButtonHandler: PropTypes.func
};
