import { AltLowAlarmTestResultFormula, AltHighAlarmTestResultFormula, AltBeamblockTestResultFormula, AsLeftResultFormula } from './M67.formula';
import { hmiStatusPlaceholder, hmiStatusLabel } from '../../../AsFoundTestTableSection/Data/M67/M67.data';
import { notRequiredVal } from '../../../DetectorLenseSection/DetectorLense.data';
import { AlarmTestResult } from 'journeys/portal/Forms/Sections.constants';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { conditionalValueSelector } from 'helpers/Validation';

export const disableAsLeftSectionCondition = (formData) => formData?.F376CleanDetectorLensesIfVisiblyDirty === notRequiredVal && formData?.F377AlignmentRequired === 'NO';
export const AsLeftTestTableSectionDataM67 = (selectedTag, formData) => {
    const lowAlarmDiscardCheck = formData.F829AltLowAlarmTest === AlarmTestResult.DISCARD;
    const highAlarmDiscardCheck = formData.F831AltHighAlarmTest === AlarmTestResult.DISCARD;
    const beamBlockDiscardCheck = formData.F833AltBeamBlockTest === AlarmTestResult.DISCARD;
    const testPerform1 = conditionalValueSelector(highAlarmDiscardCheck,
        AlarmTestResult.PERFORM, formData.F829AltLowAlarmTest);
    const testPerform2 = conditionalValueSelector(lowAlarmDiscardCheck,
        AlarmTestResult.PERFORM, formData.F831AltHighAlarmTest);
    const disableLowAlarmTestFields = lowAlarmDiscardCheck || disableAsLeftSectionCondition(formData);
    const disableHighAlarmTestFields = highAlarmDiscardCheck || disableAsLeftSectionCondition(formData);
    const disableBeamBlockTestFields = beamBlockDiscardCheck || disableAsLeftSectionCondition(formData);
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsleftTest',
                    label: 'As-Left Test (in field repair attempt)',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 5px 8px'
                }]
            },
            {
                key: 2,
                fields: [
                    {
                        key: 'F829AltLowAlarmTest',
                        label: 'Low Alarm Test',
                        value: conditionalValueSelector(disableAsLeftSectionCondition(formData), '', testPerform1),
                        isDisabled: disableAsLeftSectionCondition(formData),
                        readOnly: disableHighAlarmTestFields,
                        isRequired: !disableAsLeftSectionCondition(formData),
                        isDropdown: !disableAsLeftSectionCondition(formData) && !highAlarmDiscardCheck,
                        placeholder: 'Select Low Alarm Test',
                        options: [{ name: 'Select Low Alarm Test', value: '' },
                        { name: AlarmTestResult.DISCARD, value: AlarmTestResult.DISCARD },
                        { name: AlarmTestResult.PERFORM, value: AlarmTestResult.PERFORM }],
                        labelWidth: '51.2%',
                        marginLeft: '0.5rem',
                        inputTextAlign: 'center',
                        xs: 5,
                        textAlign: 'center',
                        width: conditionalValueSelector((disableHighAlarmTestFields), '47.2%', '48.8%')
                    }
                ]
            },
            {
                key: 3,
                fields: [
                    {
                        key: 'F380AltAppliedLowAlarmTestFilter1',
                        label: 'Applied Low Alarm Test Filter Type',
                        value: conditionalValueSelector(disableLowAlarmTestFields, '', formData.F380AltAppliedLowAlarmTestFilter1),
                        isDisabled: disableLowAlarmTestFields,
                        readOnly: disableLowAlarmTestFields,
                        xs: 5,
                        padding: '0 0 0 8px',
                        labelWidth: '51.2%',
                        width: '47.2%',
                        marginLeft: '0.5rem',
                        textAlign: 'center',
                        height: '1.2rem',
                        borderBottom: '0',
                        borderTop: '0'
                    }
                ]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F378AltHmistatus1',
                        label: hmiStatusLabel,
                        value: conditionalValueSelector(disableLowAlarmTestFields, '', formData.F378AltHmistatus1),
                        isRequired: !(disableLowAlarmTestFields),
                        isDisabled: disableLowAlarmTestFields,
                        readOnly: disableLowAlarmTestFields,
                        isDropdown: !(disableLowAlarmTestFields),
                        placeholder: hmiStatusPlaceholder,
                        options: [{ name: hmiStatusPlaceholder, value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'LOW ALARM', value: 'LOW ALARM' },
                        { name: 'FAULT', value: 'FAULT' }],
                        labelWidth: '51.2%',
                        height: (disableLowAlarmTestFields) ? '1.1rem' : '1.5rem',
                        width: (disableLowAlarmTestFields) ? '47.3%' : '48.8%',
                        marginLeft: '0.5rem',
                        marginBottom: '15px',
                        inputTextAlign: 'center',
                        xs: 5
                    },
                    {
                        key: 'F830AltLowAlarmTestResult',
                        value: conditionalValueSelector((disableLowAlarmTestFields), '', AltLowAlarmTestResultFormula(formData)),
                        isDisabled: (disableLowAlarmTestFields),
                        readOnly: true,
                        isCalculatedNew: true,
                        width: '100%',
                        labelWidth: '0',
                        xs: 2,
                        textAlign: 'center',
                        height: '1.13rem',
                        direction: 'rtl',
                        borderLeft: '0'
                    }
                ]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F831AltHighAlarmTest',
                        label: 'High Alarm Test',
                        value: conditionalValueSelector(disableAsLeftSectionCondition(formData), '', testPerform2),
                        isDisabled: disableAsLeftSectionCondition(formData),
                        readOnly: disableLowAlarmTestFields,
                        isRequired: !disableAsLeftSectionCondition(formData),
                        isDropdown: !disableAsLeftSectionCondition(formData) && !lowAlarmDiscardCheck,
                        placeholder: 'Select High Alarm Test',
                        options: [{ name: 'Select High Alarm Test', value: '' },
                        { name: AlarmTestResult.DISCARD, value: AlarmTestResult.DISCARD },
                        { name: AlarmTestResult.PERFORM, value: AlarmTestResult.PERFORM }],
                        labelWidth: '51.2%',
                        marginLeft: '0.5rem',
                        inputTextAlign: 'center',
                        xs: 5,
                        textAlign: 'center',
                        width: (disableLowAlarmTestFields) ? '47.2%' : '48.8%'
                    }
                ]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'F381AltAppliedHighAlarmTestFilter2',
                        label: 'Applied High Alarm Test Filter Type',
                        value: conditionalValueSelector(disableHighAlarmTestFields, '', formData.F381AltAppliedHighAlarmTestFilter2),
                        isDisabled: disableHighAlarmTestFields,
                        readOnly: disableHighAlarmTestFields,
                        padding: '0 0 0 8px',
                        xs: 5,
                        labelWidth: '51.2%',
                        width: '47.2%',
                        marginBottom: '0',
                        marginRight: '0',
                        marginLeft: '0.5rem',
                        textAlign: 'center',
                        height: '1.2rem',
                        borderBottom: '0',
                        borderTop: '0',
                    }]
            },
            {
                key: 7,
                fields: [
                    {
                        key: 'F379AltHmistatus2',
                        label: hmiStatusLabel,
                        value: conditionalValueSelector(disableHighAlarmTestFields, '', formData.F379AltHmistatus2),
                        isRequired: !(disableHighAlarmTestFields),
                        isDisabled: disableHighAlarmTestFields,
                        readOnly: disableHighAlarmTestFields,
                        isDropdown: !(disableHighAlarmTestFields),
                        placeholder: hmiStatusPlaceholder,
                        options: [{ name: hmiStatusPlaceholder, value: '' },
                        { name: 'NORMAL', value: 'NORMAL' },
                        { name: 'HIGH ALARM', value: 'HIGH ALARM' },
                        { name: 'FAULT', value: 'FAULT' }],
                        xs: 5,
                        labelWidth: '51.2%',
                        width: (disableHighAlarmTestFields) ? '47.3%' : '48.8%',
                        marginLeft: '0.5rem',
                        marginBottom: '15px',
                        inputTextAlign: 'center',
                        height: (disableHighAlarmTestFields) ? '1.1rem' : '1.5rem',
                    },
                    {
                        key: 'F832AltHighAlarmTestResult',
                        value: conditionalValueSelector((disableHighAlarmTestFields), '', AltHighAlarmTestResultFormula(formData)),
                        readOnly: true,
                        isDisabled: (disableHighAlarmTestFields),
                        width: '100%',
                        labelWidth: '0',
                        isCalculatedNew: true,
                        xs: 2,
                        textAlign: 'center',
                        height: '1.12rem',
                        direction: 'rtl',
                        borderLeft: '0'
                    }
                ]
            },
            {
                key: 8,
                fields: [
                    {
                        key: 'F833AltBeamBlockTest',
                        label: 'Beam Block Test',
                        value: conditionalValueSelector(disableAsLeftSectionCondition(formData), '', formData.F833AltBeamBlockTest),
                        isRequired: !disableAsLeftSectionCondition(formData),
                        isDropdown: !disableAsLeftSectionCondition(formData),
                        readOnly: disableAsLeftSectionCondition(formData),
                        placeholder: 'Select Beam Block Test',
                        options: [{ name: 'Select Beam Block Test', value: '' },
                        { name: AlarmTestResult.DISCARD, value: AlarmTestResult.DISCARD },
                        { name: AlarmTestResult.PERFORM, value: AlarmTestResult.PERFORM }],
                        labelWidth: '51.2%',
                        marginLeft: '0.5rem',
                        inputTextAlign: 'center',
                        xs: 5,
                        isDisabled: disableAsLeftSectionCondition(formData),
                        width: disableAsLeftSectionCondition(formData) ? '47%' : '48.8%',
                        height: disableAsLeftSectionCondition(formData) ? '1.35rem' : '1.5rem',
                        borderBottom: conditionalValueSelector(beamBlockDiscardCheck, '', '0'),
                    }]
            },
            {
                key: 9,
                fields: [{
                    key: 'F834AltHmiStatus',
                    label: hmiStatusLabel,
                    value: conditionalValueSelector((disableBeamBlockTestFields), '', formData.F834AltHmiStatus),
                    isRequired: !(disableBeamBlockTestFields),
                    isDropdown: !(disableBeamBlockTestFields),
                    placeholder: hmiStatusPlaceholder,
                    options: [{ name: hmiStatusPlaceholder, value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'LOW ALARM', value: 'LOW ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                    xs: 5,
                    inputTextAlign: 'center',
                    labelWidth: '51.2%',
                    marginLeft: '0.5rem',
                    marginBottom: '0.26rem',
                    borderBottom: '0',
                    isDisabled: disableBeamBlockTestFields,
                    readOnly: disableBeamBlockTestFields,
                    width: (disableBeamBlockTestFields) ? '47%' : '48.8%',
                    height: (disableBeamBlockTestFields) ? '1.25rem' : '1.5rem',
                },
                {
                    key: 'F835AltBeamBlockTestResult',
                    value: conditionalValueSelector((disableBeamBlockTestFields), '', AltBeamblockTestResultFormula(formData)),
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    height: '1.25rem',
                    direction: 'rtl',
                    borderLeft: '0',
                    borderBottom: '0',
                    isDisabled: disableBeamBlockTestFields,
                },
                {
                    key: 'F103AltResult',
                    label: 'As-Left Result',
                    xs: 3,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F103AltResult',
                    value: !disableAsLeftSectionCondition(formData) ? AsLeftResultFormula(formData) : '',
                    isDisabled: disableAsLeftSectionCondition(formData),
                    readOnly: true,
                    width: '100%',
                    labelWidth: '0',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    height: '1.25rem',
                    borderRight: '0',
                    borderBottom: '0',
                    direction: 'rtl',
                    marginBottom: '4px',
                    isLabelBold: true,
                    inputMarginRight: '4px',
                    fontWeight: '700'
                }]
            }
        ]
    };
};
