import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';

export const TestResultSectionDataP891 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F267ProcessTemperatureReadingAfterTestLabel',
                    label: 'Process Temperature Reading After Test',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F267ProcessTemperatureReadingAfterTest',
                    value: formData.F267ProcessTemperatureReadingAfterTest,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    height: '1.2rem',
                    textAlign: 'center',
                },
                {
                    key: 'F85TestResult',
                    label: 'Test Result',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 6,
                    labelWidth: '50%',
                    marginRight: '0.5rem',
                    fontWeight: '600',
                    width: '50%'
                }]
        }
    ]
});
