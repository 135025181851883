import { FieldHasValue, ValidateNumberField } from 'helpers/Validation';

export const ValveSizeStrokeTestResultFormula = (formData) => {
    if (FieldHasValue(formData.F344ValveTravelTimeRequirement) || FieldHasValue(formData.F342ValveFullStrokeTravelTime)) {
        if (FieldHasValue(formData.F344ValveTravelTimeRequirement) && FieldHasValue(formData.F342ValveFullStrokeTravelTime)) {
            if (ValidateNumberField(formData.F342ValveFullStrokeTravelTime) <= ValidateNumberField(formData.F344ValveTravelTimeRequirement)) {
                return 'PASS';
            }
            return 'FAIL';
        }
        return '';
    }
    return '';
};
