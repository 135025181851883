export const VisualInspectSectionDataP851 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F29VisuallyInspectInstrumentTaggingNote',
                label: `Visually inspect physical device for signs of damage: ID tags present, corrosion, moisture incursion, 
                inspect wiring and conduit.`,
                value: formData.F29VisuallyInspectInstrumentTaggingNote,
                isRequired: true,
                isMultiline: true,
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                alignItems: 'inherit',
                marginBottom: '15px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F32RetrieveDiagnosticMsg',
                    label: 'Retrieve diagnostic message.',
                    value: formData.F32RetrieveDiagnosticMsg,
                    isMultiline: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    alignItems: 'inherit',
                    marginTop: '15px'
                }]
        }
    ]
});
