import React from 'react';
import { ClearFilterSVGIcon } from './ClearFilter.styled';

const ClearFiltersIcon = () => (
  <ClearFilterSVGIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_161915" data-name="Group 161915" transform="translate(-750 -593)">
      <g id="Group_161914" data-name="Group 161914">
        <path
          id="Path_83507"
          data-name="Path 83507"
          d="M2173.257,605.491l4.686,6.135v5.581l4,3.877v-9.457l4.984-6.135Z"
          transform="translate(-1421 -9)"
          fill="none"
          stroke="#656565"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path id="Path_83508" data-name="Path 83508" d="M2185.569,615.8l6.965,6.965" transform="translate(-1421 -9)" fill="none" stroke="#656565" strokeWidth="2" />
        <path id="Path_83509" data-name="Path 83509" d="M2185.569,622.765l6.955-6.955" transform="translate(-1421 -9)" fill="none" stroke="#656565" strokeWidth="2" />
      </g>
      <rect id="Rectangle_147750" data-name="Rectangle 147750" width="24" height="24" transform="translate(750 593)" fill="none" />
    </g>
  </ClearFilterSVGIcon>
);

export default ClearFiltersIcon;
