import { ValidateField } from 'helpers/Validation';
import { UnitConverterMmToMil, UnitConverterMilToMm } from './P721.formulas';

export const ZeroFlowConditionsP721 = (selectedTag, formName, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'ZeroFlowReadingLabel',
                label: 'Unit Conversion (tool offered as a convenience)',
                isLabel: true,
                labelWidth: '100%',
                fontWeight: '700',
                paddingTop: '5px',
                fontSize: '9pt',
                xs: 12,
                padding: '5px 25px 5px 4px'
            }
            ]
        },
        {
            key: 2,
            fields: [{
                key: 'F764UnitMm',
                value: 'mm',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                marginLeft: '4px'
            },
            {
                key: 'F766UnitMmValue',
                value: ValidateField(formData.F766UnitMmValue),
                xs: 1,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F772MmtoMilConverter',
                value: '→',
                padding: '0',
                xs: 1,
                readOnly: true,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                inputFontSize: '18pt',
                fontWeight: 'bold',
                marginBottom: '4px',
                isLabelBold: true
            },
            {
                key: 'F768UnitMmtoMil',
                value: 'mil',
                xs: 1,
                readOnly: true,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F770UnitMmtoMilValue',
                value: UnitConverterMmToMil(formData),
                xs: 1,
                isCalculatedNew: true,
                readOnly: true,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
            },
            {
                key: 'NoData1',
                xs: 2,
                isLabel: true
            },
            {
                key: 'F765UnitMil',
                value: 'mil',
                xs: 1,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0'
            },
            {
                key: 'F767UnitMilValue',
                value: ValidateField(formData.F767UnitMilValue),
                xs: 1,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F773MiltoMmConverter',
                value: '→',
                xs: 1,
                readOnly: true,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                inputFontSize: '18pt',
                fontWeight: 'bold',
                marginBottom: '4px',
                isLabelBold: true
            },
            {
                key: 'F769UnitMiltoMm',
                value: 'mm',
                xs: 1,
                readOnly: true,
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
            },
            {
                key: 'F771UnitMiltoMmValue',
                value: UnitConverterMilToMm(formData),
                xs: 1,
                isCalculatedNew: true,
                readOnly: true,
                type: 'number',
                maxLength: '10',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                inputMarginRight: '4px'
            }
            ]
        },
    ]
});
