import { FieldHasValue, ValidateNumberField, IsANumber } from 'helpers/Validation';

export const FoundResultF61AsFoundResultFormula = (formData) => {
    if (FieldHasValue(formData.F42RtdSimulatorReading1)
        && FieldHasValue(formData.F43RtdSimulatorReading2)
        && FieldHasValue(formData.F44RtdSimulatorReading3)
        && FieldHasValue(formData.F45TxReading1)
        && FieldHasValue(formData.F46TxReading2)
        && FieldHasValue(formData.F47TxReading3)
        && FieldHasValue(formData.F48HmiReading1)
        && FieldHasValue(formData.F49HmiReading2)
        && FieldHasValue(formData.F50HmiReading3)
        && FieldHasValue(formData.F25InstrumentLrv) && IsANumber(formData.F25InstrumentLrv) && FieldHasValue(formData.F60ToleranceEu)) {
        return (Math.max.apply(Math, [ValidateNumberField(formData.F54DeviationEu1), ValidateNumberField(formData.F55DeviationEu2),
        ValidateNumberField(formData.F56DeviationEu3)]) <= ValidateNumberField(formData.F60ToleranceEu)
            ? 'PASS' : 'FAIL');
    }
    return '';
};
