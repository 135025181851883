import { ExponentialToDecimal, FieldHasValue, ValidateNumberField } from 'helpers/Validation';
import { getAftDeleteIconStatus } from '../../AsFoundTestTableSection.helpers';

export const AsFoundTestDeviationFormula = (formData) => {
    if (formData.F676TransducerOutputSignal === 'Voltage') {
        return 'mV';
    }
    if (formData.F676TransducerOutputSignal === 'Current') {
        return 'µA';
    }
    return '';
};

export const AsFoundTestDeviation1Formula = (VibrationAmplitude, HmiReading) => FieldHasValue(VibrationAmplitude) && FieldHasValue(HmiReading)
    ? ExponentialToDecimal(Math.abs(ValidateNumberField(VibrationAmplitude) - ValidateNumberField(HmiReading))) : '';

export const AsFoundResultMandatoryCheck = (formData, aftTestPoint) => {
    let flag = false;
    if (!getAftDeleteIconStatus()) {
        for (let index = 2; index < Number(aftTestPoint) + 2; index += 1) {
            if (!FieldHasValue(formData[`AftVibrationFrequency${index}`]) || !FieldHasValue(formData[`AftDeviation${index}`])) {
                flag = false;
                break;
            }
            flag = true;
        }
    }
    return flag;
};

export const AsFoundResultFormula = (formData, aftTestPoint) => {
    const deviationValue = [];
        for (let index = 2; index < Number(aftTestPoint) + 2; index += 1) {
            deviationValue.push(Number(formData[`AftDeviation${index}`]));
        }
        if (FieldHasValue(formData.F101AftTolerance) && deviationValue.length > 0) {
            if (Math.max(...deviationValue) <= ValidateNumberField(formData.F101AftTolerance)) {
                return 'PASS';
            }
            return 'FAIL';
        }
    return '';
};

export const ToleranceLabelFormula = (formData) => {
    if (FieldHasValue(formData.F24HmiEngineeringUnit)) {
        return ('Tolerance ('.concat((formData.F24HmiEngineeringUnit)).concat(')'));
    }
    return 'Tolerance ()';
};

export const OutputOffsetFormula = (formData) => {
    if (formData.F676TransducerOutputSignal === 'Voltage') {
        return 'Output Offset Voltage (V DC)';
    }
    if (formData.F676TransducerOutputSignal === 'Current') {
        return 'Output Offset Current (mA DC)';
    }
    return 'Output Offset';
};
