import { FieldHasValue, ValidateNumberField, ExponentialToDecimal, IsNonText } from 'helpers/Validation';

export const AsFoundTestTableF215AftDeviation1Formula = (formData) => {
    if (FieldHasValue(formData.F481AftLocalTransmitterTxreading1) && FieldHasValue(formData.F106AftHmireading1) && FieldHasValue(formData.F253AftMeasuredReading1)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F106AftHmireading1)
            - ValidateNumberField(formData.F481AftLocalTransmitterTxreading1)));
    }
    return '';
};

export const AsFoundTestTableF216AftDeviation2Formula = (formData) => {
    if (FieldHasValue(formData.F482AftLocalTransmitterTxreading2) && FieldHasValue(formData.F107AftHmireading2) && FieldHasValue(formData.F254AftMeasuredReading2)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F107AftHmireading2)
            - ValidateNumberField(formData.F482AftLocalTransmitterTxreading2)));
    }
    return '';
};

export const AsFoundTestTableF217AftDeviation3Formula = (formData) => {
    if (FieldHasValue(formData.F483AftLocalTransmitterTxreading3) && FieldHasValue(formData.F108AftHmireading3) && FieldHasValue(formData.F255AftMeasuredReading3)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F108AftHmireading3)
            - ValidateNumberField(formData.F483AftLocalTransmitterTxreading3)));
    }
    return '';
};

export const FoundResultF102AftResultFormula = (formData) => {
    if (formData.F472SignalToTriggerLevelPriortoTest === 'Error') {
        return 'FAIL';
    }
    if (FieldHasValue(formData.F101AftTolerance)
        && IsNonText(formData.F215AftDeviation1)
        && IsNonText(formData.F216AftDeviation2)
        && IsNonText(formData.F217AftDeviation3)) {
        return Math.max.apply(Math,
            [ValidateNumberField(formData.F215AftDeviation1),
            ValidateNumberField(formData.F216AftDeviation2),
            ValidateNumberField(formData.F217AftDeviation3)])
            > ValidateNumberField(formData.F101AftTolerance)
            ? 'FAIL' : 'PASS';
    }
    return '';
};

export const FoundResultF62SensorTrimNeedFormula = (formData) => {
    if (IsNonText(formData.F215AftDeviation1)
        && IsNonText(formData.F216AftDeviation2)
        && IsNonText(formData.F217AftDeviation3)) {
        return Math.max.apply(Math, [ValidateNumberField(formData.F215AftDeviation1),
        ValidateNumberField(formData.F216AftDeviation2),
        ValidateNumberField(formData.F217AftDeviation3)])
            / (ValidateNumberField(formData.F117InstrumentCalibratedUrv) - ValidateNumberField(formData.F116InstrumentCalibratedLrv)) <= 0.02 ? 'No' : 'Yes';
    }
    return '';
};
