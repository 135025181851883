import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField } from 'helpers/Validation';
import { isF28InstEUHasValue } from '../P65/P65.formulas';

export const AsFoundTestTableHmiInst1Formula = (formData) => (FieldHasValue(formData.F21HmiLrv) || FieldHasValue(formData.F25InstrumentLrv))
    ? '0% - '.concat(ValidateField(formData.F21HmiLrv))
        .concat('% - ')
        .concat(ValidateField(formData.F25InstrumentLrv))
        .concat(isF28InstEUHasValue(formData))
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '0%';

export const AsFoundTestTableHmiInst2Formula = (formData) => (FieldHasValue(formData.F21HmiLrv) || FieldHasValue(formData.F22HmiUrv)
    || FieldHasValue(formData.F25InstrumentLrv) || FieldHasValue(formData.F26InstrumentUrv))
    ? '50% - '.concat(ExponentialToDecimal(ValidateNumberField(formData.F21HmiLrv)
        + (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv)) * 0.5))
        .concat('% - ')
        .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
            + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
        .concat(isF28InstEUHasValue(formData))
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '50%';

export const AsFoundTestTableHmiInst3Formula = (formData) => (FieldHasValue(formData.F22HmiUrv) || FieldHasValue(formData.F26InstrumentUrv))
    ? '100% - '
        .concat(ValidateField(formData.F22HmiUrv))
        .concat('% - ')
        .concat(ValidateField(formData.F26InstrumentUrv))
        .concat(isF28InstEUHasValue(formData))
        .concat(ValidateField(formData.F28InstrumentEngineeringUnit)) : '100%';

export const AsFoundTestTableDeviationEu1Formula = (formData) => {
    if (FieldHasValue(formData.F206AftIndependentReading1) || FieldHasValue(formData.F209AftTxreading1)) {
        if (FieldHasValue(formData.F206AftIndependentReading1) && FieldHasValue(formData.F209AftTxreading1)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F209AftTxreading1) - ValidateNumberField(formData.F206AftIndependentReading1)));
        }
        return '';
    }
    return '';
};

export const AsFoundTestTableDeviationEu2Formula = (formData) => {
    if (FieldHasValue(formData.F207AftIndependentReading2) || FieldHasValue(formData.F210AftTxreading2)) {
        if (FieldHasValue(formData.F207AftIndependentReading2) && FieldHasValue(formData.F210AftTxreading2)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F210AftTxreading2) - ValidateNumberField(formData.F207AftIndependentReading2)));
        }
        return '';
    }
    return '';
};

export const AsFoundTestTableDeviationEu3Formula = (formData) => {
    if (FieldHasValue(formData.F208AftIndependentReading3) || FieldHasValue(formData.F211AftTxreading3)) {
        if (FieldHasValue(formData.F208AftIndependentReading3) && FieldHasValue(formData.F211AftTxreading3)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F211AftTxreading3) - ValidateNumberField(formData.F208AftIndependentReading3)));
        }
        return '';
    }
    return '';
};
