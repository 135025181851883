import React from 'react';
import PropTypes from 'prop-types';
import { DisplayImagesWrapper } from './ImageGallery.styled';
import DisplayImage from './DisplayImage';

const ImageGallery = (props) => {
    const { capturedImages, getImages } = props;
    return (
        <DisplayImagesWrapper id="display_images">
            {capturedImages.map((img, i) => <DisplayImage id={`display_images-${img.ImageId}`} key={img.ImageId} image={img} getImages={getImages} />)}
        </DisplayImagesWrapper>
    );
};

export default ImageGallery;

ImageGallery.propTypes = {
    capturedImages: PropTypes.array,
    getImages: PropTypes.func
};
