import { Button, Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageViewer = ({ imgData }) => {
    const [showImage, setShowImage] = useState(false);
    let image = '';
    if (!imgData?.startsWith('data:image/')) {
        image = `data:image/jpeg;base64,${imgData}`;
    } else {
        image = imgData;
    }

    const handleButtonClick = () => {
        setShowImage(true);
    };

    const handleOnClose = () => {
        setShowImage(false);
    };

    return (
        <>
            <Button
                variant="text"
                size="small"
                style={{ textTransform: 'capitalize', fontSize: '11px', color: 'blue' }}
                onClick={handleButtonClick}
            >
                View Image
            </Button>
            {showImage
                && (
                    <Dialog
                        open
                        onClose={handleOnClose}
                        scroll="paper"
                        maxWidth="lg"

                    >
                        <img alt="" src={image} />
                    </Dialog>
                )}
        </>

    );
};
export default ImageViewer;

ImageViewer.propTypes = {
    imgData: PropTypes.string
};
