import { AsFoundResultFormulaM616 } from './M616.formulas';
import { dropdownPlaceholder } from '../../../AFT_FireTestSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataM616 = (selectedTag, formData) => {
    const dropdownPlaceholder2 = 'Select YES, NO or N/A';
    return {
        rows: [{
            key: 1,
            fields: [{
                key: 'As-FoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F654AftActivatedNotificationAppliances',
                label: 'Activated Notification Appliances',
                value: formData.F654AftActivatedNotificationAppliances,
                xs: 6,
                isRequired: true,
                labelWidth: '51%',
                width: '49%',
                isDropdown: true,
                placeholder: dropdownPlaceholder,
                options: [{ name: dropdownPlaceholder, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' }],
                marginLeft: '8px',
                inputTextAlign: 'center',
                borderBottom: '0',
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F655AftConfirmAudibleAlarmsFunctionCorrectly',
                label: 'Confirm Audible Alarms Function Correctly',
                value: formData.F655AftConfirmAudibleAlarmsFunctionCorrectly,
                isRequired: true,
                isDropdown: true,
                placeholder: dropdownPlaceholder2,
                options: [{ name: dropdownPlaceholder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                labelWidth: '51%',
                width: '49%',
                marginLeft: '8px',
                inputTextAlign: 'center',
                xs: 6,
                borderBottom: '0'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F656AftConfirmVisualAlarmsFunctionCorrectly',
                label: 'Confirm Visual Alarms Function Correctly',
                value: formData.F656AftConfirmVisualAlarmsFunctionCorrectly,
                xs: 6,
                marginLeft: '8px',
                isRequired: true,
                isDropdown: true,
                placeholder: dropdownPlaceholder2,
                options: [{ name: dropdownPlaceholder2, value: '' },
                { name: 'YES', value: 'YES' },
                { name: 'NO', value: 'NO' },
                { name: 'N/A', value: 'N/A' }],
                inputTextAlign: 'center',
                labelWidth: '51%',
                width: '49%',
                borderBottom: '0',
                marginBottom: '4px'
            },
            {
                key: 'F102AftResult',
                label: 'As-Found Result',
                value: AsFoundResultFormulaM616(formData),
                readOnly: true,
                xs: 6,
                width: '50.2%',
                labelWidth: '49.8%',
                isCalculatedNew: true,
                textAlign: 'center',
                height: '1.25rem',
                borderRight: '0',
                marginRight: '8px',
                direction: 'rtl',
                inputMarginRight: '4px',
                isLabelBold: true,
                fontWeight: '700',
                borderBottom: '0',
            }]
        }]
    };
};
