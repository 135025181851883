import { disableAsLeftSection, AsLeftTestTableF61AsLeftResultFormula } from './P727.formulas';
import { fullyOpenLabel, failToOpenLabel, abnormalWarning } from '../../../AsFoundTestTableSection/Data/P727/P727.data';

export const AsLeftTestTableSectionDataP727 = (selectedTag, formData) => {
    const disableAsLeftFields = disableAsLeftSection(formData) || formData.F737AltValveActionUponTrip === failToOpenLabel;
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsLeftTest',
                    label: 'As-Left Test',
                    isLabel: true,
                    fontWeight: '700',
                    padding: '5px 0 0 8px',
                    xs: 12,
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'attention',
                    label: `Attention: The following simple corrections may be performed 
                    by technician as part of this PM work order — repeat stroking valve against stiction, 
                    cleaning vent lines and ports, flow regulator adjustments, limit switch adjustments.`,
                    width: '0',
                    marginLeft: '8px',
                    color: '#ea7c34',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    borderRight: '0',
                    labelWidth: '100%',
                    inputMarginRight: '6px',
                    marginBottom: '5px',
                    fontSize: '8.5pt',
                    marginTop: '5px'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'followingcomplicated',
                    label: `The following complicated corrections should be performed by technician as part of
                     follow-up corrective work order — component replacements
                     (e.g. solenoid, QEV), valve and actuator overhaul, lubricating and greasing valve or actuator.`,
                    width: '0',
                    marginLeft: '8px',
                    labelWidth: '100%',
                    borderBottom: '0',
                    borderLeft: '0',
                    borderTop: '0',
                    borderRight: '0',
                    fontSize: '8.5pt',
                    inputMarginRight: '6px',
                    color: '#ea7c34',
                    marginBottom: '5px'
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F737AltValveActionUponTrip',
                    label: 'Valve Action Upon Trip',
                    value: !disableAsLeftSection(formData) ? formData.F737AltValveActionUponTrip : '',
                    isDisabled: disableAsLeftSection(formData),
                    readOnly: disableAsLeftSection(formData),
                    isDropdown: !disableAsLeftSection(formData),
                    placeholder: 'Select Valve Action',
                    options: [
                        { name: 'Select Valve Action', value: '' },
                        { name: fullyOpenLabel, value: fullyOpenLabel },
                        { name: failToOpenLabel, value: failToOpenLabel }],
                    isRequired: !disableAsLeftSection(formData),
                    height: disableAsLeftSection(formData) ? '1.24rem' : '',
                    width: disableAsLeftSection(formData) ? '39.5%' : '40.9%',
                    labelWidth: '49.6%',
                    marginLeft: '8px',
                    textAlign: 'center',
                    borderBottom: disableAsLeftFields ? '' : '0',
                    xs: 5,
                    rootMarginRight: '0px',
                    inputTextAlign: 'center'
                },
                {
                    key: 'NoDataP610',
                    xs: 1,
                    isLabel: true,
                    padding: '0'
                },
                {
                    key: 'F740AltValveHmiPositionIndication',
                    label: 'Valve HMI Position Indication Upon Trip',
                    value: !disableAsLeftSection(formData) ? formData.F740AltValveHmiPositionIndication : '',
                    readOnly: disableAsLeftSection(formData),
                    isDisabled: disableAsLeftSection(formData),
                    isDropdown: !disableAsLeftSection(formData),
                    options: [
                        { name: 'Select HMI Position', value: '' },
                        { name: fullyOpenLabel, value: fullyOpenLabel },
                        { name: failToOpenLabel, value: failToOpenLabel }],
                    xs: 6,
                    placeholder: 'Select HMI Position',
                    marginRight: '0.5rem',
                    direction: 'rtl',
                    width: disableAsLeftSection(formData) ? '39.5%' : '40.6%',
                    labelWidth: '59.4%',
                    textAlign: 'center',
                    borderRight: '0',
                    rootMarginRight: '3.6px',
                    inputMarginRight: '4px',
                    inputTextAlign: 'center',
                    height: disableAsLeftSection(formData) ? '1.24rem' : '',
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F738AltValveMovementUponTrip',
                    label: 'Valve Movement Upon Trip',
                    value: !disableAsLeftFields ? formData.F738AltValveMovementUponTrip : '',
                    isDisabled: disableAsLeftFields,
                    readOnly: disableAsLeftFields,
                    isDropdown: !disableAsLeftFields,
                    placeholder: 'Select Valve Movement',
                    options: [
                        { name: 'Select Valve Movement', value: '' },
                        { name: 'Smooth', value: 'Smooth' },
                        { name: 'Abnormal', value: 'Abnormal' }],
                    isRequired: !disableAsLeftFields,
                    height: disableAsLeftFields ? '1.24rem' : '',
                    width: disableAsLeftFields ? '39.5%' : '40.9%',
                    labelWidth: '49.6%',
                    marginLeft: '8px',
                    textAlign: 'center',
                    borderBottom: '0',
                    xs: 5,
                    rootMarginRight: '0px',
                    inputTextAlign: 'center'
                },
                {
                    key: 'warning',
                    label: formData.F738AltValveMovementUponTrip === 'Abnormal' ? abnormalWarning : '',
                    width: '0',
                    marginTop: '2px',
                    color: '#ea7c34',
                    borderBottom: '0',
                    xs: 7,
                    borderLeft: '0',
                    borderTop: '0',
                    borderRight: '0',
                    labelWidth: '100%',
                    inputMarginRight: '6px',
                    fontSize: '8.5pt'
                }]
            },
            {
                key: 6,
                fields: [{
                    key: 'F739AltMeasuredValveTravelTime',
                    label: 'Measured Valve Travel Time (sec)',
                    value: disableAsLeftFields ? '' : formData.F739AltMeasuredValveTravelTime,
                    isDisabled: disableAsLeftFields,
                    readOnly: disableAsLeftFields,
                    isRequired: formData.F344ValveTravelTimeRequirement !== '' && !disableAsLeftFields,
                    width: disableAsLeftFields ? '39.4%' : '39.5%',
                    height: '1.24rem',
                    labelWidth: '49.6%',
                    type: 'number',
                    maxLength: '10',
                    marginLeft: '8px',
                    textAlign: 'center',
                    xs: 5,
                    borderBottom: '0',
                    rootMarginRight: '0px',
                    inputTextAlign: 'center',
                },
                {
                    key: 'NodataP610',
                    xs: 1,
                    isLabel: true,
                    padding: '0'
                },
                {
                    key: 'F81AsLeftResult',
                    label: 'As-Left Result',
                    value: AsLeftTestTableF61AsLeftResultFormula(formData),
                    isDisabled: disableAsLeftSection(formData),
                    xs: 6,
                    isCalculatedNew: true,
                    readOnly: true,
                    marginRight: '0.5rem',
                    direction: 'rtl',
                    height: '1.24rem',
                    width: '40%',
                    labelWidth: '60%',
                    textAlign: 'center',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '4px',
                    inputMarginRight: '4px'
                }]
            }
        ]
    };
};
