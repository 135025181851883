import { FieldHasValue, ValidateNumberField, ValidateField, ExponentialToDecimal } from 'helpers/Validation';

export const AsFoundTestTableF39HmiInst1Formula = (formData) => {
    if (FieldHasValue(formData.F21HmiLrv) || FieldHasValue(formData.F25InstrumentLrv)) {
        if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F25InstrumentLrv)) {
            return '0% - '.concat(ValidateField(formData.F21HmiLrv))
                .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
                .concat(ValidateField(formData.F115HmiengineeringUnits))
                .concat(' - ')
                .concat(ValidateField(formData.F25InstrumentLrv))
                .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
                .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        }
        return '0%';
    }
    return '0%';
};
export const AsFoundTestTableF40HmiInst2Formula = (formData) => {
    if (FieldHasValue(formData.F21HmiLrv) || FieldHasValue(formData.F22HmiUrv) || FieldHasValue(formData.F25InstrumentLrv) || FieldHasValue(formData.F26InstrumentUrv)) {
        if (FieldHasValue(formData.F21HmiLrv) && FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(formData.F26InstrumentUrv)) {
            return '50% - '.concat(ExponentialToDecimal(ValidateNumberField(formData.F21HmiLrv)
                + (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv)) * (0.5)))
                .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
                .concat(ValidateField(formData.F115HmiengineeringUnits))
                .concat(' - ')
                .concat(ExponentialToDecimal(ValidateNumberField(formData.F25InstrumentLrv)
                    + (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv)) * (0.5)))
                .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
                .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        }
        return '50%';
    }
    return '50%';
};
export const AsFoundTestTableF41HmiInst3Formula = (formData) => {
    if (FieldHasValue(formData.F22HmiUrv) || FieldHasValue(formData.F26InstrumentUrv)) {
        if (FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F26InstrumentUrv)) {
            return '100% - '.concat(ValidateField(formData.F22HmiUrv))
                .concat(FieldHasValue(formData.F115HmiengineeringUnits) ? ' ' : '')
                .concat(ValidateField(formData.F115HmiengineeringUnits))
                .concat(' - ')
                .concat(ValidateField(formData.F26InstrumentUrv))
                .concat(FieldHasValue(formData.F28InstrumentEngineeringUnit) ? ' ' : '')
                .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        }
        return '100%';
    }
    return '100%';
};
export const AsFoundTestTableF54DeviationEu1Formula = (formData) => {
    if (FieldHasValue(formData.F42GaugeReading1) || FieldHasValue(formData.F45TxReading1)) {
        if (FieldHasValue(formData.F42GaugeReading1) && FieldHasValue(formData.F45TxReading1)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F45TxReading1) - ValidateNumberField(formData.F42GaugeReading1)));
        }
        return '';
    }
    return '';
};
export const AsFoundTestTableF55DeviationEu2Formula = (formData) => {
    if (FieldHasValue(formData.F43GaugeReading2) || FieldHasValue(formData.F46TxReading2)) {
        if (FieldHasValue(formData.F43GaugeReading2) && FieldHasValue(formData.F46TxReading2)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F46TxReading2) - ValidateNumberField(formData.F43GaugeReading2)));
        }
        return '';
    }
    return '';
};
export const AsFoundTestTableF56DeviationEu3Formula = (formData) => {
    if (FieldHasValue(formData.F44GaugeReading3) || FieldHasValue(formData.F47TxReading3)) {
        if (FieldHasValue(formData.F44GaugeReading3) && FieldHasValue(formData.F47TxReading3)) {
            return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F47TxReading3) - ValidateNumberField(formData.F44GaugeReading3)));
        }
        return '';
    }
    return '';
};
