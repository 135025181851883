import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { AFTSoundPressureFormula, AsFoundResultM65Formula, isSelfPortableTest } from './M65.formula';

export const testLabel = 'Select Test Gain';
export const AsFoundTestTableSectionDataM65 = (selectedTag, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'As-FoundTest',
            label: 'As-Found Test',
            isLabel: true,
            xs: 12,
            fontWeight: '700',
            padding: '5px 0 0 8px'
        }]
    },
    {
        key: 2,
        fields: [
            {
                key: 'F430TestMethodTypeUsedLabel',
                label: 'Test Method Type Used',
                xs: 4,
                labelWidth: '98%',
                padding: '0 0 0 8px',
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F430TestMethodTypeUsed',
                value: formData.F430TestMethodTypeUsed,
                isDropdown: true,
                isRequired: true,
                placeholder: 'Select Test Method Type',
                options: [{ name: 'Select Test Method Type', value: '' },
                { name: 'SELF/PORTABLE TEST', value: 'SELF/PORTABLE TEST' },
                { name: 'CALIBRATOR TEST', value: 'CALIBRATOR TEST' }],
                labelWidth: '0',
                width: '100%',
                inputTextAlign: 'center',
                xs: 2,
                borderBottom: '0'
            }]
    },
    {
        key: 3,
        fields: [
            {
                key: 'F820TestToolActivationAftLabel',
                label: 'Confirm that test tool has been activated in the proximity of the detector',
                xs: 4,
                labelWidth: '98%',
                padding: '0 0 0 8px',
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F820TestToolActivationAft',
                value: formData.F820TestToolActivationAft,
                isDropdown: true,
                isRequired: true,
                placeholder: 'Select YES or NO',
                options: [{ name: 'Select YES or NO', value: '' },
                { name: 'YES', value: 'YES' }, { name: 'NO', value: 'NO' }],
                labelWidth: '0',
                width: '100%',
                height: '1.9rem',
                inputTextAlign: 'center',
                paddingPlaceholder: '4px',
                arrowMarginTop: '4px',
                xs: 2
            }]
    },
    {
        key: 4,
        fields: [{
            key: 'Gaintesting',
            label: 'Gain testing',
            isLabel: true,
            xs: 12,
            fontWeight: '700',
            fontSize: '9pt',
            padding: '5px 0 0 8px'
        }]
    },
    {
        key: 5,
        fields: [
            {
                key: 'F416AftSelectTestGainLabel',
                label: testLabel,
                xs: 4,
                labelWidth: '98%',
                padding: '0 0 0 8px',
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F416AftSelectTestGain',
                value: isSelfPortableTest(formData) ? '' : formData.F416AftSelectTestGain,
                isRequired: !isSelfPortableTest(formData),
                isDropdown: !isSelfPortableTest(formData),
                isDisabled: isSelfPortableTest(formData),
                readOnly: isSelfPortableTest(formData),
                placeholder: testLabel,
                options: [{ name: testLabel, value: '' }, { name: '1', value: '1' }, { name: '2', value: '2' },
                { name: '3', value: '3' }, { name: '4', value: '4' }, { name: '5', value: '5' }, { name: '6', value: '6' },
                { name: '7', value: '7' }, { name: '8', value: '8' }, { name: '9', value: '9' }],
                labelWidth: '0',
                width: '100%',
                inputTextAlign: 'center',
                xs: 2
            }]
    },
    {
        key: 6,
        fields: [
            {
                key: 'F417AftSoundPressureLevel1Label',
                label: 'Applied sound pressure level, dB',
                xs: 4,
                labelWidth: '98%',
                padding: '0 0 0 8px',
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F417AftSoundPressureLevel1',
                value: isSelfPortableTest(formData) ? '' : AFTSoundPressureFormula(formData),
                xs: 2,
                readOnly: true,
                isCalculatedNew: true,
                borderTop: '0',
                labelWidth: '0',
                width: '100%',
                textAlign: 'center',
                height: '1rem',
                marginBottom: '15px',
                isDisabled: isSelfPortableTest(formData),
            }]
    },
    {
        key: 7,
        fields: [
            {
                key: 'F372AftHmistatus1Label',
                label: 'HMI Status',
                xs: 4,
                labelWidth: '98%',
                padding: '0 0 0 8px',
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F372AftHmistatus1',
                value: formData.F372AftHmistatus1,
                isRequired: true,
                isDropdown: true,
                placeholder: 'Select HMI Status',
                options: [{ name: 'Select HMI Status', value: '' },
                { name: 'NORMAL', value: 'NORMAL' },
                { name: 'ALARM', value: 'ALARM' },
                { name: 'LOOP FAULT', value: 'LOOP FAULT' }],
                labelWidth: '0',
                width: '100%',
                inputTextAlign: 'center',
                xs: 2
            }]
    },
    {
        key: 8,
        fields: [
            {
                key: 'F418AftSoundPressureLevel2Label',
                label: 'Measured sound pressure level, dB',
                xs: 4,
                labelWidth: '98%',
                padding: '0 0 0 8px',
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F418AftSoundPressureLevel2',
                value: isSelfPortableTest(formData) ? '' : formData.F418AftSoundPressureLevel2,
                xs: 2,
                isRequired: !isSelfPortableTest(formData),
                type: 'number',
                maxLength: '10',
                labelWidth: '0',
                width: '100%',
                borderTop: '0',
                textAlign: 'center',
                isDisabled: isSelfPortableTest(formData),
                readOnly: isSelfPortableTest(formData),
                height: '1.1rem'
            }]
    },
    {
        key: 9,
        fields: [
            {
                key: 'F419AftTimeDelayLabel',
                label: 'Time delay, sec',
                xs: 4,
                labelWidth: '98%',
                padding: '0 0 0 8px',
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F419AftTimeDelay',
                value: formData.F419AftTimeDelay,
                xs: 2,
                labelWidth: '0',
                type: 'number',
                maxLength: '10',
                width: '100%',
                marginBottom: '4px',
                textAlign: 'center',
                height: '1.1rem',
                borderTop: isSelfPortableTest(formData) ? '' : '0'
            },
            {
                key: 'F102AftResultLabel',
                label: 'As-Found Result',
                xs: 4,
                isLabel: true,
                fontWeight: '700',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                padding: paddingLabel,
            },
            {
                key: 'F102AftResult',
                value: AsFoundResultM65Formula(formData),
                width: '100%',
                readOnly: true,
                labelWidth: '0',
                isCalculatedNew: true,
                xs: 2,
                textAlign: 'center',
                height: '1.1rem',
                borderRight: '0',
                direction: 'rtl',
                inputMarginRight: '4px',
                isLabelBold: true,
                fontWeight: '700',
                marginBottom: '15px'
            }]
    },
    {
        key: 10,
        fields: [
            {
                key: 'F376CleanDetectorLensesIfVisiblyDirtyLabel',
                label: 'Clean microphone head if it is visibly dirty ',
                xs: 4,
                labelWidth: '98%',
                padding: '0 0 0 8px',
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
            },
            {
                key: 'F376CleanDetectorLensesIfVisiblyDirty',
                value: formData.F376CleanDetectorLensesIfVisiblyDirty,
                isRequired: true,
                isDropdown: true,
                placeholder: 'Select from dropdown list',
                options: [{ name: 'Select from dropdown list', value: '' },
                { name: 'CLEANED', value: 'CLEANED' },
                { name: 'NOT REQUIRED', value: 'NOT REQUIRED' }],
                labelWidth: '0',
                width: '100%',
                inputTextAlign: 'center',
                xs: 2,
                fontSize: '8.9pt',
                borderBottom: '0',
                marginBottom: '4px'
            },
            {
                key: 'DetectorMicrophone_NOTE',
                label: 'If detector microphone head cleaned, repeat the test',
                isLabel: true,
                padding: '6px 0 5px 4px',
                xs: 6,
                color: '#ea7c34',
                fontSize: '8.5pt',
                fontStyle: 'italic',
                fontWeight: '600'
            }]
    }]
});
