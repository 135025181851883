import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { RadioNames, RadioLabel, RadioGroupWrapper, useStyles } from './RadioButtonsGroup.styled';
import { LinearProgress } from '@material-ui/core';
import { strEquals } from 'journeys/portal/Admin/AdminScreen.helper';
import { homePageLabels } from 'journeys/portal/Home/Home.constants';

export default function RadioButtonsGroup(props) {
    const classes = useStyles(props);
    const handleChange = (event) => {
        props.setValue(event.target.value);
    };
    const displayRadioButtons = (item) => (strEquals(props.label, homePageLabels.Group) || strEquals(props.label, homePageLabels.BarrierType)) && props.list.length === 1 ? (
        <FormControlLabel
            key={item}
            value={item?.toLowerCase()}
            control={<Radio />}
            disabled={!props.isOfflineBehaviour}
            label={item}
            checked
        />
    ) : (
        <FormControlLabel
            key={item}
            value={item?.toLowerCase()}
            control={<Radio />}
            disabled={!props.isOfflineBehaviour}
            label={item}
        />
    );
    return (
            <RadioGroupWrapper>
                <RadioLabel margin={props.margin}>
                    {props.label}
                </RadioLabel>
                {(!props.list || props.list.length === 0) && !strEquals(props.label, homePageLabels.Asset) ? <LinearProgress classes={{ root: classes.root }} />
                    : (
                        <RadioNames maxWidth={props.maxWidth}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="radio-group"
                                    name="radio-group"
                                    value={props.value}
                                    onChange={handleChange}
                                >
                                    <div>
                                        {
                                            props?.list?.map((item) => displayRadioButtons(item))
                                        }
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </RadioNames>
                    )}
            </RadioGroupWrapper>
    );
}

RadioButtonsGroup.propTypes = {
    isOfflineBehaviour: PropTypes.bool,
    label: PropTypes.string,
    list: PropTypes.array,
    margin: PropTypes.string,
    maxWidth: PropTypes.string,
    setValue: PropTypes.func,
    value: PropTypes.string
};
