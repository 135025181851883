let deleteIcons = false;
export const getAftDeleteIconStatus = () => deleteIcons;

export const showAftDeleteIcons = (testPoint, previousVal) => {
    deleteIcons = previousVal !== testPoint;
    return deleteIcons;
};

export const hideAftDeleteIcons = () => {
    deleteIcons = false;
    return deleteIcons;
};
