import {
    AsFoundTestTableF39FormulaInst1Formula,
    AsFoundTestTableF40FormulaInst2Formula,
    AsFoundTestTableF41FormulaInst3Formula,
} from './P73.formulas';
import {
    AsFoundTestTableF56DeviationEu3Formula,
    AsFoundTestTableF55DeviationEu2Formula,
    AsFoundTestTableF54DeviationEu1Formula
} from '../Formulas';
import { paddingAF } from '../../../AsLeftTestTableSection/Data/M71/M71.data';

export const AsFoundTestTableSectionDataP73 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsFoundTestLabel',
                label: 'As Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: 700,
                padding: '5px 0 0 8px'
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'AsFoundTestINST',
                label: 'INST ',
                isLabel: true,
                fontWeight: '700',
                labelWidth: '100%',
                xs: 2,
                padding: '5px 0 5px 8px',
                fontSize: '9pt'
            },
            {
                key: 'TestModuleReading',
                label: 'Test Module Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'TXReading',
                label: 'TX Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'HMIReading',
                label: 'HMI Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'mAReading',
                label: 'mA Reading',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            },
            {
                key: 'Deviation',
                label: 'Deviation(EU)',
                isLabel: true,
                fontWeight: '700',
                xs: 2,
                labelWidth: '100%',
                textAlign: 'center',
                padding: paddingAF,
                fontSize: '9pt'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F39FormulaInst1',
                value: AsFoundTestTableF39FormulaInst1Formula(formData).length > 255
                    ? AsFoundTestTableF39FormulaInst1Formula(formData).slice(0, 255) : AsFoundTestTableF39FormulaInst1Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F42TestModuleReading1',
                value: formData.F42TestModuleReading1,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F45TxReading1',
                value: formData.F45TxReading1,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F48HmiReading1',
                value: formData.F48HmiReading1,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F51MAReading1',
                value: formData.F51MAReading1,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F54DeviationEu1',
                value: AsFoundTestTableF54DeviationEu1Formula(formData),
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F40FormulaInst2',
                value: AsFoundTestTableF40FormulaInst2Formula(formData).length > 255
                    ? AsFoundTestTableF40FormulaInst2Formula(formData).slice(0, 255) : AsFoundTestTableF40FormulaInst2Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                borderBottom: '0',
                marginLeft: '4px'
            },
            {
                key: 'F43TestModuleReading2',
                value: formData.F43TestModuleReading2,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F46TxReading2',
                value: formData.F46TxReading2,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F49HmiReading2',
                value: formData.F49HmiReading2,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F52MAReading2',
                value: formData.F52MAReading2,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                textAlign: 'center',
                height: '1.2rem',
                borderRight: '0',
                borderBottom: '0',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F55DeviationEu2',
                value: AsFoundTestTableF55DeviationEu2Formula(formData),
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderBottom: '0',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                xs: 2,
                textAlign: 'center'
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F41FormulaInst3',
                value: AsFoundTestTableF41FormulaInst3Formula(formData).length > 255
                    ? AsFoundTestTableF41FormulaInst3Formula(formData).slice(0, 255) : AsFoundTestTableF41FormulaInst3Formula(formData),
                xs: 2,
                width: '100%',
                labelWidth: '0',
                readOnly: true,
                height: '1.2rem',
                borderRight: '0',
                borderLeft: '0',
                marginLeft: '4px'
            },
            {
                key: 'F44TestModuleReading3',
                value: formData.F44TestModuleReading3,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F47TxReading3',
                value: formData.F47TxReading3,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F50HmiReading3',
                value: formData.F50HmiReading3,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F53MAReading3',
                value: formData.F53MAReading3,
                isRequired: true,
                type: 'number',
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                textAlign: 'center',
                xs: 2,
                maxLength: '10'
            },
            {
                key: 'F56DeviationEu3',
                value: AsFoundTestTableF56DeviationEu3Formula(formData),
                width: '100%',
                labelWidth: '0',
                height: '1.2rem',
                borderRight: '0',
                inputMarginRight: '4px',
                readOnly: true,
                xs: 2,
                textAlign: 'center'
            }]
        }
    ]
});
