import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import createDebounce from 'redux-debounced';
import commonReducer from 'journeys/common/common.reducer';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import { isDebugEnabled } from 'helpers/isDebugEnabled';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import portalReducers from './journeys/portal/portal.reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducerEnhancer = rootReducer => (state, action) => {
    if (navigator.onLine && action.meta?.messageType === MESSAGE_TYPE.ERROR) {
        window.appInsights?.trackException({
            error: action.meta?.message,
            exception: action.meta?.exception,
            severityLevel: SeverityLevel.Error,
            properties: { ...action.meta?.exception }
        });
    }

    return rootReducer(state, action);
  };

const combinedReducer = () => {
    const allReducers = { ...portalReducers, AppData: commonReducer };
    const appReducer = combineReducers(allReducers);
    let store = '';
    if (isDebugEnabled()) {
        store = createStore(
            reducerEnhancer(appReducer),
            {},
            composeEnhancers(applyMiddleware(promise, createDebounce(), thunk, logger))
        );
    } else {
        store = createStore(
            reducerEnhancer(appReducer),
            {},
            composeEnhancers(applyMiddleware(promise, createDebounce(), thunk))
        );
    }
    return { store };
};
export default combinedReducer;
