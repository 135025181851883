export const VisualInspectSectionDataP62 = (selectedTag, formName, formData) => {
    let visualLabel;
    if (formName === 'P8-2.1') {
        visualLabel = `Visually inspect instrument tagging, tubing/fittings/ manifold integrity, instrument wiring connection/ 
        terminations, instrument condition, general deterioration and no leak sign`;
    } else {
        visualLabel = `Visually inspect instrument tagging, instrument mounting/manifold conditions, the state of instrument 
        wiring connection/terminations, tubing/fitting integrity, the general instrument condition, for general signs deterioration 
        as well as for signs of process fluid weeping/leaking`;
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F193VisuallyInspect',
                    label: visualLabel,
                    value: formData.F193VisuallyInspect,
                    isRequired: true,
                    isMultiline: true,
                    xs: 12,
                    width: '50%',
                    inputWidth: '50%',
                    alignItems: 'inherit'
                }]
            }]
    };
};
