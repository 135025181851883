import { ExponentialToDecimal, FieldHasValue, ValidateField, ValidateNumberField } from 'helpers/Validation';

export const AsLeftTableF63Hmi2Inst1Formula = (formData) => {
    let Hmi2Inst1;
    if (FieldHasValue(formData.F21HmiLrv)) {
        Hmi2Inst1 = '0% - '.concat(ValidateField(formData.F21HmiLrv))
            .concat(' ')
            .concat(ValidateField(formData.F24HmiEngineeringUnit))
            .concat(' - ')
            .concat(ValidateField(formData.F25InstrumentLrv))
            .concat(' ')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        return Hmi2Inst1.length > 255 ? Hmi2Inst1.slice(0, 255) : Hmi2Inst1;
    }
    return '0%';
};

export const AsLeftTableF65Hmi2Inst3Formula = (formData) => {
    let Hmi2Inst3;
    if (FieldHasValue(formData.F22HmiUrv)) {
        Hmi2Inst3 = '100% - '.concat(formData.F22HmiUrv)
            .concat(' ')
            .concat(ValidateField(formData.F24HmiEngineeringUnit))
            .concat(' - ')
            .concat(ValidateField(formData.F26InstrumentUrv))
            .concat(' ')
            .concat(ValidateField(formData.F28InstrumentEngineeringUnit));
        return Hmi2Inst3.length > 255 ? Hmi2Inst3.slice(0, 255) : Hmi2Inst3;
    }
    return '100%';
};

export const AsLeftTableF78Deviation2Eu1Formula = (formData) => {
    if (FieldHasValue(formData.F66TestModule2Reading1)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F69Tx2Reading1) - ValidateNumberField(formData.F66TestModule2Reading1)));
    }
    return '';
};

export const AsLeftTableF79Deviation2Eu2Formula = (formData) => {
    if (FieldHasValue(formData.F67TestModule2Reading2)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F70Tx2Reading2) - ValidateNumberField(formData.F67TestModule2Reading2)));
    }
    return '';
};

export const AsLeftTableF80Deviation2Eu3Formula = (formData) => {
    if (FieldHasValue(formData.F68TestModule2Reading3)) {
        return ExponentialToDecimal(Math.abs(ValidateNumberField(formData.F71Tx2Reading3) - ValidateNumberField(formData.F68TestModule2Reading3)));
    }
    return '';
};
