import { FieldHasValue } from 'helpers/Validation';

export const AsFoundResultFormulaM616 = (formData) => {
    if (FieldHasValue(formData.F654AftActivatedNotificationAppliances)
        && FieldHasValue(formData.F655AftConfirmAudibleAlarmsFunctionCorrectly)
        && FieldHasValue(formData.F656AftConfirmVisualAlarmsFunctionCorrectly)) {
        if (formData.F654AftActivatedNotificationAppliances === 'YES'
            && (formData.F655AftConfirmAudibleAlarmsFunctionCorrectly === 'YES'
                || formData.F656AftConfirmVisualAlarmsFunctionCorrectly === 'YES')
            && (formData.F655AftConfirmAudibleAlarmsFunctionCorrectly !== 'NO'
                && formData.F656AftConfirmVisualAlarmsFunctionCorrectly !== 'NO')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
