import { FieldHasValue, CustomRound, ValidateNumberField, ExponentialToDecimal } from 'helpers/Validation';

export const totalNumberOfFields = 8;
export const expectedReadingMultiplier1 = 0.0039083;
export const expectedReadingMultiplier2 = -0.0000005775;
export const expectedReadingMultiplier3 = -0.000000000004183;
export const valueToApplyConstant1 = 16;
export const valueToApplyConstant2 = 4;

export const getHmiUnit = (formData) => formData.F115HmiengineeringUnits ? `(${formData.F115HmiengineeringUnits})` : '';
export const getInstrumentUnit = (formData) => formData.F28InstrumentEngineeringUnit ? `(${formData.F28InstrumentEngineeringUnit})` : '';

export const getValueToApplyUnit = (formData, formName, isSetPoint) => {
    const hmiUnit = getHmiUnit(formData);
    const readingUnit = getUnits(formData);
    const valueToApplyUnit = isSetPoint ? hmiUnit : readingUnit;
    if (formName === 'S7-3') {
        return formData.F987UsePtConversion === 'NO' ? getInstrumentUnit(formData) : '(Ω)';
    }
    if (formName === 'S7-2') {
        return getInstrumentUnit(formData);
    }
    return (formData.F988ActualSimualtedLevel === 'Simulated') && (formData.F989SimulatedCurrentInjection === 'YES') ? '(mA)' : valueToApplyUnit;
};

export const getUnits = (formData) => {
    const hmiUnit = getHmiUnit(formData);
    const instrumentUnit = getInstrumentUnit(formData);
    if (formData.F22TrfId === 'S7-4') {
       return formData.F980UnitsUsed === 'HMI' ? hmiUnit : instrumentUnit;
    }
    return formData.F980UnitsUsed === 'HMI' || FieldHasValue(formData.F988ActualSimualtedLevel) ? hmiUnit : instrumentUnit;
};

export const valueToApplyFormula = (formData, formName, value, expectedReadingValue) => {
    if (formName === 'S7-3') {
        return calculateValueToApplyS73(formData, expectedReadingValue);
    }
    if (formName === 'S7-2') {
        return calculateValueToApplyS72(formData, value);
    }
    return calculateValueToApplyS74(formData, value, expectedReadingValue);
};

export const calculateValueToApplyS73 = (formData, expectedReadingValue) => {
    if (formData.F987UsePtConversion === 'NO') {
        return expectedReadingValue;
    }
    if (FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(formData.F26InstrumentUrv)) {
        const expectedReadingValues = ValidateNumberField(expectedReadingValue);
        const part1 = 1 + expectedReadingValues * expectedReadingMultiplier1;
        const part2 = Math.pow(expectedReadingValues, 2) * expectedReadingMultiplier2;
        const part3 = expectedReadingValues < 0 ? expectedReadingMultiplier3 * (expectedReadingValues - 100) * Math.pow(expectedReadingValues, 3) : 0;
        const result = 100 * (part1 + part2 + part3);
        return Math.round(result * 10) / 10;
    }
    return '';
};

export const calculateValueToApplyS72 = (formData, value) => {
    if (FieldHasValue(formData.F26InstrumentUrv) && FieldHasValue(formData.F25InstrumentLrv)) {
        const urv = ValidateNumberField(formData.F26InstrumentUrv);
        const lrv = ValidateNumberField(formData.F25InstrumentLrv);
        const stepVal = ValidateNumberField(value / 100);
        return ExponentialToDecimal(CustomRound(stepVal * (urv - lrv) + lrv));
    }
    return '';
};

export const calculateValueToApplyS74 = (formData, value, expectedReadingValue) => {
    if (FieldHasValue(formData.F988ActualSimualtedLevel) === 'Simulated' && FieldHasValue(formData.F989SimulatedCurrentInjection) === 'YES') {
        const stepVal = ValidateNumberField(value / 100);
        return ExponentialToDecimal(CustomRound(stepVal * valueToApplyConstant1 + valueToApplyConstant2));
    }
    return expectedReadingValue;
};

export const expectedReadingFormula = (formData, formName, value) => {
    if (formName === 'S7-3') {
        return calculateValueToApplyS72(formData, value);
    }
    if (formName === 'S7-2') {
        return expectedReadingFormulaS72(formData, value);
    }
    return expectedReadingFormulaS74(formData, value);
};

export const expectedReadingFormulaS72 = (formData, value) => {
    const instUrv = ValidateNumberField(formData.F26InstrumentUrv);
    const instLrv = ValidateNumberField(formData.F25InstrumentLrv);
    const hmiLrv = ValidateNumberField(formData.F21HmiLrv);
    const hmiUrv = ValidateNumberField(formData.F22HmiUrv);
    const stepVal = ValidateNumberField(value / 100);
    if (FieldHasValue(formData.F980UnitsUsed) === 'HMI' && FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F21HmiLrv)) {
        if (FieldHasValue(formData.F979SqrtExtraction) === 'YES') {
            return ExponentialToDecimal(CustomRound(Math.sqrt(stepVal) * (hmiUrv - hmiLrv) + hmiLrv));
        }
        return ExponentialToDecimal(stepVal * (hmiUrv - hmiLrv) + hmiLrv);
    }
    if (FieldHasValue(formData.F26InstrumentUrv) && FieldHasValue(formData.F25InstrumentLrv)) {
        return ExponentialToDecimal(stepVal * (instUrv - instLrv) + instLrv);
    }
    return '';
};

export const expectedReadingFormulaS74 = (formData, value) => {
    const hmiLrv = ValidateNumberField(formData.F21HmiLrv);
    const hmiUrv = ValidateNumberField(formData.F22HmiUrv);
    const instUrv = ValidateNumberField(formData.F26InstrumentUrv);
    const instLrv = ValidateNumberField(formData.F25InstrumentLrv);
    const stepVal = ValidateNumberField(value / 100);
    if (FieldHasValue(formData.F980UnitsUsed) === 'HMI' && FieldHasValue(formData.F22HmiUrv) && FieldHasValue(formData.F21HmiLrv)) {
        return ExponentialToDecimal(CustomRound(stepVal * (hmiUrv - hmiLrv) + hmiLrv));
    }
    if (FieldHasValue(formData.F26InstrumentUrv) && FieldHasValue(formData.F25InstrumentLrv)) {
        return ExponentialToDecimal(stepVal * (instUrv - instLrv) + instLrv);
    }
    return '';
};

export const DeviationAftFormula = (formName, formData, expectedReadingAft, actualTxReadingAft) => {
    if (FieldHasValue(actualTxReadingAft)) {
        if (FieldHasValue(formData.F980UnitsUsed === 'HMI') && FieldHasValue(formData.F22HmiUrv)
            && FieldHasValue(formData.F21HmiLrv) && FieldHasValue(expectedReadingAft)) {
            return ExponentialToDecimal((Math.round((100 * Math.abs(ValidateNumberField(actualTxReadingAft) - ValidateNumberField(expectedReadingAft))
                / (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv))) * 100)) / 100);
        }
        if (FieldHasValue(formData.F26InstrumentUrv)
            && FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(expectedReadingAft)) {
            return ExponentialToDecimal((Math.round((100 * Math.abs(ValidateNumberField(actualTxReadingAft) - ValidateNumberField(expectedReadingAft))
                / (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv))) * 100)) / 100);
        }
    }
    return '';
};

export const AsFoundResultFormula = (formName, formData) => {
    const deviationValues = [];
    for (let i = 0; i <= totalNumberOfFields; i += 1) {
        if (FieldHasValue(formData[`AftExpectedReading${i + 2}`]) && FieldHasValue(formData[`AftTxReading${i + 2}`])) {
            deviationValues.push(DeviationAftFormula(formName, formData, formData[`AftExpectedReading${i + 2}`], formData[`AftTxReading${i + 2}`]));
        }
    }
    if (FieldHasValue(formData.F101AftTolerance) && deviationValues.length > totalNumberOfFields) {
        if (Math.max(...deviationValues) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};

export const DeviationAltFormula = (formName, formData, expectedReadingAlt, actualTxReadingAlt) => {
    if (FieldHasValue(actualTxReadingAlt)) {
        if ((FieldHasValue(formData.F980UnitsUsed) === 'HMI') && FieldHasValue(formData.F22HmiUrv)
            && FieldHasValue(formData.F21HmiLrv) && FieldHasValue(expectedReadingAlt)) {
            return ExponentialToDecimal((Math.round((100 * Math.abs(ValidateNumberField(actualTxReadingAlt) - ValidateNumberField(expectedReadingAlt))
                / (ValidateNumberField(formData.F22HmiUrv) - ValidateNumberField(formData.F21HmiLrv))) * 100)) / 100);
        }
        if (FieldHasValue(formData.F26InstrumentUrv)
            && FieldHasValue(formData.F25InstrumentLrv) && FieldHasValue(expectedReadingAlt)) {
            return ExponentialToDecimal((Math.round((100 * Math.abs(ValidateNumberField(actualTxReadingAlt) - ValidateNumberField(expectedReadingAlt))
                / (ValidateNumberField(formData.F26InstrumentUrv) - ValidateNumberField(formData.F25InstrumentLrv))) * 100)) / 100);
        }
    }
    return '';
};

export const AsLeftResultFormula = (formName, formData) => {
    const deviationValues = [];
    for (let i = 0; i <= totalNumberOfFields; i += 1) {
        if (FieldHasValue(formData[`AltExpectedReading${i + 2}`]) && FieldHasValue(formData[`AltTxReading${i + 2}`])) {
            deviationValues.push(DeviationAltFormula(formName, formData, formData[`AltExpectedReading${i + 2}`], formData[`AltTxReading${i + 2}`]));
        }
    }
    if (FieldHasValue(formData.F101AftTolerance) && deviationValues.length > totalNumberOfFields) {
        if (Math.max(...deviationValues) <= ValidateNumberField(formData.F101AftTolerance)) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
