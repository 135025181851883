import { formatDate } from 'helpers/DateFormatter';

export const UserInformationSectionData = (selectedTag, userName, formValues) => {
    const createdDate = formValues?.CreatedDate ? formatDate(new Date(formValues?.CreatedDate)) : '';
    const fontFamily = '"Open Sans", sans-serif';
    return { rows: [
        {
            key: 1,
            fields: [{
                key: 'Nodata',
                label: '',
                value: '',
                labelWidth: '7rem'
            },
            {
                key: 'CreatedBy',
                label: 'Created By',
                value: formValues?.Rowid ? formValues?.CreatedBy : userName?.name,
                labelWidth: '8rem',
                marginTop: '10px',
                paddingLeft: '13px',
                labelfontSize: '10pt',
                labelfontFamily: fontFamily,
                fontWeight: '550'
            },
            {
                key: 'CreatedDate',
                label: 'Created Date',
                value: formValues?.Rowid ? createdDate : formatDate(new Date()),
                labelWidth: '8rem',
                marginTop: '10px',
                paddingLeft: '17px',
                labelfontSize: '10pt',
                labelfontFamily: fontFamily,
                fontWeight: '550'
            },
            {
                key: 'Nodata1',
                label: '',
                value: ''
            },
            {
                key: 'ModifiedBy',
                label: 'Modified By',
                value: formValues?.ModifiedBy, //  ?? userName?.name
                labelWidth: '8rem',
                marginTop: '10px',
                paddingLeft: '13px',
                labelfontSize: '10pt',
                labelfontFamily: fontFamily,
                fontWeight: '550'
            },
            {
                key: 'LastModifiedDate',
                label: 'Last Modified Date',
                value: formValues?.LastModifiedDate ? formatDate(new Date(formValues.LastModifiedDate)) : '', // dd-mmm-yyyy HH:mm AM/PM, // formatDate(new Date())
                labelWidth: '8rem',
                marginTop: '10px',
                paddingLeft: '17px',
                labelfontSize: '10pt',
                labelfontFamily: fontFamily,
                fontWeight: '550'
            }]
        }
    ]

};
};
