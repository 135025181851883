import { MatchSRA, Trim } from 'helpers/Validation';

export const AdditionalInformationDataP81 = (selectedTag, formData, selectedTrfId) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F86Mi',
                value: formData.F86Mi ?? selectedTag.Mi,
            },
            {
                key: 'F87MiDescription',
                value: formData.F87MiDescription,
            },
            {
                key: 'F88Mp',
                value: formData.F88Mp ?? selectedTag.MaintenanceItemPm,
            },
            {
                key: 'F89Mo',
                value: formData.F89Mo ?? selectedTag.HdWorkOrder,
            },
            {
                key: 'F91TrfId',
                value: 'P8-1',
            },
            {
                key: 'F92RegionDocId',
                value: formData.F92RegionDocId ?? selectedTag.BpRegion,
            },
            {
                key: 'F335StandAlone',
                value: MatchSRA(formData?.TrfId) ?? MatchSRA(selectedTrfId) ?? MatchSRA(Trim(selectedTag?.TrfId)) ? 'No' : 'Yes',
            },
            {
                key: 'RowId',
                value: formData.RowId,
            }]
        }
    ]

});
