import { SraPassFailFormula } from './P81.formulas';
import { TestResultDropdownOptions, TestAsFoundResultValue } from '../../../TestResultComponent/TestResult.formulas';

export const TestResultSectionDataP81StandAlone = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F85TestResult',
                    label: 'Test Result',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 4,
                    labelWidth: '26%',
                    width: '64%',
                    direction: 'rtl'
                },
                {
                    key: 'F334SraPassFailLabel',
                    label: 'As Found Test',
                    xs: 2,
                    labelWidth: '96%',
                    fontWeight: 600,
                    fontSize: '9pt',
                    textAlign: 'end',
                    padding: '5px 0 0 0',
                    isLabel: true
                },
                {
                    key: 'F334SraPassFail',
                    value: SraPassFailFormula(formData),
                    readOnly: true,
                    isCalculatedNew: true,
                    xs: 2,
                    labelWidth: '0',
                    width: '91%',
                    textAlign: 'center',
                }
            ]
        },

    ]
});
