import { EngineeringUnit } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../C11/C11.data';
import { labelWidth } from '../P710/P710.data';

export const HMISectionDataM611 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F370HmistatusPriorToTestLabel',
                    label: 'HMI Status Prior to Test',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: '4px 0 0 0'
                },
                {
                    key: 'F370HmistatusPriorToTest',
                    value: formData.F370HmistatusPriorToTest,
                    isDropdown: true,
                    isRequired: true,
                    placeholder: 'Select HMI Status',
                    options: [{ name: 'Select HMI Status', value: '' },
                    { name: 'NORMAL', value: 'NORMAL' },
                    { name: 'ALARM', value: 'ALARM' },
                    { name: 'FAULT', value: 'FAULT' }],
                    labelWidth: '0%',
                    width: '100%',
                    height: '1.6rem',
                    inputTextAlign: 'center',
                    paddingRightPlaceholder: '15px',
                    xs: 2
                },
                {
                    key: 'F435DcsControlTagLabel',
                    label: 'DCS Control Tag/Address',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F435DcsControlTag',
                    value: selectedTag.DateTested ? formData?.F435DcsControlTag : formData?.F435DcsControlTag ?? selectedTag?.DcsControlTag,
                    xs: 2,
                    direction: 'rtl',
                    inputWidth: '100%',
                    labelWidth: '0%',
                    marginRight: '0',
                    textAlign: 'center',
                    maxLength: '40',
                    isAutoGrow: true,
                    marginTop: '0',
                },
            ]
        },
        {
            key: 2,
            fields: [{
                key: 'F371DetectorLocalStatusPriorToTestLabel',
                label: 'Detector Local Status Prior to Test',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: '4px 0 0 0'
            },
            {
                key: 'F371DetectorLocalStatusPriorToTest',
                value: formData.F371DetectorLocalStatusPriorToTest,
                isDropdown: true,
                placeholder: 'Select Detector Local Status',
                options: [{ name: 'Select Detector Local Status', value: '' },
                { name: 'NORMAL', value: 'NORMAL' },
                { name: 'ALARM', value: 'ALARM' },
                { name: 'FAULT', value: 'FAULT' }],
                labelWidth: '0%',
                width: '100%',
                inputTextAlign: 'center',
                borderTop: '0',
                paddingRightPlaceholder: '15px',
                xs: 2,
                height: '1.6rem'
            },
            {
                key: 'F28InstrumentEngineeringUnitLabel',
                label: EngineeringUnit.INSTRUMENT_ENGINEERING_UNIT_EU,
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'right',
                labelWidth,
                padding: paddingLabel,
            },
            {
                key: 'F28InstrumentEngineeringUnit',
                value: selectedTag.DateTested ? formData?.F28InstrumentEngineeringUnit : formData?.F28InstrumentEngineeringUnit ?? selectedTag?.TsprInstrumentEngineeringUnits,
                xs: 2,
                direction: 'rtl',
                inputWidth: '100%',
                labelWidth: '0%',
                marginRight: '0',
                textAlign: 'center',
                maxLength: '17',
                isAutoGrow: true,
                marginTop: '0',
                isRequired: true
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F20SpanLabel',
                label: 'Span',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                fontSize: '9pt',
                padding: paddingLabel
            },
            {
                key: 'F20Span',
                value: selectedTag.DateTested
                    ? formData?.F20Span : formData?.F20Span ?? selectedTag?.Span,
                xs: 2,
                inputWidth: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                isAutoGrow: true,
                marginRight: '0',
                maxLength: '30',
                marginBottom: '15px'
            }]
        }
    ]
});
