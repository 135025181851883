import { ValidateField } from 'helpers/Validation';
import {
    AsLeftTableF63Hmi2Inst1Formula,
    AsLeftTableF64Hmi2Inst2Formula,
    AsLeftTableF65Hmi2Inst3Formula,
    AsLeftTableF78Deviation2Eu1Formula,
    AsLeftTableF79Deviation2Eu2Formula,
    AsLeftTableF80Deviation2Eu3Formula,
    TestResultF81AsLeftResultFormula
} from './P851.formulas';
import { paddingAF } from '../M71/M71.data';
import { isEnabledcondition } from '../M611/M611.data';

export const AsLeftTestTableSectionDataP851 = (selectedTag, formData) => {
    const disableField = formData.F61AsFoundResult === 'PASS' && formData.F281AftCalibrationNeeded === 'NO';
    return ({
        rows: [
            {
                key: 1,
                fields: [{
                    label: 'As-Left Test',
                    isLabel: true,
                    fontWeight: '700',
                    padding: '5px 0 0 8px',
                    xs: 12,
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'AsLeftTestHMIINST',
                    label: '% - HMI - Inst.',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: '5px 0 5px 8px',
                    fontSize: '9pt'
                },
                {
                    key: 'GaugeReading',
                    label: 'Gauge Reading',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    labelWidth: '100%',
                    textAlign: 'center',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'TXReading',
                    label: 'TX Reading',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    labelWidth: '100%',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'HMIReading',
                    label: 'HMI Reading',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    labelWidth: '100%',
                    textAlign: 'center',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'mAReading',
                    label: 'mA Reading',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    labelWidth: '100%',
                    textAlign: 'center',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation (EU)',
                    isLabel: true,
                    fontWeight: '700',
                    xs: 2,
                    textAlign: 'center',
                    padding: paddingAF,
                    fontSize: '9pt',
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F63Hmi2Inst1',
                    xs: 2,
                    value: !disableField
                        ? AsLeftTableF63Hmi2Inst1Formula(formData) : '',
                    isDisabled: disableField,
                    borderRight: '0',
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F71Gauge2Reading1',
                    value: isEnabledcondition(ValidateField(formData.F71Gauge2Reading1), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F69Tx2Reading1',
                    value: isEnabledcondition(ValidateField(formData.F69Tx2Reading1), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F72Hmi2Reading1',
                    value: isEnabledcondition(ValidateField(formData.F72Hmi2Reading1), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F75MA2Reading1',
                    value: !disableField ? ValidateField(formData.F75MA2Reading1) : '',
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F78Deviation2Eu1',
                    value: isEnabledcondition(AsLeftTableF78Deviation2Eu1Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    readOnly: true,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F64Hmi2Inst2',
                    xs: 2,
                    value: isEnabledcondition(AsLeftTableF64Hmi2Inst2Formula(formData), disableField),
                    isDisabled: disableField,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F72Gauge2Reading2',
                    value: isEnabledcondition(ValidateField(formData.F72Gauge2Reading2), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F70Tx2Reading2',
                    value: isEnabledcondition(ValidateField(formData.F70Tx2Reading2), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F73Hmi2Reading2',
                    value: isEnabledcondition(ValidateField(formData.F73Hmi2Reading2), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F76MA2Reading2',
                    value: isEnabledcondition(ValidateField(formData.F76MA2Reading2), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F79Deviation2Eu2',
                    value: isEnabledcondition(AsLeftTableF79Deviation2Eu2Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    readOnly: true,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F65Hmi2Inst3',
                    xs: 2,
                    value: isEnabledcondition(AsLeftTableF65Hmi2Inst3Formula(formData), disableField),
                    isDisabled: disableField,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: 'F73Gauge2Reading3',
                    value: isEnabledcondition(ValidateField(formData.F73Gauge2Reading3), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F71Tx2Reading3',
                    value: isEnabledcondition(ValidateField(formData.F71Tx2Reading3), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F74Hmi2Reading3',
                    value: isEnabledcondition(ValidateField(formData.F74Hmi2Reading3), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F77MA2Reading3',
                    value: isEnabledcondition(ValidateField(formData.F77MA2Reading3), disableField),
                    isRequired: !disableField,
                    isDisabled: disableField,
                    readOnly: disableField,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F80Deviation2Eu3',
                    value: isEnabledcondition(AsLeftTableF80Deviation2Eu3Formula(formData), disableField),
                    isDisabled: disableField,
                    xs: 2,
                    readOnly: true,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px',
                }]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'NoData2',
                        xs: 4,
                        isLabel: true,
                        padding: '5px 25px 18.5px 0'
                    },
                    {
                        key: 'F81AsLeftResultLabel',
                        value: 'As-Left Result',
                        readOnly: true,
                        xs: 2,
                        isLabelBold: true,
                        textAlign: 'end',
                        labelWidth: '0',
                        width: '100%',
                        height: '1.08rem',
                        borderRight: '0',
                        borderTop: '0',
                        borderLeft: disableField ? '0' : '',
                        borderBottom: '0',
                    },
                    {
                        key: 'F81AsLeftResult',
                        value: TestResultF81AsLeftResultFormula(formData),
                        readOnly: true,
                        isCalculatedNew: true,
                        isDisabled: disableField,
                        xs: 2,
                        labelWidth: '0',
                        width: '100%',
                        height: '1.08rem',
                        borderTop: disableField ? '' : '0',
                        inputMarginRight: '-2px',
                        borderBottom: '0',
                        textAlign: 'center',
                        marginBottom: '4px'
                    }
                ]
            }
        ]
    });
};
