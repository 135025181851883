import { valveToLouverDamper } from '../../Sections.constants';

const manufacturerLabel = (formName) => `Manufacturer - ${valveToLouverDamper(formName)}`;
const modelLabel = (formName) => `Model - ${valveToLouverDamper(formName)}`;
const manufactureActuatorLabel = 'Manufacturer - Actuator';
const modelActuatorLabel = 'Model - Actuator';
const manufacturerSolenoidLabel = 'Manufacturer - Solenoid';
const modelSolenoidLabel = 'Model - Solenoid';
const manufacturerPositionLabel = 'Manufacturer - Position transmitter';
const modelPositionLabel = 'Model - Position transmitter';
const maxLengthManufacture = '30';
const maxLengthModel = '65';
const manufacturer = (selectedTag, formData) => selectedTag.DateTested ? formData?.F3Manufacture : formData?.F3Manufacture ?? selectedTag?.TsprManufacturer;
const model = (selectedTag, formData) => selectedTag.DateTested ? formData?.F6Model : formData?.F6Model ?? selectedTag?.TsprModel;
const manufacturerValve = (selectedTag, formData) => selectedTag.DateTested ? formData?.F320ManufacturerValve : formData?.F320ManufacturerValve ?? selectedTag?.Manufacturer2;
const modelValve = (selectedTag, formData) => selectedTag.DateTested ? formData?.F329ModelValve : formData?.F329ModelValve ?? selectedTag?.Model2;
const manufacturerActuator = (selectedTag, formData) => selectedTag.DateTested ? formData?.F321ManufacturerActuator
    : formData?.F321ManufacturerActuator ?? selectedTag?.Manufacturer3;
const modelActuator = (selectedTag, formData) => selectedTag.DateTested ? formData?.F330ModelActuator : formData?.F330ModelActuator ?? selectedTag?.Model3;
const manufacturerSolenoid = (selectedTag, formData) => selectedTag.DateTested ? formData?.F322ManufacturerSolenoid
    : formData?.F322ManufacturerSolenoid ?? selectedTag?.Manufacturer4;
const modelSolenoid = (selectedTag, formData) => selectedTag.DateTested ? formData?.F331ModelSolenoid
    : formData?.F331ModelSolenoid ?? selectedTag?.Model4;
const manufacturerIppositioner = (selectedTag, formData) => selectedTag.DateTested ? formData?.F323ManufacturerIppositioner
    : formData?.F323ManufacturerIppositioner ?? selectedTag?.Manufacturer5;
const modelIppositioner = (selectedTag, formData) => selectedTag.DateTested ? formData?.F332ModelIppositioner
    : formData?.F332ModelIppositioner ?? selectedTag?.Model5;
const manufacturerPositionTransmitter = (selectedTag, formData) => selectedTag.DateTested ? formData?.F324ManufacturerPositionTransmitter
    : formData?.F324ManufacturerPositionTransmitter ?? selectedTag?.Manufacturer6;
const modelPositionTransmitter = (selectedTag, formData) => selectedTag.DateTested ? formData?.F333ModelPositionTransmitter
    : formData?.F333ModelPositionTransmitter ?? selectedTag?.Model6;

export const ManufacturerModelSectionData = (selectedTag, formData, formName) => {
    const widthLabel = '36%';
    const inputwidth = '64%';
    const bottomMargin = '0px';
    const descInputWidthLabel = '68%';
    const descWidthLabel = '32%';
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F3Manufacture',
                    label: 'Manufacturer',
                    value: manufacturer(selectedTag, formData),
                    inputWidth: inputwidth,
                    labelWidth: widthLabel,
                    xs: 6,
                    marginBottom: bottomMargin,
                    isAutoGrow: true,
                    maxLength: maxLengthManufacture,
                    marginRight: '0px'
                },
                {
                    key: 'F6Model',
                    label: 'Model',
                    value: model(selectedTag, formData),
                    marginLeft: '2rem',
                    inputWidth: descInputWidthLabel,
                    labelWidth: descWidthLabel,
                    xs: 6,
                    marginBottom: bottomMargin,
                    isAutoGrow: true,
                    maxLength: maxLengthModel,
                    marginRight: '0px'
                }]
            }

        ]
    };
};

export const ManufacturerModelSectionDataP610 = (selectedTag, formName, formData) => ({
    rows: [{
        key: 1,
        fields: [{
            key: 'F3Manufacture',
            label: 'Manufacturer',
            value: manufacturer(selectedTag, formData),
            inputWidth: '58%',
            labelWidth: '43%',
            height: '1.2rem',
            xs: 6,
            isAutoGrow: true,
            marginRight: '0',
            maxLength: maxLengthManufacture
        },
        {
            key: 'F6Model',
            label: 'Model',
            value: model(selectedTag, formData),
            inputWidth: '66%',
            labelWidth: '34.4%',
            marginLeft: '0.8rem',
            marginRight: '0.1rem',
            xs: 6,
            isAutoGrow: true,
            maxLength: maxLengthModel
        }]
    },
    {
        key: 2,
        fields: [{
            key: 'F320ManufacturerValve',
            label: manufacturerLabel(formName),
            value: manufacturerValve(selectedTag, formData),
            inputWidth: '58%',
            labelWidth: '43%',
            height: '1.2rem',
            marginTop: '10px',
            xs: 6,
            maxLength: '25',
            marginRight: '0',
            isAutoGrow: true,
        },
        {
            key: 'F329ModelValve',
            label: modelLabel(formName),
            value: modelValve(selectedTag, formData),
            marginRight: '0.1rem',
            inputWidth: '66%',
            labelWidth: '34.4%',
            marginLeft: '0.8rem',
            height: '1.2rem',
            marginTop: '10px',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
        }]
    },
    {
        key: 3,
        fields: [{
            key: 'F321ManufacturerActuator',
            label: manufactureActuatorLabel,
            value: manufacturerActuator(selectedTag, formData),
            inputWidth: '58%',
            labelWidth: '43%',
            marginRight: '0',
            height: '1.2rem',
            borderTop: '0',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
        },
        {
            key: 'F330ModelActuator',
            label: modelActuatorLabel,
            value: modelActuator(selectedTag, formData),
            marginRight: '0.1rem',
            inputWidth: '66%',
            labelWidth: '34.4%',
            marginLeft: '0.8rem',
            height: '1.2rem',
            borderTop: '0',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
        }]
    },
    {
        key: 4,
        fields: [{
            key: 'F322ManufacturerSolenoid',
            label: manufacturerSolenoidLabel,
            value: manufacturerSolenoid(selectedTag, formData),
            inputWidth: '58%',
            labelWidth: '43%',
            height: '1.2rem',
            borderTop: '0',
            marginRight: '0',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
        },
        {
            key: 'F331ModelSolenoid',
            label: modelSolenoidLabel,
            value: modelSolenoid(selectedTag, formData),
            marginRight: '0.1rem',
            inputWidth: '66%',
            labelWidth: '34.4%',
            height: '1.2rem',
            borderTop: '0',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
            marginLeft: '0.8rem',
        }]
    },
    {
        key: 5,
        fields: [{
            key: 'F323ManufacturerIppositioner',
            label: 'Manufacturer - I/P positioner',
            value: manufacturerIppositioner(selectedTag, formData),
            inputWidth: '58%',
            labelWidth: '43%',
            height: '1.2rem',
            borderTop: '0',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
            marginRight: '0',
        },
        {
            key: 'F332ModelIppositioner',
            label: 'Model - I/P positioner',
            value: modelIppositioner(selectedTag, formData),
            marginRight: '0.1rem',
            marginLeft: '0.8rem',
            inputWidth: '66%',
            labelWidth: '34.4%',
            height: '1.2rem',
            borderTop: '0',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
        }]
    },
    {
        key: 6,
        fields: [{
            key: 'F324ManufacturerPositionTransmitter',
            label: manufacturerPositionLabel,
            value: manufacturerPositionTransmitter(selectedTag, formData),
            inputWidth: '58%',
            labelWidth: '43%',
            height: '1.2rem',
            borderTop: '0',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
            marginRight: '0',
        },
        {
            key: 'F333ModelPositionTransmitter',
            label: modelPositionLabel,
            value: modelPositionTransmitter(selectedTag, formData),
            marginRight: '0.1rem',
            inputWidth: '66%',
            labelWidth: '34.4%',
            height: '1.2rem',
            borderTop: '0',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
            marginLeft: '0.8rem',
        }]
    },
    {
        key: 7,
        fields: [{
            key: 'F325ManufacturerLimitSwitches',
            label: 'Manufacturer - Limit switch',
            value: selectedTag.DateTested ? formData?.F325ManufacturerLimitSwitches : formData?.F325ManufacturerLimitSwitches ?? selectedTag?.Manufacturer7,
            inputWidth: '58%',
            labelWidth: '43%',
            height: '1.2rem',
            borderTop: '0',
            xs: 6,
            maxLength: '25',
            isAutoGrow: true,
            marginRight: '0',
            marginBottom: '20px',
        },
        {
            key: 'F334ModelLimitSwitches',
            label: 'Model - Limit switch',
            value: selectedTag.DateTested ? formData?.F334ModelLimitSwitches : formData?.F334ModelLimitSwitches ?? selectedTag?.Model7,
            marginRight: '0.1rem',
            inputWidth: '66%',
            labelWidth: '34.4%',
            height: '1.2rem',
            borderTop: '0',
            xs: 6,
            marginLeft: '0.8rem',
            maxLength: '25',
            isAutoGrow: true,
            marginBottom: '20px',
        }]
    }
    ]
});

export const ManufacturerModelSectionDataM613 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F3Manufacture',
                label: 'Manufacturer',
                value: manufacturer(selectedTag, formData),
                inputWidth: '58%',
                labelWidth: '43%',
                xs: 6,
                isAutoGrow: true,
                borderBottom: '0',
                marginRight: '0',
                rows: 3,
                maxLength: maxLengthManufacture
            },
            {
                key: 'F6Model',
                label: 'Model',
                value: model(selectedTag, formData),
                marginRight: '0.5rem',
                inputWidth: '66%',
                borderBottom: '0',
                labelWidth: '34.4%',
                marginLeft: '0.8rem',
                xs: 6,
                isAutoGrow: true,
                rows: 3,
                maxLength: maxLengthModel
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F359ManufacturerDamper',
                label: 'Manufacturer - Damper',
                value: selectedTag.DateTested ? formData?.F359ManufacturerDamper : formData?.F359ManufacturerDamper ?? selectedTag?.Manufacturer2,
                inputWidth: '58%',
                labelWidth: '43%',
                borderBottom: '0',
                marginTop: '10px',
                xs: 6,
                maxLength: '25',
                isAutoGrow: true,
                marginRight: '0',
            },
            {
                key: 'F360ModelDamper',
                label: 'Model - Damper',
                value: selectedTag.DateTested ? formData?.F360ModelDamper : formData?.F360ModelDamper ?? selectedTag?.Model2,
                marginRight: '0.5rem',
                borderBottom: '0',
                inputWidth: '66%',
                labelWidth: '34.4%',
                marginLeft: '0.8rem',
                marginTop: '10px',
                xs: 6,
                maxLength: '25',
                isAutoGrow: true,
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F321ManufacturerActuator',
                label: manufactureActuatorLabel,
                value: manufacturerActuator(selectedTag, formData),
                inputWidth: '58%',
                labelWidth: '43%',
                borderBottom: '0',
                xs: 6,
                maxLength: '25',
                isAutoGrow: true,
                marginRight: '0',
            },
            {
                key: 'F330ModelActuator',
                label: modelActuatorLabel,
                value: modelActuator(selectedTag, formData),
                marginRight: '0.5rem',
                borderBottom: '0',
                inputWidth: '66%',
                labelWidth: '34.4%',
                marginLeft: '0.8rem',
                xs: 6,
                maxLength: '25',
                isAutoGrow: true,
            }]
        },
        {
            key: 4,
            fields: [{
                key: 'F322ManufacturerSolenoid',
                label: manufacturerSolenoidLabel,
                value: manufacturerSolenoid(selectedTag, formData),
                inputWidth: '58%',
                labelWidth: '43%',
                borderBottom: '0',
                xs: 6,
                maxLength: '25',
                isAutoGrow: true,
                marginRight: '0',
            },
            {
                key: 'F331ModelSolenoid',
                label: modelSolenoidLabel,
                value: modelSolenoid(selectedTag, formData),
                marginRight: '0.5rem',
                borderBottom: '0',
                inputWidth: '66%',
                xs: 6,
                maxLength: '25',
                labelWidth: '34.4%',
                marginLeft: '0.8rem',
                isAutoGrow: true,
            }]
        },
        {
            key: 5,
            fields: [{
                key: 'F323ManufacturerIppositioner',
                label: 'Manufacturer - Positioner',
                value: manufacturerIppositioner(selectedTag, formData),
                inputWidth: '58%',
                labelWidth: '43%',
                borderBottom: '0',
                xs: 6,
                maxLength: '25',
                isAutoGrow: true,
                marginRight: '0',
            },
            {
                key: 'F332ModelIppositioner',
                label: 'Model - Positioner',
                value: modelIppositioner(selectedTag, formData),
                marginRight: '0.5rem',
                inputWidth: '66%',
                labelWidth: '34.4%',
                marginLeft: '0.8rem',
                borderBottom: '0',
                xs: 6,
                maxLength: '25',
                isAutoGrow: true,
            }]
        },
        {
            key: 6,
            fields: [{
                key: 'F324ManufacturerPositionTransmitter',
                label: manufacturerPositionLabel,
                value: manufacturerPositionTransmitter(selectedTag, formData),
                inputWidth: '58%',
                labelWidth: '43%',
                marginBottom: '15px',
                xs: 6,
                maxLength: '25',
                isAutoGrow: true,
                marginRight: '0',
            },
            {
                key: 'F333ModelPositionTransmitter',
                label: modelPositionLabel,
                value: modelPositionTransmitter(selectedTag, formData),
                marginRight: '0.5rem',
                inputWidth: '66%',
                labelWidth: '34.4%',
                marginLeft: '0.8rem',
                marginBottom: '15px',
                xs: 6,
                maxLength: '25',
                isAutoGrow: true,
            }]
        },
    ]
});

export const selectManufacturerModelTemplateForForm = (selectedTag, formName, formData) => {
    let filterViewData = ManufacturerModelSectionData(selectedTag, formData, formName);
    if (['P6-10', 'P7-6', 'P6-14.2', 'P6-23.2', 'C1-2', 'P7-31', 'P7-16', 'P6-13', 'P7-27', 'P6-14'].includes(formName)) {
        filterViewData = ManufacturerModelSectionDataP610(selectedTag, formName, formData);
    } else if (formName === 'M6-13') {
        filterViewData = ManufacturerModelSectionDataM613(selectedTag, formData);
    }
    return filterViewData;
};
