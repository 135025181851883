import styled from 'styled-components';

export const TRFFilterWrapper = styled.div`
.MuiFormControl-root {
    text-align: 'left';
    width: '75%';
    font-size: '10px';
    padding: '5px';
    height: '2rem';
    outline: none;
}
.MuiInputBase-input {
    font-size: 10.5px;
}
.MuiInputBase-fullWidth {
    width: 98%;
    height: 2rem;
    margin-right: 15px;
}
.MuiOutlinedInput-root {
    position: relative;
    border-radius: 0;
    height: 2rem;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
}
.MuiAutocomplete-listbox {
    border: 1px solid #498205;
    font-size: 10.5px;
    font-weight: 500;
    overflow-x: hidden;
    word-wrap: break-word;
}
.MuiAutocomplete-li {
    border: 1px solid #498205;
    font-size: 10.5px;
    font-weight: 500;
    overflow-x: hidden;
    word-wrap: break-word;
}
.rmsc .multi-select{
    height: 2rem;
}

.rmsc .dropdown-container{
    border-color:  #498205;
    border-width: 2px;
    border-radius: 0;
    height: 2rem;
    width: 98%;
}
.rmsc .dropdown-content{
    z-index: 100;
}
.rmsc .panel-content{
    border: 1px solid #498205;
    border-radius: 0;
    font: inherit;
    font-size: 10.5px;
    font-weight: 500;
    overflow-x: hidden;
    word-wrap: break-word;
}
.rmsc .search{
    height: 2rem;
    font-size: 10px;
}
.rmsc .search input{
    height: 2rem;
    font-size: 10.5px;
}
.rmsc .options{
    font-size: 10.5px;
    font-weight: 500;
    overflow-x: hidden;
    word-wrap: break-word;
}
.rmsc .dropdown-container:focus-within{
    border-color:  #498205;
    border-width: 1px;
    border-radius: 0;
    height: 2rem;
    width: 98%;
}
.rmsc .dropdown-heading{
    height: 2.1rem;
    width: 98%;
    padding: 2px;
    align-items: inherit;
}
.MuiSvgIcon-root {
    color: #498205;
}
.rmsc .gray {
    height: 10px;
    width: 10px;
}
.rmsc{
    --rmsc-main: #498205;
}
.rmsc .dropdown-heading .dropdown-heading-value {
    padding-top: 6px;
    padding-left: 3px;
    font-size: 10.5px;
    color: #000000
}

.rmsc .dropdown-heading-dropdown-arrow {
    color: #498205;
    width: 2em;
    fill: currentColor;
    height: 1.9rem;
}

.rmsc .dropdown-search-clear-icon {
    color: #498205;
    width: 1.5em;
    fill: currentColor;
    height: 2.5em;

}

.MuiAutocomplete-listbox li {
    padding-left: 5px;
    word-wrap: break-word;
    width: 100%;

}
.MuiAutocomplete-listbox li:hover {
    background-color: #D3D3D3;
    width: 100%;
}

.MuiOutlinedInput-notchedOutline{
    border-color:  #498205;
    border-width: 2px;
}
.MuiIconButton-root {
    color: #498205;
}
.MuiInputBase-inputTypeSearch {
    height: 10px;
}
.MuiAutocomplete-inputRoot {
    flex-wrap: nowrap;
}
.MuiAutocomplete-option {
    min-height: auto;
    width: max-content;
}
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 30px;
}
.MuiGrid-grid-xs-2 {
    padding: 2px;
}
.MuiAutocomplete-noOptions {
    border: 1px solid #498205;
    font-size: 11px;
    font-weight: 500;
    overflow-x: hidden;
    word-wrap: break-word;
}
`;

export const DatePickerStyle = styled.div`
width: 100%;
margin-left: -1.7rem;
`;
