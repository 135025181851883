import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import _ from 'lodash';
import { APIEndpoints } from 'helpers/APILists';
import { formatDate } from 'helpers/DateFormatter';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import TextInput from '../../elements/TextInput';
import { useStyles } from '../AdminForm.styled';
import { trackErrors } from '../helper';
import DropdownInput from '../../elements/DropdownInput';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { strEquals } from '../../AdminScreen.helper';

const AdGroupTypeForm = ({ isOpen, title, onClose, rowData, onRecordSubmit }) => {
    const classes = useStyles();
    const [createRowData, setCreateRowData] = useState(rowData);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [typeError, setTypeError] = useState('');
    const [adGroupTypeData, setadGroupTypeData] = useState([]);
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();

    const FooterButtonVisible = !_.isEqual(rowData, createRowData) && !typeError && createRowData.Code
        && createRowData.Description;

    useEffect(() => {
        axios.get(APIEndpoints.ADMIN_GetAdGroupType)
            .then((res) => {
                setadGroupTypeData(res.data.map((x) => ({
                    AdGroupTypeId: x.AdGroupTypeId,
                    Code: x.Code
                })));
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    }, []);

    const handleInputChange = ((e) => setCreateRowData((prev) => (
        {
            ...prev,
            [e.target.name]: e.target.value
        }
    )));
    const handleBlur = () => {
        setTypeError('');
        if (adGroupTypeData?.find(x => strEquals(x?.Code, createRowData?.Code)
            && x?.AdGroupTypeId !== createRowData?.AdGroupTypeId)) {
            setTypeError(' *(Code already exists)');
        }
    };
    const handleSubmitButton = () => {
        createRowData.ModifiedBy = userName?.name;
        createRowData.LastModifiedDate = formatDate(new Date());
        if (createRowData.AdGroupTypeId) {
            updateRecord(createRowData);
        } else {
            createRowData.AdGroupTypeId = 0;
            createRowData.CreatedBy = userName?.name;
            createRowData.CreatedDate = formatDate(new Date());
            submitRecord(createRowData);
        }
    };

    const resetButtonHandler = () => {
        setTypeError('');
        setCreateRowData(rowData);
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddAdGroupType, dataRow)
            .then((res) => {
                onClose();
                onRecordSubmit(res.data.AdGroupTypeId);
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const updateRecord = (dataRow) => {
        axios.put(APIEndpoints.ADMIN_UpdateAdGroupType, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.AdGroupTypeId);
                onClose();
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" classes={{ root: classes.headerRoot }} className={classes.header}>{title}</DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={() => handleUnsaveChanges()}
                    handleAlertFunction={onClose}
                />
            )}

            <DialogContent>

                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="Code"
                        title="Code (AD Group Type)"
                        value={createRowData.Code}
                        required
                        handleInputChange={handleInputChange}
                        type="string"
                        errorMsg={typeError}
                        maxLength="20"
                        handleBlur={handleBlur}
                    />
                    <TextInput
                        name="Description"
                        title="Description"
                        value={createRowData.Description}
                        handleInputChange={handleInputChange}
                        type="string"
                        required
                        maxLength="45"
                        isMultiline
                    />
                    <DropdownInput
                        name="Status"
                        title="Status"
                        value={createRowData.Status}
                        options={['Active', 'Inactive']}
                        handleInputChange={handleInputChange}
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '0 10px' }}>
                    <TextInput
                        name="CreatedBy"
                        title="Created By"
                        value={createRowData.CreatedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="CreatedDate"
                        title="Created Date"
                        value={createRowData.CreatedDate || formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="ModifiedBy"
                        title="Last Modified By"
                        value={createRowData.ModifiedBy || userName?.name}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                    <TextInput
                        name="LastModifiedDate"
                        title="Last Modified Date"
                        value={createRowData.LastModifiedDate || formatDate(new Date())}
                        disabled
                        handleInputChange={handleInputChange}
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '10px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        type="string"
                        isMultiline
                        width="1080px"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginRight: '15px', paddingBottom: '18px', paddingTop: '15px', justifyContent: 'space-between' }}>
                <div>
                    <InputLabel className={classes.inputLabel}>
                        <i> Note: This is a lookup record for AD Group Type dropdown options in Security Groups table. </i>
                    </InputLabel>
                </div>
                <div style={{ display: 'flex', gap: '0 8px' }}>
                    {FooterButtonVisible && (
                        <Button
                            classes={{ root: classes.button }}
                            onClick={handleSubmitButton}
                            variant="outlined"
                        >
                            Submit
                        </Button>
                    )}
                    <Button
                        classes={{ root: classes.button }}
                        onClick={resetButtonHandler}
                        variant="outlined"
                    >
                        Reset
                    </Button>
                    <Button
                        classes={{ root: classes.button }}
                        onClick={cancelButtonHandler}
                        variant="outlined"
                    >
                        Close
                    </Button>
                </div>
            </DialogActions>
        </Dialog>

    );
};
export default AdGroupTypeForm;

AdGroupTypeForm.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string,

};
