import { Setpoint } from 'journeys/portal/Forms/Sections.constants';
import {
    setpointNote, paddingLabel, highHighSetpointValue, highSetpointValue, lowSetpointValue, lowLowSetpointValue, highHighSetpointComment, lowSetpointComment,
    lowLowSetpointComment, highSetPointComment, highHighSetpointCommentValue, highSetpointCommentValue, lowLowSetpointCommentValue, lowSetpointCommentValue,
    borderBottomHHDisable, borderBottomDisable, borderBottomLDisable
} from '../C11/C11.data';

export const SetpointSectionDataP720P721 = (selectedTag, formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'remark',
                label: setpointNote,
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '99%',
                padding: '5px 1px 10px 3px',
                fontSize: '8.5pt',
                color: '#ea7c34',
                marginTop: '0'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F240HighHighSetpoint',
                    label: 'High High Setpoint (HMI EU)',
                    value: highHighSetpointValue(selectedTag, formData),
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '50%',
                    width: '50%',
                    height: '1.2rem',
                    direction: 'rtl',
                    textAlign: 'center',
                    marginRight: '0.5rem',
                    marginLeft: '0.2rem',
                    borderBottom: borderBottomHHDisable(selectedTag, formData)
                },

                {
                    key: 'F51HmiReading1',
                    label: Setpoint.HMI_READING_EU,
                    value: formData.F51HmiReading1,
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '50%',
                    width: '50%',
                    height: '1.2rem',
                    marginRight: '0.5rem',
                    textAlign: 'center',
                    borderBottom: borderBottomHHDisable(selectedTag, formData)
                },
                {
                    key: 'F750SystemReading1',
                    label: Setpoint.SYSTEM1_READING,
                    value: formData.F750SystemReading1,
                    type: 'number',
                    maxLength: '10',
                    xs: 4,
                    labelWidth: '50%',
                    width: '50%',
                    height: '1.2rem',
                    marginRight: '0.5rem',
                    textAlign: 'center',
                    borderRight: '0',
                    borderBottom: borderBottomHHDisable(selectedTag, formData)
                }]
        },
        highHighSetpointComment(selectedTag, formData) ? {
            key: 3,
            fields: [
                {
                    key: 'F448HighHighSetpointCommentLabel',
                    label: 'High High Setpoint Comment',
                    xs: 2,
                    textAlign: 'left',
                    fontStyle: 'italic',
                    padding: paddingLabel,
                    isLabel: 'true',
                    labelWidth: '97%',
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F448HighHighSetpointComment',
                    value: highHighSetpointCommentValue(selectedTag, formData),
                    textAlign: 'center',
                    fontStyle: 'italic',
                    isInfo: true,
                    wordBreak: false,
                    width: '100%',
                    height: '1.2rem',
                    padding: paddingLabel,
                    labelWidth: '0',
                    noWrap: true
                }]
        } : {
            key: 3,
            fields: []
        },
        {
            key: 4,
            fields: [{
                key: 'F32HighSetpoint',
                label: 'High Setpoint (HMI EU)',
                value: highSetpointValue(selectedTag, formData),
                xs: 4,
                type: 'number',
                maxLength: '10',
                labelWidth: '50%',
                direction: 'rtl',
                width: '50%',
                height: '1.2rem',
                textAlign: 'center',
                marginRight: '0.5rem',
                marginLeft: '0.2rem',
                borderBottom: borderBottomDisable(selectedTag, formData)
            },
            {
                key: 'F53HmiReading3',
                label: Setpoint.HMI_READING_EU,
                value: formData.F53HmiReading3,
                type: 'number',
                maxLength: '10',
                xs: 4,
                labelWidth: '50%',
                width: '50%',
                textAlign: 'center',
                height: '1.2rem',
                marginRight: '0.5rem',
                borderBottom: borderBottomDisable(selectedTag, formData)
            },
            {
                key: 'F752SystemReading3',
                label: Setpoint.SYSTEM1_READING,
                value: formData.F752SystemReading3,
                type: 'number',
                maxLength: '10',
                xs: 4,
                labelWidth: '50%',
                width: '50%',
                textAlign: 'center',
                height: '1.2rem',
                marginRight: '0.5rem',
                borderRight: '0',
                borderBottom: borderBottomDisable(selectedTag, formData)
            }]
        },
        highSetPointComment(selectedTag, formData) ? {
            key: 5,
            fields: [
                {
                    key: 'F796HighSetpointCommentLabel',
                    label: 'High Setpoint Comment',
                    xs: 2,
                    textAlign: 'left',
                    fontStyle: 'italic',
                    padding: paddingLabel,
                    isLabel: 'true',
                    labelWidth: '97%',
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F796HighSetpointComment',
                    value: highSetpointCommentValue(selectedTag, formData),
                    textAlign: 'center',
                    fontStyle: 'italic',
                    isInfo: true,
                    wordBreak: false,
                    width: '100%',
                    height: '1.2rem',
                    padding: paddingLabel,
                    labelWidth: '0',
                    noWrap: true
                }]
        } : {
            key: 5,
            fields: []
        },
        {
            key: 6,
            fields: [{
                key: 'F33LowSetpoint',
                label: 'Low Setpoint (HMI EU)',
                value: lowSetpointValue(selectedTag, formData),
                xs: 4,
                type: 'number',
                maxLength: '10',
                direction: 'rtl',
                labelWidth: '50%',
                width: '50%',
                height: '1.2rem',
                textAlign: 'center',
                marginRight: '0.5rem',
                marginLeft: '0.2rem',
                borderBottom: borderBottomLDisable(selectedTag, formData)
            },
            {
                key: 'F54HmiReading4',
                label: Setpoint.HMI_READING_EU,
                value: formData.F54HmiReading4,
                type: 'number',
                maxLength: '10',
                textAlign: 'center',
                xs: 4,
                height: '1.2rem',
                labelWidth: '50%',
                width: '50%',
                borderBottom: borderBottomLDisable(selectedTag, formData),
                marginRight: '0.5rem'
            },
            {
                key: 'F753SystemReading4',
                label: Setpoint.SYSTEM1_READING,
                value: formData.F753SystemReading4,
                type: 'number',
                maxLength: '10',
                textAlign: 'center',
                xs: 4,
                height: '1.2rem',
                labelWidth: '50%',
                width: '50%',
                marginRight: '0.5rem',
                borderRight: '0',
                borderBottom: borderBottomLDisable(selectedTag, formData)
            }]
        },
        lowSetpointComment(selectedTag, formData) ? {
            key: 7,
            fields: [
                {
                    key: 'F797LowSetpointCommentLabel',
                    label: 'Low Setpoint Comment',
                    xs: 2,
                    textAlign: 'left',
                    padding: paddingLabel,
                    isLabel: 'true',
                    labelWidth: '97%',
                    fontSize: '9pt',
                    fontWeight: '600',
                    fontStyle: 'italic'
                },
                {
                    key: 'F797LowSetpointComment',
                    value: lowSetpointCommentValue(selectedTag, formData),
                    textAlign: 'center',
                    fontStyle: 'italic',
                    isInfo: true,
                    wordBreak: false,
                    width: '100%',
                    height: '1.2rem',
                    padding: paddingLabel,
                    labelWidth: '0',
                    noWrap: true
                }]
        } : {
            key: 7,
            fields: []
        },
        {
            key: 8,
            fields: [
                {
                    key: 'F241LowLowSetpoint',
                    label: 'Low Low Setpoint (HMI EU)',
                    value: lowLowSetpointValue(selectedTag, formData),
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '50%',
                    width: '50%',
                    height: '1.2rem',
                    direction: 'rtl',
                    textAlign: 'center',
                    marginRight: '0.5rem',
                    marginLeft: '0.2rem',
                    borderTop: !lowSetpointComment(selectedTag, formData),
                    borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                    marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px'
                },
                {
                    key: 'F52HmiReading2',
                    label: Setpoint.HMI_READING_EU,
                    value: formData.F52HmiReading2,
                    xs: 4,
                    type: 'number',
                    maxLength: '10',
                    labelWidth: '50%',
                    width: '50%',
                    height: '1.2rem',
                    marginRight: '0.5rem',
                    textAlign: 'center',
                    borderTop: !lowSetpointComment(selectedTag, formData),
                    borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                    marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px'
                },
                {
                    key: 'F751SystemReading2',
                    label: Setpoint.SYSTEM1_READING,
                    value: formData.F751SystemReading2,
                    type: 'number',
                    maxLength: '10',
                    xs: 4,
                    labelWidth: '50%',
                    width: '50%',
                    height: '1.2rem',
                    marginRight: '0.5rem',
                    textAlign: 'center',
                    borderRight: '0',
                    borderTop: !lowSetpointComment(selectedTag, formData),
                    borderBottom: lowLowSetpointComment(selectedTag, formData) ? '' : 0,
                    marginBottom: lowLowSetpointComment(selectedTag, formData) ? '' : '4px'
                }]
        },
        lowLowSetpointComment(selectedTag, formData) ? {
            key: 9,
            fields: [
                {
                    key: 'F449LowLowSetpointCommentLabel',
                    label: 'Low Low Setpoint Comment',
                    xs: 2,
                    textAlign: 'left',
                    padding: '5px 0 3px 3px',
                    isLabel: 'true',
                    labelWidth: '97%',
                    fontSize: '9pt',
                    fontWeight: '600',
                    fontStyle: 'italic'
                },
                {
                    key: 'F449LowLowSetpointComment',
                    value: lowLowSetpointCommentValue(selectedTag, formData),
                    textAlign: 'center',
                    fontStyle: 'italic',
                    isInfo: true,
                    wordBreak: false,
                    width: '100%',
                    height: '1.2rem',
                    padding: '5px 0 3px 3px',
                    labelWidth: '0',
                    noWrap: true
                }]
        } : {
            key: 9,
            fields: []
        }
    ]
});
